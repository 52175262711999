import React, { useState } from 'react';
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router';
import {
  Form,
  Button,
  Container,
  Col,
  Row
} from "react-bootstrap";
import { generateRoute } from '../services/routes';
import {
  getContent
} from '../services/language';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { createAppointmentCode, getAndParseFinalUtilityProviderFromAppState, parseYcbmLeadProvider } from '../services/strings';
import { getIsZipCodeHouston, getIsZipCodeTexasAndInServiceArea, getStateFromZip, getIsZipCodeCaliforniaAndInServiceArea, getIsValidAreaForGenerationSolar, getIsZipCodeNevadaAndInServiceArea } from '../services/zipCodes';

function ChannelPartnersPage(props) {
  const history = useHistory();
  const params = useParams();
  const queryString = useLocation().search;

  function useQuery() {
    return new URLSearchParams(queryString);
  }
  const query = useQuery();
  // let firstName = query.get("firstName");
  // if (!firstName) { firstName = '' }
  // let lastName = query.get("lastName");
  // if (!lastName) { lastName = '' }
  // let email = query.get("email");
  // if (!email) { email = '' }
  // let phoneNumber = query.get("phoneNumber");
  // if (!phoneNumber) { phoneNumber = '' }
  // let notes = query.get("notes");
  // if (!notes) { notes = '' }

  const initialState = {
    firstName: query.get("firstName") || '',
    lastName: query.get("lastName") || '',
    email: query.get("email") || '',
    phoneNumber: query.get("phoneNumber") || '',
    notes: ''
  }

  const [state, setState] = useState(initialState);

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    notes
  } = state;

  const { leadId } = params;
  const leadProvider = parseYcbmLeadProvider(params.leadProvider);

  let url = !!window && !!window.location && window.location.href;
  if (!!url) {
    url = encodeURIComponent(url);
  } else {
    url = "none"
  }
  const zipCode = !!props.appState && props.appState.zipCode;
  const zipFromGoogleMapsAddress = !!props.appState && props.appState.zipFromGoogleMapsAddress;
  const isZipCodeHouston = getIsZipCodeHouston(zipFromGoogleMapsAddress);
  const isZipCodeTexas = getIsZipCodeTexasAndInServiceArea(zipFromGoogleMapsAddress || zipCode);
  const isZipCodeCaliforniaAndInServiceArea = getIsZipCodeCaliforniaAndInServiceArea(zipFromGoogleMapsAddress || zipCode);
  const isZipCodeNevadaAndInServiceArea = getIsZipCodeNevadaAndInServiceArea(zipFromGoogleMapsAddress || zipCode);
  console.log({ isZipCodeNevadaAndInServiceArea });
  const isValidAreaforGenerationSolar = getIsValidAreaForGenerationSolar(zipFromGoogleMapsAddress || zipCode);
  const region = !!props.appState && props.appState.region;
  const abbreviation = createAppointmentCode(params.leadProvider, (!!props.appState && !!props.appState.appointmentType) ? props.appState.appointmentType : 'online', (!!props.appState && !!props.appState.zipCode) ? props.appState.zipCode : params.zipCode);
  const hadOptionForInPersonAppointment = !!props.appState ? (!!props.appState.isInPersonZip ? "YES" : "NO") : "ERROR";
  let address = !!props.appState && props.appState.address;
  let lat = !!props.appState && props.appState.lat;
  let lng = !!props.appState && props.appState.lng;
  if (!address && !!params && !!params.address) {
    address = params.address;
  }
  if (!lat && !!params && !!params.lat) {
    lat = params.lat;
  }
  if (!lng && !!params && !!params.lng) {
    lng = params.lng;
  }

  const utilityProvider = getAndParseFinalUtilityProviderFromAppState(props.appState);

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleGoBack = e => {
    e.preventDefault();
    const route = generateRoute(params, "address", null, queryString);
    history.push(route);
  }

  const handleOpenCalendar = (e, type) => {
    e.preventDefault();

    let link = '';
    if (!!type) {
      if (type === "Sungrade") {
        link = `https://sungrade-main.youcanbook.me`;
      } else if (type === "Generation Solar") {
        link = `https://partner-generation-solar.youcanbook.me`;
      } else if (type === "Sunrun Houston Appointments") {
        link = `https://sun-run-houston.youcanbook.me`;
      } else if (type === "Sunrun Houston Appointments 2") {
        link = `https://sun-run-houston-2.youcanbook.me`;
      } else if (type === "Channel Partner 3") {
        link = `https://sungrade-channel-partner-1.youcanbook.me`;
      } else if (type === "Tommy Long") {
        link = `https://aspen-tommy-long.youcanbook.me`;
      } else if (type === "Ted Spradley") {
        link = `https://aspen-ted-spradley.youcanbook.me`;
      } else if (type === "Phillip Jefferson") {
        link = `https://aspen-phillip-jefferson.youcanbook.me`;
      } else if (type === "Aaron Katz") {
        link = `https://aspen-aaron-katz.youcanbook.me`;
      }
    }
    link += `?FNAME=${firstName}&LNAME=${lastName}&EMAIL=${email}&NOTES=${notes}&PHONE=${phoneNumber}&ZIP=${zipCode}&LEADPROVIDER=${leadProvider}&LEADID=${leadId}&REGION=${region}&ADDRESS=${address}&LAT=${lat}&LNG=${lng}&USER_URL=${url}&CODE=${abbreviation}&LANGUAGE=${props.appState.language}&UTILITY=${utilityProvider}&ZIP_GOOGLE_MAPS=${zipFromGoogleMapsAddress}`;
    if (!!link) {
      window.open(link);
    }
  }

  return (
    <Container className="mt-5">
      <Row className="rowWide">
        <Col className="text-center">
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="firstName" className="mb-20">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className="text-center"
                    type="text"
                    placeholder="First name"
                    name="firstName"
                    onChange={handleChange}
                    value={firstName}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="lastName" className="mb-20">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    className="text-center"
                    type="text"
                    placeholder="Last name"
                    name="lastName"
                    onChange={handleChange}
                    value={lastName}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="lastName" className="mb-20">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    className="text-center"
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    value={email}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="phoneNumber" className="mb-20">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    className="text-center"
                    type="tel"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    onChange={handleChange}
                    value={phoneNumber}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="notes">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="notes"
                    value={notes}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>

          <div className="buttonsColumn">
            {/* The Sungrade calendar will be Jayden Smith, and it will be the emergency calendar */}
            {/* <Button disabled={!isZipCodeCaliforniaAndInServiceArea} onClick={e => handleOpenCalendar(e, "Sungrade")}>Sungrade</Button> */}
            <Button disabled={!isZipCodeCaliforniaAndInServiceArea && !isZipCodeNevadaAndInServiceArea} onClick={e => handleOpenCalendar(e, "Aaron Katz")}>Aaron Katz</Button>
            {/* <Button disabled={!isZipCodeCaliforniaAndInServiceArea} onClick={e => handleOpenCalendar(e, "Tommy Long")}>Tommy Long</Button> */}
            {/* <Button disabled={!isZipCodeTexas || !!isZipCodeHouston} onClick={e => handleOpenCalendar(e, "Ted Spradley")}>Ted Spradley</Button> */}
            {/* <Button disabled={!isZipCodeCaliforniaAndInServiceArea && !isZipCodeNevadaAndInServiceArea && !isZipCodeHouston} onClick={e => handleOpenCalendar(e, "Phillip Jefferson")}>Phillip Jefferson</Button> */}
            {/* <Button disabled={!isValidAreaforGenerationSolar} onClick={e => handleOpenCalendar(e, "Generation Solar")}>Generation Solar</Button> */}
            {/* <Button disabled={!isZipCodeHouston} onClick={e => handleOpenCalendar(e, "Sunrun Houston Appointments")}>Sunrun Houston Appointments</Button> */}
            {/* <Button disabled={!isZipCodeHouston} onClick={e => handleOpenCalendar(e, "Sunrun Houston Appointments 2")}>Sunrun Houston Appointments #2</Button> */}
            {/* <Button onClick={e => handleOpenCalendar(e, "Channel Partner 1")}>Channel Partner 1</Button>
            <Button onClick={e => handleOpenCalendar(e, "Channel Partner 2")}>Channel Partner 2</Button>
            <Button onClick={e => handleOpenCalendar(e, "Channel Partner 3")}>Channel Partner 3</Button> */}
          </div>
          <div className="flexCenter spaceBetween mt-20">
            <Button className="btn btn-secondary" variant="outline" onClick={handleGoBack}>
              {getContent('back', props.appState.language)}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>

  )
}

export default ChannelPartnersPage;
