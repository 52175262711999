const approvedZipCodesWithData = [
    {"zipCode":"92677","state":"California","city":"Laguna Niguel","region":"HPSC"},
    {"zipCode":"93534","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93535","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93536","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93539","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93510","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93543","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93544","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93550","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93551","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93552","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93591","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93599","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"93560","state":"California","city":"Lancaster","region":"HPSC"},
    {"zipCode":"92240","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92252","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92258","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92211","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92260","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92262","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92263","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92264","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92292","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92276","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92284","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92270","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92234","state":"California","city":"Palm Desert Area","region":"HPSC"},
    {"zipCode":"92223","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92226","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92320","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92235","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92877","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92878","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92879","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92880","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92881","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92882","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92883","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92543","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92545","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92546","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92548","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92530","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92531","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92532","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92518","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92584","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"91752","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92553","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92557","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92554","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92555","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92556","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92551","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92552","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92562","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92563","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92564","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92860","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92567","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92255","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92261","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92570","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92571","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92572","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92599","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92581","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92582","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92583","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92585","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92586","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92587","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92595","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"92596","state":"California","city":"Perris","region":"HPSC"},
    {"zipCode":"91737","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91739","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92312","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92316","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92318","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91708","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91710","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91709","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92323","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92326","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92331","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92334","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92335","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92336","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92337","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92313","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91743","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92340","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92346","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92350","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92354","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92357","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92359","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91763","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92366","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91758","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91761","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91762","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91764","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91798","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92369","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92329","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91701","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92324","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91729","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91730","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92373","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92374","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92375","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92376","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92377","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91784","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91785","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91786","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92393","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92399","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92401","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92402","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92403","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92404","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92405","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92406","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92407","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92408","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92410","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92411","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92412","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92413","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92414","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92415","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92418","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92423","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92424","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"92427","state":"California","city":"San Bernardino","region":"HPSC"},
    {"zipCode":"91903","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91902","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91908","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92003","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92007","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92008","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92009","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92010","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92011","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92013","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92018","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91909","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91910","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91911","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91912","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91913","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91914","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91915","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91921","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92118","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92178","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92014","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92019","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92020","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92021","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92022","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92090","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92023","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92024","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92027","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92025","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92026","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92029","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92030","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92033","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92046","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92088","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92028","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91932","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91933","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92037","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92038","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92039","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92092","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92093","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91941","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91942","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91943","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91944","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92607","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92040","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91945","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91946","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91947","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91906","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91934","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92690","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91950","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91951","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92049","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92051","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92052","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92054","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92056","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92057","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91990","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92064","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92074","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92065","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92067","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92091","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92674","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92101","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92102","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92103","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92104","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92105","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92106","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92107","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92108","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92109","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92110","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92111","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92112","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92113","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92114","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92115","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92116","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92117","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92119","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92120","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92121","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92122","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92123","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92124","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92126","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92127","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92128","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92129","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92130","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92131","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92132","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92133","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92134","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92135","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92136","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92137","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92138","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92139","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92140","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92142","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92145","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92147","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92149","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92150","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92152","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92153","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92154","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92155","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92158","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92159","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92160","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92161","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92162","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92163","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92164","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92165","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92166","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92167","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92168","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92169","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92170","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92171","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92172","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92174","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92175","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92176","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92177","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92179","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92182","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92184","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92186","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92187","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92190","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92191","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92192","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92193","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92194","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92195","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92196","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92197","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92198","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92199","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92068","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92069","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92078","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92079","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92096","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92143","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92173","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92071","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92072","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92075","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91976","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91977","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91978","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91979","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91987","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92081","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92083","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92084","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92085","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91917","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91963","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91980","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"91905","state":"California","city":"San Diego","region":"HPSC"},
    {"zipCode":"92392","state":"California","city":"Victorville","region":"HPSC"},
    {"zipCode":"92307","state":"California","city":"Victorville","region":"HPSC"},
    {"zipCode":"92344","state":"California","city":"Victorville","region":"HPSC"},
    {"zipCode":"92395","state":"California","city":"Victorville","region":"HPSC"},
    {"zipCode":"92308","state":"California","city":"Victorville","region":"HPSC"},
    {"zipCode":"92301","state":"California","city":"Victorville","region":"HPSC"},
    {"zipCode":"92345","state":"California","city":"Victorville","region":"HPSC"},
    {"zipCode":"92371","state":"California","city":"Victorville","region":"HPSC"},
    {"zipCode":"92372","state":"California","city":"Victorville","region":"HPSC"},
    {"zipCode":"92394","state":"California","city":"Victorville","region":"HPSC"},
    {"zipCode":"Zip Code","state":"State","city":"Region","region":"GS"},
    {"zipCode":"80101","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80001","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80006","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80003","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80002","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80004","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80005","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80007","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80040","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80010","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80047","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80045","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80012","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80042","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80041","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80046","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80014","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80044","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80011","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80017","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80013","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80015","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80019","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80018","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80016","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80602","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80601","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80603","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80020","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80038","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80021","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80023","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80108","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80109","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80104","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80921","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80908","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80924","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80920","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80919","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80923","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80918","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80927","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80917","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80938","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80907","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80922","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80904","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80939","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80909","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80951","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80915","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80931","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80932","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80933","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80934","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80935","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80936","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80937","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80941","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80942","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80946","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80947","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80949","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80950","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80960","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80962","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80970","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80977","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80995","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80997","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80901","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80912","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80903","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80905","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80914","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80910","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80916","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80929","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80906","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80930","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80902","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80911","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80913","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80926","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80928","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80925","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80037","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80022","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80150","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80151","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80155","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80113","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80110","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80111","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80112","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80516","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80402","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80419","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80401","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80403","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80634","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80639","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80632","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80633","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80638","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80631","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80121","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80160","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80161","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80165","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80166","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80120","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80123","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80122","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80162","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80128","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80163","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80130","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80129","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80126","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80127","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80125","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80504","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80501","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80502","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"80503","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81004","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81005","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81003","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81009","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81010","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81011","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81012","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81002","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81007","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81006","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81001","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"81008","state":"CO","city":"CO","region":"GS"},
    {"zipCode":"76621","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75001","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76008","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75013","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75002","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76009","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76225","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75409","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76622","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76226","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76006","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76011","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76094","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76096","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76003","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76004","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76005","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76007","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76012","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76010","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76019","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76014","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76013","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76015","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76018","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76016","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76017","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76002","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76001","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76227","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76623","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76098","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76020","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75413","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75180","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75101","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75102","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76021","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76022","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76095","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75414","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76626","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75424","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76627","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76230","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76023","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76628","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76426","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76097","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76028","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76631","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75135","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75422","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75006","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75011","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75007","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75010","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75104","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75106","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75423","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75009","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75105","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76431","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76031","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76033","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76034","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76233","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75121","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75099","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75019","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75110","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75151","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75109","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76636","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75114","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76035","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76036","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75261","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75368","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75247","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75211","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75212","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75220","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75236","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75234","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75235","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75229","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75233","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75209","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75249","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75208","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75260","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75358","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75207","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75219","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75267","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75342","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75370","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75224","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75262","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75263","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75398","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75242","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75202","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75270","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75201","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75244","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75221","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75222","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75250","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75264","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75265","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75266","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75275","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75277","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75283","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75284","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75285","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75301","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75303","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75312","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75313","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75315","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75320","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75326","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75336","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75339","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75354","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75355","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75356","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75357","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75359","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75360","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75367","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75371","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75372","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75373","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75374","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75376","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75378","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75379","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75380","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75381","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75382","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75389","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75390","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75391","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75392","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75393","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75394","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75395","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75397","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75237","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75205","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75204","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75225","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75203","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75230","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75226","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75246","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75206","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75232","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75254","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75215","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75240","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75251","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75216","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75287","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75223","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75214","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75248","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75231","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75210","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75243","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75252","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75241","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75218","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75238","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75227","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75228","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75217","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75253","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76639","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76234","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76439","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76210","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76205","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76208","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76202","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76203","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76204","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76206","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76207","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76201","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76209","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75115","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75123","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75116","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75138","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75137","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75439","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75117","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75118","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75120","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75119","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76238","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76040","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76039","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75442","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75132","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75125","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75028","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75022","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75027","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76239","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75126","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76041","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76155","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76120","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76118","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76112","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76148","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76103","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76137","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76105","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76190","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76119","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76111","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76104","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76166","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76102","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76101","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76113","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76121","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76124","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76130","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76136","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76147","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76150","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76161","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76162","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76163","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76181","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76185","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76191","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76192","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76193","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76195","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76196","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76197","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76198","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76199","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76177","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76164","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76106","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76131","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76110","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76140","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76115","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76129","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76122","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76107","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76134","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76114","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76109","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76133","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76179","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76132","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76123","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76135","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76116","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76108","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76126","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75036","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75034","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75033","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75035","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76641","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76241","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76240","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76043","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76044","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76049","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76048","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75050","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75053","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75051","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75052","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75054","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76050","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76051","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76099","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75402","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75403","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75404","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75401","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76246","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75058","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76117","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76052","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76645","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75459","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76648","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76053","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76054","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75141","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76650","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75062","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75061","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75038","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75014","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75015","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75016","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75017","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75060","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75039","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75059","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75063","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76651","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76055","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75164","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76058","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76247","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75142","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76059","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76248","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76244","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75143","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76060","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76652","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76249","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75065","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75134","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75146","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75166","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75452","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75067","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75029","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75057","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75077","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76061","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76250","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76462","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75068","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75453","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75147","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75156","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76660","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76063","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76064","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75070","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75072","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75069","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75071","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75454","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75458","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76666","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75150","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75149","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75185","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75187","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75181","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76065","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76670","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76066","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76068","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76067","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76671","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76252","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76253","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76127","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76070","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75173","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76071","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76180","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76182","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75152","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76467","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76073","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76485","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76676","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76486","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76258","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75472","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76259","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76487","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75153","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75407","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75078","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76679","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75474","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76077","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75475","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75154","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76078","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75155","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75083","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75085","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75080","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75081","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75082","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76093","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76262","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75087","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75032","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75157","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76263","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75030","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75088","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75089","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75189","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75048","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76264","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76265","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76266","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76472","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75479","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75158","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75159","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75092","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75090","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75091","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76267","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76092","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76268","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76082","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75182","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76270","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75160","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75161","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75056","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76271","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76476","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75489","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75490","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76272","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75495","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76084","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76690","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75167","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75168","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75165","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76085","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76086","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76087","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76088","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75485","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75097","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76273","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75491","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76692","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"76490","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75169","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75172","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75496","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"75098","state":"TX","city":"TX","region":"GS"},
    {"zipCode":"84004","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84003","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84006","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84011","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84010","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84013","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84014","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84089","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84016","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84015","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84017","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84018","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84020","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84024","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84310","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84626","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84025","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84633","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84029","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84032","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84033","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84096","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84056","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84315","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84317","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84318","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84319","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84036","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84037","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84041","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84040","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84043","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84042","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84044","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84325","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84047","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84049","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84326","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84645","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84054","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84055","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84402","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84407","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84409","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84412","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84201","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84244","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84401","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84404","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84415","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84414","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84057","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84058","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84097","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84328","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84098","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84060","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84068","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84651","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84061","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84062","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84332","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84604","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84602","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84601","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84603","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84605","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84606","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84065","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84067","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84069","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84653","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84116","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84104","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84180","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84114","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84101","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84138","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84110","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84122","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84125","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84126","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84127","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84130","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84131","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84132","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84134","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84139","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84141","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84143","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84145","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84147","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84148","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84150","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84151","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84158","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84165","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84170","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84171","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84184","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84190","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84199","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84103","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84111","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84133","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84102","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84112","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84105","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84115","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84113","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84106","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84157","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84123","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84118","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84107","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84108","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84117","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84129","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84109","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84124","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84152","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84121","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84090","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84091","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84093","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84070","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84094","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84092","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84655","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84045","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84095","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84009","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84660","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84663","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84075","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84074","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84080","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84059","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84082","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84339","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84084","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84088","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84081","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84119","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84120","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84128","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"84087","state":"UT","city":"UT","region":"GS"},
    {"zipCode":"Zip Code","state":"State","city":"Region","region":"HOU"},
    {"zipCode":"77613","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77622","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77629","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77002","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77003","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77004","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77005","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77006","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77007","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77008","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77009","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77010","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77011","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77012","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77013","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77014","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77015","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77016","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77017","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77018","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77019","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77020","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77021","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77022","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77023","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77024","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77025","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77026","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77027","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77028","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77029","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77030","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77031","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77032","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77033","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77034","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77035","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77036","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77037","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77038","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77039","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77040","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77041","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77042","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77043","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77044","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77045","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77046","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77047","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77048","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77049","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77050","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77051","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77052","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77053","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77054","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77055","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77056","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77057","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77058","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77059","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77060","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77061","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77062","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77063","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77064","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77065","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77066","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77067","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77068","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77069","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77070","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77071","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77072","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77073","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77074","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77075","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77076","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77077","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77078","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77079","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77080","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77081","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77082","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77083","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77084","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77085","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77086","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77087","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77088","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77089","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77090","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77091","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77092","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77093","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77094","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77095","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77096","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77098","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77099","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77204","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77301","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77302","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77303","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77304","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77306","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77316","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77318","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77325","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77327","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77328","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77336","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77338","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77339","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77345","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77346","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77354","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77355","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77356","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77357","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77358","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77362","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77363","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77365","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77368","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77369","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77372","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77373","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77374","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77375","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77376","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77377","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77378","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77379","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77380","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77381","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77382","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77384","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77385","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77386","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77388","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77389","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77396","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77401","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77406","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77407","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77417","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77418","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77420","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77423","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77426","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77429","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77430","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77431","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77433","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77434","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77435","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77436","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77441","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77443","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77444","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77445","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77446","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77447","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77448","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77449","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77450","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77451","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77453","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77454","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77459","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77461","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77464","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77466","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77468","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77469","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77471","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77474","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77476","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77477","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77478","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77479","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77480","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77481","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77482","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77484","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77486","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77488","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77489","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77492","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77493","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77494","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77497","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77498","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77502","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77503","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77504","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77505","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77506","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77507","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77510","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77511","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77514","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77515","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77516","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77517","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77518","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77519","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77520","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77521","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77523","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77530","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77531","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77532","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77533","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77534","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77535","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77536","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77538","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77539","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77542","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77545","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77546","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77547","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77552","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77553","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77555","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77560","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77561","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77562","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77563","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77564","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77565","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77566","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77568","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77571","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77573","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77574","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77575","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77577","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77578","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77580","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77581","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77582","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77583","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77584","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77585","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77586","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77587","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77588","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77590","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77591","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77598","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77659","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77661","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77665","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77830","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77833","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77868","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"77880","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"78931","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"78933","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"78935","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"78944","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"78950","state":"Texas","city":"Houston","region":"HOU"},
    {"zipCode":"89199","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89111","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89112","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89114","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89116","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89125","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89126","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89127","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89132","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89133","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89137","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89150","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89151","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89152","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89153","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89155","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89159","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89160","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89162","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89163","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89164","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89177","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89180","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89185","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89193","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89195","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89157","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89106","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89101","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89104","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89102","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89087","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89036","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89169","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89109","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89107","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89030","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89032","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89154","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89170","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89173","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89158","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89121","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89146","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89108","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89103","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89110","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89119","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89031","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89142","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89081","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89128","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89120","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89118","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89130","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89117","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89145","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89115","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89122","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89147","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89086","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89136","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89129","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89014","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89123","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89084","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89134","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89144","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89113","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89140","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89139","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89053","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89085","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89156","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89191","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89074","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89033","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89011","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89149","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89131","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89148","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89077","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89135","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89138","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89178","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89183","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89012","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89105","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89165","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89141","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"88901","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"88905","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89009","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89016","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89052","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89143","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89179","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89166","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89015","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89002","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89161","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89044","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89004","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89054","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89124","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89006","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89005","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89026","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89018","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89019","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89040","state":"Nevada","city":"Las Vegas","region":"NEV"},
    {"zipCode":"89025","state":"Nevada","city":"Las Vegas","region":"NEV"}
    ]
    
    module.exports={approvedZipCodesWithData}
    