import React from 'react';
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router';
import {
  Form,
  Button,
  Container,
  Col,
  Row
} from "react-bootstrap";
import { generateRoute } from '../services/routes';
import {
  getContent
} from '../services/language';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function OutOfAreaPage(props) {
  const history = useHistory();
  const params = useParams();
  const queryString = useLocation().search;

  function useQuery() {
    return new URLSearchParams(queryString);
  }
  const query = useQuery();

  const handleGoBack = e => {
    e.preventDefault();
    const page = "address";
    const route = generateRoute(params, page, null, queryString);
    history.push(route);
  }

  return (
    <Container className="mt-5">
      <Row className="rowWide">
        <Col className="text-center">
            <Form.Text className="text-center text-muted display-4">
                {getContent('alertZipOutsideOfServiceArea', props.appState.language)}
            </Form.Text>
            <div className="flexCenter spaceBetween mt-20">
              <Button className="btn btn-secondary" variant="outline" onClick={handleGoBack}>
                {getContent('back', props.appState.language)}
              </Button>
            </div>
        </Col>
      </Row>
    </Container>

  )
}

export default OutOfAreaPage;
