export const getContent = (name, language) => {
  const content = {
    'error': {
      'english': 'Oops, there seems to be an error!  One of our solar consultants will reach out to help you book an appointment.',
      'spanish': '¡Vaya, parece haber un error! Uno de nuestros consultores solares se comunicará con usted para ayudarlo a reservar una cita.'
    },
    'next': {
      'english': 'Next',
      'spanish': 'Siguiente'
    },
    'back': {
      'english': 'Back',
      'spanish': 'Atras'
    },
    'zipCode': {
      'english': 'Zip Code',
      'spanish': 'Código Postal'
    },
    'enterZipCode': {
      'english': 'Enter Zip Code',
      'spanish': 'Ingresa tu código postal'
    },
    'alertZipOutsideOfServiceArea': {
      'english': 'We\'re not currently servicing your zip code.  Sorry!',
      'spanish': 'Actualmente no estamos atendiendo su código postal. ¡Lo siento!'
    },
    'inputValidZip': {
      'english': 'Please input a valid, 5-digit zip code.',
      'spanish': 'Por favor, ingrese un código postal válido de 5 dígitos.'
    },
    'bookInPersonAppointment': {
      'english': 'Book an In-Person Appointment',
      'spanish': 'Reserve una cita en persona'
    },
    'bookOnlineAppointment': {
      'english': 'Book an Online Appointment',
      'spanish': 'Reserve una cita en línea'
    },
    'mapStep1': {
      'english': 'Step 1: Enter your address',
      'spanish': 'Paso 1: ingrese su dirección'
    },
    'mapStep1Placeholder': {
      'english': 'Enter a location',
      'spanish': 'Ingrese una ubicación'
    },
    'mapStep2': {
      'english': 'Step 2: Let\'s locate your home! Drag the pin onto the center of your property',
      'spanish': 'Paso 2: ¡Ubiquemos su casa! Arrastra la chincheta al centro de tu propiedad'
    },
    'inPersonTitle': {
      'english': 'In-Person Appointment Calendar',
      'spanish': 'Calendario de citas en persona'
    },
    'inPersonSubtitle': {
      'english': 'Don\'t see the time you\'re looking for?',
      'spanish': '¿No ves el tiempo que estás buscando?'
    },
    'inPersonButton': {
      'english': 'Book an online appointment instead',
      'spanish': 'Reserve una cita en línea en su lugar'
    },
    'onlineTitle': {
      'english': 'Online Appointment Calendar',
      'spanish': 'Calendario de citas en línea'
    },
    'language': {
      'english': 'Language',
      'spanish': 'Idioma'
    },
    'chooseLanguage': {
      'english': 'Please choose your language',
      'spanish': 'Por favor elija su idioma'
    },
    'whoAreYou': {
      'english': 'Please enter your name',
      'spanish': 'Please enter your name'
    },
    'enterName': {
      'english': 'Please enter your name',
      'spanish': 'Please enter your name'
    },
    'utilityProvider': {
      'english': 'Utility provider',
      'spanish': 'Utility provider'
    },
    'enterUtilityProvider': {
      'english': 'Please choose your utility provider',
      'spanish': 'Please choose your utility provider'
    },
    'name': {
      'english': 'Name',
      'spanish': 'Name'
    },
    'enterTimezone': {
      'english': 'Please choose your timezone',
      'spanish': 'Please choose your timezone'
    }
  }
  const entry = content[name];
  if(!!entry) {
    let item = entry[language];
    if(!item) {
      item = entry['english'];
      if(!item) {
        return 'ERROR';
      }
    }
    return item;
  } else {
    return 'ERROR';
  }
}

export const getIsSpanishSpeakingLead = (appState) => {
  if(!!appState && !!appState.language && appState.language === 'spanish') {
    return true;
  }
}
