import React, { useState } from 'react';
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router';
import {
  Form,
  Button,
  Container,
  Col,
  Row
} from "react-bootstrap";
import GoogleMap from '../components/GoogleMap/GoogleMap';
import { generateRoute } from '../services/routes';
import {
  getContent
} from '../services/language';
import {
  getIsZipInServiceArea,
  getStateFromZip,
  getIsStateOutOfCaButInServiceArea
} from '../services/zipCodes';

function MapPage(props) {
  const history = useHistory();
  const params = useParams();
  const queryString = useLocation().search;

  const handleGoBack = e => {
    e.preventDefault();
    let page = "utility";
    const isSelfGenerated = !!params && !!params.leadProvider && ['self-generated', 'self-generation'].indexOf(params.leadProvider) > -1;
    const stateAbbreviation = getStateFromZip(!!props.appState && props.appState.zipCode);
    const isStateOutOfCaButInServiceArea = getIsStateOutOfCaButInServiceArea(stateAbbreviation);
    if(!isSelfGenerated && isStateOutOfCaButInServiceArea) {
      page = "zip";
    }
    const route = generateRoute(params, page, null, queryString);
    history.push(route);
  }

  const handleNext = e => {
    e.preventDefault();
    const isZipInServiceArea = getIsZipInServiceArea(props.appState.zipFromGoogleMapsAddress);
    const host = !!window && !!window.location && window.location.host;
    const isCustomerFacing = !!host && (host.includes("meet") || host.includes("aspen-renewables.netlify.app"));
    console.log({ host, isCustomerFacing });
    const isSelfGenerated = !!params && !!params.leadProvider && ['self-generated', 'self-generation'].indexOf(params.leadProvider) > -1;
    let route;
    if(isZipInServiceArea) {
      if (!!props.appState && !!props.appState.appointmentType) {
        route = props.appState.appointmentType;
      } else {
        route = "online";
      }
    } else {
      route = "out-of-area";
    }
    if(!isCustomerFacing && !isSelfGenerated) {
      route = "timezone";
    }
    route = generateRoute(params, route, { lat: props.appState.lat, lng: props.appState.lng }, queryString);
    history.push(route);
  }

  const enableNext = !!props.appState && !!props.appState.address;

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <Row className="mapGoogle">
            <GoogleMap
              lat={!!props.appState && props.appState.lat}
              lng={!!props.appState && props.appState.lng}
              zoom={!!props.appState && props.appState.zoom}
              updateLocation={props.setAppState}
              appState={props.appState}
            />
          </Row>
          <Row className="flexCenter spaceBetween mt-20">
            <Button className="btn btn-secondary" variant="outline" onClick={handleGoBack}>
              {getContent('back', props.appState.language)}
            </Button>
            <Button  onClick={handleNext} disabled={!enableNext}>
              {getContent('next', props.appState.language)}
            </Button>
          </Row>
          <Row>

          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default MapPage;
