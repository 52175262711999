import React, { useState } from 'react';
import * as Sentry from "@sentry/react";
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router';
import {
  Form,
  Button,
  Container,
  Col,
  Row
} from "react-bootstrap";
import {
  getIsInPersonZip,
  getIsZipInServiceArea,
  getIsValidZip,
  getStateFromZip,
  getIsStateOutOfCaButInServiceArea
} from '../services/zipCodes';
import {
  generateRoute,
} from '../services/routes';
import {
  getContent
} from '../services/language';

function ZipCodePage(props) {
  const queryString = useLocation().search;
  function useQuery() {
    return new URLSearchParams(queryString);
  }
  const query = useQuery();
  let initialZipCode = !!props.appState && !!props.appState.zipCode ? props.appState.zipCode : '';
  if (!initialZipCode) {
    initialZipCode = query.get("zipCode");
  }

  const initialState = {
    zipCode: initialZipCode,
    isInPersonZip: !!props.appState && !!props.appState.isInPersonZip,
    region: !!props.appState && !!props.appState.region ? props.appState.region : '',
    isValidZip: true,
    alertZipOutsideOfServiceArea: '',
    didSendAlertToSentry: false
  }
  const [state, setState] = useState(initialState);
  const history = useHistory();
  const params = useParams();

  const isSelfGenerated = !!params && !!params.leadProvider && ['self-generated', 'self-generation'].indexOf(params.leadProvider) > -1;
  const isCortez = !!params && !!params.leadProvider && ['cortez-capital'].indexOf(params.leadProvider) > -1;
  const handleSubmitZip = e => {
    e.preventDefault();
    console.log('submitting zip');
    const isValidZip = getIsValidZip(state.zipCode);
    if (isValidZip) {
      const isZipInServiceArea = getIsZipInServiceArea(state.zipCode);
      const stateAbbreviation = getStateFromZip(state.zipCode);
      const isStateCalifornia = stateAbbreviation === "CA";
      const isStateOutOfCaButInServiceArea = getIsStateOutOfCaButInServiceArea(stateAbbreviation);
      if (isZipInServiceArea || (isStateOutOfCaButInServiceArea && !isSelfGenerated)) {
        const { isInPersonZip, region } = getIsInPersonZip(state.zipCode, props.appState.language);
        let page = 'utility';
        if (isSelfGenerated) {
          page = 'user';
        }
        if (isStateOutOfCaButInServiceArea) {
          page = "address";
        }
        if (isInPersonZip) {
          props.setAppState({
            zipCode: state.zipCode,
            region,
            isInPersonZip: true,
            appointmentType: 'in-person'
          });
          const route = generateRoute(params, page, null, queryString, state.zipCode);
          history.push(route);
        } else {
          props.setAppState({
            zipCode: state.zipCode,
            region,
            isInPersonZip: false,
            appointmentType: 'online'
          });
          const route = generateRoute(params, page, null, queryString, state.zipCode);
          history.push(route);
        }
      } else {
        if (!state.didSendAlertToSentry) {
          handleSendMessageToSentry(state.zipCode);
        }
        setState({
          ...state,
          alertZipOutsideOfServiceArea: getContent('alertZipOutsideOfServiceArea', props.appState.language),
          didSendAlertToSentry: true
        });
      }
    } else {
      setState({
        ...state,
        alertZipOutsideOfServiceArea: '',
        isValidZip
      })
    }
  }

  const handleGoBack = e => {
    const route = generateRoute(params, 'landing', null, queryString, state.zipCode);
    history.push(route);
  }

  const handleChange = e => {
    if (e.target.name === 'zipCode' && (!!e.target.value && e.target.value.length === 5)) {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        isValidZip: true,
        isInPersonZip: false,
        region: '',
        alertZipOutsideOfServiceArea: ''
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        isInPersonZip: false,
        region: ''
      });
    }
  }

  const handleSendMessageToSentry = (zipCode) => {
    const url = !!window && !!window.location && window.location.href;
    Sentry.captureMessage(`Zip code outside of service area.  Zip: ${zipCode}.  URL: ${url}`);
  }

  const {
    zipCode,
    isValidZip,
    alertZipOutsideOfServiceArea
  } = state;

  return (
    <Container className="mt-5 landingWidth">
      <Row className="mapBox">
        <Col className="text-center">
          <Form onSubmit={handleSubmitZip}>
            <Form.Group controlId="zipCode">
              <Form.Label>{getContent('zipCode', props.appState.language)}</Form.Label>
              <Form.Control
                className="text-center"
                type="text"
                // minLength={5}
                maxLength={5}
                placeholder={getContent('enterZipCode', props.appState.language)}
                name="zipCode"
                onChange={handleChange}
                value={zipCode}
              />
              {!isValidZip && <Form.Text className="text-center text-muted display-4">
                {getContent('inputValidZip', props.appState.language)}
              </Form.Text>}
              {!!alertZipOutsideOfServiceArea && <Form.Text className="text-center text-muted display-4">
                {alertZipOutsideOfServiceArea}
              </Form.Text>}
            </Form.Group>
            <div className="flexCenter spaceBetween mt-20">
              <Button className="btn btn-secondary" variant="outline" onClick={handleGoBack}>
                {getContent('back', props.appState.language)}
              </Button>
              <Button variant="primary" type="submit">
                {getContent('next', props.appState.language)}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default ZipCodePage;
