const users = [
  // SELF GENERATED
  {
    id: 'Abe Tenorio',
    name: 'Abe Tenorio',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Austen Green',
    name: 'Austen Green',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Ayoube Errouihni',
    name: 'Ayoube Errouihni',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Bill Arellano',
    name: 'Bill Arellano',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Donovan Casey',
    name: 'Donovan Casey',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Eric Berman',
    name: 'Eric Berman',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Generation Solar',
    name: 'Generation Solar',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Greg Bretzing',
    name: 'Greg Bretzing',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Johnnie Freeze',
    name: 'Johnnie Freeze',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Jose Gonzalez',
    name: 'Jose Gonzalez',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Josh Norman',
    name: 'Josh Norman',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Lawrence Garmon',
    name: 'Lawrence Garmon',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Lucas Fresenius',
    name: 'Lucas Fresenius',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Masoud Nilforush',
    name: 'Masoud Nilforush',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Michael Bani',
    name: 'Michael Bani',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Olivia (test)',
    name: 'Olivia (test)',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Randy Quiroa',
    name: 'Randy Quiroa',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Scott Sullivan',
    name: 'Scott Sullivan',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Sebastian Manon',
    name: 'Sebastian Manon',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Skyler Stone',
    name: 'Skyler Stone',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'Tony Urbina',
    name: 'Tony Urbina',
    belongsTo: ['self-generated', 'self-generation']
  },
  {
    id: 'William Santisteban',
    name: 'William Santisteban',
    belongsTo: ['self-generated', 'self-generation']
  },

  // CORTEZ CAPITAL
  {
    belongsTo: ['cortez-capital'],
    id: 'David Cortez',
    name: 'David Cortez'
  },
  {
    belongsTo: ['cortez-capital'],
    id: 'Test user who works with David Cortez',
    name: 'Test user who works with David Cortez'
  },
  {
    belongsTo: ['cortez-capital'],
    id: 'Test user 2',
    name: 'Test user 2'
  },

  // OTHER
  {
    belongsTo: ['self-generated', 'self-generation', 'cortez-capital'],
    id: 'other',
    name: 'Other'
  }
]

export const getUserOptionsBasedOnParams = (params, returnAsArray) => {
  if (!!params && !!params.leadProvider) {
    const filteredUsers = users.filter(user => user.belongsTo.includes(params.leadProvider))
    if (returnAsArray) {
      const usersArray = [];
      filteredUsers.forEach(user => usersArray.push(user.id));
      return usersArray;
    }
    return filteredUsers;
  }
}
