import React, { Component } from 'react';
import {
  Spinner,
  Row
} from "react-bootstrap";
import {
  getContent
} from '../../services/language';
import {
  getZipFromAddressComponents
} from '../../services/googleMaps';

import "./GoogleMap.css";

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.myMapContainer = React.createRef();
    this.state = {
      script: '',
      isGoogleMapLoaded: false,
      addressInput: ''
    }
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCzuc8DSb4_K0XEfvI5SPGEd6Nb1RwG_4g&libraries=places`;
    script.id = 'googleMapScript'
    // script.async = false;
    script.onload = () => {
      console.log('LOADED');
      this.setState({ isGoogleMapLoaded: true, script });
      this.initMap();
    }
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    console.log('component will unmount');
    document.body.removeChild(this.state.script);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!!prevState.addressInput && !this.state.addressInput) {
      console.log('address deleted');
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  initMap = () => {
    const google = window.google;
    if (!!google) {
      const map = new google.maps.Map(this.myMapContainer.current, {
        center: { lat: this.props.lat || 37.9270408, lng: this.props.lng || -122.061386 },
        zoom: this.props.zoom || 9,
        mapTypeId: 'satellite',
        tilt: 0
      });
      const input = document.getElementById("pac-input");
      const options = {
        componentRestrictions: { country: "us" },
        fields: ["formatted_address", "geometry", "name", "address_components"],
        origin: map.getCenter(),
        strictBounds: false,
        types: ["address"]
      };
      const autocomplete = new google.maps.places.Autocomplete(input, options);
      // Bind the map's bounds (viewport) property to the autocomplete object,
      // so that the autocomplete requests use the current map bounds for the
      // bounds option in the request.
      autocomplete.bindTo("bounds", map);
      const infowindow = new google.maps.InfoWindow();
      const infowindowContent = document.getElementById("infowindow-content");
      infowindow.setContent(infowindowContent);

      const markerOptions = {
        map,
        anchorPoint: new google.maps.Point(0, -29),
        draggable: true
      }
      if (!!this.props.lat && this.props.lng) {
        markerOptions['position'] = { lat: this.props.lat, lng: this.props.lng }
      }
      const marker = new google.maps.Marker(markerOptions);

      marker.addListener("dragend", () => {
        const position = marker.getPosition();
        if (!!position) {
          const lat = position.lat();
          const lng = position.lng();
          if (!!lat && !!lng) {
            this.props.updateLocation({ lat, lng });
          }
        }
      });

      autocomplete.addListener("place_changed", () => {
        infowindow.close();
        marker.setVisible(false);
        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(30);
        }
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
        infowindowContent.children["place-name"].textContent = place.name;
        infowindowContent.children["place-address"].textContent =
          place.formatted_address;
        infowindow.open(map, marker);
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const address = place.formatted_address;
        const zipFromGoogleMapsAddress = getZipFromAddressComponents(place.address_components);
        const addressData = {
          address,
          zipFromGoogleMapsAddress,
          lat,
          lng,
          zoom: map.getZoom()
        }
        this.props.updateLocation(addressData);
      });
    } else {
      return <p className="error">{getContent('error', this.props.appState.language)}</p>;
    }
  }

  render() {
    const {
      isGoogleMapLoaded
    } = this.state;
    if (!isGoogleMapLoaded) {
      return <Row><Spinner animation="border" variant="primary" /></Row>
    } else {
      return (
        <>
          <p className="text-black display-4">{getContent('mapStep1', this.props.appState.language)}</p>
          <input id="pac-input" type="text" placeholder={getContent('mapStep1Placeholder', this.props.appState.language)} onChange={this.handleChange} name="addressInput" />
          <p className="text-black display-4">{getContent('mapStep2', this.props.appState.language)}</p>
          <div ref={this.myMapContainer} className="GoogleMap"></div>
          <div id="infowindow-content">
            <span id="place-name" className="title"></span><br />
            <span id="place-address"></span>
          </div>
        </>
      );
    }
  }
}

export default GoogleMap;
