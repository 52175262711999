import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Navbar from './components/Navbar/Navbar';
import ChooseAppointmentTypePage from './containers/ChooseAppointmentTypePage';
import ChooseUserPage from './containers/ChooseUserPage';
import InPersonAppointmentPage from './containers/InPersonAppointmentPage';
import LandingPage from './containers/LandingPage';
import MapPage from './containers/MapPage';
import OnlineAppointmentPage from './containers/OnlineAppointmentPage';
import OutOfAreaPage from './containers/OutOfAreaPage';
import ZipCodePage from "./containers/ZipCodePage";
import ThankYouOnlinePage from "./containers/ThankYouOnlinePage";
import ThankYouInPersonPage from "./containers/ThankYouInPersonPage";
import UtilityProviderPage from "./containers/UtilityProviderPage";
import ChannelPartnersPage from "./containers/ChannelPartnersPage";
import TimezonePage from "./containers/TimezonePage";

function App() {
  const initialState = {
    appointmentType: '',
    zipCode: '',
    zipFromGoogleMapsAddress: '',
    region: '',
    address: '',
    lat: '',
    lng: '',
    zoom: 14,
    language: 'english',
    user: '',
    enteredUser: '',
    utilityProvider: '',
    enteredUtilityProvider: '',
    timezone: ''
  }

  const [state, setState] = useState(initialState);

  const handleSetState = (updates) => {
    setState({
      ...state,
      ...updates
    });
  }

  return (
    <Router>
      <Switch>
        {/* out of area appointment page */}
        <Route path="/:leadProvider/:leadId/out-of-area/:lat/:lng/:zipCode">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <OutOfAreaPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/out-of-area/:lat/:lng">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <OutOfAreaPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/out-of-area">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <OutOfAreaPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>

        {/* online appointment page */}
        <Route path="/:leadProvider/:leadId/online/:lat/:lng/:zipCode">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          {/* <OnlineAppointmentPage
            appState={state}
            setAppState={handleSetState}
          /> */}
          <InPersonAppointmentPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/online/:lat/:lng">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          {/* <OnlineAppointmentPage
            appState={state}
            setAppState={handleSetState}
          /> */}
          <InPersonAppointmentPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/online">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          {/* <OnlineAppointmentPage
            appState={state}
            setAppState={handleSetState}
          /> */}
          <InPersonAppointmentPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        {/* in-person appointment page */}
        <Route path="/:leadProvider/:leadId/in-person/:lat/:lng/:zipCode">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <InPersonAppointmentPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/in-person/:lat/:lng">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <InPersonAppointmentPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/in-person">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <InPersonAppointmentPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        {/* channel partners page */}
        <Route path="/:leadProvider/:leadId/channel-partners/:lat/:lng/:zipCode">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ChannelPartnersPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/channel-partners/:lat/:lng">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ChannelPartnersPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/channel-partners">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ChannelPartnersPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        {/* address page */}
        <Route path="/:leadProvider/:leadId/address/:lat/:lng/:zipCode">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <MapPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/address/:lat/:lng">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <MapPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/address">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <MapPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        {/* choose appointment type page */}
        <Route path="/:leadProvider/:leadId/choose-type/:lat/:lng/:zipCode">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ChooseAppointmentTypePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/choose-type/:lat/:lng">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ChooseAppointmentTypePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/choose-type">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ChooseAppointmentTypePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        {/* user page */}
        <Route path="/:leadProvider/:leadId/user/:lat/:lng/:zipCode">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ChooseUserPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/user/:lat/:lng">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ChooseUserPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/user">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ChooseUserPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/utility/:lat/:lng/:zipCode">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <UtilityProviderPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/utility/:lat/:lng">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <UtilityProviderPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/utility">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <UtilityProviderPage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        {/* zip code page */}
        <Route path="/:leadProvider/:leadId/zip/:lat/:lng/:zipCode">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ZipCodePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/zip/:lat/:lng">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ZipCodePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/zip">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ZipCodePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        {/* timezone page */}
        <Route path="/:leadProvider/:leadId/timezone/:lat/:lng/:zipCode">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <TimezonePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/timezone/:lat/:lng">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <TimezonePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider/:leadId/timezone">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <TimezonePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        {/* thank you online */}
        <Route path="/thank-you-online">
          <div className="vh100">
            <Navbar
              appState={state}
              setAppState={handleSetState}
              displayLanguageButton={false}
            />
            <ThankYouOnlinePage />
          </div>
        </Route>
        {/* thank you in person */}
        <Route path="/thank-you-in-person">
          <div className="vh100">
            <Navbar
              appState={state}
              setAppState={handleSetState}
              displayLanguageButton={false}
            />
            <ThankYouInPersonPage />
          </div>
        </Route>
        {/* landing page */}
        <Route path="/:leadProvider/:leadId">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ZipCodePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="/:leadProvider">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ZipCodePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
        <Route path="*">
          <Navbar
            appState={state}
            setAppState={handleSetState}
            displayLanguageButton={false}
          />
          <ZipCodePage
            appState={state}
            setAppState={handleSetState}
          />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
