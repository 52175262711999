const approvedZipCodes = [
  '92677',
  '93534',
  '93535',
  '93536',
  '93539',
  '93510',
  '93543',
  '93544',
  '93550',
  '93551',
  '93552',
  '93591',
  '93599',
  '93560',
  '92240',
  '92252',
  '92258',
  '92211',
  '92260',
  '92262',
  '92263',
  '92264',
  '92292',
  '92276',
  '92284',
  '92270',
  '92234',
  '92223',
  '92226',
  '92320',
  '92235',
  '92877',
  '92878',
  '92879',
  '92880',
  '92881',
  '92882',
  '92883',
  '92543',
  '92545',
  '92546',
  '92548',
  '92530',
  '92531',
  '92532',
  '92518',
  '92584',
  '91752',
  '92553',
  '92557',
  '92554',
  '92555',
  '92556',
  '92551',
  '92552',
  '92562',
  '92563',
  '92564',
  '92860',
  '92567',
  '92255',
  '92261',
  '92570',
  '92571',
  '92572',
  '92599',
  '92581',
  '92582',
  '92583',
  '92585',
  '92586',
  '92587',
  '92595',
  '92596',
  '91737',
  '91739',
  '92312',
  '92316',
  '92318',
  '91708',
  '91710',
  '91709',
  '92323',
  '92326',
  '92331',
  '92334',
  '92335',
  '92336',
  '92337',
  '92313',
  '91743',
  '92340',
  '92346',
  '92350',
  '92354',
  '92357',
  '92359',
  '91763',
  '92366',
  '91758',
  '91761',
  '91762',
  '91764',
  '91798',
  '92369',
  '92329',
  '91701',
  '92324',
  '91729',
  '91730',
  '92373',
  '92374',
  '92375',
  '92376',
  '92377',
  '91784',
  '91785',
  '91786',
  '92393',
  '92399',
  '92401',
  '92402',
  '92403',
  '92404',
  '92405',
  '92406',
  '92407',
  '92408',
  '92410',
  '92411',
  '92412',
  '92413',
  '92414',
  '92415',
  '92418',
  '92423',
  '92424',
  '92427',
  '91903',
  '91902',
  '91908',
  '92003',
  '92007',
  '92008',
  '92009',
  '92010',
  '92011',
  '92013',
  '92018',
  '91909',
  '91910',
  '91911',
  '91912',
  '91913',
  '91914',
  '91915',
  '91921',
  '92118',
  '92178',
  '92014',
  '92019',
  '92020',
  '92021',
  '92022',
  '92090',
  '92023',
  '92024',
  '92027',
  '92025',
  '92026',
  '92029',
  '92030',
  '92033',
  '92046',
  '92088',
  '92028',
  '91932',
  '91933',
  '92037',
  '92038',
  '92039',
  '92092',
  '92093',
  '91941',
  '91942',
  '91943',
  '91944',
  '92607',
  '92040',
  '91945',
  '91946',
  '91947',
  '91906',
  '91934',
  '92690',
  '91950',
  '91951',
  '92049',
  '92051',
  '92052',
  '92054',
  '92056',
  '92057',
  '91990',
  '92064',
  '92074',
  '92065',
  '92067',
  '92091',
  '92674',
  '92101',
  '92102',
  '92103',
  '92104',
  '92105',
  '92106',
  '92107',
  '92108',
  '92109',
  '92110',
  '92111',
  '92112',
  '92113',
  '92114',
  '92115',
  '92116',
  '92117',
  '92119',
  '92120',
  '92121',
  '92122',
  '92123',
  '92124',
  '92126',
  '92127',
  '92128',
  '92129',
  '92130',
  '92131',
  '92132',
  '92133',
  '92134',
  '92135',
  '92136',
  '92137',
  '92138',
  '92139',
  '92140',
  '92142',
  '92145',
  '92147',
  '92149',
  '92150',
  '92152',
  '92153',
  '92154',
  '92155',
  '92158',
  '92159',
  '92160',
  '92161',
  '92162',
  '92163',
  '92164',
  '92165',
  '92166',
  '92167',
  '92168',
  '92169',
  '92170',
  '92171',
  '92172',
  '92174',
  '92175',
  '92176',
  '92177',
  '92179',
  '92182',
  '92184',
  '92186',
  '92187',
  '92190',
  '92191',
  '92192',
  '92193',
  '92194',
  '92195',
  '92196',
  '92197',
  '92198',
  '92199',
  '92068',
  '92069',
  '92078',
  '92079',
  '92096',
  '92143',
  '92173',
  '92071',
  '92072',
  '92075',
  '91976',
  '91977',
  '91978',
  '91979',
  '91987',
  '92081',
  '92083',
  '92084',
  '92085',
  '91917',
  '91963',
  '91980',
  '91905',
  '92392',
  '92307',
  '92344',
  '92395',
  '92308',
  '92301',
  '92345',
  '92371',
  '92372',
  '92394',
  'Zip Code',
  '80101',
  '80001',
  '80006',
  '80003',
  '80002',
  '80004',
  '80005',
  '80007',
  '80040',
  '80010',
  '80047',
  '80045',
  '80012',
  '80042',
  '80041',
  '80046',
  '80014',
  '80044',
  '80011',
  '80017',
  '80013',
  '80015',
  '80019',
  '80018',
  '80016',
  '80602',
  '80601',
  '80603',
  '80020',
  '80038',
  '80021',
  '80023',
  '80108',
  '80109',
  '80104',
  '80921',
  '80908',
  '80924',
  '80920',
  '80919',
  '80923',
  '80918',
  '80927',
  '80917',
  '80938',
  '80907',
  '80922',
  '80904',
  '80939',
  '80909',
  '80951',
  '80915',
  '80931',
  '80932',
  '80933',
  '80934',
  '80935',
  '80936',
  '80937',
  '80941',
  '80942',
  '80946',
  '80947',
  '80949',
  '80950',
  '80960',
  '80962',
  '80970',
  '80977',
  '80995',
  '80997',
  '80901',
  '80912',
  '80903',
  '80905',
  '80914',
  '80910',
  '80916',
  '80929',
  '80906',
  '80930',
  '80902',
  '80911',
  '80913',
  '80926',
  '80928',
  '80925',
  '80037',
  '80022',
  '80150',
  '80151',
  '80155',
  '80113',
  '80110',
  '80111',
  '80112',
  '80516',
  '80402',
  '80419',
  '80401',
  '80403',
  '80634',
  '80639',
  '80632',
  '80633',
  '80638',
  '80631',
  '80121',
  '80160',
  '80161',
  '80165',
  '80166',
  '80120',
  '80123',
  '80122',
  '80162',
  '80128',
  '80163',
  '80130',
  '80129',
  '80126',
  '80127',
  '80125',
  '80504',
  '80501',
  '80502',
  '80503',
  '81004',
  '81005',
  '81003',
  '81009',
  '81010',
  '81011',
  '81012',
  '81002',
  '81007',
  '81006',
  '81001',
  '81008',
  '76621',
  '75001',
  '76008',
  '75013',
  '75002',
  '76009',
  '76225',
  '75409',
  '76622',
  '76226',
  '76006',
  '76011',
  '76094',
  '76096',
  '76003',
  '76004',
  '76005',
  '76007',
  '76012',
  '76010',
  '76019',
  '76014',
  '76013',
  '76015',
  '76018',
  '76016',
  '76017',
  '76002',
  '76001',
  '76227',
  '76623',
  '76098',
  '76020',
  '75413',
  '75180',
  '75101',
  '75102',
  '76021',
  '76022',
  '76095',
  '75414',
  '76626',
  '75424',
  '76627',
  '76230',
  '76023',
  '76628',
  '76426',
  '76097',
  '76028',
  '76631',
  '75135',
  '75422',
  '75006',
  '75011',
  '75007',
  '75010',
  '75104',
  '75106',
  '75423',
  '75009',
  '75105',
  '76431',
  '76031',
  '76033',
  '76034',
  '76233',
  '75121',
  '75099',
  '75019',
  '75110',
  '75151',
  '75109',
  '76636',
  '75114',
  '76035',
  '76036',
  '75261',
  '75368',
  '75247',
  '75211',
  '75212',
  '75220',
  '75236',
  '75234',
  '75235',
  '75229',
  '75233',
  '75209',
  '75249',
  '75208',
  '75260',
  '75358',
  '75207',
  '75219',
  '75267',
  '75342',
  '75370',
  '75224',
  '75262',
  '75263',
  '75398',
  '75242',
  '75202',
  '75270',
  '75201',
  '75244',
  '75221',
  '75222',
  '75250',
  '75264',
  '75265',
  '75266',
  '75275',
  '75277',
  '75283',
  '75284',
  '75285',
  '75301',
  '75303',
  '75312',
  '75313',
  '75315',
  '75320',
  '75326',
  '75336',
  '75339',
  '75354',
  '75355',
  '75356',
  '75357',
  '75359',
  '75360',
  '75367',
  '75371',
  '75372',
  '75373',
  '75374',
  '75376',
  '75378',
  '75379',
  '75380',
  '75381',
  '75382',
  '75389',
  '75390',
  '75391',
  '75392',
  '75393',
  '75394',
  '75395',
  '75397',
  '75237',
  '75205',
  '75204',
  '75225',
  '75203',
  '75230',
  '75226',
  '75246',
  '75206',
  '75232',
  '75254',
  '75215',
  '75240',
  '75251',
  '75216',
  '75287',
  '75223',
  '75214',
  '75248',
  '75231',
  '75210',
  '75243',
  '75252',
  '75241',
  '75218',
  '75238',
  '75227',
  '75228',
  '75217',
  '75253',
  '76639',
  '76234',
  '76439',
  '76210',
  '76205',
  '76208',
  '76202',
  '76203',
  '76204',
  '76206',
  '76207',
  '76201',
  '76209',
  '75115',
  '75123',
  '75116',
  '75138',
  '75137',
  '75439',
  '75117',
  '75118',
  '75120',
  '75119',
  '76238',
  '76040',
  '76039',
  '75442',
  '75132',
  '75125',
  '75028',
  '75022',
  '75027',
  '76239',
  '75126',
  '76041',
  '76155',
  '76120',
  '76118',
  '76112',
  '76148',
  '76103',
  '76137',
  '76105',
  '76190',
  '76119',
  '76111',
  '76104',
  '76166',
  '76102',
  '76101',
  '76113',
  '76121',
  '76124',
  '76130',
  '76136',
  '76147',
  '76150',
  '76161',
  '76162',
  '76163',
  '76181',
  '76185',
  '76191',
  '76192',
  '76193',
  '76195',
  '76196',
  '76197',
  '76198',
  '76199',
  '76177',
  '76164',
  '76106',
  '76131',
  '76110',
  '76140',
  '76115',
  '76129',
  '76122',
  '76107',
  '76134',
  '76114',
  '76109',
  '76133',
  '76179',
  '76132',
  '76123',
  '76135',
  '76116',
  '76108',
  '76126',
  '75036',
  '75034',
  '75033',
  '75035',
  '76641',
  '76241',
  '76240',
  '76043',
  '76044',
  '76049',
  '76048',
  '75050',
  '75053',
  '75051',
  '75052',
  '75054',
  '76050',
  '76051',
  '76099',
  '75402',
  '75403',
  '75404',
  '75401',
  '76246',
  '75058',
  '76117',
  '76052',
  '76645',
  '75459',
  '76648',
  '76053',
  '76054',
  '75141',
  '76650',
  '75062',
  '75061',
  '75038',
  '75014',
  '75015',
  '75016',
  '75017',
  '75060',
  '75039',
  '75059',
  '75063',
  '76651',
  '76055',
  '75164',
  '76058',
  '76247',
  '75142',
  '76059',
  '76248',
  '76244',
  '75143',
  '76060',
  '76652',
  '76249',
  '75065',
  '75134',
  '75146',
  '75166',
  '75452',
  '75067',
  '75029',
  '75057',
  '75077',
  '76061',
  '76250',
  '76462',
  '75068',
  '75453',
  '75147',
  '75156',
  '76660',
  '76063',
  '76064',
  '75070',
  '75072',
  '75069',
  '75071',
  '75454',
  '75458',
  '76666',
  '75150',
  '75149',
  '75185',
  '75187',
  '75181',
  '76065',
  '76670',
  '76066',
  '76068',
  '76067',
  '76671',
  '76252',
  '76253',
  '76127',
  '76070',
  '75173',
  '76071',
  '76180',
  '76182',
  '75152',
  '76467',
  '76073',
  '76485',
  '76676',
  '76486',
  '76258',
  '75472',
  '76259',
  '76487',
  '75153',
  '75407',
  '75078',
  '76679',
  '75474',
  '76077',
  '75475',
  '75154',
  '76078',
  '75155',
  '75083',
  '75085',
  '75080',
  '75081',
  '75082',
  '76093',
  '76262',
  '75087',
  '75032',
  '75157',
  '76263',
  '75030',
  '75088',
  '75089',
  '75189',
  '75048',
  '76264',
  '76265',
  '76266',
  '76472',
  '75479',
  '75158',
  '75159',
  '75092',
  '75090',
  '75091',
  '76267',
  '76092',
  '76268',
  '76082',
  '75182',
  '76270',
  '75160',
  '75161',
  '75056',
  '76271',
  '76476',
  '75489',
  '75490',
  '76272',
  '75495',
  '76084',
  '76690',
  '75167',
  '75168',
  '75165',
  '76085',
  '76086',
  '76087',
  '76088',
  '75485',
  '75097',
  '76273',
  '75491',
  '76692',
  '76490',
  '75169',
  '75172',
  '75496',
  '75098',
  '84004',
  '84003',
  '84006',
  '84011',
  '84010',
  '84013',
  '84014',
  '84089',
  '84016',
  '84015',
  '84017',
  '84018',
  '84020',
  '84024',
  '84310',
  '84626',
  '84025',
  '84633',
  '84029',
  '84032',
  '84033',
  '84096',
  '84056',
  '84315',
  '84317',
  '84318',
  '84319',
  '84036',
  '84037',
  '84041',
  '84040',
  '84043',
  '84042',
  '84044',
  '84325',
  '84047',
  '84049',
  '84326',
  '84645',
  '84054',
  '84055',
  '84402',
  '84407',
  '84409',
  '84412',
  '84201',
  '84244',
  '84401',
  '84404',
  '84415',
  '84414',
  '84057',
  '84058',
  '84097',
  '84328',
  '84098',
  '84060',
  '84068',
  '84651',
  '84061',
  '84062',
  '84332',
  '84604',
  '84602',
  '84601',
  '84603',
  '84605',
  '84606',
  '84065',
  '84067',
  '84069',
  '84653',
  '84116',
  '84104',
  '84180',
  '84114',
  '84101',
  '84138',
  '84110',
  '84122',
  '84125',
  '84126',
  '84127',
  '84130',
  '84131',
  '84132',
  '84134',
  '84139',
  '84141',
  '84143',
  '84145',
  '84147',
  '84148',
  '84150',
  '84151',
  '84158',
  '84165',
  '84170',
  '84171',
  '84184',
  '84190',
  '84199',
  '84103',
  '84111',
  '84133',
  '84102',
  '84112',
  '84105',
  '84115',
  '84113',
  '84106',
  '84157',
  '84123',
  '84118',
  '84107',
  '84108',
  '84117',
  '84129',
  '84109',
  '84124',
  '84152',
  '84121',
  '84090',
  '84091',
  '84093',
  '84070',
  '84094',
  '84092',
  '84655',
  '84045',
  '84095',
  '84009',
  '84660',
  '84663',
  '84075',
  '84074',
  '84080',
  '84059',
  '84082',
  '84339',
  '84084',
  '84088',
  '84081',
  '84119',
  '84120',
  '84128',
  '84087',
  'Zip Code',
  '77613',
  '77622',
  '77629',
  '77002',
  '77003',
  '77004',
  '77005',
  '77006',
  '77007',
  '77008',
  '77009',
  '77010',
  '77011',
  '77012',
  '77013',
  '77014',
  '77015',
  '77016',
  '77017',
  '77018',
  '77019',
  '77020',
  '77021',
  '77022',
  '77023',
  '77024',
  '77025',
  '77026',
  '77027',
  '77028',
  '77029',
  '77030',
  '77031',
  '77032',
  '77033',
  '77034',
  '77035',
  '77036',
  '77037',
  '77038',
  '77039',
  '77040',
  '77041',
  '77042',
  '77043',
  '77044',
  '77045',
  '77046',
  '77047',
  '77048',
  '77049',
  '77050',
  '77051',
  '77052',
  '77053',
  '77054',
  '77055',
  '77056',
  '77057',
  '77058',
  '77059',
  '77060',
  '77061',
  '77062',
  '77063',
  '77064',
  '77065',
  '77066',
  '77067',
  '77068',
  '77069',
  '77070',
  '77071',
  '77072',
  '77073',
  '77074',
  '77075',
  '77076',
  '77077',
  '77078',
  '77079',
  '77080',
  '77081',
  '77082',
  '77083',
  '77084',
  '77085',
  '77086',
  '77087',
  '77088',
  '77089',
  '77090',
  '77091',
  '77092',
  '77093',
  '77094',
  '77095',
  '77096',
  '77098',
  '77099',
  '77204',
  '77301',
  '77302',
  '77303',
  '77304',
  '77306',
  '77316',
  '77318',
  '77325',
  '77327',
  '77328',
  '77336',
  '77338',
  '77339',
  '77345',
  '77346',
  '77354',
  '77355',
  '77356',
  '77357',
  '77358',
  '77362',
  '77363',
  '77365',
  '77368',
  '77369',
  '77372',
  '77373',
  '77374',
  '77375',
  '77376',
  '77377',
  '77378',
  '77379',
  '77380',
  '77381',
  '77382',
  '77384',
  '77385',
  '77386',
  '77388',
  '77389',
  '77396',
  '77401',
  '77406',
  '77407',
  '77417',
  '77418',
  '77420',
  '77423',
  '77426',
  '77429',
  '77430',
  '77431',
  '77433',
  '77434',
  '77435',
  '77436',
  '77441',
  '77443',
  '77444',
  '77445',
  '77446',
  '77447',
  '77448',
  '77449',
  '77450',
  '77451',
  '77453',
  '77454',
  '77459',
  '77461',
  '77464',
  '77466',
  '77468',
  '77469',
  '77471',
  '77474',
  '77476',
  '77477',
  '77478',
  '77479',
  '77480',
  '77481',
  '77482',
  '77484',
  '77486',
  '77488',
  '77489',
  '77492',
  '77493',
  '77494',
  '77497',
  '77498',
  '77502',
  '77503',
  '77504',
  '77505',
  '77506',
  '77507',
  '77510',
  '77511',
  '77514',
  '77515',
  '77516',
  '77517',
  '77518',
  '77519',
  '77520',
  '77521',
  '77523',
  '77530',
  '77531',
  '77532',
  '77533',
  '77534',
  '77535',
  '77536',
  '77538',
  '77539',
  '77542',
  '77545',
  '77546',
  '77547',
  '77552',
  '77553',
  '77555',
  '77560',
  '77561',
  '77562',
  '77563',
  '77564',
  '77565',
  '77566',
  '77568',
  '77571',
  '77573',
  '77574',
  '77575',
  '77577',
  '77578',
  '77580',
  '77581',
  '77582',
  '77583',
  '77584',
  '77585',
  '77586',
  '77587',
  '77588',
  '77590',
  '77591',
  '77598',
  '77659',
  '77661',
  '77665',
  '77830',
  '77833',
  '77868',
  '77880',
  '78931',
  '78933',
  '78935',
  '78944',
  '78950',
  '89199',
  '89111',
  '89112',
  '89114',
  '89116',
  '89125',
  '89126',
  '89127',
  '89132',
  '89133',
  '89137',
  '89150',
  '89151',
  '89152',
  '89153',
  '89155',
  '89159',
  '89160',
  '89162',
  '89163',
  '89164',
  '89177',
  '89180',
  '89185',
  '89193',
  '89195',
  '89157',
  '89106',
  '89101',
  '89104',
  '89102',
  '89087',
  '89036',
  '89169',
  '89109',
  '89107',
  '89030',
  '89032',
  '89154',
  '89170',
  '89173',
  '89158',
  '89121',
  '89146',
  '89108',
  '89103',
  '89110',
  '89119',
  '89031',
  '89142',
  '89081',
  '89128',
  '89120',
  '89118',
  '89130',
  '89117',
  '89145',
  '89115',
  '89122',
  '89147',
  '89086',
  '89136',
  '89129',
  '89014',
  '89123',
  '89084',
  '89134',
  '89144',
  '89113',
  '89140',
  '89139',
  '89053',
  '89085',
  '89156',
  '89191',
  '89074',
  '89033',
  '89011',
  '89149',
  '89131',
  '89148',
  '89077',
  '89135',
  '89138',
  '89178',
  '89183',
  '89012',
  '89105',
  '89165',
  '89141',
  '88901',
  '88905',
  '89009',
  '89016',
  '89052',
  '89143',
  '89179',
  '89166',
  '89015',
  '89002',
  '89161',
  '89044',
  '89004',
  '89054',
  '89124',
  '89006',
  '89005',
  '89026',
  '89018',
  '89019',
  '89040',
  '89025'
  ]
  
  module.exports={approvedZipCodes}
  