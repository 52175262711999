import { inPersonZipCodes } from './inPersonZipCodes';
import { getIsInPersonZip, getIsZipCodeHouston } from './zipCodes';

export const leadProviders = [
  {
    nameInUrl: 'phi',
    abbreviation: 'P16-3',
    nameInYcbm: 'PHI CONSULTING',
    nameInLeadGenApp: 'taylor_williams'
  },
  {
    nameInUrl: 'solar-link',
    abbreviation: 'SLA-6',
    nameInYcbm: 'SOLAR LINK',
    nameInLeadGenApp: 'Solar Link'
  },
  {
    nameInUrl: 'jayden-smith',
    abbreviation: 'MCH',
    nameInYcbm: 'JAYDEN SMITH',
    nameInLeadGenApp: 'internal_sales_team'
  },
  {
    nameInUrl: 'maria-rodriguez',
    abbreviation: 'mar',
    nameInYcbm: 'MARIA RODRIGUEZ',
    nameInLeadGenApp: 'spanish_sales_team'
  },
  {
    nameInUrl: 'verse',
    abbreviation: 'SRV-03',
    nameInYcbm: 'VERSE',
    nameInLeadGenApp: 'Verse'
  },
  {
    nameInUrl: 'll-media',
    abbreviation: 'PMB 5',
    nameInYcbm: 'LL MEDIA',
    nameInLeadGenApp: 'LL Media Appointments'
  },
  {
    nameInUrl: 'energy-wyze',
    abbreviation: 'YRS-Z',
    nameInYcbm: 'ENERGY WYZE',
    nameInLeadGenApp: 'Energy Wyze'
  },
  {
    //formerly appt
    nameInUrl: 'sessa-solutions',
    abbreviation: 'SS-8',
    nameInYcbm: 'SESSA SOLUTIONS',
    nameInLeadGenApp: 'Sessa Solutions'
  },
  {
    nameInUrl: 'solarise',
    abbreviation: 'ITRG/E',
    nameInYcbm: 'SOLARISE',
    nameInLeadGenApp: 'Solarise Appointments'
  },
  {
    nameInUrl: 'snk-media',
    abbreviation: 'MDSK/',
    nameInYcbm: 'SNK MEDIA',
    nameInLeadGenApp: 'SNK Media'
  },
  {
    nameInUrl: '0',
    abbreviation: 'cus',
    nameInYcbm: 'CUSTOMER',
    nameInLeadGenApp: 'customer'
  },
  {
    nameInUrl: 'cortez-capital',
    abbreviation: 'Cortez Capital',
    nameInYcbm: 'CORTEZ CAPITAL',
    nameInLeadGenApp: 'Cortez Capital'
  },
  {
    nameInUrl: 'self-generated',
    abbreviation: 'CA',
    nameInYcbm: 'SELF GENERATED',
    nameInLeadGenApp: 'self-generated'
  },
  {
    nameInUrl: 'self-generation',
    abbreviation: 'CA',
    nameInYcbm: 'SELF GENERATED',
    nameInLeadGenApp: 'self-generated'
  },
  {
    nameInUrl: 'solar-boss',
    abbreviation: 'SHF',
    nameInYcbm: 'SOLAR BOSS',
    nameInLeadGenApp: 'solar-boss'
  }
]

export const parseYcbmLeadProvider = (leadProviderFromUrl, type = "ycbm") => {
  if (!!leadProviderFromUrl) {
    const foundLeadProvider = leadProviders.find(leadProvider => leadProvider.nameInUrl === leadProviderFromUrl);
    if (!!foundLeadProvider) {
      if (type === "ycbm") {
        if (!!foundLeadProvider.nameInYcbm) {
          return foundLeadProvider.nameInYcbm;
        } else {
          return leadProviderFromUrl;
        }
      } else if (type === "abbreviation") {
        if (!!foundLeadProvider.abbreviation) {
          return foundLeadProvider.abbreviation;
        } else {
          return leadProviderFromUrl;
        }
      }
    } else {
      return leadProviderFromUrl;
    }
  }
}

export const createAppointmentCode = (leadProviderFromUrl, providedAppointmentType, zipCode) => {
  let appointmentType = providedAppointmentType;
  if (!appointmentType && !!zipCode) {
    const isInPersonZip = getIsInPersonZip(zipCode);
    if (isInPersonZip) {
      appointmentType = "in-person";
    } else {
      appointmentType = "online";
    }
  }

  const abbreviation = !!leadProviderFromUrl ? parseYcbmLeadProvider(leadProviderFromUrl, "abbreviation") : "n/a";
  let appointmentAbbrevation = '';
  if (!!appointmentType) {
    if (appointmentType === "online") {
      appointmentAbbrevation = "onl";
    } else if (appointmentType === "in-person") {
      appointmentAbbrevation = "inp";
    } else {
      appointmentAbbrevation = "n/a";
    }
  }
  return abbreviation + "_" + appointmentAbbrevation;
}

export const getCalendar = (providedZipCode, providedLanguage, leadProvider) => {
  const isZipCodeHouston = getIsZipCodeHouston(providedZipCode);
  console.log({ leadProvider })
  if (!!leadProvider) {
    if (leadProvider === "CORTEZ CAPITAL") {
      return {
        id: 'ycbmiframecortez-capital',
        url: 'https://cortez-capital.youcanbook.me',
        index: 0
      }
    } else if (leadProvider === "SELF GENERATED") {
      return {
        id: 'ycbmiframesungrade-self-generation',
        url: 'https://sungrade-self-generation.youcanbook.me',
        index: 1
      }
    }
  }
  // override all calendar links and only use the following until further notice
  return {
    
    id: 'ycbmiframeaspen-aaron-katz',
    url: 'https://aspen-aaron-katz.youcanbook.me',
    index: 2
  }
  const calendar = {
    id: '',
    url: ''
  }
  let language = 'english';
  // temporarily don't use any spanish-only calendars
  // if(providedLanguage === 'spanish' || !!leadProvider && leadProvider === "maria-rodriguez") {
  //   language = 'spanish'
  // }

  const zipCode = parseInt(providedZipCode);
  if (!!zipCode) {
    const match = inPersonZipCodes.find(item => item.zipCode == zipCode);
    if (!!match) {
      if (!!match[language] && !!match[language].calendarUrl) {
        calendar.id = match[language].calendarId;
        calendar.url = match[language].calendarUrl;
      }
    }
  }

  if (!calendar.id) {
    calendar.id = "https://sungrade-online-new.youcanbook.me/";
    calendar.url = "ycbmiframesungrade-online-new";
  }

  return calendar;
}

export const getAndParseFinalUtilityProviderFromAppState = appState => {
  let utilityProvider = '';
  if (!!appState) {
    utilityProvider = appState.enteredUtilityProvider || appState.utilityProvider || '';
    if (!!utilityProvider) {
      utilityProvider = encodeURIComponent(utilityProvider);
      // utilityProvider = utilityProvider.replace('&', '%26');
    }
  }
  return utilityProvider;
}
