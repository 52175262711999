import React from 'react';
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router';
import {
  Form,
  Button,
  Container,
  Col,
  Row
} from "react-bootstrap";
import {
  createAppointmentCode,
  getCalendar,
  parseYcbmLeadProvider,
  getAndParseFinalUtilityProviderFromAppState
} from '../services/strings';
import { generateRoute } from '../services/routes';
import {
  getContent
} from '../services/language';

function InPersonAppointmentPage(props) {
  const history = useHistory();
  const params = useParams();
  const queryString = useLocation().search;
  function useQuery() {
    return new URLSearchParams(queryString);
  }
  const query = useQuery();
  let firstName = query.get("firstName");
  if (!firstName) { firstName = '' }
  let lastName = query.get("lastName");
  if (!lastName) { lastName = '' }
  let email = query.get("email");
  if (!email) { email = '' }
  let phoneNumber = query.get("phoneNumber");
  if (!phoneNumber) { phoneNumber = '' }
  let notes = query.get("notes");
  if (!notes) { notes = '' }
  const { leadId } = params;
  const leadProvider = parseYcbmLeadProvider(params.leadProvider);
  const url = !!window && !!window.location && window.location.href;
  const location = !!window && !!window.location && window.location.origin;
  const zipCode = !!props.appState && props.appState.zipCode ? props.appState.zipCode : (!!params && params.zipCode);
  const zipFromGoogleMapsAddress = !!props.appState && props.appState.zipFromGoogleMapsAddress;
  const region = !!props.appState && props.appState.region;
  const abbreviation = createAppointmentCode(params.leadProvider, !!props.appState && props.appState.appointmentType, (!!props.appState && !!props.appState.zipCode) ? props.appState.zipCode : zipCode);
  let address = !!props.appState && props.appState.address;
  const encodedAddress = encodeURIComponent(address);
  let lat = !!props.appState && props.appState.lat ? props.appState.lat : (!!params && params.lat);
  let lng = !!props.appState && props.appState.lng ? props.appState.lng : (!!params && params.lng);
  if (!address && !!params && !!params.address) {
    address = params.address;
  }
  // https://sungrade-main.youcanbook.me/?FNAME=firstName&LNAME=&EMAIL=&NOTES=&PHONE=&ZIP=80007&LEADPROVIDER=CUSTOMER&LEADID=0&REGION=&ADDRESS=Valley%20Vista%20Rd%2C%20Bonita%2C%20CA%2091902%2C%20USA&LAT=32.6583997&LNG=-117.0484879&USER_URL=http://localhost:3000&CODE=cus_inp&LANGUAGE=english&UTILITY=&ZIP_GOOGLE_MAPS=91902
  // https://sungrade-main.youcanbook.me/?FNAME=firstName&LNAME=&EMAIL=&NOTES=&PHONE=&ZIP=80007&LEADPROVIDER=CUSTOMER&LEADID=0
  // https://sungrade-main.youcanbook.me/?FNAME=testfirstName&LNAME=&EMAIL=&NOTES=
  // https://sungrade-main.youcanbook.me/?FNAME=testfirstName&LNAME=&EMAIL=olivia.keene@sungrade.solar&NOTES=
  // https://sungrade-main.youcanbook.me/?FNAME=testfirstName&LNAME=lastName&EMAIL=olivia.keene@sungrade.solar&NOTES=
  // https://sungrade-main.youcanbook.me/?FNAME=firstName&LNAME=lastName&EMAIL=email&NOTES=notes&PHONE=4079860278&ZIP=80007&LEADPROVIDER=CUSTOMER&LEADID=0&REGION=&ADDRESS=Valley%20Vista%20Rd%2C%20Bonita%2C%20CA%2091902%2C%20USA&LAT=32.6583997&LNG=-117.0484879&USER_URL=http://localhost:3000&CODE=cus_inp&LANGUAGE=english&UTILITY=&ZIP_GOOGLE_MAPS=91902
  // https://sungrade-main.youcanbook.me/?FNAME=firstName&LNAME=lastName&EMAIL=olivia.keene@sungrade.solar&NOTES=notes&PHONE=4079860278&ZIP=80007&LEADPROVIDER=CUSTOMER&LEADID=0&REGION=&ADDRESS=Valley%20Vista%20Rd%2C%20Bonita%2C%20CA%2091902%2C%20USA&LAT=32.6583997&LNG=-117.0484879&CODE=cus_inp&LANGUAGE=english&UTILITY=abc&ZIP_GOOGLE_MAPS=91902
  const handleChangeRoute = (e, page) => {
    e.preventDefault();
    const route = generateRoute(params, page, null, queryString);
    history.push(route);
  }

  console.log({
    zipCode,
    zipFromGoogleMapsAddress,
    leadProvider,
    leadId,
    region,
    address,
    lat,
    lng,
    url,
    abbreviation
  })

  const calendar = getCalendar(zipCode, props.appState.language, leadProvider, region);
  const utilityProvider = getAndParseFinalUtilityProviderFromAppState(props.appState);
  let calendarUrl = calendar.url;
  let queryParameters = '';
  if(!!calendarUrl) {
    queryParameters += 'noframe=true&skipHeaderFooter=true';
    if(!!firstName) {
      queryParameters += `${!!queryParameters ? "&" : ""}FNAME=${firstName}`;
    }
    if(!!lastName) {
      queryParameters += `${!!queryParameters ? "&" : ""}LNAME=${lastName}`;
    }
    if(!!email) {
      queryParameters += `${!!queryParameters ? "&" : ""}EMAIL=${email}`;
    }
    if(!!notes) {
      queryParameters += `${!!queryParameters ? "&" : ""}NOTES=${notes}`;
    }
    if(!!phoneNumber) {
      queryParameters += `${!!queryParameters ? "&" : ""}phone=${phoneNumber}`;
    }
    if(!!zipCode) {
      queryParameters += `${!!queryParameters ? "&" : ""}ZIP=${zipCode}`;
    }
    if(!!leadProvider) {
      queryParameters += `${!!queryParameters ? "&" : ""}LEADPROVIDER=${leadProvider}`;
    }
    if(!!leadId) {
      queryParameters += `${!!queryParameters ? "&" : ""}LEADID=${leadId}`;
    }
    if(!!region) {
      queryParameters += `${!!queryParameters ? "&" : ""}REGION=${region}`;
    }
    if(!!encodedAddress) {
      queryParameters += `${!!queryParameters ? "&" : ""}ADDRESS=${encodedAddress}`;
    }
    if(!!lat) {
      queryParameters += `${!!queryParameters ? "&" : ""}LAT=${lat}`;
    }
    if(!!lng) {
      queryParameters += `${!!queryParameters ? "&" : ""}LNG=${lng}`;
    }
    if(!!location) {
      queryParameters += `${!!queryParameters ? "&" : ""}USER_URL=${location}`;
    }
    if(!!abbreviation) {
      queryParameters += `${!!queryParameters ? "&" : ""}CODE=${abbreviation}`;
    }
    if(!!props.appState.language) {
      queryParameters += `${!!queryParameters ? "&" : ""}LANGUAGE=${props.appState.language}`;
    }
    if(!!utilityProvider) {
      queryParameters += `${!!queryParameters ? "&" : ""}UTILITY=${utilityProvider}`;
    }
    if(!!zipFromGoogleMapsAddress) {
      queryParameters += `${!!queryParameters ? "&" : ""}ZIP_GOOGLE_MAPS=${zipFromGoogleMapsAddress}`;
    }
  }
  if(!!queryParameters) {
    calendarUrl += "/?" + queryParameters;
  }
  console.log(JSON.stringify(calendarUrl));
  return (
    <Container className="mt-5">
      <Row>
        <Col className="noBorderRadious">
          <iframe
            src={calendarUrl}
            id={calendar.id}
            style={{ width: "100%", minHeight: '1290px', border: '0px', backgroundColor: "transparent" }}
            scrolling="no"
            seamless="seamless"
            frameBorder="0"
            allowtransparency="true"
            title="Sungrade Appointments - You Can Book Me"
          />
        </Col>
      </Row>
      <Row>
        <Col className="noBorderRadiousTop">
          <Button className="btn btn-secondary" variant="outline" onClick={e => handleChangeRoute(e, 'address')}>
            {getContent('back', props.appState.language)}
          </Button>
        </Col>
      </Row>

    </Container>
  )
}

export default InPersonAppointmentPage;
