import React, { useState } from 'react';
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router';
import {
  Form,
  Button,
  Container,
  Col,
  Row
} from "react-bootstrap";
import { generateRoute } from '../services/routes';
import {
  getContent
} from '../services/language';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function UtilityProviderPage(props) {
  const history = useHistory();
  const params = useParams();
  const queryString = useLocation().search;

  function useQuery() {
    return new URLSearchParams(queryString);
  }
  const query = useQuery();

  let defaultUtilityProvider = '';
  let defaultEnteredUtilityProvider = '';
  if (!!props.appState) {
    defaultUtilityProvider = props.appState.utilityProvider;
    defaultEnteredUtilityProvider = props.appState.enteredUtilityProvider;
  }
  if (!defaultUtilityProvider) {
    const utilityProviderFromUrl = query.get("utilityProvider");
    if (!!utilityProviderFromUrl) {
      const parsedUtilityProviderFromUrl = decodeURIComponent(utilityProviderFromUrl);
      defaultUtilityProvider = parsedUtilityProviderFromUrl;
      // defaultEnteredUtilityProvider = parsedUtilityProviderFromUrl;
    }
  }
  const initialState = {
    utilityProvider: defaultUtilityProvider,
    enteredUtilityProvider: defaultEnteredUtilityProvider
  }

  const [state, setState] = useState(initialState);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleGoBack = e => {
    e.preventDefault();
    const isSelfGenerated = !!params && !!params.leadProvider && ['self-generated', 'self-generation'].indexOf(params.leadProvider) > -1;
    const page = !!isSelfGenerated ? "user" : "zip";
    const route = generateRoute(params, page, null, queryString);
    history.push(route);
  }

  const handleNext = e => {
    e.preventDefault();
    const utilityProvider = state.utilityProvider;
    let enteredUtilityProvider = state.enteredUtilityProvider;
    if (!!utilityProvider && utilityProvider !== 'Other') {
      enteredUtilityProvider = '';
    }
    props.setAppState({ utilityProvider, enteredUtilityProvider });
    const route = generateRoute(params, "address", null, queryString);
    history.push(route);
  }

  const {
    utilityProvider,
    enteredUtilityProvider
  } = state;

  let allowSubmission = false;
  if (!!utilityProvider) {
    if (utilityProvider === 'Other') {
      if (!!enteredUtilityProvider) {
        allowSubmission = true;
      }
    } else {
      if ([
        "PG&E - Pacific Gas & Electric",
        "SCE - Southern California Edison",
        "SDG&E - San Diego Gas & Electric"
        // "SMUD - Sacramento Municipal Utility District"
      ].includes(utilityProvider)) {
        allowSubmission = true;
      }
    }
  }

  return (
    <Container className="mt-5">
      <Row className="rowWide">
        <Col className="text-center">
          <Form className="maxWidth-90" onSubmit={handleNext} value={utilityProvider}>
            <Form.Group controlId="utilityProvider">
              <Form.Label>{getContent('enterUtilityProvider', props.appState.language)}</Form.Label>
              <Form.Select
                className="select-center"
                value={utilityProvider}
                name="utilityProvider"
                onChange={handleChange}
              >
                <option id=""></option>
                <option
                  id="PG&E - Pacific Gas & Electric"
                >PG&E - Pacific Gas & Electric</option>
                <option
                  id="SCE - Southern California Edison"
                >SCE - Southern California Edison</option>
                <option
                  id="SDG&E - San Diego Gas & Electric"
                >SDG&E - San Diego Gas & Electric</option>
                {/* <option
                  id="SMUD - Sacramento Municipal Utility District"
                >SMUD - Sacramento Municipal Utility District</option> */}
                <option
                  id="Other"
                >Other</option>
              </Form.Select>
            </Form.Group>
            {!!utilityProvider && utilityProvider === "Other" && <Form.Group className="mt-20" controlId="enteredUtilityProvider">
              <Form.Control
                className="text-center"
                type="text"
                placeholder={getContent('utilityProvider', props.appState.language)}
                name="enteredUtilityProvider"
                onChange={handleChange}
                value={enteredUtilityProvider}
              />
            </Form.Group>}
            <div className="flexCenter spaceBetween mt-20">
              <Button className="btn btn-secondary" variant="outline" onClick={handleGoBack}>
                {getContent('back', props.appState.language)}
              </Button>
              <Button variant="primary" type="submit" disabled={!allowSubmission}>
                {getContent('next', props.appState.language)}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>

  )
}

export default UtilityProviderPage;
