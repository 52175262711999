const zipCodesUsps = [
	{"zipCode":"71854","state":"TX"},
	{"zipCode":"75001","state":"TX"},
	{"zipCode":"75002","state":"TX"},
	{"zipCode":"75006","state":"TX"},
	{"zipCode":"75007","state":"TX"},
	{"zipCode":"75009","state":"TX"},
	{"zipCode":"75010","state":"TX"},
	{"zipCode":"75011","state":"TX"},
	{"zipCode":"75011","state":"TX"},
	{"zipCode":"75013","state":"TX"},
	{"zipCode":"75014","state":"TX"},
	{"zipCode":"75015","state":"TX"},
	{"zipCode":"75016","state":"TX"},
	{"zipCode":"75017","state":"TX"},
	{"zipCode":"75019","state":"TX"},
	{"zipCode":"75020","state":"TX"},
	{"zipCode":"75021","state":"TX"},
	{"zipCode":"75022","state":"TX"},
	{"zipCode":"75023","state":"TX"},
	{"zipCode":"75024","state":"TX"},
	{"zipCode":"75025","state":"TX"},
	{"zipCode":"75026","state":"TX"},
	{"zipCode":"75027","state":"TX"},
	{"zipCode":"75028","state":"TX"},
	{"zipCode":"75029","state":"TX"},
	{"zipCode":"75030","state":"TX"},
	{"zipCode":"75032","state":"TX"},
	{"zipCode":"75033","state":"TX"},
	{"zipCode":"75034","state":"TX"},
	{"zipCode":"75035","state":"TX"},
	{"zipCode":"75035","state":"TX"},
	{"zipCode":"75036","state":"TX"},
	{"zipCode":"75038","state":"TX"},
	{"zipCode":"75039","state":"TX"},
	{"zipCode":"75040","state":"TX"},
	{"zipCode":"75041","state":"TX"},
	{"zipCode":"75042","state":"TX"},
	{"zipCode":"75043","state":"TX"},
	{"zipCode":"75044","state":"TX"},
	{"zipCode":"75045","state":"TX"},
	{"zipCode":"75046","state":"TX"},
	{"zipCode":"75047","state":"TX"},
	{"zipCode":"75048","state":"TX"},
	{"zipCode":"75049","state":"TX"},
	{"zipCode":"75050","state":"TX"},
	{"zipCode":"75051","state":"TX"},
	{"zipCode":"75052","state":"TX"},
	{"zipCode":"75053","state":"TX"},
	{"zipCode":"75053","state":"TX"},
	{"zipCode":"75054","state":"TX"},
	{"zipCode":"75056","state":"TX"},
	{"zipCode":"75056","state":"TX"},
	{"zipCode":"75057","state":"TX"},
	{"zipCode":"75058","state":"TX"},
	{"zipCode":"75060","state":"TX"},
	{"zipCode":"75061","state":"TX"},
	{"zipCode":"75062","state":"TX"},
	{"zipCode":"75063","state":"TX"},
	{"zipCode":"75065","state":"TX"},
	{"zipCode":"75067","state":"TX"},
	{"zipCode":"75067","state":"TX"},
	{"zipCode":"75068","state":"TX"},
	{"zipCode":"75069","state":"TX"},
	{"zipCode":"75070","state":"TX"},
	{"zipCode":"75071","state":"TX"},
	{"zipCode":"75071","state":"TX"},
	{"zipCode":"75072","state":"TX"},
	{"zipCode":"75074","state":"TX"},
	{"zipCode":"75075","state":"TX"},
	{"zipCode":"75076","state":"TX"},
	{"zipCode":"75077","state":"TX"},
	{"zipCode":"75078","state":"TX"},
	{"zipCode":"75080","state":"TX"},
	{"zipCode":"75081","state":"TX"},
	{"zipCode":"75082","state":"TX"},
	{"zipCode":"75083","state":"TX"},
	{"zipCode":"75083","state":"TX"},
	{"zipCode":"75085","state":"TX"},
	{"zipCode":"75086","state":"TX"},
	{"zipCode":"75086","state":"TX"},
	{"zipCode":"75086","state":"TX"},
	{"zipCode":"75087","state":"TX"},
	{"zipCode":"75088","state":"TX"},
	{"zipCode":"75089","state":"TX"},
	{"zipCode":"75090","state":"TX"},
	{"zipCode":"75091","state":"TX"},
	{"zipCode":"75092","state":"TX"},
	{"zipCode":"75093","state":"TX"},
	{"zipCode":"75094","state":"TX"},
	{"zipCode":"75097","state":"TX"},
	{"zipCode":"75098","state":"TX"},
	{"zipCode":"75101","state":"TX"},
	{"zipCode":"75102","state":"TX"},
	{"zipCode":"75103","state":"TX"},
	{"zipCode":"75104","state":"TX"},
	{"zipCode":"75105","state":"TX"},
	{"zipCode":"75106","state":"TX"},
	{"zipCode":"75109","state":"TX"},
	{"zipCode":"75110","state":"TX"},
	{"zipCode":"75114","state":"TX"},
	{"zipCode":"75115","state":"TX"},
	{"zipCode":"75116","state":"TX"},
	{"zipCode":"75117","state":"TX"},
	{"zipCode":"75118","state":"TX"},
	{"zipCode":"75119","state":"TX"},
	{"zipCode":"75120","state":"TX"},
	{"zipCode":"75121","state":"TX"},
	{"zipCode":"75123","state":"TX"},
	{"zipCode":"75124","state":"TX"},
	{"zipCode":"75125","state":"TX"},
	{"zipCode":"75126","state":"TX"},
	{"zipCode":"75127","state":"TX"},
	{"zipCode":"75132","state":"TX"},
	{"zipCode":"75134","state":"TX"},
	{"zipCode":"75135","state":"TX"},
	{"zipCode":"75137","state":"TX"},
	{"zipCode":"75138","state":"TX"},
	{"zipCode":"75140","state":"TX"},
	{"zipCode":"75141","state":"TX"},
	{"zipCode":"75141","state":"TX"},
	{"zipCode":"75142","state":"TX"},
	{"zipCode":"75143","state":"TX"},
	{"zipCode":"75143","state":"TX"},
	{"zipCode":"75144","state":"TX"},
	{"zipCode":"75146","state":"TX"},
	{"zipCode":"75147","state":"TX"},
	{"zipCode":"75148","state":"TX"},
	{"zipCode":"75149","state":"TX"},
	{"zipCode":"75150","state":"TX"},
	{"zipCode":"75151","state":"TX"},
	{"zipCode":"75152","state":"TX"},
	{"zipCode":"75153","state":"TX"},
	{"zipCode":"75154","state":"TX"},
	{"zipCode":"75155","state":"TX"},
	{"zipCode":"75156","state":"TX"},
	{"zipCode":"75157","state":"TX"},
	{"zipCode":"75158","state":"TX"},
	{"zipCode":"75159","state":"TX"},
	{"zipCode":"75160","state":"TX"},
	{"zipCode":"75161","state":"TX"},
	{"zipCode":"75163","state":"TX"},
	{"zipCode":"75164","state":"TX"},
	{"zipCode":"75165","state":"TX"},
	{"zipCode":"75166","state":"TX"},
	{"zipCode":"75167","state":"TX"},
	{"zipCode":"75168","state":"TX"},
	{"zipCode":"75169","state":"TX"},
	{"zipCode":"75172","state":"TX"},
	{"zipCode":"75173","state":"TX"},
	{"zipCode":"75180","state":"TX"},
	{"zipCode":"75180","state":"TX"},
	{"zipCode":"75181","state":"TX"},
	{"zipCode":"75182","state":"TX"},
	{"zipCode":"75185","state":"TX"},
	{"zipCode":"75187","state":"TX"},
	{"zipCode":"75189","state":"TX"},
	{"zipCode":"75201","state":"TX"},
	{"zipCode":"75202","state":"TX"},
	{"zipCode":"75203","state":"TX"},
	{"zipCode":"75204","state":"TX"},
	{"zipCode":"75205","state":"TX"},
	{"zipCode":"75206","state":"TX"},
	{"zipCode":"75207","state":"TX"},
	{"zipCode":"75208","state":"TX"},
	{"zipCode":"75209","state":"TX"},
	{"zipCode":"75210","state":"TX"},
	{"zipCode":"75211","state":"TX"},
	{"zipCode":"75212","state":"TX"},
	{"zipCode":"75214","state":"TX"},
	{"zipCode":"75215","state":"TX"},
	{"zipCode":"75216","state":"TX"},
	{"zipCode":"75217","state":"TX"},
	{"zipCode":"75218","state":"TX"},
	{"zipCode":"75219","state":"TX"},
	{"zipCode":"75219","state":"TX"},
	{"zipCode":"75220","state":"TX"},
	{"zipCode":"75221","state":"TX"},
	{"zipCode":"75222","state":"TX"},
	{"zipCode":"75223","state":"TX"},
	{"zipCode":"75224","state":"TX"},
	{"zipCode":"75225","state":"TX"},
	{"zipCode":"75226","state":"TX"},
	{"zipCode":"75227","state":"TX"},
	{"zipCode":"75228","state":"TX"},
	{"zipCode":"75229","state":"TX"},
	{"zipCode":"75230","state":"TX"},
	{"zipCode":"75231","state":"TX"},
	{"zipCode":"75232","state":"TX"},
	{"zipCode":"75233","state":"TX"},
	{"zipCode":"75234","state":"TX"},
	{"zipCode":"75235","state":"TX"},
	{"zipCode":"75236","state":"TX"},
	{"zipCode":"75237","state":"TX"},
	{"zipCode":"75238","state":"TX"},
	{"zipCode":"75238","state":"TX"},
	{"zipCode":"75240","state":"TX"},
	{"zipCode":"75241","state":"TX"},
	{"zipCode":"75242","state":"TX"},
	{"zipCode":"75243","state":"TX"},
	{"zipCode":"75244","state":"TX"},
	{"zipCode":"75246","state":"TX"},
	{"zipCode":"75247","state":"TX"},
	{"zipCode":"75248","state":"TX"},
	{"zipCode":"75249","state":"TX"},
	{"zipCode":"75250","state":"TX"},
	{"zipCode":"75251","state":"TX"},
	{"zipCode":"75252","state":"TX"},
	{"zipCode":"75253","state":"TX"},
	{"zipCode":"75254","state":"TX"},
	{"zipCode":"75261","state":"TX"},
	{"zipCode":"75262","state":"TX"},
	{"zipCode":"75263","state":"TX"},
	{"zipCode":"75264","state":"TX"},
	{"zipCode":"75265","state":"TX"},
	{"zipCode":"75266","state":"TX"},
	{"zipCode":"75267","state":"TX"},
	{"zipCode":"75270","state":"TX"},
	{"zipCode":"75287","state":"TX"},
	{"zipCode":"75313","state":"TX"},
	{"zipCode":"75315","state":"TX"},
	{"zipCode":"75336","state":"TX"},
	{"zipCode":"75339","state":"TX"},
	{"zipCode":"75342","state":"TX"},
	{"zipCode":"75354","state":"TX"},
	{"zipCode":"75355","state":"TX"},
	{"zipCode":"75356","state":"TX"},
	{"zipCode":"75357","state":"TX"},
	{"zipCode":"75360","state":"TX"},
	{"zipCode":"75367","state":"TX"},
	{"zipCode":"75370","state":"TX"},
	{"zipCode":"75371","state":"TX"},
	{"zipCode":"75372","state":"TX"},
	{"zipCode":"75374","state":"TX"},
	{"zipCode":"75376","state":"TX"},
	{"zipCode":"75378","state":"TX"},
	{"zipCode":"75379","state":"TX"},
	{"zipCode":"75380","state":"TX"},
	{"zipCode":"75381","state":"TX"},
	{"zipCode":"75382","state":"TX"},
	{"zipCode":"75401","state":"TX"},
	{"zipCode":"75402","state":"TX"},
	{"zipCode":"75403","state":"TX"},
	{"zipCode":"75404","state":"TX"},
	{"zipCode":"75407","state":"TX"},
	{"zipCode":"75409","state":"TX"},
	{"zipCode":"75410","state":"TX"},
	{"zipCode":"75411","state":"TX"},
	{"zipCode":"75412","state":"TX"},
	{"zipCode":"75412","state":"TX"},
	{"zipCode":"75413","state":"TX"},
	{"zipCode":"75414","state":"TX"},
	{"zipCode":"75415","state":"TX"},
	{"zipCode":"75416","state":"TX"},
	{"zipCode":"75417","state":"TX"},
	{"zipCode":"75418","state":"TX"},
	{"zipCode":"75420","state":"TX"},
	{"zipCode":"75421","state":"TX"},
	{"zipCode":"75422","state":"TX"},
	{"zipCode":"75422","state":"TX"},
	{"zipCode":"75423","state":"TX"},
	{"zipCode":"75424","state":"TX"},
	{"zipCode":"75425","state":"TX"},
	{"zipCode":"75426","state":"TX"},
	{"zipCode":"75428","state":"TX"},
	{"zipCode":"75429","state":"TX"},
	{"zipCode":"75429","state":"TX"},
	{"zipCode":"75431","state":"TX"},
	{"zipCode":"75432","state":"TX"},
	{"zipCode":"75433","state":"TX"},
	{"zipCode":"75434","state":"TX"},
	{"zipCode":"75435","state":"TX"},
	{"zipCode":"75436","state":"TX"},
	{"zipCode":"75437","state":"TX"},
	{"zipCode":"75438","state":"TX"},
	{"zipCode":"75439","state":"TX"},
	{"zipCode":"75440","state":"TX"},
	{"zipCode":"75441","state":"TX"},
	{"zipCode":"75442","state":"TX"},
	{"zipCode":"75443","state":"TX"},
	{"zipCode":"75444","state":"TX"},
	{"zipCode":"75446","state":"TX"},
	{"zipCode":"75447","state":"TX"},
	{"zipCode":"75448","state":"TX"},
	{"zipCode":"75449","state":"TX"},
	{"zipCode":"75450","state":"TX"},
	{"zipCode":"75451","state":"TX"},
	{"zipCode":"75452","state":"TX"},
	{"zipCode":"75453","state":"TX"},
	{"zipCode":"75454","state":"TX"},
	{"zipCode":"75455","state":"TX"},
	{"zipCode":"75456","state":"TX"},
	{"zipCode":"75457","state":"TX"},
	{"zipCode":"75458","state":"TX"},
	{"zipCode":"75459","state":"TX"},
	{"zipCode":"75460","state":"TX"},
	{"zipCode":"75461","state":"TX"},
	{"zipCode":"75462","state":"TX"},
	{"zipCode":"75468","state":"TX"},
	{"zipCode":"75469","state":"TX"},
	{"zipCode":"75470","state":"TX"},
	{"zipCode":"75471","state":"TX"},
	{"zipCode":"75472","state":"TX"},
	{"zipCode":"75473","state":"TX"},
	{"zipCode":"75473","state":"TX"},
	{"zipCode":"75474","state":"TX"},
	{"zipCode":"75475","state":"TX"},
	{"zipCode":"75476","state":"TX"},
	{"zipCode":"75476","state":"TX"},
	{"zipCode":"75477","state":"TX"},
	{"zipCode":"75478","state":"TX"},
	{"zipCode":"75478","state":"TX"},
	{"zipCode":"75479","state":"TX"},
	{"zipCode":"75479","state":"TX"},
	{"zipCode":"75480","state":"TX"},
	{"zipCode":"75481","state":"TX"},
	{"zipCode":"75482","state":"TX"},
	{"zipCode":"75483","state":"TX"},
	{"zipCode":"75485","state":"TX"},
	{"zipCode":"75486","state":"TX"},
	{"zipCode":"75487","state":"TX"},
	{"zipCode":"75488","state":"TX"},
	{"zipCode":"75489","state":"TX"},
	{"zipCode":"75490","state":"TX"},
	{"zipCode":"75491","state":"TX"},
	{"zipCode":"75492","state":"TX"},
	{"zipCode":"75493","state":"TX"},
	{"zipCode":"75494","state":"TX"},
	{"zipCode":"75495","state":"TX"},
	{"zipCode":"75496","state":"TX"},
	{"zipCode":"75497","state":"TX"},
	{"zipCode":"75501","state":"TX"},
	{"zipCode":"75503","state":"TX"},
	{"zipCode":"75505","state":"TX"},
	{"zipCode":"75550","state":"TX"},
	{"zipCode":"75551","state":"TX"},
	{"zipCode":"75554","state":"TX"},
	{"zipCode":"75555","state":"TX"},
	{"zipCode":"75556","state":"TX"},
	{"zipCode":"75558","state":"TX"},
	{"zipCode":"75559","state":"TX"},
	{"zipCode":"75560","state":"TX"},
	{"zipCode":"75561","state":"TX"},
	{"zipCode":"75562","state":"TX"},
	{"zipCode":"75563","state":"TX"},
	{"zipCode":"75564","state":"TX"},
	{"zipCode":"75565","state":"TX"},
	{"zipCode":"75566","state":"TX"},
	{"zipCode":"75567","state":"TX"},
	{"zipCode":"75568","state":"TX"},
	{"zipCode":"75569","state":"TX"},
	{"zipCode":"75569","state":"TX"},
	{"zipCode":"75570","state":"TX"},
	{"zipCode":"75571","state":"TX"},
	{"zipCode":"75572","state":"TX"},
	{"zipCode":"75573","state":"TX"},
	{"zipCode":"75574","state":"TX"},
	{"zipCode":"75601","state":"TX"},
	{"zipCode":"75602","state":"TX"},
	{"zipCode":"75603","state":"TX"},
	{"zipCode":"75604","state":"TX"},
	{"zipCode":"75605","state":"TX"},
	{"zipCode":"75606","state":"TX"},
	{"zipCode":"75607","state":"TX"},
	{"zipCode":"75608","state":"TX"},
	{"zipCode":"75615","state":"TX"},
	{"zipCode":"75630","state":"TX"},
	{"zipCode":"75631","state":"TX"},
	{"zipCode":"75633","state":"TX"},
	{"zipCode":"75636","state":"TX"},
	{"zipCode":"75637","state":"TX"},
	{"zipCode":"75638","state":"TX"},
	{"zipCode":"75639","state":"TX"},
	{"zipCode":"75640","state":"TX"},
	{"zipCode":"75642","state":"TX"},
	{"zipCode":"75643","state":"TX"},
	{"zipCode":"75644","state":"TX"},
	{"zipCode":"75645","state":"TX"},
	{"zipCode":"75647","state":"TX"},
	{"zipCode":"75650","state":"TX"},
	{"zipCode":"75651","state":"TX"},
	{"zipCode":"75652","state":"TX"},
	{"zipCode":"75653","state":"TX"},
	{"zipCode":"75654","state":"TX"},
	{"zipCode":"75656","state":"TX"},
	{"zipCode":"75657","state":"TX"},
	{"zipCode":"75658","state":"TX"},
	{"zipCode":"75659","state":"TX"},
	{"zipCode":"75660","state":"TX"},
	{"zipCode":"75661","state":"TX"},
	{"zipCode":"75662","state":"TX"},
	{"zipCode":"75663","state":"TX"},
	{"zipCode":"75666","state":"TX"},
	{"zipCode":"75667","state":"TX"},
	{"zipCode":"75668","state":"TX"},
	{"zipCode":"75669","state":"TX"},
	{"zipCode":"75669","state":"TX"},
	{"zipCode":"75670","state":"TX"},
	{"zipCode":"75671","state":"TX"},
	{"zipCode":"75672","state":"TX"},
	{"zipCode":"75680","state":"TX"},
	{"zipCode":"75681","state":"TX"},
	{"zipCode":"75682","state":"TX"},
	{"zipCode":"75683","state":"TX"},
	{"zipCode":"75684","state":"TX"},
	{"zipCode":"75685","state":"TX"},
	{"zipCode":"75686","state":"TX"},
	{"zipCode":"75687","state":"TX"},
	{"zipCode":"75688","state":"TX"},
	{"zipCode":"75689","state":"TX"},
	{"zipCode":"75691","state":"TX"},
	{"zipCode":"75692","state":"TX"},
	{"zipCode":"75693","state":"TX"},
	{"zipCode":"75694","state":"TX"},
	{"zipCode":"75701","state":"TX"},
	{"zipCode":"75702","state":"TX"},
	{"zipCode":"75703","state":"TX"},
	{"zipCode":"75704","state":"TX"},
	{"zipCode":"75705","state":"TX"},
	{"zipCode":"75706","state":"TX"},
	{"zipCode":"75707","state":"TX"},
	{"zipCode":"75708","state":"TX"},
	{"zipCode":"75709","state":"TX"},
	{"zipCode":"75710","state":"TX"},
	{"zipCode":"75711","state":"TX"},
	{"zipCode":"75712","state":"TX"},
	{"zipCode":"75713","state":"TX"},
	{"zipCode":"75750","state":"TX"},
	{"zipCode":"75751","state":"TX"},
	{"zipCode":"75752","state":"TX"},
	{"zipCode":"75754","state":"TX"},
	{"zipCode":"75755","state":"TX"},
	{"zipCode":"75756","state":"TX"},
	{"zipCode":"75757","state":"TX"},
	{"zipCode":"75758","state":"TX"},
	{"zipCode":"75759","state":"TX"},
	{"zipCode":"75760","state":"TX"},
	{"zipCode":"75762","state":"TX"},
	{"zipCode":"75763","state":"TX"},
	{"zipCode":"75764","state":"TX"},
	{"zipCode":"75765","state":"TX"},
	{"zipCode":"75766","state":"TX"},
	{"zipCode":"75770","state":"TX"},
	{"zipCode":"75771","state":"TX"},
	{"zipCode":"75772","state":"TX"},
	{"zipCode":"75773","state":"TX"},
	{"zipCode":"75778","state":"TX"},
	{"zipCode":"75779","state":"TX"},
	{"zipCode":"75780","state":"TX"},
	{"zipCode":"75782","state":"TX"},
	{"zipCode":"75783","state":"TX"},
	{"zipCode":"75784","state":"TX"},
	{"zipCode":"75785","state":"TX"},
	{"zipCode":"75788","state":"TX"},
	{"zipCode":"75789","state":"TX"},
	{"zipCode":"75790","state":"TX"},
	{"zipCode":"75791","state":"TX"},
	{"zipCode":"75792","state":"TX"},
	{"zipCode":"75801","state":"TX"},
	{"zipCode":"75802","state":"TX"},
	{"zipCode":"75803","state":"TX"},
	{"zipCode":"75831","state":"TX"},
	{"zipCode":"75832","state":"TX"},
	{"zipCode":"75833","state":"TX"},
	{"zipCode":"75835","state":"TX"},
	{"zipCode":"75838","state":"TX"},
	{"zipCode":"75838","state":"TX"},
	{"zipCode":"75839","state":"TX"},
	{"zipCode":"75840","state":"TX"},
	{"zipCode":"75844","state":"TX"},
	{"zipCode":"75845","state":"TX"},
	{"zipCode":"75846","state":"TX"},
	{"zipCode":"75847","state":"TX"},
	{"zipCode":"75848","state":"TX"},
	{"zipCode":"75849","state":"TX"},
	{"zipCode":"75850","state":"TX"},
	{"zipCode":"75851","state":"TX"},
	{"zipCode":"75852","state":"TX"},
	{"zipCode":"75853","state":"TX"},
	{"zipCode":"75855","state":"TX"},
	{"zipCode":"75856","state":"TX"},
	{"zipCode":"75858","state":"TX"},
	{"zipCode":"75859","state":"TX"},
	{"zipCode":"75860","state":"TX"},
	{"zipCode":"75861","state":"TX"},
	{"zipCode":"75862","state":"TX"},
	{"zipCode":"75865","state":"TX"},
	{"zipCode":"75901","state":"TX"},
	{"zipCode":"75902","state":"TX"},
	{"zipCode":"75903","state":"TX"},
	{"zipCode":"75904","state":"TX"},
	{"zipCode":"75915","state":"TX"},
	{"zipCode":"75925","state":"TX"},
	{"zipCode":"75926","state":"TX"},
	{"zipCode":"75928","state":"TX"},
	{"zipCode":"75929","state":"TX"},
	{"zipCode":"75930","state":"TX"},
	{"zipCode":"75930","state":"TX"},
	{"zipCode":"75931","state":"TX"},
	{"zipCode":"75932","state":"TX"},
	{"zipCode":"75933","state":"TX"},
	{"zipCode":"75934","state":"TX"},
	{"zipCode":"75935","state":"TX"},
	{"zipCode":"75936","state":"TX"},
	{"zipCode":"75937","state":"TX"},
	{"zipCode":"75938","state":"TX"},
	{"zipCode":"75939","state":"TX"},
	{"zipCode":"75941","state":"TX"},
	{"zipCode":"75942","state":"TX"},
	{"zipCode":"75943","state":"TX"},
	{"zipCode":"75944","state":"TX"},
	{"zipCode":"75946","state":"TX"},
	{"zipCode":"75948","state":"TX"},
	{"zipCode":"75949","state":"TX"},
	{"zipCode":"75951","state":"TX"},
	{"zipCode":"75951","state":"TX"},
	{"zipCode":"75954","state":"TX"},
	{"zipCode":"75956","state":"TX"},
	{"zipCode":"75958","state":"TX"},
	{"zipCode":"75959","state":"TX"},
	{"zipCode":"75960","state":"TX"},
	{"zipCode":"75961","state":"TX"},
	{"zipCode":"75963","state":"TX"},
	{"zipCode":"75964","state":"TX"},
	{"zipCode":"75965","state":"TX"},
	{"zipCode":"75966","state":"TX"},
	{"zipCode":"75968","state":"TX"},
	{"zipCode":"75969","state":"TX"},
	{"zipCode":"75972","state":"TX"},
	{"zipCode":"75973","state":"TX"},
	{"zipCode":"75974","state":"TX"},
	{"zipCode":"75975","state":"TX"},
	{"zipCode":"75976","state":"TX"},
	{"zipCode":"75977","state":"TX"},
	{"zipCode":"75978","state":"TX"},
	{"zipCode":"75979","state":"TX"},
	{"zipCode":"75980","state":"TX"},
	{"zipCode":"76001","state":"TX"},
	{"zipCode":"76002","state":"TX"},
	{"zipCode":"76003","state":"TX"},
	{"zipCode":"76004","state":"TX"},
	{"zipCode":"76005","state":"TX"},
	{"zipCode":"76006","state":"TX"},
	{"zipCode":"76007","state":"TX"},
	{"zipCode":"76008","state":"TX"},
	{"zipCode":"76009","state":"TX"},
	{"zipCode":"76010","state":"TX"},
	{"zipCode":"76011","state":"TX"},
	{"zipCode":"76012","state":"TX"},
	{"zipCode":"76013","state":"TX"},
	{"zipCode":"76014","state":"TX"},
	{"zipCode":"76015","state":"TX"},
	{"zipCode":"76016","state":"TX"},
	{"zipCode":"76017","state":"TX"},
	{"zipCode":"76018","state":"TX"},
	{"zipCode":"76020","state":"TX"},
	{"zipCode":"76021","state":"TX"},
	{"zipCode":"76022","state":"TX"},
	{"zipCode":"76023","state":"TX"},
	{"zipCode":"76028","state":"TX"},
	{"zipCode":"76031","state":"TX"},
	{"zipCode":"76033","state":"TX"},
	{"zipCode":"76034","state":"TX"},
	{"zipCode":"76035","state":"TX"},
	{"zipCode":"76036","state":"TX"},
	{"zipCode":"76039","state":"TX"},
	{"zipCode":"76040","state":"TX"},
	{"zipCode":"76041","state":"TX"},
	{"zipCode":"76041","state":"TX"},
	{"zipCode":"76043","state":"TX"},
	{"zipCode":"76044","state":"TX"},
	{"zipCode":"76048","state":"TX"},
	{"zipCode":"76049","state":"TX"},
	{"zipCode":"76050","state":"TX"},
	{"zipCode":"76051","state":"TX"},
	{"zipCode":"76052","state":"TX"},
	{"zipCode":"76053","state":"TX"},
	{"zipCode":"76054","state":"TX"},
	{"zipCode":"76055","state":"TX"},
	{"zipCode":"76058","state":"TX"},
	{"zipCode":"76059","state":"TX"},
	{"zipCode":"76060","state":"TX"},
	{"zipCode":"76061","state":"TX"},
	{"zipCode":"76063","state":"TX"},
	{"zipCode":"76064","state":"TX"},
	{"zipCode":"76065","state":"TX"},
	{"zipCode":"76066","state":"TX"},
	{"zipCode":"76067","state":"TX"},
	{"zipCode":"76068","state":"TX"},
	{"zipCode":"76070","state":"TX"},
	{"zipCode":"76071","state":"TX"},
	{"zipCode":"76073","state":"TX"},
	{"zipCode":"76077","state":"TX"},
	{"zipCode":"76078","state":"TX"},
	{"zipCode":"76082","state":"TX"},
	{"zipCode":"76084","state":"TX"},
	{"zipCode":"76085","state":"TX"},
	{"zipCode":"76086","state":"TX"},
	{"zipCode":"76087","state":"TX"},
	{"zipCode":"76088","state":"TX"},
	{"zipCode":"76092","state":"TX"},
	{"zipCode":"76093","state":"TX"},
	{"zipCode":"76094","state":"TX"},
	{"zipCode":"76095","state":"TX"},
	{"zipCode":"76095","state":"TX"},
	{"zipCode":"76096","state":"TX"},
	{"zipCode":"76097","state":"TX"},
	{"zipCode":"76098","state":"TX"},
	{"zipCode":"76099","state":"TX"},
	{"zipCode":"76101","state":"TX"},
	{"zipCode":"76102","state":"TX"},
	{"zipCode":"76102","state":"TX"},
	{"zipCode":"76103","state":"TX"},
	{"zipCode":"76104","state":"TX"},
	{"zipCode":"76105","state":"TX"},
	{"zipCode":"76106","state":"TX"},
	{"zipCode":"76107","state":"TX"},
	{"zipCode":"76108","state":"TX"},
	{"zipCode":"76109","state":"TX"},
	{"zipCode":"76110","state":"TX"},
	{"zipCode":"76111","state":"TX"},
	{"zipCode":"76112","state":"TX"},
	{"zipCode":"76113","state":"TX"},
	{"zipCode":"76114","state":"TX"},
	{"zipCode":"76115","state":"TX"},
	{"zipCode":"76116","state":"TX"},
	{"zipCode":"76117","state":"TX"},
	{"zipCode":"76118","state":"TX"},
	{"zipCode":"76119","state":"TX"},
	{"zipCode":"76120","state":"TX"},
	{"zipCode":"76121","state":"TX"},
	{"zipCode":"76123","state":"TX"},
	{"zipCode":"76124","state":"TX"},
	{"zipCode":"76126","state":"TX"},
	{"zipCode":"76127","state":"TX"},
	{"zipCode":"76127","state":"TX"},
	{"zipCode":"76131","state":"TX"},
	{"zipCode":"76132","state":"TX"},
	{"zipCode":"76133","state":"TX"},
	{"zipCode":"76134","state":"TX"},
	{"zipCode":"76135","state":"TX"},
	{"zipCode":"76136","state":"TX"},
	{"zipCode":"76137","state":"TX"},
	{"zipCode":"76140","state":"TX"},
	{"zipCode":"76147","state":"TX"},
	{"zipCode":"76148","state":"TX"},
	{"zipCode":"76148","state":"TX"},
	{"zipCode":"76155","state":"TX"},
	{"zipCode":"76161","state":"TX"},
	{"zipCode":"76162","state":"TX"},
	{"zipCode":"76163","state":"TX"},
	{"zipCode":"76164","state":"TX"},
	{"zipCode":"76177","state":"TX"},
	{"zipCode":"76179","state":"TX"},
	{"zipCode":"76179","state":"TX"},
	{"zipCode":"76180","state":"TX"},
	{"zipCode":"76181","state":"TX"},
	{"zipCode":"76182","state":"TX"},
	{"zipCode":"76185","state":"TX"},
	{"zipCode":"76201","state":"TX"},
	{"zipCode":"76202","state":"TX"},
	{"zipCode":"76204","state":"TX"},
	{"zipCode":"76205","state":"TX"},
	{"zipCode":"76206","state":"TX"},
	{"zipCode":"76207","state":"TX"},
	{"zipCode":"76208","state":"TX"},
	{"zipCode":"76209","state":"TX"},
	{"zipCode":"76210","state":"TX"},
	{"zipCode":"76225","state":"TX"},
	{"zipCode":"76226","state":"TX"},
	{"zipCode":"76227","state":"TX"},
	{"zipCode":"76228","state":"TX"},
	{"zipCode":"76228","state":"TX"},
	{"zipCode":"76230","state":"TX"},
	{"zipCode":"76233","state":"TX"},
	{"zipCode":"76234","state":"TX"},
	{"zipCode":"76238","state":"TX"},
	{"zipCode":"76239","state":"TX"},
	{"zipCode":"76240","state":"TX"},
	{"zipCode":"76241","state":"TX"},
	{"zipCode":"76244","state":"TX"},
	{"zipCode":"76245","state":"TX"},
	{"zipCode":"76247","state":"TX"},
	{"zipCode":"76248","state":"TX"},
	{"zipCode":"76249","state":"TX"},
	{"zipCode":"76250","state":"TX"},
	{"zipCode":"76251","state":"TX"},
	{"zipCode":"76252","state":"TX"},
	{"zipCode":"76253","state":"TX"},
	{"zipCode":"76255","state":"TX"},
	{"zipCode":"76258","state":"TX"},
	{"zipCode":"76259","state":"TX"},
	{"zipCode":"76261","state":"TX"},
	{"zipCode":"76262","state":"TX"},
	{"zipCode":"76263","state":"TX"},
	{"zipCode":"76264","state":"TX"},
	{"zipCode":"76265","state":"TX"},
	{"zipCode":"76266","state":"TX"},
	{"zipCode":"76267","state":"TX"},
	{"zipCode":"76268","state":"TX"},
	{"zipCode":"76270","state":"TX"},
	{"zipCode":"76271","state":"TX"},
	{"zipCode":"76272","state":"TX"},
	{"zipCode":"76273","state":"TX"},
	{"zipCode":"76301","state":"TX"},
	{"zipCode":"76302","state":"TX"},
	{"zipCode":"76305","state":"TX"},
	{"zipCode":"76306","state":"TX"},
	{"zipCode":"76307","state":"TX"},
	{"zipCode":"76308","state":"TX"},
	{"zipCode":"76309","state":"TX"},
	{"zipCode":"76310","state":"TX"},
	{"zipCode":"76311","state":"TX"},
	{"zipCode":"76311","state":"TX"},
	{"zipCode":"76351","state":"TX"},
	{"zipCode":"76352","state":"TX"},
	{"zipCode":"76354","state":"TX"},
	{"zipCode":"76357","state":"TX"},
	{"zipCode":"76357","state":"TX"},
	{"zipCode":"76360","state":"TX"},
	{"zipCode":"76363","state":"TX"},
	{"zipCode":"76363","state":"TX"},
	{"zipCode":"76364","state":"TX"},
	{"zipCode":"76365","state":"TX"},
	{"zipCode":"76366","state":"TX"},
	{"zipCode":"76367","state":"TX"},
	{"zipCode":"76369","state":"TX"},
	{"zipCode":"76370","state":"TX"},
	{"zipCode":"76371","state":"TX"},
	{"zipCode":"76372","state":"TX"},
	{"zipCode":"76373","state":"TX"},
	{"zipCode":"76373","state":"TX"},
	{"zipCode":"76374","state":"TX"},
	{"zipCode":"76377","state":"TX"},
	{"zipCode":"76379","state":"TX"},
	{"zipCode":"76380","state":"TX"},
	{"zipCode":"76384","state":"TX"},
	{"zipCode":"76385","state":"TX"},
	{"zipCode":"76388","state":"TX"},
	{"zipCode":"76388","state":"TX"},
	{"zipCode":"76389","state":"TX"},
	{"zipCode":"76401","state":"TX"},
	{"zipCode":"76424","state":"TX"},
	{"zipCode":"76426","state":"TX"},
	{"zipCode":"76427","state":"TX"},
	{"zipCode":"76427","state":"TX"},
	{"zipCode":"76429","state":"TX"},
	{"zipCode":"76430","state":"TX"},
	{"zipCode":"76431","state":"TX"},
	{"zipCode":"76432","state":"TX"},
	{"zipCode":"76432","state":"TX"},
	{"zipCode":"76433","state":"TX"},
	{"zipCode":"76435","state":"TX"},
	{"zipCode":"76435","state":"TX"},
	{"zipCode":"76436","state":"TX"},
	{"zipCode":"76436","state":"TX"},
	{"zipCode":"76437","state":"TX"},
	{"zipCode":"76439","state":"TX"},
	{"zipCode":"76442","state":"TX"},
	{"zipCode":"76443","state":"TX"},
	{"zipCode":"76444","state":"TX"},
	{"zipCode":"76445","state":"TX"},
	{"zipCode":"76445","state":"TX"},
	{"zipCode":"76446","state":"TX"},
	{"zipCode":"76448","state":"TX"},
	{"zipCode":"76449","state":"TX"},
	{"zipCode":"76450","state":"TX"},
	{"zipCode":"76452","state":"TX"},
	{"zipCode":"76453","state":"TX"},
	{"zipCode":"76454","state":"TX"},
	{"zipCode":"76455","state":"TX"},
	{"zipCode":"76455","state":"TX"},
	{"zipCode":"76457","state":"TX"},
	{"zipCode":"76458","state":"TX"},
	{"zipCode":"76459","state":"TX"},
	{"zipCode":"76460","state":"TX"},
	{"zipCode":"76460","state":"TX"},
	{"zipCode":"76461","state":"TX"},
	{"zipCode":"76462","state":"TX"},
	{"zipCode":"76463","state":"TX"},
	{"zipCode":"76463","state":"TX"},
	{"zipCode":"76464","state":"TX"},
	{"zipCode":"76465","state":"TX"},
	{"zipCode":"76466","state":"TX"},
	{"zipCode":"76467","state":"TX"},
	{"zipCode":"76468","state":"TX"},
	{"zipCode":"76469","state":"TX"},
	{"zipCode":"76470","state":"TX"},
	{"zipCode":"76471","state":"TX"},
	{"zipCode":"76472","state":"TX"},
	{"zipCode":"76474","state":"TX"},
	{"zipCode":"76474","state":"TX"},
	{"zipCode":"76475","state":"TX"},
	{"zipCode":"76476","state":"TX"},
	{"zipCode":"76481","state":"TX"},
	{"zipCode":"76481","state":"TX"},
	{"zipCode":"76483","state":"TX"},
	{"zipCode":"76484","state":"TX"},
	{"zipCode":"76485","state":"TX"},
	{"zipCode":"76486","state":"TX"},
	{"zipCode":"76487","state":"TX"},
	{"zipCode":"76490","state":"TX"},
	{"zipCode":"76491","state":"TX"},
	{"zipCode":"76501","state":"TX"},
	{"zipCode":"76502","state":"TX"},
	{"zipCode":"76503","state":"TX"},
	{"zipCode":"76504","state":"TX"},
	{"zipCode":"76511","state":"TX"},
	{"zipCode":"76513","state":"TX"},
	{"zipCode":"76518","state":"TX"},
	{"zipCode":"76519","state":"TX"},
	{"zipCode":"76520","state":"TX"},
	{"zipCode":"76522","state":"TX"},
	{"zipCode":"76523","state":"TX"},
	{"zipCode":"76524","state":"TX"},
	{"zipCode":"76525","state":"TX"},
	{"zipCode":"76526","state":"TX"},
	{"zipCode":"76527","state":"TX"},
	{"zipCode":"76528","state":"TX"},
	{"zipCode":"76530","state":"TX"},
	{"zipCode":"76530","state":"TX"},
	{"zipCode":"76531","state":"TX"},
	{"zipCode":"76533","state":"TX"},
	{"zipCode":"76534","state":"TX"},
	{"zipCode":"76534","state":"TX"},
	{"zipCode":"76537","state":"TX"},
	{"zipCode":"76538","state":"TX"},
	{"zipCode":"76539","state":"TX"},
	{"zipCode":"76540","state":"TX"},
	{"zipCode":"76541","state":"TX"},
	{"zipCode":"76542","state":"TX"},
	{"zipCode":"76543","state":"TX"},
	{"zipCode":"76544","state":"TX"},
	{"zipCode":"76544","state":"TX"},
	{"zipCode":"76547","state":"TX"},
	{"zipCode":"76548","state":"TX"},
	{"zipCode":"76549","state":"TX"},
	{"zipCode":"76549","state":"TX"},
	{"zipCode":"76550","state":"TX"},
	{"zipCode":"76554","state":"TX"},
	{"zipCode":"76556","state":"TX"},
	{"zipCode":"76557","state":"TX"},
	{"zipCode":"76558","state":"TX"},
	{"zipCode":"76559","state":"TX"},
	{"zipCode":"76561","state":"TX"},
	{"zipCode":"76564","state":"TX"},
	{"zipCode":"76565","state":"TX"},
	{"zipCode":"76566","state":"TX"},
	{"zipCode":"76567","state":"TX"},
	{"zipCode":"76569","state":"TX"},
	{"zipCode":"76570","state":"TX"},
	{"zipCode":"76571","state":"TX"},
	{"zipCode":"76573","state":"TX"},
	{"zipCode":"76574","state":"TX"},
	{"zipCode":"76577","state":"TX"},
	{"zipCode":"76578","state":"TX"},
	{"zipCode":"76579","state":"TX"},
	{"zipCode":"76621","state":"TX"},
	{"zipCode":"76622","state":"TX"},
	{"zipCode":"76623","state":"TX"},
	{"zipCode":"76624","state":"TX"},
	{"zipCode":"76626","state":"TX"},
	{"zipCode":"76627","state":"TX"},
	{"zipCode":"76627","state":"TX"},
	{"zipCode":"76628","state":"TX"},
	{"zipCode":"76629","state":"TX"},
	{"zipCode":"76630","state":"TX"},
	{"zipCode":"76631","state":"TX"},
	{"zipCode":"76632","state":"TX"},
	{"zipCode":"76633","state":"TX"},
	{"zipCode":"76634","state":"TX"},
	{"zipCode":"76635","state":"TX"},
	{"zipCode":"76636","state":"TX"},
	{"zipCode":"76637","state":"TX"},
	{"zipCode":"76638","state":"TX"},
	{"zipCode":"76639","state":"TX"},
	{"zipCode":"76639","state":"TX"},
	{"zipCode":"76640","state":"TX"},
	{"zipCode":"76641","state":"TX"},
	{"zipCode":"76642","state":"TX"},
	{"zipCode":"76643","state":"TX"},
	{"zipCode":"76644","state":"TX"},
	{"zipCode":"76645","state":"TX"},
	{"zipCode":"76648","state":"TX"},
	{"zipCode":"76649","state":"TX"},
	{"zipCode":"76650","state":"TX"},
	{"zipCode":"76651","state":"TX"},
	{"zipCode":"76652","state":"TX"},
	{"zipCode":"76653","state":"TX"},
	{"zipCode":"76654","state":"TX"},
	{"zipCode":"76655","state":"TX"},
	{"zipCode":"76656","state":"TX"},
	{"zipCode":"76657","state":"TX"},
	{"zipCode":"76660","state":"TX"},
	{"zipCode":"76661","state":"TX"},
	{"zipCode":"76664","state":"TX"},
	{"zipCode":"76665","state":"TX"},
	{"zipCode":"76666","state":"TX"},
	{"zipCode":"76667","state":"TX"},
	{"zipCode":"76670","state":"TX"},
	{"zipCode":"76671","state":"TX"},
	{"zipCode":"76673","state":"TX"},
	{"zipCode":"76676","state":"TX"},
	{"zipCode":"76678","state":"TX"},
	{"zipCode":"76679","state":"TX"},
	{"zipCode":"76680","state":"TX"},
	{"zipCode":"76681","state":"TX"},
	{"zipCode":"76681","state":"TX"},
	{"zipCode":"76682","state":"TX"},
	{"zipCode":"76684","state":"TX"},
	{"zipCode":"76685","state":"TX"},
	{"zipCode":"76686","state":"TX"},
	{"zipCode":"76687","state":"TX"},
	{"zipCode":"76689","state":"TX"},
	{"zipCode":"76690","state":"TX"},
	{"zipCode":"76691","state":"TX"},
	{"zipCode":"76692","state":"TX"},
	{"zipCode":"76693","state":"TX"},
	{"zipCode":"76701","state":"TX"},
	{"zipCode":"76702","state":"TX"},
	{"zipCode":"76703","state":"TX"},
	{"zipCode":"76704","state":"TX"},
	{"zipCode":"76705","state":"TX"},
	{"zipCode":"76706","state":"TX"},
	{"zipCode":"76707","state":"TX"},
	{"zipCode":"76708","state":"TX"},
	{"zipCode":"76710","state":"TX"},
	{"zipCode":"76711","state":"TX"},
	{"zipCode":"76712","state":"TX"},
	{"zipCode":"76714","state":"TX"},
	{"zipCode":"76715","state":"TX"},
	{"zipCode":"76716","state":"TX"},
	{"zipCode":"76801","state":"TX"},
	{"zipCode":"76802","state":"TX"},
	{"zipCode":"76803","state":"TX"},
	{"zipCode":"76804","state":"TX"},
	{"zipCode":"76820","state":"TX"},
	{"zipCode":"76820","state":"TX"},
	{"zipCode":"76821","state":"TX"},
	{"zipCode":"76823","state":"TX"},
	{"zipCode":"76824","state":"TX"},
	{"zipCode":"76825","state":"TX"},
	{"zipCode":"76827","state":"TX"},
	{"zipCode":"76828","state":"TX"},
	{"zipCode":"76831","state":"TX"},
	{"zipCode":"76832","state":"TX"},
	{"zipCode":"76834","state":"TX"},
	{"zipCode":"76836","state":"TX"},
	{"zipCode":"76836","state":"TX"},
	{"zipCode":"76837","state":"TX"},
	{"zipCode":"76841","state":"TX"},
	{"zipCode":"76842","state":"TX"},
	{"zipCode":"76844","state":"TX"},
	{"zipCode":"76845","state":"TX"},
	{"zipCode":"76848","state":"TX"},
	{"zipCode":"76849","state":"TX"},
	{"zipCode":"76852","state":"TX"},
	{"zipCode":"76852","state":"TX"},
	{"zipCode":"76853","state":"TX"},
	{"zipCode":"76854","state":"TX"},
	{"zipCode":"76855","state":"TX"},
	{"zipCode":"76856","state":"TX"},
	{"zipCode":"76857","state":"TX"},
	{"zipCode":"76857","state":"TX"},
	{"zipCode":"76858","state":"TX"},
	{"zipCode":"76858","state":"TX"},
	{"zipCode":"76859","state":"TX"},
	{"zipCode":"76861","state":"TX"},
	{"zipCode":"76862","state":"TX"},
	{"zipCode":"76864","state":"TX"},
	{"zipCode":"76865","state":"TX"},
	{"zipCode":"76866","state":"TX"},
	{"zipCode":"76869","state":"TX"},
	{"zipCode":"76870","state":"TX"},
	{"zipCode":"76870","state":"TX"},
	{"zipCode":"76871","state":"TX"},
	{"zipCode":"76872","state":"TX"},
	{"zipCode":"76872","state":"TX"},
	{"zipCode":"76873","state":"TX"},
	{"zipCode":"76874","state":"TX"},
	{"zipCode":"76875","state":"TX"},
	{"zipCode":"76877","state":"TX"},
	{"zipCode":"76878","state":"TX"},
	{"zipCode":"76880","state":"TX"},
	{"zipCode":"76882","state":"TX"},
	{"zipCode":"76883","state":"TX"},
	{"zipCode":"76884","state":"TX"},
	{"zipCode":"76885","state":"TX"},
	{"zipCode":"76885","state":"TX"},
	{"zipCode":"76886","state":"TX"},
	{"zipCode":"76887","state":"TX"},
	{"zipCode":"76887","state":"TX"},
	{"zipCode":"76888","state":"TX"},
	{"zipCode":"76890","state":"TX"},
	{"zipCode":"76890","state":"TX"},
	{"zipCode":"76901","state":"TX"},
	{"zipCode":"76902","state":"TX"},
	{"zipCode":"76903","state":"TX"},
	{"zipCode":"76904","state":"TX"},
	{"zipCode":"76905","state":"TX"},
	{"zipCode":"76906","state":"TX"},
	{"zipCode":"76908","state":"TX"},
	{"zipCode":"76930","state":"TX"},
	{"zipCode":"76932","state":"TX"},
	{"zipCode":"76933","state":"TX"},
	{"zipCode":"76934","state":"TX"},
	{"zipCode":"76935","state":"TX"},
	{"zipCode":"76936","state":"TX"},
	{"zipCode":"76937","state":"TX"},
	{"zipCode":"76939","state":"TX"},
	{"zipCode":"76940","state":"TX"},
	{"zipCode":"76941","state":"TX"},
	{"zipCode":"76943","state":"TX"},
	{"zipCode":"76945","state":"TX"},
	{"zipCode":"76950","state":"TX"},
	{"zipCode":"76951","state":"TX"},
	{"zipCode":"76953","state":"TX"},
	{"zipCode":"76955","state":"TX"},
	{"zipCode":"76957","state":"TX"},
	{"zipCode":"76957","state":"TX"},
	{"zipCode":"76958","state":"TX"},
	{"zipCode":"77001","state":"TX"},
	{"zipCode":"77002","state":"TX"},
	{"zipCode":"77003","state":"TX"},
	{"zipCode":"77004","state":"TX"},
	{"zipCode":"77005","state":"TX"},
	{"zipCode":"77006","state":"TX"},
	{"zipCode":"77007","state":"TX"},
	{"zipCode":"77008","state":"TX"},
	{"zipCode":"77009","state":"TX"},
	{"zipCode":"77010","state":"TX"},
	{"zipCode":"77011","state":"TX"},
	{"zipCode":"77012","state":"TX"},
	{"zipCode":"77013","state":"TX"},
	{"zipCode":"77014","state":"TX"},
	{"zipCode":"77015","state":"TX"},
	{"zipCode":"77016","state":"TX"},
	{"zipCode":"77017","state":"TX"},
	{"zipCode":"77018","state":"TX"},
	{"zipCode":"77019","state":"TX"},
	{"zipCode":"77020","state":"TX"},
	{"zipCode":"77021","state":"TX"},
	{"zipCode":"77022","state":"TX"},
	{"zipCode":"77023","state":"TX"},
	{"zipCode":"77024","state":"TX"},
	{"zipCode":"77025","state":"TX"},
	{"zipCode":"77026","state":"TX"},
	{"zipCode":"77027","state":"TX"},
	{"zipCode":"77028","state":"TX"},
	{"zipCode":"77029","state":"TX"},
	{"zipCode":"77030","state":"TX"},
	{"zipCode":"77031","state":"TX"},
	{"zipCode":"77032","state":"TX"},
	{"zipCode":"77033","state":"TX"},
	{"zipCode":"77034","state":"TX"},
	{"zipCode":"77035","state":"TX"},
	{"zipCode":"77036","state":"TX"},
	{"zipCode":"77037","state":"TX"},
	{"zipCode":"77038","state":"TX"},
	{"zipCode":"77039","state":"TX"},
	{"zipCode":"77040","state":"TX"},
	{"zipCode":"77041","state":"TX"},
	{"zipCode":"77042","state":"TX"},
	{"zipCode":"77043","state":"TX"},
	{"zipCode":"77044","state":"TX"},
	{"zipCode":"77045","state":"TX"},
	{"zipCode":"77046","state":"TX"},
	{"zipCode":"77047","state":"TX"},
	{"zipCode":"77048","state":"TX"},
	{"zipCode":"77049","state":"TX"},
	{"zipCode":"77050","state":"TX"},
	{"zipCode":"77051","state":"TX"},
	{"zipCode":"77052","state":"TX"},
	{"zipCode":"77053","state":"TX"},
	{"zipCode":"77054","state":"TX"},
	{"zipCode":"77055","state":"TX"},
	{"zipCode":"77056","state":"TX"},
	{"zipCode":"77056","state":"TX"},
	{"zipCode":"77057","state":"TX"},
	{"zipCode":"77058","state":"TX"},
	{"zipCode":"77059","state":"TX"},
	{"zipCode":"77060","state":"TX"},
	{"zipCode":"77061","state":"TX"},
	{"zipCode":"77062","state":"TX"},
	{"zipCode":"77063","state":"TX"},
	{"zipCode":"77064","state":"TX"},
	{"zipCode":"77065","state":"TX"},
	{"zipCode":"77066","state":"TX"},
	{"zipCode":"77067","state":"TX"},
	{"zipCode":"77068","state":"TX"},
	{"zipCode":"77069","state":"TX"},
	{"zipCode":"77070","state":"TX"},
	{"zipCode":"77071","state":"TX"},
	{"zipCode":"77072","state":"TX"},
	{"zipCode":"77073","state":"TX"},
	{"zipCode":"77074","state":"TX"},
	{"zipCode":"77075","state":"TX"},
	{"zipCode":"77076","state":"TX"},
	{"zipCode":"77077","state":"TX"},
	{"zipCode":"77078","state":"TX"},
	{"zipCode":"77079","state":"TX"},
	{"zipCode":"77080","state":"TX"},
	{"zipCode":"77081","state":"TX"},
	{"zipCode":"77082","state":"TX"},
	{"zipCode":"77083","state":"TX"},
	{"zipCode":"77084","state":"TX"},
	{"zipCode":"77085","state":"TX"},
	{"zipCode":"77086","state":"TX"},
	{"zipCode":"77087","state":"TX"},
	{"zipCode":"77088","state":"TX"},
	{"zipCode":"77089","state":"TX"},
	{"zipCode":"77090","state":"TX"},
	{"zipCode":"77091","state":"TX"},
	{"zipCode":"77092","state":"TX"},
	{"zipCode":"77093","state":"TX"},
	{"zipCode":"77094","state":"TX"},
	{"zipCode":"77095","state":"TX"},
	{"zipCode":"77096","state":"TX"},
	{"zipCode":"77098","state":"TX"},
	{"zipCode":"77099","state":"TX"},
	{"zipCode":"77202","state":"TX"},
	{"zipCode":"77203","state":"TX"},
	{"zipCode":"77205","state":"TX"},
	{"zipCode":"77206","state":"TX"},
	{"zipCode":"77207","state":"TX"},
	{"zipCode":"77208","state":"TX"},
	{"zipCode":"77210","state":"TX"},
	{"zipCode":"77213","state":"TX"},
	{"zipCode":"77215","state":"TX"},
	{"zipCode":"77217","state":"TX"},
	{"zipCode":"77218","state":"TX"},
	{"zipCode":"77219","state":"TX"},
	{"zipCode":"77220","state":"TX"},
	{"zipCode":"77221","state":"TX"},
	{"zipCode":"77222","state":"TX"},
	{"zipCode":"77223","state":"TX"},
	{"zipCode":"77224","state":"TX"},
	{"zipCode":"77225","state":"TX"},
	{"zipCode":"77226","state":"TX"},
	{"zipCode":"77227","state":"TX"},
	{"zipCode":"77228","state":"TX"},
	{"zipCode":"77229","state":"TX"},
	{"zipCode":"77230","state":"TX"},
	{"zipCode":"77231","state":"TX"},
	{"zipCode":"77233","state":"TX"},
	{"zipCode":"77234","state":"TX"},
	{"zipCode":"77235","state":"TX"},
	{"zipCode":"77236","state":"TX"},
	{"zipCode":"77237","state":"TX"},
	{"zipCode":"77238","state":"TX"},
	{"zipCode":"77240","state":"TX"},
	{"zipCode":"77241","state":"TX"},
	{"zipCode":"77242","state":"TX"},
	{"zipCode":"77243","state":"TX"},
	{"zipCode":"77244","state":"TX"},
	{"zipCode":"77245","state":"TX"},
	{"zipCode":"77248","state":"TX"},
	{"zipCode":"77249","state":"TX"},
	{"zipCode":"77251","state":"TX"},
	{"zipCode":"77252","state":"TX"},
	{"zipCode":"77253","state":"TX"},
	{"zipCode":"77254","state":"TX"},
	{"zipCode":"77255","state":"TX"},
	{"zipCode":"77256","state":"TX"},
	{"zipCode":"77257","state":"TX"},
	{"zipCode":"77258","state":"TX"},
	{"zipCode":"77259","state":"TX"},
	{"zipCode":"77261","state":"TX"},
	{"zipCode":"77262","state":"TX"},
	{"zipCode":"77263","state":"TX"},
	{"zipCode":"77265","state":"TX"},
	{"zipCode":"77266","state":"TX"},
	{"zipCode":"77267","state":"TX"},
	{"zipCode":"77268","state":"TX"},
	{"zipCode":"77269","state":"TX"},
	{"zipCode":"77270","state":"TX"},
	{"zipCode":"77271","state":"TX"},
	{"zipCode":"77272","state":"TX"},
	{"zipCode":"77273","state":"TX"},
	{"zipCode":"77274","state":"TX"},
	{"zipCode":"77275","state":"TX"},
	{"zipCode":"77277","state":"TX"},
	{"zipCode":"77279","state":"TX"},
	{"zipCode":"77280","state":"TX"},
	{"zipCode":"77282","state":"TX"},
	{"zipCode":"77284","state":"TX"},
	{"zipCode":"77287","state":"TX"},
	{"zipCode":"77288","state":"TX"},
	{"zipCode":"77289","state":"TX"},
	{"zipCode":"77290","state":"TX"},
	{"zipCode":"77291","state":"TX"},
	{"zipCode":"77292","state":"TX"},
	{"zipCode":"77293","state":"TX"},
	{"zipCode":"77301","state":"TX"},
	{"zipCode":"77302","state":"TX"},
	{"zipCode":"77303","state":"TX"},
	{"zipCode":"77304","state":"TX"},
	{"zipCode":"77305","state":"TX"},
	{"zipCode":"77306","state":"TX"},
	{"zipCode":"77316","state":"TX"},
	{"zipCode":"77318","state":"TX"},
	{"zipCode":"77320","state":"TX"},
	{"zipCode":"77325","state":"TX"},
	{"zipCode":"77326","state":"TX"},
	{"zipCode":"77327","state":"TX"},
	{"zipCode":"77328","state":"TX"},
	{"zipCode":"77331","state":"TX"},
	{"zipCode":"77332","state":"TX"},
	{"zipCode":"77333","state":"TX"},
	{"zipCode":"77334","state":"TX"},
	{"zipCode":"77335","state":"TX"},
	{"zipCode":"77336","state":"TX"},
	{"zipCode":"77338","state":"TX"},
	{"zipCode":"77339","state":"TX"},
	{"zipCode":"77340","state":"TX"},
	{"zipCode":"77342","state":"TX"},
	{"zipCode":"77345","state":"TX"},
	{"zipCode":"77346","state":"TX"},
	{"zipCode":"77347","state":"TX"},
	{"zipCode":"77350","state":"TX"},
	{"zipCode":"77351","state":"TX"},
	{"zipCode":"77353","state":"TX"},
	{"zipCode":"77354","state":"TX"},
	{"zipCode":"77355","state":"TX"},
	{"zipCode":"77356","state":"TX"},
	{"zipCode":"77356","state":"TX"},
	{"zipCode":"77357","state":"TX"},
	{"zipCode":"77358","state":"TX"},
	{"zipCode":"77359","state":"TX"},
	{"zipCode":"77360","state":"TX"},
	{"zipCode":"77362","state":"TX"},
	{"zipCode":"77363","state":"TX"},
	{"zipCode":"77364","state":"TX"},
	{"zipCode":"77364","state":"TX"},
	{"zipCode":"77365","state":"TX"},
	{"zipCode":"77367","state":"TX"},
	{"zipCode":"77368","state":"TX"},
	{"zipCode":"77369","state":"TX"},
	{"zipCode":"77371","state":"TX"},
	{"zipCode":"77372","state":"TX"},
	{"zipCode":"77373","state":"TX"},
	{"zipCode":"77374","state":"TX"},
	{"zipCode":"77375","state":"TX"},
	{"zipCode":"77376","state":"TX"},
	{"zipCode":"77377","state":"TX"},
	{"zipCode":"77378","state":"TX"},
	{"zipCode":"77379","state":"TX"},
	{"zipCode":"77380","state":"TX"},
	{"zipCode":"77381","state":"TX"},
	{"zipCode":"77382","state":"TX"},
	{"zipCode":"77383","state":"TX"},
	{"zipCode":"77384","state":"TX"},
	{"zipCode":"77385","state":"TX"},
	{"zipCode":"77386","state":"TX"},
	{"zipCode":"77387","state":"TX"},
	{"zipCode":"77388","state":"TX"},
	{"zipCode":"77389","state":"TX"},
	{"zipCode":"77391","state":"TX"},
	{"zipCode":"77393","state":"TX"},
	{"zipCode":"77396","state":"TX"},
	{"zipCode":"77401","state":"TX"},
	{"zipCode":"77402","state":"TX"},
	{"zipCode":"77404","state":"TX"},
	{"zipCode":"77404","state":"TX"},
	{"zipCode":"77406","state":"TX"},
	{"zipCode":"77407","state":"TX"},
	{"zipCode":"77410","state":"TX"},
	{"zipCode":"77411","state":"TX"},
	{"zipCode":"77412","state":"TX"},
	{"zipCode":"77413","state":"TX"},
	{"zipCode":"77414","state":"TX"},
	{"zipCode":"77415","state":"TX"},
	{"zipCode":"77417","state":"TX"},
	{"zipCode":"77417","state":"TX"},
	{"zipCode":"77418","state":"TX"},
	{"zipCode":"77419","state":"TX"},
	{"zipCode":"77420","state":"TX"},
	{"zipCode":"77422","state":"TX"},
	{"zipCode":"77423","state":"TX"},
	{"zipCode":"77426","state":"TX"},
	{"zipCode":"77428","state":"TX"},
	{"zipCode":"77429","state":"TX"},
	{"zipCode":"77430","state":"TX"},
	{"zipCode":"77431","state":"TX"},
	{"zipCode":"77432","state":"TX"},
	{"zipCode":"77433","state":"TX"},
	{"zipCode":"77434","state":"TX"},
	{"zipCode":"77435","state":"TX"},
	{"zipCode":"77436","state":"TX"},
	{"zipCode":"77437","state":"TX"},
	{"zipCode":"77440","state":"TX"},
	{"zipCode":"77441","state":"TX"},
	{"zipCode":"77442","state":"TX"},
	{"zipCode":"77443","state":"TX"},
	{"zipCode":"77444","state":"TX"},
	{"zipCode":"77445","state":"TX"},
	{"zipCode":"77446","state":"TX"},
	{"zipCode":"77447","state":"TX"},
	{"zipCode":"77448","state":"TX"},
	{"zipCode":"77449","state":"TX"},
	{"zipCode":"77450","state":"TX"},
	{"zipCode":"77451","state":"TX"},
	{"zipCode":"77452","state":"TX"},
	{"zipCode":"77453","state":"TX"},
	{"zipCode":"77454","state":"TX"},
	{"zipCode":"77455","state":"TX"},
	{"zipCode":"77456","state":"TX"},
	{"zipCode":"77457","state":"TX"},
	{"zipCode":"77458","state":"TX"},
	{"zipCode":"77459","state":"TX"},
	{"zipCode":"77459","state":"TX"},
	{"zipCode":"77460","state":"TX"},
	{"zipCode":"77461","state":"TX"},
	{"zipCode":"77463","state":"TX"},
	{"zipCode":"77464","state":"TX"},
	{"zipCode":"77465","state":"TX"},
	{"zipCode":"77466","state":"TX"},
	{"zipCode":"77467","state":"TX"},
	{"zipCode":"77468","state":"TX"},
	{"zipCode":"77469","state":"TX"},
	{"zipCode":"77470","state":"TX"},
	{"zipCode":"77471","state":"TX"},
	{"zipCode":"77473","state":"TX"},
	{"zipCode":"77474","state":"TX"},
	{"zipCode":"77475","state":"TX"},
	{"zipCode":"77476","state":"TX"},
	{"zipCode":"77477","state":"TX"},
	{"zipCode":"77478","state":"TX"},
	{"zipCode":"77479","state":"TX"},
	{"zipCode":"77480","state":"TX"},
	{"zipCode":"77480","state":"TX"},
	{"zipCode":"77481","state":"TX"},
	{"zipCode":"77482","state":"TX"},
	{"zipCode":"77483","state":"TX"},
	{"zipCode":"77484","state":"TX"},
	{"zipCode":"77485","state":"TX"},
	{"zipCode":"77486","state":"TX"},
	{"zipCode":"77487","state":"TX"},
	{"zipCode":"77488","state":"TX"},
	{"zipCode":"77489","state":"TX"},
	{"zipCode":"77491","state":"TX"},
	{"zipCode":"77492","state":"TX"},
	{"zipCode":"77493","state":"TX"},
	{"zipCode":"77494","state":"TX"},
	{"zipCode":"77496","state":"TX"},
	{"zipCode":"77497","state":"TX"},
	{"zipCode":"77498","state":"TX"},
	{"zipCode":"77501","state":"TX"},
	{"zipCode":"77502","state":"TX"},
	{"zipCode":"77502","state":"TX"},
	{"zipCode":"77503","state":"TX"},
	{"zipCode":"77504","state":"TX"},
	{"zipCode":"77505","state":"TX"},
	{"zipCode":"77506","state":"TX"},
	{"zipCode":"77507","state":"TX"},
	{"zipCode":"77508","state":"TX"},
	{"zipCode":"77510","state":"TX"},
	{"zipCode":"77511","state":"TX"},
	{"zipCode":"77512","state":"TX"},
	{"zipCode":"77514","state":"TX"},
	{"zipCode":"77515","state":"TX"},
	{"zipCode":"77516","state":"TX"},
	{"zipCode":"77517","state":"TX"},
	{"zipCode":"77518","state":"TX"},
	{"zipCode":"77519","state":"TX"},
	{"zipCode":"77520","state":"TX"},
	{"zipCode":"77521","state":"TX"},
	{"zipCode":"77522","state":"TX"},
	{"zipCode":"77522","state":"TX"},
	{"zipCode":"77523","state":"TX"},
	{"zipCode":"77530","state":"TX"},
	{"zipCode":"77531","state":"TX"},
	{"zipCode":"77531","state":"TX"},
	{"zipCode":"77532","state":"TX"},
	{"zipCode":"77533","state":"TX"},
	{"zipCode":"77534","state":"TX"},
	{"zipCode":"77535","state":"TX"},
	{"zipCode":"77536","state":"TX"},
	{"zipCode":"77538","state":"TX"},
	{"zipCode":"77539","state":"TX"},
	{"zipCode":"77541","state":"TX"},
	{"zipCode":"77542","state":"TX"},
	{"zipCode":"77545","state":"TX"},
	{"zipCode":"77546","state":"TX"},
	{"zipCode":"77547","state":"TX"},
	{"zipCode":"77549","state":"TX"},
	{"zipCode":"77550","state":"TX"},
	{"zipCode":"77551","state":"TX"},
	{"zipCode":"77552","state":"TX"},
	{"zipCode":"77553","state":"TX"},
	{"zipCode":"77554","state":"TX"},
	{"zipCode":"77560","state":"TX"},
	{"zipCode":"77561","state":"TX"},
	{"zipCode":"77562","state":"TX"},
	{"zipCode":"77563","state":"TX"},
	{"zipCode":"77563","state":"TX"},
	{"zipCode":"77564","state":"TX"},
	{"zipCode":"77565","state":"TX"},
	{"zipCode":"77565","state":"TX"},
	{"zipCode":"77566","state":"TX"},
	{"zipCode":"77568","state":"TX"},
	{"zipCode":"77571","state":"TX"},
	{"zipCode":"77572","state":"TX"},
	{"zipCode":"77573","state":"TX"},
	{"zipCode":"77574","state":"TX"},
	{"zipCode":"77575","state":"TX"},
	{"zipCode":"77577","state":"TX"},
	{"zipCode":"77578","state":"TX"},
	{"zipCode":"77580","state":"TX"},
	{"zipCode":"77581","state":"TX"},
	{"zipCode":"77582","state":"TX"},
	{"zipCode":"77583","state":"TX"},
	{"zipCode":"77584","state":"TX"},
	{"zipCode":"77585","state":"TX"},
	{"zipCode":"77586","state":"TX"},
	{"zipCode":"77587","state":"TX"},
	{"zipCode":"77588","state":"TX"},
	{"zipCode":"77590","state":"TX"},
	{"zipCode":"77591","state":"TX"},
	{"zipCode":"77592","state":"TX"},
	{"zipCode":"77597","state":"TX"},
	{"zipCode":"77598","state":"TX"},
	{"zipCode":"77611","state":"TX"},
	{"zipCode":"77612","state":"TX"},
	{"zipCode":"77613","state":"TX"},
	{"zipCode":"77614","state":"TX"},
	{"zipCode":"77615","state":"TX"},
	{"zipCode":"77616","state":"TX"},
	{"zipCode":"77617","state":"TX"},
	{"zipCode":"77619","state":"TX"},
	{"zipCode":"77622","state":"TX"},
	{"zipCode":"77623","state":"TX"},
	{"zipCode":"77624","state":"TX"},
	{"zipCode":"77625","state":"TX"},
	{"zipCode":"77625","state":"TX"},
	{"zipCode":"77626","state":"TX"},
	{"zipCode":"77627","state":"TX"},
	{"zipCode":"77629","state":"TX"},
	{"zipCode":"77630","state":"TX"},
	{"zipCode":"77631","state":"TX"},
	{"zipCode":"77632","state":"TX"},
	{"zipCode":"77639","state":"TX"},
	{"zipCode":"77640","state":"TX"},
	{"zipCode":"77641","state":"TX"},
	{"zipCode":"77642","state":"TX"},
	{"zipCode":"77643","state":"TX"},
	{"zipCode":"77650","state":"TX"},
	{"zipCode":"77650","state":"TX"},
	{"zipCode":"77651","state":"TX"},
	{"zipCode":"77655","state":"TX"},
	{"zipCode":"77656","state":"TX"},
	{"zipCode":"77657","state":"TX"},
	{"zipCode":"77659","state":"TX"},
	{"zipCode":"77660","state":"TX"},
	{"zipCode":"77661","state":"TX"},
	{"zipCode":"77662","state":"TX"},
	{"zipCode":"77663","state":"TX"},
	{"zipCode":"77664","state":"TX"},
	{"zipCode":"77665","state":"TX"},
	{"zipCode":"77670","state":"TX"},
	{"zipCode":"77701","state":"TX"},
	{"zipCode":"77702","state":"TX"},
	{"zipCode":"77703","state":"TX"},
	{"zipCode":"77704","state":"TX"},
	{"zipCode":"77705","state":"TX"},
	{"zipCode":"77706","state":"TX"},
	{"zipCode":"77707","state":"TX"},
	{"zipCode":"77708","state":"TX"},
	{"zipCode":"77713","state":"TX"},
	{"zipCode":"77720","state":"TX"},
	{"zipCode":"77725","state":"TX"},
	{"zipCode":"77726","state":"TX"},
	{"zipCode":"77801","state":"TX"},
	{"zipCode":"77802","state":"TX"},
	{"zipCode":"77803","state":"TX"},
	{"zipCode":"77805","state":"TX"},
	{"zipCode":"77806","state":"TX"},
	{"zipCode":"77807","state":"TX"},
	{"zipCode":"77808","state":"TX"},
	{"zipCode":"77830","state":"TX"},
	{"zipCode":"77831","state":"TX"},
	{"zipCode":"77833","state":"TX"},
	{"zipCode":"77834","state":"TX"},
	{"zipCode":"77835","state":"TX"},
	{"zipCode":"77836","state":"TX"},
	{"zipCode":"77837","state":"TX"},
	{"zipCode":"77838","state":"TX"},
	{"zipCode":"77840","state":"TX"},
	{"zipCode":"77841","state":"TX"},
	{"zipCode":"77842","state":"TX"},
	{"zipCode":"77845","state":"TX"},
	{"zipCode":"77852","state":"TX"},
	{"zipCode":"77853","state":"TX"},
	{"zipCode":"77855","state":"TX"},
	{"zipCode":"77856","state":"TX"},
	{"zipCode":"77857","state":"TX"},
	{"zipCode":"77859","state":"TX"},
	{"zipCode":"77861","state":"TX"},
	{"zipCode":"77862","state":"TX"},
	{"zipCode":"77863","state":"TX"},
	{"zipCode":"77864","state":"TX"},
	{"zipCode":"77865","state":"TX"},
	{"zipCode":"77866","state":"TX"},
	{"zipCode":"77867","state":"TX"},
	{"zipCode":"77868","state":"TX"},
	{"zipCode":"77870","state":"TX"},
	{"zipCode":"77871","state":"TX"},
	{"zipCode":"77871","state":"TX"},
	{"zipCode":"77872","state":"TX"},
	{"zipCode":"77873","state":"TX"},
	{"zipCode":"77875","state":"TX"},
	{"zipCode":"77876","state":"TX"},
	{"zipCode":"77878","state":"TX"},
	{"zipCode":"77879","state":"TX"},
	{"zipCode":"77880","state":"TX"},
	{"zipCode":"77881","state":"TX"},
	{"zipCode":"77882","state":"TX"},
	{"zipCode":"77901","state":"TX"},
	{"zipCode":"77902","state":"TX"},
	{"zipCode":"77903","state":"TX"},
	{"zipCode":"77904","state":"TX"},
	{"zipCode":"77905","state":"TX"},
	{"zipCode":"77950","state":"TX"},
	{"zipCode":"77951","state":"TX"},
	{"zipCode":"77954","state":"TX"},
	{"zipCode":"77957","state":"TX"},
	{"zipCode":"77960","state":"TX"},
	{"zipCode":"77961","state":"TX"},
	{"zipCode":"77962","state":"TX"},
	{"zipCode":"77963","state":"TX"},
	{"zipCode":"77964","state":"TX"},
	{"zipCode":"77967","state":"TX"},
	{"zipCode":"77968","state":"TX"},
	{"zipCode":"77968","state":"TX"},
	{"zipCode":"77969","state":"TX"},
	{"zipCode":"77970","state":"TX"},
	{"zipCode":"77971","state":"TX"},
	{"zipCode":"77973","state":"TX"},
	{"zipCode":"77974","state":"TX"},
	{"zipCode":"77975","state":"TX"},
	{"zipCode":"77976","state":"TX"},
	{"zipCode":"77977","state":"TX"},
	{"zipCode":"77978","state":"TX"},
	{"zipCode":"77979","state":"TX"},
	{"zipCode":"77982","state":"TX"},
	{"zipCode":"77983","state":"TX"},
	{"zipCode":"77984","state":"TX"},
	{"zipCode":"77986","state":"TX"},
	{"zipCode":"77987","state":"TX"},
	{"zipCode":"77988","state":"TX"},
	{"zipCode":"77989","state":"TX"},
	{"zipCode":"77990","state":"TX"},
	{"zipCode":"77991","state":"TX"},
	{"zipCode":"77993","state":"TX"},
	{"zipCode":"77994","state":"TX"},
	{"zipCode":"77995","state":"TX"},
	{"zipCode":"78002","state":"TX"},
	{"zipCode":"78003","state":"TX"},
	{"zipCode":"78004","state":"TX"},
	{"zipCode":"78005","state":"TX"},
	{"zipCode":"78006","state":"TX"},
	{"zipCode":"78006","state":"TX"},
	{"zipCode":"78007","state":"TX"},
	{"zipCode":"78008","state":"TX"},
	{"zipCode":"78009","state":"TX"},
	{"zipCode":"78010","state":"TX"},
	{"zipCode":"78011","state":"TX"},
	{"zipCode":"78011","state":"TX"},
	{"zipCode":"78012","state":"TX"},
	{"zipCode":"78013","state":"TX"},
	{"zipCode":"78014","state":"TX"},
	{"zipCode":"78015","state":"TX"},
	{"zipCode":"78016","state":"TX"},
	{"zipCode":"78017","state":"TX"},
	{"zipCode":"78017","state":"TX"},
	{"zipCode":"78019","state":"TX"},
	{"zipCode":"78021","state":"TX"},
	{"zipCode":"78022","state":"TX"},
	{"zipCode":"78023","state":"TX"},
	{"zipCode":"78024","state":"TX"},
	{"zipCode":"78025","state":"TX"},
	{"zipCode":"78025","state":"TX"},
	{"zipCode":"78026","state":"TX"},
	{"zipCode":"78027","state":"TX"},
	{"zipCode":"78028","state":"TX"},
	{"zipCode":"78029","state":"TX"},
	{"zipCode":"78039","state":"TX"},
	{"zipCode":"78040","state":"TX"},
	{"zipCode":"78041","state":"TX"},
	{"zipCode":"78042","state":"TX"},
	{"zipCode":"78043","state":"TX"},
	{"zipCode":"78044","state":"TX"},
	{"zipCode":"78045","state":"TX"},
	{"zipCode":"78046","state":"TX"},
	{"zipCode":"78050","state":"TX"},
	{"zipCode":"78052","state":"TX"},
	{"zipCode":"78054","state":"TX"},
	{"zipCode":"78055","state":"TX"},
	{"zipCode":"78056","state":"TX"},
	{"zipCode":"78057","state":"TX"},
	{"zipCode":"78058","state":"TX"},
	{"zipCode":"78059","state":"TX"},
	{"zipCode":"78059","state":"TX"},
	{"zipCode":"78060","state":"TX"},
	{"zipCode":"78061","state":"TX"},
	{"zipCode":"78063","state":"TX"},
	{"zipCode":"78064","state":"TX"},
	{"zipCode":"78065","state":"TX"},
	{"zipCode":"78066","state":"TX"},
	{"zipCode":"78067","state":"TX"},
	{"zipCode":"78069","state":"TX"},
	{"zipCode":"78069","state":"TX"},
	{"zipCode":"78070","state":"TX"},
	{"zipCode":"78071","state":"TX"},
	{"zipCode":"78072","state":"TX"},
	{"zipCode":"78073","state":"TX"},
	{"zipCode":"78074","state":"TX"},
	{"zipCode":"78075","state":"TX"},
	{"zipCode":"78076","state":"TX"},
	{"zipCode":"78101","state":"TX"},
	{"zipCode":"78101","state":"TX"},
	{"zipCode":"78102","state":"TX"},
	{"zipCode":"78104","state":"TX"},
	{"zipCode":"78107","state":"TX"},
	{"zipCode":"78108","state":"TX"},
	{"zipCode":"78109","state":"TX"},
	{"zipCode":"78111","state":"TX"},
	{"zipCode":"78112","state":"TX"},
	{"zipCode":"78113","state":"TX"},
	{"zipCode":"78114","state":"TX"},
	{"zipCode":"78115","state":"TX"},
	{"zipCode":"78116","state":"TX"},
	{"zipCode":"78117","state":"TX"},
	{"zipCode":"78118","state":"TX"},
	{"zipCode":"78118","state":"TX"},
	{"zipCode":"78119","state":"TX"},
	{"zipCode":"78121","state":"TX"},
	{"zipCode":"78122","state":"TX"},
	{"zipCode":"78123","state":"TX"},
	{"zipCode":"78123","state":"TX"},
	{"zipCode":"78124","state":"TX"},
	{"zipCode":"78125","state":"TX"},
	{"zipCode":"78130","state":"TX"},
	{"zipCode":"78131","state":"TX"},
	{"zipCode":"78132","state":"TX"},
	{"zipCode":"78133","state":"TX"},
	{"zipCode":"78135","state":"TX"},
	{"zipCode":"78140","state":"TX"},
	{"zipCode":"78141","state":"TX"},
	{"zipCode":"78142","state":"TX"},
	{"zipCode":"78143","state":"TX"},
	{"zipCode":"78144","state":"TX"},
	{"zipCode":"78145","state":"TX"},
	{"zipCode":"78146","state":"TX"},
	{"zipCode":"78147","state":"TX"},
	{"zipCode":"78148","state":"TX"},
	{"zipCode":"78148","state":"TX"},
	{"zipCode":"78151","state":"TX"},
	{"zipCode":"78152","state":"TX"},
	{"zipCode":"78154","state":"TX"},
	{"zipCode":"78155","state":"TX"},
	{"zipCode":"78156","state":"TX"},
	{"zipCode":"78159","state":"TX"},
	{"zipCode":"78160","state":"TX"},
	{"zipCode":"78161","state":"TX"},
	{"zipCode":"78162","state":"TX"},
	{"zipCode":"78163","state":"TX"},
	{"zipCode":"78163","state":"TX"},
	{"zipCode":"78164","state":"TX"},
	{"zipCode":"78201","state":"TX"},
	{"zipCode":"78202","state":"TX"},
	{"zipCode":"78203","state":"TX"},
	{"zipCode":"78204","state":"TX"},
	{"zipCode":"78205","state":"TX"},
	{"zipCode":"78207","state":"TX"},
	{"zipCode":"78208","state":"TX"},
	{"zipCode":"78208","state":"TX"},
	{"zipCode":"78209","state":"TX"},
	{"zipCode":"78209","state":"TX"},
	{"zipCode":"78210","state":"TX"},
	{"zipCode":"78211","state":"TX"},
	{"zipCode":"78212","state":"TX"},
	{"zipCode":"78213","state":"TX"},
	{"zipCode":"78213","state":"TX"},
	{"zipCode":"78214","state":"TX"},
	{"zipCode":"78214","state":"TX"},
	{"zipCode":"78215","state":"TX"},
	{"zipCode":"78216","state":"TX"},
	{"zipCode":"78217","state":"TX"},
	{"zipCode":"78218","state":"TX"},
	{"zipCode":"78219","state":"TX"},
	{"zipCode":"78220","state":"TX"},
	{"zipCode":"78221","state":"TX"},
	{"zipCode":"78222","state":"TX"},
	{"zipCode":"78223","state":"TX"},
	{"zipCode":"78224","state":"TX"},
	{"zipCode":"78225","state":"TX"},
	{"zipCode":"78226","state":"TX"},
	{"zipCode":"78227","state":"TX"},
	{"zipCode":"78228","state":"TX"},
	{"zipCode":"78229","state":"TX"},
	{"zipCode":"78230","state":"TX"},
	{"zipCode":"78231","state":"TX"},
	{"zipCode":"78232","state":"TX"},
	{"zipCode":"78233","state":"TX"},
	{"zipCode":"78234","state":"TX"},
	{"zipCode":"78234","state":"TX"},
	{"zipCode":"78235","state":"TX"},
	{"zipCode":"78236","state":"TX"},
	{"zipCode":"78237","state":"TX"},
	{"zipCode":"78238","state":"TX"},
	{"zipCode":"78239","state":"TX"},
	{"zipCode":"78240","state":"TX"},
	{"zipCode":"78242","state":"TX"},
	{"zipCode":"78244","state":"TX"},
	{"zipCode":"78245","state":"TX"},
	{"zipCode":"78246","state":"TX"},
	{"zipCode":"78247","state":"TX"},
	{"zipCode":"78248","state":"TX"},
	{"zipCode":"78249","state":"TX"},
	{"zipCode":"78250","state":"TX"},
	{"zipCode":"78251","state":"TX"},
	{"zipCode":"78252","state":"TX"},
	{"zipCode":"78253","state":"TX"},
	{"zipCode":"78254","state":"TX"},
	{"zipCode":"78255","state":"TX"},
	{"zipCode":"78256","state":"TX"},
	{"zipCode":"78257","state":"TX"},
	{"zipCode":"78258","state":"TX"},
	{"zipCode":"78259","state":"TX"},
	{"zipCode":"78260","state":"TX"},
	{"zipCode":"78261","state":"TX"},
	{"zipCode":"78263","state":"TX"},
	{"zipCode":"78264","state":"TX"},
	{"zipCode":"78265","state":"TX"},
	{"zipCode":"78266","state":"TX"},
	{"zipCode":"78268","state":"TX"},
	{"zipCode":"78269","state":"TX"},
	{"zipCode":"78270","state":"TX"},
	{"zipCode":"78278","state":"TX"},
	{"zipCode":"78279","state":"TX"},
	{"zipCode":"78280","state":"TX"},
	{"zipCode":"78283","state":"TX"},
	{"zipCode":"78291","state":"TX"},
	{"zipCode":"78292","state":"TX"},
	{"zipCode":"78293","state":"TX"},
	{"zipCode":"78294","state":"TX"},
	{"zipCode":"78295","state":"TX"},
	{"zipCode":"78296","state":"TX"},
	{"zipCode":"78297","state":"TX"},
	{"zipCode":"78298","state":"TX"},
	{"zipCode":"78299","state":"TX"},
	{"zipCode":"78330","state":"TX"},
	{"zipCode":"78332","state":"TX"},
	{"zipCode":"78333","state":"TX"},
	{"zipCode":"78335","state":"TX"},
	{"zipCode":"78336","state":"TX"},
	{"zipCode":"78338","state":"TX"},
	{"zipCode":"78339","state":"TX"},
	{"zipCode":"78340","state":"TX"},
	{"zipCode":"78341","state":"TX"},
	{"zipCode":"78342","state":"TX"},
	{"zipCode":"78343","state":"TX"},
	{"zipCode":"78343","state":"TX"},
	{"zipCode":"78344","state":"TX"},
	{"zipCode":"78347","state":"TX"},
	{"zipCode":"78349","state":"TX"},
	{"zipCode":"78351","state":"TX"},
	{"zipCode":"78352","state":"TX"},
	{"zipCode":"78353","state":"TX"},
	{"zipCode":"78355","state":"TX"},
	{"zipCode":"78357","state":"TX"},
	{"zipCode":"78358","state":"TX"},
	{"zipCode":"78359","state":"TX"},
	{"zipCode":"78360","state":"TX"},
	{"zipCode":"78361","state":"TX"},
	{"zipCode":"78362","state":"TX"},
	{"zipCode":"78363","state":"TX"},
	{"zipCode":"78363","state":"TX"},
	{"zipCode":"78364","state":"TX"},
	{"zipCode":"78368","state":"TX"},
	{"zipCode":"78369","state":"TX"},
	{"zipCode":"78370","state":"TX"},
	{"zipCode":"78371","state":"TX"},
	{"zipCode":"78372","state":"TX"},
	{"zipCode":"78373","state":"TX"},
	{"zipCode":"78374","state":"TX"},
	{"zipCode":"78375","state":"TX"},
	{"zipCode":"78376","state":"TX"},
	{"zipCode":"78377","state":"TX"},
	{"zipCode":"78379","state":"TX"},
	{"zipCode":"78380","state":"TX"},
	{"zipCode":"78381","state":"TX"},
	{"zipCode":"78382","state":"TX"},
	{"zipCode":"78383","state":"TX"},
	{"zipCode":"78383","state":"TX"},
	{"zipCode":"78384","state":"TX"},
	{"zipCode":"78384","state":"TX"},
	{"zipCode":"78385","state":"TX"},
	{"zipCode":"78387","state":"TX"},
	{"zipCode":"78389","state":"TX"},
	{"zipCode":"78390","state":"TX"},
	{"zipCode":"78390","state":"TX"},
	{"zipCode":"78391","state":"TX"},
	{"zipCode":"78393","state":"TX"},
	{"zipCode":"78401","state":"TX"},
	{"zipCode":"78402","state":"TX"},
	{"zipCode":"78403","state":"TX"},
	{"zipCode":"78404","state":"TX"},
	{"zipCode":"78405","state":"TX"},
	{"zipCode":"78406","state":"TX"},
	{"zipCode":"78407","state":"TX"},
	{"zipCode":"78408","state":"TX"},
	{"zipCode":"78409","state":"TX"},
	{"zipCode":"78410","state":"TX"},
	{"zipCode":"78411","state":"TX"},
	{"zipCode":"78412","state":"TX"},
	{"zipCode":"78413","state":"TX"},
	{"zipCode":"78414","state":"TX"},
	{"zipCode":"78415","state":"TX"},
	{"zipCode":"78416","state":"TX"},
	{"zipCode":"78417","state":"TX"},
	{"zipCode":"78418","state":"TX"},
	{"zipCode":"78426","state":"TX"},
	{"zipCode":"78427","state":"TX"},
	{"zipCode":"78460","state":"TX"},
	{"zipCode":"78463","state":"TX"},
	{"zipCode":"78465","state":"TX"},
	{"zipCode":"78466","state":"TX"},
	{"zipCode":"78467","state":"TX"},
	{"zipCode":"78468","state":"TX"},
	{"zipCode":"78469","state":"TX"},
	{"zipCode":"78480","state":"TX"},
	{"zipCode":"78501","state":"TX"},
	{"zipCode":"78502","state":"TX"},
	{"zipCode":"78503","state":"TX"},
	{"zipCode":"78504","state":"TX"},
	{"zipCode":"78504","state":"TX"},
	{"zipCode":"78505","state":"TX"},
	{"zipCode":"78516","state":"TX"},
	{"zipCode":"78520","state":"TX"},
	{"zipCode":"78521","state":"TX"},
	{"zipCode":"78522","state":"TX"},
	{"zipCode":"78523","state":"TX"},
	{"zipCode":"78526","state":"TX"},
	{"zipCode":"78526","state":"TX"},
	{"zipCode":"78535","state":"TX"},
	{"zipCode":"78536","state":"TX"},
	{"zipCode":"78537","state":"TX"},
	{"zipCode":"78538","state":"TX"},
	{"zipCode":"78538","state":"TX"},
	{"zipCode":"78539","state":"TX"},
	{"zipCode":"78540","state":"TX"},
	{"zipCode":"78541","state":"TX"},
	{"zipCode":"78542","state":"TX"},
	{"zipCode":"78543","state":"TX"},
	{"zipCode":"78545","state":"TX"},
	{"zipCode":"78547","state":"TX"},
	{"zipCode":"78548","state":"TX"},
	{"zipCode":"78549","state":"TX"},
	{"zipCode":"78550","state":"TX"},
	{"zipCode":"78551","state":"TX"},
	{"zipCode":"78552","state":"TX"},
	{"zipCode":"78553","state":"TX"},
	{"zipCode":"78557","state":"TX"},
	{"zipCode":"78558","state":"TX"},
	{"zipCode":"78559","state":"TX"},
	{"zipCode":"78560","state":"TX"},
	{"zipCode":"78560","state":"TX"},
	{"zipCode":"78561","state":"TX"},
	{"zipCode":"78562","state":"TX"},
	{"zipCode":"78563","state":"TX"},
	{"zipCode":"78564","state":"TX"},
	{"zipCode":"78565","state":"TX"},
	{"zipCode":"78566","state":"TX"},
	{"zipCode":"78566","state":"TX"},
	{"zipCode":"78567","state":"TX"},
	{"zipCode":"78568","state":"TX"},
	{"zipCode":"78569","state":"TX"},
	{"zipCode":"78569","state":"TX"},
	{"zipCode":"78570","state":"TX"},
	{"zipCode":"78572","state":"TX"},
	{"zipCode":"78573","state":"TX"},
	{"zipCode":"78574","state":"TX"},
	{"zipCode":"78575","state":"TX"},
	{"zipCode":"78575","state":"TX"},
	{"zipCode":"78576","state":"TX"},
	{"zipCode":"78576","state":"TX"},
	{"zipCode":"78577","state":"TX"},
	{"zipCode":"78577","state":"TX"},
	{"zipCode":"78578","state":"TX"},
	{"zipCode":"78579","state":"TX"},
	{"zipCode":"78580","state":"TX"},
	{"zipCode":"78582","state":"TX"},
	{"zipCode":"78583","state":"TX"},
	{"zipCode":"78583","state":"TX"},
	{"zipCode":"78584","state":"TX"},
	{"zipCode":"78585","state":"TX"},
	{"zipCode":"78586","state":"TX"},
	{"zipCode":"78588","state":"TX"},
	{"zipCode":"78589","state":"TX"},
	{"zipCode":"78590","state":"TX"},
	{"zipCode":"78591","state":"TX"},
	{"zipCode":"78592","state":"TX"},
	{"zipCode":"78593","state":"TX"},
	{"zipCode":"78593","state":"TX"},
	{"zipCode":"78594","state":"TX"},
	{"zipCode":"78595","state":"TX"},
	{"zipCode":"78596","state":"TX"},
	{"zipCode":"78597","state":"TX"},
	{"zipCode":"78599","state":"TX"},
	{"zipCode":"78602","state":"TX"},
	{"zipCode":"78602","state":"TX"},
	{"zipCode":"78604","state":"TX"},
	{"zipCode":"78605","state":"TX"},
	{"zipCode":"78606","state":"TX"},
	{"zipCode":"78607","state":"TX"},
	{"zipCode":"78608","state":"TX"},
	{"zipCode":"78609","state":"TX"},
	{"zipCode":"78610","state":"TX"},
	{"zipCode":"78611","state":"TX"},
	{"zipCode":"78612","state":"TX"},
	{"zipCode":"78613","state":"TX"},
	{"zipCode":"78614","state":"TX"},
	{"zipCode":"78615","state":"TX"},
	{"zipCode":"78616","state":"TX"},
	{"zipCode":"78617","state":"TX"},
	{"zipCode":"78618","state":"TX"},
	{"zipCode":"78618","state":"TX"},
	{"zipCode":"78619","state":"TX"},
	{"zipCode":"78620","state":"TX"},
	{"zipCode":"78621","state":"TX"},
	{"zipCode":"78622","state":"TX"},
	{"zipCode":"78623","state":"TX"},
	{"zipCode":"78624","state":"TX"},
	{"zipCode":"78626","state":"TX"},
	{"zipCode":"78627","state":"TX"},
	{"zipCode":"78628","state":"TX"},
	{"zipCode":"78629","state":"TX"},
	{"zipCode":"78630","state":"TX"},
	{"zipCode":"78631","state":"TX"},
	{"zipCode":"78632","state":"TX"},
	{"zipCode":"78632","state":"TX"},
	{"zipCode":"78633","state":"TX"},
	{"zipCode":"78634","state":"TX"},
	{"zipCode":"78635","state":"TX"},
	{"zipCode":"78636","state":"TX"},
	{"zipCode":"78638","state":"TX"},
	{"zipCode":"78638","state":"TX"},
	{"zipCode":"78639","state":"TX"},
	{"zipCode":"78640","state":"TX"},
	{"zipCode":"78641","state":"TX"},
	{"zipCode":"78642","state":"TX"},
	{"zipCode":"78643","state":"TX"},
	{"zipCode":"78644","state":"TX"},
	{"zipCode":"78645","state":"TX"},
	{"zipCode":"78645","state":"TX"},
	{"zipCode":"78646","state":"TX"},
	{"zipCode":"78648","state":"TX"},
	{"zipCode":"78650","state":"TX"},
	{"zipCode":"78651","state":"TX"},
	{"zipCode":"78652","state":"TX"},
	{"zipCode":"78653","state":"TX"},
	{"zipCode":"78654","state":"TX"},
	{"zipCode":"78655","state":"TX"},
	{"zipCode":"78656","state":"TX"},
	{"zipCode":"78657","state":"TX"},
	{"zipCode":"78658","state":"TX"},
	{"zipCode":"78659","state":"TX"},
	{"zipCode":"78660","state":"TX"},
	{"zipCode":"78661","state":"TX"},
	{"zipCode":"78662","state":"TX"},
	{"zipCode":"78663","state":"TX"},
	{"zipCode":"78664","state":"TX"},
	{"zipCode":"78665","state":"TX"},
	{"zipCode":"78666","state":"TX"},
	{"zipCode":"78667","state":"TX"},
	{"zipCode":"78669","state":"TX"},
	{"zipCode":"78670","state":"TX"},
	{"zipCode":"78671","state":"TX"},
	{"zipCode":"78671","state":"TX"},
	{"zipCode":"78672","state":"TX"},
	{"zipCode":"78672","state":"TX"},
	{"zipCode":"78673","state":"TX"},
	{"zipCode":"78674","state":"TX"},
	{"zipCode":"78675","state":"TX"},
	{"zipCode":"78676","state":"TX"},
	{"zipCode":"78677","state":"TX"},
	{"zipCode":"78680","state":"TX"},
	{"zipCode":"78681","state":"TX"},
	{"zipCode":"78683","state":"TX"},
	{"zipCode":"78691","state":"TX"},
	{"zipCode":"78701","state":"TX"},
	{"zipCode":"78702","state":"TX"},
	{"zipCode":"78703","state":"TX"},
	{"zipCode":"78704","state":"TX"},
	{"zipCode":"78705","state":"TX"},
	{"zipCode":"78708","state":"TX"},
	{"zipCode":"78709","state":"TX"},
	{"zipCode":"78711","state":"TX"},
	{"zipCode":"78713","state":"TX"},
	{"zipCode":"78714","state":"TX"},
	{"zipCode":"78715","state":"TX"},
	{"zipCode":"78716","state":"TX"},
	{"zipCode":"78717","state":"TX"},
	{"zipCode":"78718","state":"TX"},
	{"zipCode":"78719","state":"TX"},
	{"zipCode":"78720","state":"TX"},
	{"zipCode":"78721","state":"TX"},
	{"zipCode":"78722","state":"TX"},
	{"zipCode":"78723","state":"TX"},
	{"zipCode":"78724","state":"TX"},
	{"zipCode":"78725","state":"TX"},
	{"zipCode":"78726","state":"TX"},
	{"zipCode":"78727","state":"TX"},
	{"zipCode":"78728","state":"TX"},
	{"zipCode":"78729","state":"TX"},
	{"zipCode":"78730","state":"TX"},
	{"zipCode":"78731","state":"TX"},
	{"zipCode":"78732","state":"TX"},
	{"zipCode":"78733","state":"TX"},
	{"zipCode":"78734","state":"TX"},
	{"zipCode":"78735","state":"TX"},
	{"zipCode":"78736","state":"TX"},
	{"zipCode":"78737","state":"TX"},
	{"zipCode":"78738","state":"TX"},
	{"zipCode":"78739","state":"TX"},
	{"zipCode":"78741","state":"TX"},
	{"zipCode":"78742","state":"TX"},
	{"zipCode":"78744","state":"TX"},
	{"zipCode":"78745","state":"TX"},
	{"zipCode":"78746","state":"TX"},
	{"zipCode":"78747","state":"TX"},
	{"zipCode":"78748","state":"TX"},
	{"zipCode":"78749","state":"TX"},
	{"zipCode":"78750","state":"TX"},
	{"zipCode":"78751","state":"TX"},
	{"zipCode":"78752","state":"TX"},
	{"zipCode":"78753","state":"TX"},
	{"zipCode":"78754","state":"TX"},
	{"zipCode":"78755","state":"TX"},
	{"zipCode":"78756","state":"TX"},
	{"zipCode":"78757","state":"TX"},
	{"zipCode":"78758","state":"TX"},
	{"zipCode":"78759","state":"TX"},
	{"zipCode":"78760","state":"TX"},
	{"zipCode":"78761","state":"TX"},
	{"zipCode":"78762","state":"TX"},
	{"zipCode":"78763","state":"TX"},
	{"zipCode":"78764","state":"TX"},
	{"zipCode":"78765","state":"TX"},
	{"zipCode":"78766","state":"TX"},
	{"zipCode":"78767","state":"TX"},
	{"zipCode":"78768","state":"TX"},
	{"zipCode":"78801","state":"TX"},
	{"zipCode":"78802","state":"TX"},
	{"zipCode":"78827","state":"TX"},
	{"zipCode":"78828","state":"TX"},
	{"zipCode":"78828","state":"TX"},
	{"zipCode":"78829","state":"TX"},
	{"zipCode":"78830","state":"TX"},
	{"zipCode":"78832","state":"TX"},
	{"zipCode":"78833","state":"TX"},
	{"zipCode":"78834","state":"TX"},
	{"zipCode":"78837","state":"TX"},
	{"zipCode":"78838","state":"TX"},
	{"zipCode":"78839","state":"TX"},
	{"zipCode":"78840","state":"TX"},
	{"zipCode":"78841","state":"TX"},
	{"zipCode":"78842","state":"TX"},
	{"zipCode":"78850","state":"TX"},
	{"zipCode":"78851","state":"TX"},
	{"zipCode":"78852","state":"TX"},
	{"zipCode":"78853","state":"TX"},
	{"zipCode":"78860","state":"TX"},
	{"zipCode":"78861","state":"TX"},
	{"zipCode":"78870","state":"TX"},
	{"zipCode":"78871","state":"TX"},
	{"zipCode":"78872","state":"TX"},
	{"zipCode":"78873","state":"TX"},
	{"zipCode":"78877","state":"TX"},
	{"zipCode":"78879","state":"TX"},
	{"zipCode":"78880","state":"TX"},
	{"zipCode":"78881","state":"TX"},
	{"zipCode":"78883","state":"TX"},
	{"zipCode":"78884","state":"TX"},
	{"zipCode":"78885","state":"TX"},
	{"zipCode":"78886","state":"TX"},
	{"zipCode":"78931","state":"TX"},
	{"zipCode":"78932","state":"TX"},
	{"zipCode":"78933","state":"TX"},
	{"zipCode":"78934","state":"TX"},
	{"zipCode":"78935","state":"TX"},
	{"zipCode":"78938","state":"TX"},
	{"zipCode":"78940","state":"TX"},
	{"zipCode":"78941","state":"TX"},
	{"zipCode":"78942","state":"TX"},
	{"zipCode":"78943","state":"TX"},
	{"zipCode":"78944","state":"TX"},
	{"zipCode":"78945","state":"TX"},
	{"zipCode":"78946","state":"TX"},
	{"zipCode":"78946","state":"TX"},
	{"zipCode":"78947","state":"TX"},
	{"zipCode":"78948","state":"TX"},
	{"zipCode":"78948","state":"TX"},
	{"zipCode":"78949","state":"TX"},
	{"zipCode":"78950","state":"TX"},
	{"zipCode":"78951","state":"TX"},
	{"zipCode":"78952","state":"TX"},
	{"zipCode":"78953","state":"TX"},
	{"zipCode":"78954","state":"TX"},
	{"zipCode":"78956","state":"TX"},
	{"zipCode":"78957","state":"TX"},
	{"zipCode":"78959","state":"TX"},
	{"zipCode":"78960","state":"TX"},
	{"zipCode":"78961","state":"TX"},
	{"zipCode":"78962","state":"TX"},
	{"zipCode":"78963","state":"TX"},
	{"zipCode":"78963","state":"TX"},
	{"zipCode":"79001","state":"TX"},
	{"zipCode":"79002","state":"TX"},
	{"zipCode":"79003","state":"TX"},
	{"zipCode":"79005","state":"TX"},
	{"zipCode":"79007","state":"TX"},
	{"zipCode":"79008","state":"TX"},
	{"zipCode":"79009","state":"TX"},
	{"zipCode":"79010","state":"TX"},
	{"zipCode":"79010","state":"TX"},
	{"zipCode":"79011","state":"TX"},
	{"zipCode":"79011","state":"TX"},
	{"zipCode":"79012","state":"TX"},
	{"zipCode":"79013","state":"TX"},
	{"zipCode":"79014","state":"TX"},
	{"zipCode":"79015","state":"TX"},
	{"zipCode":"79018","state":"TX"},
	{"zipCode":"79019","state":"TX"},
	{"zipCode":"79021","state":"TX"},
	{"zipCode":"79022","state":"TX"},
	{"zipCode":"79024","state":"TX"},
	{"zipCode":"79025","state":"TX"},
	{"zipCode":"79027","state":"TX"},
	{"zipCode":"79029","state":"TX"},
	{"zipCode":"79031","state":"TX"},
	{"zipCode":"79032","state":"TX"},
	{"zipCode":"79033","state":"TX"},
	{"zipCode":"79034","state":"TX"},
	{"zipCode":"79035","state":"TX"},
	{"zipCode":"79036","state":"TX"},
	{"zipCode":"79039","state":"TX"},
	{"zipCode":"79040","state":"TX"},
	{"zipCode":"79041","state":"TX"},
	{"zipCode":"79041","state":"TX"},
	{"zipCode":"79042","state":"TX"},
	{"zipCode":"79043","state":"TX"},
	{"zipCode":"79044","state":"TX"},
	{"zipCode":"79044","state":"TX"},
	{"zipCode":"79045","state":"TX"},
	{"zipCode":"79046","state":"TX"},
	{"zipCode":"79052","state":"TX"},
	{"zipCode":"79052","state":"TX"},
	{"zipCode":"79053","state":"TX"},
	{"zipCode":"79054","state":"TX"},
	{"zipCode":"79056","state":"TX"},
	{"zipCode":"79056","state":"TX"},
	{"zipCode":"79057","state":"TX"},
	{"zipCode":"79058","state":"TX"},
	{"zipCode":"79059","state":"TX"},
	{"zipCode":"79061","state":"TX"},
	{"zipCode":"79061","state":"TX"},
	{"zipCode":"79062","state":"TX"},
	{"zipCode":"79062","state":"TX"},
	{"zipCode":"79063","state":"TX"},
	{"zipCode":"79063","state":"TX"},
	{"zipCode":"79064","state":"TX"},
	{"zipCode":"79065","state":"TX"},
	{"zipCode":"79066","state":"TX"},
	{"zipCode":"79068","state":"TX"},
	{"zipCode":"79070","state":"TX"},
	{"zipCode":"79072","state":"TX"},
	{"zipCode":"79073","state":"TX"},
	{"zipCode":"79078","state":"TX"},
	{"zipCode":"79079","state":"TX"},
	{"zipCode":"79080","state":"TX"},
	{"zipCode":"79080","state":"TX"},
	{"zipCode":"79081","state":"TX"},
	{"zipCode":"79082","state":"TX"},
	{"zipCode":"79082","state":"TX"},
	{"zipCode":"79083","state":"TX"},
	{"zipCode":"79084","state":"TX"},
	{"zipCode":"79085","state":"TX"},
	{"zipCode":"79086","state":"TX"},
	{"zipCode":"79087","state":"TX"},
	{"zipCode":"79088","state":"TX"},
	{"zipCode":"79091","state":"TX"},
	{"zipCode":"79092","state":"TX"},
	{"zipCode":"79093","state":"TX"},
	{"zipCode":"79094","state":"TX"},
	{"zipCode":"79094","state":"TX"},
	{"zipCode":"79095","state":"TX"},
	{"zipCode":"79096","state":"TX"},
	{"zipCode":"79097","state":"TX"},
	{"zipCode":"79098","state":"TX"},
	{"zipCode":"79101","state":"TX"},
	{"zipCode":"79102","state":"TX"},
	{"zipCode":"79103","state":"TX"},
	{"zipCode":"79104","state":"TX"},
	{"zipCode":"79105","state":"TX"},
	{"zipCode":"79106","state":"TX"},
	{"zipCode":"79107","state":"TX"},
	{"zipCode":"79108","state":"TX"},
	{"zipCode":"79109","state":"TX"},
	{"zipCode":"79110","state":"TX"},
	{"zipCode":"79111","state":"TX"},
	{"zipCode":"79114","state":"TX"},
	{"zipCode":"79116","state":"TX"},
	{"zipCode":"79117","state":"TX"},
	{"zipCode":"79118","state":"TX"},
	{"zipCode":"79119","state":"TX"},
	{"zipCode":"79120","state":"TX"},
	{"zipCode":"79121","state":"TX"},
	{"zipCode":"79124","state":"TX"},
	{"zipCode":"79159","state":"TX"},
	{"zipCode":"79189","state":"TX"},
	{"zipCode":"79201","state":"TX"},
	{"zipCode":"79220","state":"TX"},
	{"zipCode":"79225","state":"TX"},
	{"zipCode":"79226","state":"TX"},
	{"zipCode":"79227","state":"TX"},
	{"zipCode":"79229","state":"TX"},
	{"zipCode":"79230","state":"TX"},
	{"zipCode":"79230","state":"TX"},
	{"zipCode":"79231","state":"TX"},
	{"zipCode":"79233","state":"TX"},
	{"zipCode":"79234","state":"TX"},
	{"zipCode":"79234","state":"TX"},
	{"zipCode":"79235","state":"TX"},
	{"zipCode":"79236","state":"TX"},
	{"zipCode":"79237","state":"TX"},
	{"zipCode":"79239","state":"TX"},
	{"zipCode":"79239","state":"TX"},
	{"zipCode":"79240","state":"TX"},
	{"zipCode":"79241","state":"TX"},
	{"zipCode":"79243","state":"TX"},
	{"zipCode":"79243","state":"TX"},
	{"zipCode":"79244","state":"TX"},
	{"zipCode":"79245","state":"TX"},
	{"zipCode":"79247","state":"TX"},
	{"zipCode":"79248","state":"TX"},
	{"zipCode":"79250","state":"TX"},
	{"zipCode":"79251","state":"TX"},
	{"zipCode":"79252","state":"TX"},
	{"zipCode":"79255","state":"TX"},
	{"zipCode":"79256","state":"TX"},
	{"zipCode":"79257","state":"TX"},
	{"zipCode":"79258","state":"TX"},
	{"zipCode":"79259","state":"TX"},
	{"zipCode":"79261","state":"TX"},
	{"zipCode":"79261","state":"TX"},
	{"zipCode":"79311","state":"TX"},
	{"zipCode":"79312","state":"TX"},
	{"zipCode":"79313","state":"TX"},
	{"zipCode":"79314","state":"TX"},
	{"zipCode":"79316","state":"TX"},
	{"zipCode":"79322","state":"TX"},
	{"zipCode":"79323","state":"TX"},
	{"zipCode":"79324","state":"TX"},
	{"zipCode":"79325","state":"TX"},
	{"zipCode":"79326","state":"TX"},
	{"zipCode":"79329","state":"TX"},
	{"zipCode":"79330","state":"TX"},
	{"zipCode":"79331","state":"TX"},
	{"zipCode":"79336","state":"TX"},
	{"zipCode":"79338","state":"TX"},
	{"zipCode":"79339","state":"TX"},
	{"zipCode":"79342","state":"TX"},
	{"zipCode":"79343","state":"TX"},
	{"zipCode":"79344","state":"TX"},
	{"zipCode":"79345","state":"TX"},
	{"zipCode":"79346","state":"TX"},
	{"zipCode":"79347","state":"TX"},
	{"zipCode":"79350","state":"TX"},
	{"zipCode":"79351","state":"TX"},
	{"zipCode":"79353","state":"TX"},
	{"zipCode":"79353","state":"TX"},
	{"zipCode":"79355","state":"TX"},
	{"zipCode":"79356","state":"TX"},
	{"zipCode":"79357","state":"TX"},
	{"zipCode":"79357","state":"TX"},
	{"zipCode":"79358","state":"TX"},
	{"zipCode":"79359","state":"TX"},
	{"zipCode":"79360","state":"TX"},
	{"zipCode":"79363","state":"TX"},
	{"zipCode":"79364","state":"TX"},
	{"zipCode":"79366","state":"TX"},
	{"zipCode":"79367","state":"TX"},
	{"zipCode":"79369","state":"TX"},
	{"zipCode":"79370","state":"TX"},
	{"zipCode":"79371","state":"TX"},
	{"zipCode":"79372","state":"TX"},
	{"zipCode":"79373","state":"TX"},
	{"zipCode":"79376","state":"TX"},
	{"zipCode":"79377","state":"TX"},
	{"zipCode":"79377","state":"TX"},
	{"zipCode":"79378","state":"TX"},
	{"zipCode":"79379","state":"TX"},
	{"zipCode":"79380","state":"TX"},
	{"zipCode":"79381","state":"TX"},
	{"zipCode":"79381","state":"TX"},
	{"zipCode":"79382","state":"TX"},
	{"zipCode":"79401","state":"TX"},
	{"zipCode":"79403","state":"TX"},
	{"zipCode":"79404","state":"TX"},
	{"zipCode":"79407","state":"TX"},
	{"zipCode":"79408","state":"TX"},
	{"zipCode":"79409","state":"TX"},
	{"zipCode":"79410","state":"TX"},
	{"zipCode":"79411","state":"TX"},
	{"zipCode":"79412","state":"TX"},
	{"zipCode":"79413","state":"TX"},
	{"zipCode":"79414","state":"TX"},
	{"zipCode":"79415","state":"TX"},
	{"zipCode":"79416","state":"TX"},
	{"zipCode":"79423","state":"TX"},
	{"zipCode":"79424","state":"TX"},
	{"zipCode":"79452","state":"TX"},
	{"zipCode":"79453","state":"TX"},
	{"zipCode":"79464","state":"TX"},
	{"zipCode":"79490","state":"TX"},
	{"zipCode":"79493","state":"TX"},
	{"zipCode":"79499","state":"TX"},
	{"zipCode":"79501","state":"TX"},
	{"zipCode":"79502","state":"TX"},
	{"zipCode":"79503","state":"TX"},
	{"zipCode":"79504","state":"TX"},
	{"zipCode":"79505","state":"TX"},
	{"zipCode":"79505","state":"TX"},
	{"zipCode":"79506","state":"TX"},
	{"zipCode":"79508","state":"TX"},
	{"zipCode":"79510","state":"TX"},
	{"zipCode":"79511","state":"TX"},
	{"zipCode":"79512","state":"TX"},
	{"zipCode":"79518","state":"TX"},
	{"zipCode":"79519","state":"TX"},
	{"zipCode":"79520","state":"TX"},
	{"zipCode":"79521","state":"TX"},
	{"zipCode":"79525","state":"TX"},
	{"zipCode":"79526","state":"TX"},
	{"zipCode":"79526","state":"TX"},
	{"zipCode":"79527","state":"TX"},
	{"zipCode":"79527","state":"TX"},
	{"zipCode":"79528","state":"TX"},
	{"zipCode":"79529","state":"TX"},
	{"zipCode":"79530","state":"TX"},
	{"zipCode":"79530","state":"TX"},
	{"zipCode":"79532","state":"TX"},
	{"zipCode":"79533","state":"TX"},
	{"zipCode":"79533","state":"TX"},
	{"zipCode":"79534","state":"TX"},
	{"zipCode":"79534","state":"TX"},
	{"zipCode":"79535","state":"TX"},
	{"zipCode":"79536","state":"TX"},
	{"zipCode":"79537","state":"TX"},
	{"zipCode":"79538","state":"TX"},
	{"zipCode":"79538","state":"TX"},
	{"zipCode":"79539","state":"TX"},
	{"zipCode":"79539","state":"TX"},
	{"zipCode":"79540","state":"TX"},
	{"zipCode":"79540","state":"TX"},
	{"zipCode":"79541","state":"TX"},
	{"zipCode":"79543","state":"TX"},
	{"zipCode":"79543","state":"TX"},
	{"zipCode":"79544","state":"TX"},
	{"zipCode":"79544","state":"TX"},
	{"zipCode":"79545","state":"TX"},
	{"zipCode":"79546","state":"TX"},
	{"zipCode":"79547","state":"TX"},
	{"zipCode":"79548","state":"TX"},
	{"zipCode":"79549","state":"TX"},
	{"zipCode":"79550","state":"TX"},
	{"zipCode":"79553","state":"TX"},
	{"zipCode":"79556","state":"TX"},
	{"zipCode":"79560","state":"TX"},
	{"zipCode":"79560","state":"TX"},
	{"zipCode":"79561","state":"TX"},
	{"zipCode":"79561","state":"TX"},
	{"zipCode":"79562","state":"TX"},
	{"zipCode":"79563","state":"TX"},
	{"zipCode":"79565","state":"TX"},
	{"zipCode":"79565","state":"TX"},
	{"zipCode":"79566","state":"TX"},
	{"zipCode":"79566","state":"TX"},
	{"zipCode":"79567","state":"TX"},
	{"zipCode":"79601","state":"TX"},
	{"zipCode":"79602","state":"TX"},
	{"zipCode":"79603","state":"TX"},
	{"zipCode":"79604","state":"TX"},
	{"zipCode":"79605","state":"TX"},
	{"zipCode":"79606","state":"TX"},
	{"zipCode":"79607","state":"TX"},
	{"zipCode":"79607","state":"TX"},
	{"zipCode":"79608","state":"TX"},
	{"zipCode":"79701","state":"TX"},
	{"zipCode":"79702","state":"TX"},
	{"zipCode":"79703","state":"TX"},
	{"zipCode":"79704","state":"TX"},
	{"zipCode":"79705","state":"TX"},
	{"zipCode":"79706","state":"TX"},
	{"zipCode":"79707","state":"TX"},
	{"zipCode":"79708","state":"TX"},
	{"zipCode":"79710","state":"TX"},
	{"zipCode":"79711","state":"TX"},
	{"zipCode":"79713","state":"TX"},
	{"zipCode":"79714","state":"TX"},
	{"zipCode":"79718","state":"TX"},
	{"zipCode":"79718","state":"TX"},
	{"zipCode":"79719","state":"TX"},
	{"zipCode":"79720","state":"TX"},
	{"zipCode":"79721","state":"TX"},
	{"zipCode":"79730","state":"TX"},
	{"zipCode":"79731","state":"TX"},
	{"zipCode":"79733","state":"TX"},
	{"zipCode":"79734","state":"TX"},
	{"zipCode":"79735","state":"TX"},
	{"zipCode":"79738","state":"TX"},
	{"zipCode":"79739","state":"TX"},
	{"zipCode":"79740","state":"TX"},
	{"zipCode":"79741","state":"TX"},
	{"zipCode":"79742","state":"TX"},
	{"zipCode":"79743","state":"TX"},
	{"zipCode":"79744","state":"TX"},
	{"zipCode":"79745","state":"TX"},
	{"zipCode":"79748","state":"TX"},
	{"zipCode":"79748","state":"TX"},
	{"zipCode":"79749","state":"TX"},
	{"zipCode":"79752","state":"TX"},
	{"zipCode":"79754","state":"TX"},
	{"zipCode":"79755","state":"TX"},
	{"zipCode":"79756","state":"TX"},
	{"zipCode":"79758","state":"TX"},
	{"zipCode":"79759","state":"TX"},
	{"zipCode":"79760","state":"TX"},
	{"zipCode":"79761","state":"TX"},
	{"zipCode":"79762","state":"TX"},
	{"zipCode":"79763","state":"TX"},
	{"zipCode":"79764","state":"TX"},
	{"zipCode":"79765","state":"TX"},
	{"zipCode":"79766","state":"TX"},
	{"zipCode":"79768","state":"TX"},
	{"zipCode":"79769","state":"TX"},
	{"zipCode":"79770","state":"TX"},
	{"zipCode":"79772","state":"TX"},
	{"zipCode":"79776","state":"TX"},
	{"zipCode":"79777","state":"TX"},
	{"zipCode":"79778","state":"TX"},
	{"zipCode":"79780","state":"TX"},
	{"zipCode":"79781","state":"TX"},
	{"zipCode":"79782","state":"TX"},
	{"zipCode":"79783","state":"TX"},
	{"zipCode":"79785","state":"TX"},
	{"zipCode":"79786","state":"TX"},
	{"zipCode":"79788","state":"TX"},
	{"zipCode":"79789","state":"TX"},
	{"zipCode":"79830","state":"TX"},
	{"zipCode":"79831","state":"TX"},
	{"zipCode":"79834","state":"TX"},
	{"zipCode":"79835","state":"TX"},
	{"zipCode":"79836","state":"TX"},
	{"zipCode":"79837","state":"TX"},
	{"zipCode":"79838","state":"TX"},
	{"zipCode":"79839","state":"TX"},
	{"zipCode":"79842","state":"TX"},
	{"zipCode":"79843","state":"TX"},
	{"zipCode":"79845","state":"TX"},
	{"zipCode":"79846","state":"TX"},
	{"zipCode":"79847","state":"TX"},
	{"zipCode":"79848","state":"TX"},
	{"zipCode":"79849","state":"TX"},
	{"zipCode":"79849","state":"TX"},
	{"zipCode":"79851","state":"TX"},
	{"zipCode":"79852","state":"TX"},
	{"zipCode":"79853","state":"TX"},
	{"zipCode":"79854","state":"TX"},
	{"zipCode":"79855","state":"TX"},
	{"zipCode":"79901","state":"TX"},
	{"zipCode":"79902","state":"TX"},
	{"zipCode":"79903","state":"TX"},
	{"zipCode":"79904","state":"TX"},
	{"zipCode":"79905","state":"TX"},
	{"zipCode":"79906","state":"TX"},
	{"zipCode":"79907","state":"TX"},
	{"zipCode":"79908","state":"TX"},
	{"zipCode":"79911","state":"TX"},
	{"zipCode":"79912","state":"TX"},
	{"zipCode":"79913","state":"TX"},
	{"zipCode":"79914","state":"TX"},
	{"zipCode":"79915","state":"TX"},
	{"zipCode":"79917","state":"TX"},
	{"zipCode":"79922","state":"TX"},
	{"zipCode":"79923","state":"TX"},
	{"zipCode":"79924","state":"TX"},
	{"zipCode":"79925","state":"TX"},
	{"zipCode":"79926","state":"TX"},
	{"zipCode":"79927","state":"TX"},
	{"zipCode":"79928","state":"TX"},
	{"zipCode":"79929","state":"TX"},
	{"zipCode":"79930","state":"TX"},
	{"zipCode":"79931","state":"TX"},
	{"zipCode":"79932","state":"TX"},
	{"zipCode":"79934","state":"TX"},
	{"zipCode":"79935","state":"TX"},
	{"zipCode":"79936","state":"TX"},
	{"zipCode":"79937","state":"TX"},
	{"zipCode":"79938","state":"TX"},
	{"zipCode":"79940","state":"TX"},
	{"zipCode":"79941","state":"TX"},
	{"zipCode":"79942","state":"TX"},
	{"zipCode":"79943","state":"TX"},
	{"zipCode":"79944","state":"TX"},
	{"zipCode":"79945","state":"TX"},
	{"zipCode":"79946","state":"TX"},
	{"zipCode":"79947","state":"TX"},
	{"zipCode":"79948","state":"TX"},
	{"zipCode":"79949","state":"TX"},
	{"zipCode":"79950","state":"TX"},
	{"zipCode":"79951","state":"TX"},
	{"zipCode":"79952","state":"TX"},
	{"zipCode":"79953","state":"TX"},
	{"zipCode":"79954","state":"TX"},
	{"zipCode":"79955","state":"TX"},
	{"zipCode":"79995","state":"TX"},
	{"zipCode":"79996","state":"TX"},
	{"zipCode":"79997","state":"TX"},
	{"zipCode":"79998","state":"TX"},
	{"zipCode":"79999","state":"TX"},
	{"zipCode":"80001","state":"CO"},
	{"zipCode":"80002","state":"CO"},
	{"zipCode":"80003","state":"CO"},
	{"zipCode":"80004","state":"CO"},
	{"zipCode":"80005","state":"CO"},
	{"zipCode":"80006","state":"CO"},
	{"zipCode":"80007","state":"CO"},
	{"zipCode":"80010","state":"CO"},
	{"zipCode":"80011","state":"CO"},
	{"zipCode":"80012","state":"CO"},
	{"zipCode":"80013","state":"CO"},
	{"zipCode":"80014","state":"CO"},
	{"zipCode":"80015","state":"CO"},
	{"zipCode":"80016","state":"CO"},
	{"zipCode":"80017","state":"CO"},
	{"zipCode":"80018","state":"CO"},
	{"zipCode":"80019","state":"CO"},
	{"zipCode":"80020","state":"CO"},
	{"zipCode":"80021","state":"CO"},
	{"zipCode":"80022","state":"CO"},
	{"zipCode":"80022","state":"CO"},
	{"zipCode":"80023","state":"CO"},
	{"zipCode":"80024","state":"CO"},
	{"zipCode":"80025","state":"CO"},
	{"zipCode":"80026","state":"CO"},
	{"zipCode":"80026","state":"CO"},
	{"zipCode":"80027","state":"CO"},
	{"zipCode":"80030","state":"CO"},
	{"zipCode":"80031","state":"CO"},
	{"zipCode":"80033","state":"CO"},
	{"zipCode":"80034","state":"CO"},
	{"zipCode":"80035","state":"CO"},
	{"zipCode":"80036","state":"CO"},
	{"zipCode":"80037","state":"CO"},
	{"zipCode":"80038","state":"CO"},
	{"zipCode":"80040","state":"CO"},
	{"zipCode":"80041","state":"CO"},
	{"zipCode":"80042","state":"CO"},
	{"zipCode":"80044","state":"CO"},
	{"zipCode":"80045","state":"CO"},
	{"zipCode":"80045","state":"CO"},
	{"zipCode":"80046","state":"CO"},
	{"zipCode":"80047","state":"CO"},
	{"zipCode":"80101","state":"CO"},
	{"zipCode":"80101","state":"CO"},
	{"zipCode":"80102","state":"CO"},
	{"zipCode":"80103","state":"CO"},
	{"zipCode":"80104","state":"CO"},
	{"zipCode":"80105","state":"CO"},
	{"zipCode":"80106","state":"CO"},
	{"zipCode":"80107","state":"CO"},
	{"zipCode":"80108","state":"CO"},
	{"zipCode":"80109","state":"CO"},
	{"zipCode":"80110","state":"CO"},
	{"zipCode":"80111","state":"CO"},
	{"zipCode":"80112","state":"CO"},
	{"zipCode":"80113","state":"CO"},
	{"zipCode":"80116","state":"CO"},
	{"zipCode":"80117","state":"CO"},
	{"zipCode":"80118","state":"CO"},
	{"zipCode":"80120","state":"CO"},
	{"zipCode":"80121","state":"CO"},
	{"zipCode":"80122","state":"CO"},
	{"zipCode":"80123","state":"CO"},
	{"zipCode":"80124","state":"CO"},
	{"zipCode":"80125","state":"CO"},
	{"zipCode":"80126","state":"CO"},
	{"zipCode":"80127","state":"CO"},
	{"zipCode":"80128","state":"CO"},
	{"zipCode":"80129","state":"CO"},
	{"zipCode":"80130","state":"CO"},
	{"zipCode":"80131","state":"CO"},
	{"zipCode":"80132","state":"CO"},
	{"zipCode":"80133","state":"CO"},
	{"zipCode":"80133","state":"CO"},
	{"zipCode":"80134","state":"CO"},
	{"zipCode":"80135","state":"CO"},
	{"zipCode":"80136","state":"CO"},
	{"zipCode":"80136","state":"CO"},
	{"zipCode":"80137","state":"CO"},
	{"zipCode":"80138","state":"CO"},
	{"zipCode":"80150","state":"CO"},
	{"zipCode":"80151","state":"CO"},
	{"zipCode":"80155","state":"CO"},
	{"zipCode":"80160","state":"CO"},
	{"zipCode":"80161","state":"CO"},
	{"zipCode":"80162","state":"CO"},
	{"zipCode":"80163","state":"CO"},
	{"zipCode":"80201","state":"CO"},
	{"zipCode":"80202","state":"CO"},
	{"zipCode":"80203","state":"CO"},
	{"zipCode":"80203","state":"CO"},
	{"zipCode":"80204","state":"CO"},
	{"zipCode":"80205","state":"CO"},
	{"zipCode":"80206","state":"CO"},
	{"zipCode":"80206","state":"CO"},
	{"zipCode":"80207","state":"CO"},
	{"zipCode":"80209","state":"CO"},
	{"zipCode":"80209","state":"CO"},
	{"zipCode":"80210","state":"CO"},
	{"zipCode":"80211","state":"CO"},
	{"zipCode":"80212","state":"CO"},
	{"zipCode":"80214","state":"CO"},
	{"zipCode":"80215","state":"CO"},
	{"zipCode":"80216","state":"CO"},
	{"zipCode":"80217","state":"CO"},
	{"zipCode":"80218","state":"CO"},
	{"zipCode":"80218","state":"CO"},
	{"zipCode":"80219","state":"CO"},
	{"zipCode":"80220","state":"CO"},
	{"zipCode":"80221","state":"CO"},
	{"zipCode":"80222","state":"CO"},
	{"zipCode":"80223","state":"CO"},
	{"zipCode":"80224","state":"CO"},
	{"zipCode":"80226","state":"CO"},
	{"zipCode":"80227","state":"CO"},
	{"zipCode":"80228","state":"CO"},
	{"zipCode":"80229","state":"CO"},
	{"zipCode":"80230","state":"CO"},
	{"zipCode":"80231","state":"CO"},
	{"zipCode":"80232","state":"CO"},
	{"zipCode":"80233","state":"CO"},
	{"zipCode":"80234","state":"CO"},
	{"zipCode":"80235","state":"CO"},
	{"zipCode":"80236","state":"CO"},
	{"zipCode":"80237","state":"CO"},
	{"zipCode":"80238","state":"CO"},
	{"zipCode":"80239","state":"CO"},
	{"zipCode":"80241","state":"CO"},
	{"zipCode":"80246","state":"CO"},
	{"zipCode":"80247","state":"CO"},
	{"zipCode":"80248","state":"CO"},
	{"zipCode":"80249","state":"CO"},
	{"zipCode":"80250","state":"CO"},
	{"zipCode":"80260","state":"CO"},
	{"zipCode":"80264","state":"CO"},
	{"zipCode":"80265","state":"CO"},
	{"zipCode":"80290","state":"CO"},
	{"zipCode":"80293","state":"CO"},
	{"zipCode":"80294","state":"CO"},
	{"zipCode":"80299","state":"CO"},
	{"zipCode":"80301","state":"CO"},
	{"zipCode":"80302","state":"CO"},
	{"zipCode":"80303","state":"CO"},
	{"zipCode":"80304","state":"CO"},
	{"zipCode":"80305","state":"CO"},
	{"zipCode":"80306","state":"CO"},
	{"zipCode":"80307","state":"CO"},
	{"zipCode":"80308","state":"CO"},
	{"zipCode":"80401","state":"CO"},
	{"zipCode":"80402","state":"CO"},
	{"zipCode":"80402","state":"CO"},
	{"zipCode":"80403","state":"CO"},
	{"zipCode":"80403","state":"CO"},
	{"zipCode":"80420","state":"CO"},
	{"zipCode":"80421","state":"CO"},
	{"zipCode":"80422","state":"CO"},
	{"zipCode":"80423","state":"CO"},
	{"zipCode":"80424","state":"CO"},
	{"zipCode":"80425","state":"CO"},
	{"zipCode":"80426","state":"CO"},
	{"zipCode":"80427","state":"CO"},
	{"zipCode":"80428","state":"CO"},
	{"zipCode":"80430","state":"CO"},
	{"zipCode":"80432","state":"CO"},
	{"zipCode":"80433","state":"CO"},
	{"zipCode":"80434","state":"CO"},
	{"zipCode":"80435","state":"CO"},
	{"zipCode":"80436","state":"CO"},
	{"zipCode":"80437","state":"CO"},
	{"zipCode":"80438","state":"CO"},
	{"zipCode":"80439","state":"CO"},
	{"zipCode":"80440","state":"CO"},
	{"zipCode":"80442","state":"CO"},
	{"zipCode":"80443","state":"CO"},
	{"zipCode":"80444","state":"CO"},
	{"zipCode":"80446","state":"CO"},
	{"zipCode":"80447","state":"CO"},
	{"zipCode":"80448","state":"CO"},
	{"zipCode":"80449","state":"CO"},
	{"zipCode":"80451","state":"CO"},
	{"zipCode":"80452","state":"CO"},
	{"zipCode":"80453","state":"CO"},
	{"zipCode":"80454","state":"CO"},
	{"zipCode":"80455","state":"CO"},
	{"zipCode":"80456","state":"CO"},
	{"zipCode":"80456","state":"CO"},
	{"zipCode":"80457","state":"CO"},
	{"zipCode":"80459","state":"CO"},
	{"zipCode":"80461","state":"CO"},
	{"zipCode":"80463","state":"CO"},
	{"zipCode":"80465","state":"CO"},
	{"zipCode":"80466","state":"CO"},
	{"zipCode":"80467","state":"CO"},
	{"zipCode":"80468","state":"CO"},
	{"zipCode":"80469","state":"CO"},
	{"zipCode":"80470","state":"CO"},
	{"zipCode":"80471","state":"CO"},
	{"zipCode":"80473","state":"CO"},
	{"zipCode":"80474","state":"CO"},
	{"zipCode":"80475","state":"CO"},
	{"zipCode":"80476","state":"CO"},
	{"zipCode":"80477","state":"CO"},
	{"zipCode":"80478","state":"CO"},
	{"zipCode":"80479","state":"CO"},
	{"zipCode":"80480","state":"CO"},
	{"zipCode":"80481","state":"CO"},
	{"zipCode":"80481","state":"CO"},
	{"zipCode":"80482","state":"CO"},
	{"zipCode":"80483","state":"CO"},
	{"zipCode":"80487","state":"CO"},
	{"zipCode":"80488","state":"CO"},
	{"zipCode":"80497","state":"CO"},
	{"zipCode":"80498","state":"CO"},
	{"zipCode":"80498","state":"CO"},
	{"zipCode":"80501","state":"CO"},
	{"zipCode":"80501","state":"CO"},
	{"zipCode":"80502","state":"CO"},
	{"zipCode":"80503","state":"CO"},
	{"zipCode":"80504","state":"CO"},
	{"zipCode":"80510","state":"CO"},
	{"zipCode":"80511","state":"CO"},
	{"zipCode":"80512","state":"CO"},
	{"zipCode":"80513","state":"CO"},
	{"zipCode":"80514","state":"CO"},
	{"zipCode":"80515","state":"CO"},
	{"zipCode":"80516","state":"CO"},
	{"zipCode":"80516","state":"CO"},
	{"zipCode":"80517","state":"CO"},
	{"zipCode":"80520","state":"CO"},
	{"zipCode":"80521","state":"CO"},
	{"zipCode":"80522","state":"CO"},
	{"zipCode":"80524","state":"CO"},
	{"zipCode":"80525","state":"CO"},
	{"zipCode":"80526","state":"CO"},
	{"zipCode":"80527","state":"CO"},
	{"zipCode":"80528","state":"CO"},
	{"zipCode":"80530","state":"CO"},
	{"zipCode":"80532","state":"CO"},
	{"zipCode":"80533","state":"CO"},
	{"zipCode":"80534","state":"CO"},
	{"zipCode":"80535","state":"CO"},
	{"zipCode":"80536","state":"CO"},
	{"zipCode":"80537","state":"CO"},
	{"zipCode":"80537","state":"CO"},
	{"zipCode":"80538","state":"CO"},
	{"zipCode":"80539","state":"CO"},
	{"zipCode":"80540","state":"CO"},
	{"zipCode":"80541","state":"CO"},
	{"zipCode":"80542","state":"CO"},
	{"zipCode":"80543","state":"CO"},
	{"zipCode":"80543","state":"CO"},
	{"zipCode":"80544","state":"CO"},
	{"zipCode":"80545","state":"CO"},
	{"zipCode":"80546","state":"CO"},
	{"zipCode":"80547","state":"CO"},
	{"zipCode":"80547","state":"CO"},
	{"zipCode":"80549","state":"CO"},
	{"zipCode":"80550","state":"CO"},
	{"zipCode":"80550","state":"CO"},
	{"zipCode":"80601","state":"CO"},
	{"zipCode":"80602","state":"CO"},
	{"zipCode":"80603","state":"CO"},
	{"zipCode":"80610","state":"CO"},
	{"zipCode":"80611","state":"CO"},
	{"zipCode":"80612","state":"CO"},
	{"zipCode":"80614","state":"CO"},
	{"zipCode":"80615","state":"CO"},
	{"zipCode":"80620","state":"CO"},
	{"zipCode":"80621","state":"CO"},
	{"zipCode":"80622","state":"CO"},
	{"zipCode":"80623","state":"CO"},
	{"zipCode":"80624","state":"CO"},
	{"zipCode":"80631","state":"CO"},
	{"zipCode":"80632","state":"CO"},
	{"zipCode":"80633","state":"CO"},
	{"zipCode":"80634","state":"CO"},
	{"zipCode":"80640","state":"CO"},
	{"zipCode":"80642","state":"CO"},
	{"zipCode":"80643","state":"CO"},
	{"zipCode":"80644","state":"CO"},
	{"zipCode":"80645","state":"CO"},
	{"zipCode":"80645","state":"CO"},
	{"zipCode":"80646","state":"CO"},
	{"zipCode":"80648","state":"CO"},
	{"zipCode":"80648","state":"CO"},
	{"zipCode":"80649","state":"CO"},
	{"zipCode":"80650","state":"CO"},
	{"zipCode":"80651","state":"CO"},
	{"zipCode":"80652","state":"CO"},
	{"zipCode":"80653","state":"CO"},
	{"zipCode":"80654","state":"CO"},
	{"zipCode":"80701","state":"CO"},
	{"zipCode":"80705","state":"CO"},
	{"zipCode":"80720","state":"CO"},
	{"zipCode":"80721","state":"CO"},
	{"zipCode":"80721","state":"CO"},
	{"zipCode":"80722","state":"CO"},
	{"zipCode":"80723","state":"CO"},
	{"zipCode":"80726","state":"CO"},
	{"zipCode":"80727","state":"CO"},
	{"zipCode":"80728","state":"CO"},
	{"zipCode":"80729","state":"CO"},
	{"zipCode":"80731","state":"CO"},
	{"zipCode":"80732","state":"CO"},
	{"zipCode":"80733","state":"CO"},
	{"zipCode":"80734","state":"CO"},
	{"zipCode":"80735","state":"CO"},
	{"zipCode":"80736","state":"CO"},
	{"zipCode":"80737","state":"CO"},
	{"zipCode":"80740","state":"CO"},
	{"zipCode":"80741","state":"CO"},
	{"zipCode":"80742","state":"CO"},
	{"zipCode":"80743","state":"CO"},
	{"zipCode":"80744","state":"CO"},
	{"zipCode":"80744","state":"CO"},
	{"zipCode":"80745","state":"CO"},
	{"zipCode":"80746","state":"CO"},
	{"zipCode":"80747","state":"CO"},
	{"zipCode":"80749","state":"CO"},
	{"zipCode":"80750","state":"CO"},
	{"zipCode":"80751","state":"CO"},
	{"zipCode":"80754","state":"CO"},
	{"zipCode":"80755","state":"CO"},
	{"zipCode":"80757","state":"CO"},
	{"zipCode":"80758","state":"CO"},
	{"zipCode":"80759","state":"CO"},
	{"zipCode":"80801","state":"CO"},
	{"zipCode":"80802","state":"CO"},
	{"zipCode":"80804","state":"CO"},
	{"zipCode":"80805","state":"CO"},
	{"zipCode":"80807","state":"CO"},
	{"zipCode":"80808","state":"CO"},
	{"zipCode":"80809","state":"CO"},
	{"zipCode":"80810","state":"CO"},
	{"zipCode":"80812","state":"CO"},
	{"zipCode":"80813","state":"CO"},
	{"zipCode":"80814","state":"CO"},
	{"zipCode":"80815","state":"CO"},
	{"zipCode":"80816","state":"CO"},
	{"zipCode":"80817","state":"CO"},
	{"zipCode":"80818","state":"CO"},
	{"zipCode":"80819","state":"CO"},
	{"zipCode":"80820","state":"CO"},
	{"zipCode":"80821","state":"CO"},
	{"zipCode":"80822","state":"CO"},
	{"zipCode":"80823","state":"CO"},
	{"zipCode":"80824","state":"CO"},
	{"zipCode":"80825","state":"CO"},
	{"zipCode":"80827","state":"CO"},
	{"zipCode":"80828","state":"CO"},
	{"zipCode":"80829","state":"CO"},
	{"zipCode":"80830","state":"CO"},
	{"zipCode":"80831","state":"CO"},
	{"zipCode":"80832","state":"CO"},
	{"zipCode":"80832","state":"CO"},
	{"zipCode":"80833","state":"CO"},
	{"zipCode":"80833","state":"CO"},
	{"zipCode":"80834","state":"CO"},
	{"zipCode":"80835","state":"CO"},
	{"zipCode":"80836","state":"CO"},
	{"zipCode":"80840","state":"CO"},
	{"zipCode":"80841","state":"CO"},
	{"zipCode":"80860","state":"CO"},
	{"zipCode":"80861","state":"CO"},
	{"zipCode":"80861","state":"CO"},
	{"zipCode":"80862","state":"CO"},
	{"zipCode":"80863","state":"CO"},
	{"zipCode":"80864","state":"CO"},
	{"zipCode":"80866","state":"CO"},
	{"zipCode":"80901","state":"CO"},
	{"zipCode":"80902","state":"CO"},
	{"zipCode":"80903","state":"CO"},
	{"zipCode":"80904","state":"CO"},
	{"zipCode":"80905","state":"CO"},
	{"zipCode":"80906","state":"CO"},
	{"zipCode":"80907","state":"CO"},
	{"zipCode":"80908","state":"CO"},
	{"zipCode":"80908","state":"CO"},
	{"zipCode":"80909","state":"CO"},
	{"zipCode":"80910","state":"CO"},
	{"zipCode":"80911","state":"CO"},
	{"zipCode":"80914","state":"CO"},
	{"zipCode":"80915","state":"CO"},
	{"zipCode":"80916","state":"CO"},
	{"zipCode":"80917","state":"CO"},
	{"zipCode":"80918","state":"CO"},
	{"zipCode":"80919","state":"CO"},
	{"zipCode":"80920","state":"CO"},
	{"zipCode":"80921","state":"CO"},
	{"zipCode":"80922","state":"CO"},
	{"zipCode":"80923","state":"CO"},
	{"zipCode":"80924","state":"CO"},
	{"zipCode":"80925","state":"CO"},
	{"zipCode":"80926","state":"CO"},
	{"zipCode":"80927","state":"CO"},
	{"zipCode":"80928","state":"CO"},
	{"zipCode":"80929","state":"CO"},
	{"zipCode":"80930","state":"CO"},
	{"zipCode":"80931","state":"CO"},
	{"zipCode":"80932","state":"CO"},
	{"zipCode":"80933","state":"CO"},
	{"zipCode":"80934","state":"CO"},
	{"zipCode":"80935","state":"CO"},
	{"zipCode":"80936","state":"CO"},
	{"zipCode":"80937","state":"CO"},
	{"zipCode":"80938","state":"CO"},
	{"zipCode":"80939","state":"CO"},
	{"zipCode":"80949","state":"CO"},
	{"zipCode":"80951","state":"CO"},
	{"zipCode":"80960","state":"CO"},
	{"zipCode":"80962","state":"CO"},
	{"zipCode":"80970","state":"CO"},
	{"zipCode":"81001","state":"CO"},
	{"zipCode":"81001","state":"CO"},
	{"zipCode":"81002","state":"CO"},
	{"zipCode":"81003","state":"CO"},
	{"zipCode":"81004","state":"CO"},
	{"zipCode":"81005","state":"CO"},
	{"zipCode":"81006","state":"CO"},
	{"zipCode":"81007","state":"CO"},
	{"zipCode":"81007","state":"CO"},
	{"zipCode":"81008","state":"CO"},
	{"zipCode":"81019","state":"CO"},
	{"zipCode":"81020","state":"CO"},
	{"zipCode":"81021","state":"CO"},
	{"zipCode":"81022","state":"CO"},
	{"zipCode":"81023","state":"CO"},
	{"zipCode":"81024","state":"CO"},
	{"zipCode":"81025","state":"CO"},
	{"zipCode":"81027","state":"CO"},
	{"zipCode":"81029","state":"CO"},
	{"zipCode":"81030","state":"CO"},
	{"zipCode":"81033","state":"CO"},
	{"zipCode":"81036","state":"CO"},
	{"zipCode":"81039","state":"CO"},
	{"zipCode":"81040","state":"CO"},
	{"zipCode":"81041","state":"CO"},
	{"zipCode":"81043","state":"CO"},
	{"zipCode":"81044","state":"CO"},
	{"zipCode":"81045","state":"CO"},
	{"zipCode":"81046","state":"CO"},
	{"zipCode":"81047","state":"CO"},
	{"zipCode":"81049","state":"CO"},
	{"zipCode":"81050","state":"CO"},
	{"zipCode":"81052","state":"CO"},
	{"zipCode":"81054","state":"CO"},
	{"zipCode":"81055","state":"CO"},
	{"zipCode":"81057","state":"CO"},
	{"zipCode":"81058","state":"CO"},
	{"zipCode":"81059","state":"CO"},
	{"zipCode":"81062","state":"CO"},
	{"zipCode":"81062","state":"CO"},
	{"zipCode":"81063","state":"CO"},
	{"zipCode":"81064","state":"CO"},
	{"zipCode":"81067","state":"CO"},
	{"zipCode":"81069","state":"CO"},
	{"zipCode":"81071","state":"CO"},
	{"zipCode":"81073","state":"CO"},
	{"zipCode":"81076","state":"CO"},
	{"zipCode":"81077","state":"CO"},
	{"zipCode":"81081","state":"CO"},
	{"zipCode":"81082","state":"CO"},
	{"zipCode":"81084","state":"CO"},
	{"zipCode":"81087","state":"CO"},
	{"zipCode":"81089","state":"CO"},
	{"zipCode":"81090","state":"CO"},
	{"zipCode":"81091","state":"CO"},
	{"zipCode":"81091","state":"CO"},
	{"zipCode":"81092","state":"CO"},
	{"zipCode":"81101","state":"CO"},
	{"zipCode":"81120","state":"CO"},
	{"zipCode":"81121","state":"CO"},
	{"zipCode":"81122","state":"CO"},
	{"zipCode":"81123","state":"CO"},
	{"zipCode":"81124","state":"CO"},
	{"zipCode":"81125","state":"CO"},
	{"zipCode":"81126","state":"CO"},
	{"zipCode":"81129","state":"CO"},
	{"zipCode":"81130","state":"CO"},
	{"zipCode":"81131","state":"CO"},
	{"zipCode":"81132","state":"CO"},
	{"zipCode":"81133","state":"CO"},
	{"zipCode":"81135","state":"CO"},
	{"zipCode":"81136","state":"CO"},
	{"zipCode":"81136","state":"CO"},
	{"zipCode":"81137","state":"CO"},
	{"zipCode":"81138","state":"CO"},
	{"zipCode":"81140","state":"CO"},
	{"zipCode":"81141","state":"CO"},
	{"zipCode":"81143","state":"CO"},
	{"zipCode":"81144","state":"CO"},
	{"zipCode":"81146","state":"CO"},
	{"zipCode":"81147","state":"CO"},
	{"zipCode":"81148","state":"CO"},
	{"zipCode":"81149","state":"CO"},
	{"zipCode":"81151","state":"CO"},
	{"zipCode":"81152","state":"CO"},
	{"zipCode":"81154","state":"CO"},
	{"zipCode":"81155","state":"CO"},
	{"zipCode":"81157","state":"CO"},
	{"zipCode":"81201","state":"CO"},
	{"zipCode":"81210","state":"CO"},
	{"zipCode":"81211","state":"CO"},
	{"zipCode":"81212","state":"CO"},
	{"zipCode":"81215","state":"CO"},
	{"zipCode":"81220","state":"CO"},
	{"zipCode":"81221","state":"CO"},
	{"zipCode":"81222","state":"CO"},
	{"zipCode":"81223","state":"CO"},
	{"zipCode":"81224","state":"CO"},
	{"zipCode":"81225","state":"CO"},
	{"zipCode":"81226","state":"CO"},
	{"zipCode":"81228","state":"CO"},
	{"zipCode":"81230","state":"CO"},
	{"zipCode":"81233","state":"CO"},
	{"zipCode":"81235","state":"CO"},
	{"zipCode":"81236","state":"CO"},
	{"zipCode":"81237","state":"CO"},
	{"zipCode":"81239","state":"CO"},
	{"zipCode":"81240","state":"CO"},
	{"zipCode":"81241","state":"CO"},
	{"zipCode":"81242","state":"CO"},
	{"zipCode":"81243","state":"CO"},
	{"zipCode":"81244","state":"CO"},
	{"zipCode":"81248","state":"CO"},
	{"zipCode":"81251","state":"CO"},
	{"zipCode":"81252","state":"CO"},
	{"zipCode":"81253","state":"CO"},
	{"zipCode":"81301","state":"CO"},
	{"zipCode":"81302","state":"CO"},
	{"zipCode":"81303","state":"CO"},
	{"zipCode":"81320","state":"CO"},
	{"zipCode":"81320","state":"CO"},
	{"zipCode":"81321","state":"CO"},
	{"zipCode":"81323","state":"CO"},
	{"zipCode":"81324","state":"CO"},
	{"zipCode":"81325","state":"CO"},
	{"zipCode":"81325","state":"CO"},
	{"zipCode":"81326","state":"CO"},
	{"zipCode":"81327","state":"CO"},
	{"zipCode":"81327","state":"CO"},
	{"zipCode":"81328","state":"CO"},
	{"zipCode":"81329","state":"CO"},
	{"zipCode":"81330","state":"CO"},
	{"zipCode":"81331","state":"CO"},
	{"zipCode":"81331","state":"CO"},
	{"zipCode":"81332","state":"CO"},
	{"zipCode":"81334","state":"CO"},
	{"zipCode":"81335","state":"CO"},
	{"zipCode":"81335","state":"CO"},
	{"zipCode":"81401","state":"CO"},
	{"zipCode":"81402","state":"CO"},
	{"zipCode":"81403","state":"CO"},
	{"zipCode":"81410","state":"CO"},
	{"zipCode":"81410","state":"CO"},
	{"zipCode":"81411","state":"CO"},
	{"zipCode":"81413","state":"CO"},
	{"zipCode":"81415","state":"CO"},
	{"zipCode":"81416","state":"CO"},
	{"zipCode":"81418","state":"CO"},
	{"zipCode":"81419","state":"CO"},
	{"zipCode":"81420","state":"CO"},
	{"zipCode":"81422","state":"CO"},
	{"zipCode":"81423","state":"CO"},
	{"zipCode":"81424","state":"CO"},
	{"zipCode":"81425","state":"CO"},
	{"zipCode":"81425","state":"CO"},
	{"zipCode":"81426","state":"CO"},
	{"zipCode":"81427","state":"CO"},
	{"zipCode":"81428","state":"CO"},
	{"zipCode":"81429","state":"CO"},
	{"zipCode":"81430","state":"CO"},
	{"zipCode":"81431","state":"CO"},
	{"zipCode":"81432","state":"CO"},
	{"zipCode":"81433","state":"CO"},
	{"zipCode":"81434","state":"CO"},
	{"zipCode":"81434","state":"CO"},
	{"zipCode":"81435","state":"CO"},
	{"zipCode":"81501","state":"CO"},
	{"zipCode":"81502","state":"CO"},
	{"zipCode":"81503","state":"CO"},
	{"zipCode":"81504","state":"CO"},
	{"zipCode":"81504","state":"CO"},
	{"zipCode":"81505","state":"CO"},
	{"zipCode":"81506","state":"CO"},
	{"zipCode":"81507","state":"CO"},
	{"zipCode":"81520","state":"CO"},
	{"zipCode":"81520","state":"CO"},
	{"zipCode":"81521","state":"CO"},
	{"zipCode":"81521","state":"CO"},
	{"zipCode":"81522","state":"CO"},
	{"zipCode":"81523","state":"CO"},
	{"zipCode":"81524","state":"CO"},
	{"zipCode":"81525","state":"CO"},
	{"zipCode":"81526","state":"CO"},
	{"zipCode":"81526","state":"CO"},
	{"zipCode":"81527","state":"CO"},
	{"zipCode":"81527","state":"CO"},
	{"zipCode":"81601","state":"CO"},
	{"zipCode":"81602","state":"CO"},
	{"zipCode":"81610","state":"CO"},
	{"zipCode":"81611","state":"CO"},
	{"zipCode":"81612","state":"CO"},
	{"zipCode":"81615","state":"CO"},
	{"zipCode":"81620","state":"CO"},
	{"zipCode":"81621","state":"CO"},
	{"zipCode":"81623","state":"CO"},
	{"zipCode":"81624","state":"CO"},
	{"zipCode":"81625","state":"CO"},
	{"zipCode":"81626","state":"CO"},
	{"zipCode":"81630","state":"CO"},
	{"zipCode":"81631","state":"CO"},
	{"zipCode":"81632","state":"CO"},
	{"zipCode":"81635","state":"CO"},
	{"zipCode":"81636","state":"CO"},
	{"zipCode":"81637","state":"CO"},
	{"zipCode":"81638","state":"CO"},
	{"zipCode":"81639","state":"CO"},
	{"zipCode":"81640","state":"CO"},
	{"zipCode":"81641","state":"CO"},
	{"zipCode":"81642","state":"CO"},
	{"zipCode":"81643","state":"CO"},
	{"zipCode":"81645","state":"CO"},
	{"zipCode":"81646","state":"CO"},
	{"zipCode":"81647","state":"CO"},
	{"zipCode":"81648","state":"CO"},
	{"zipCode":"81649","state":"CO"},
	{"zipCode":"81650","state":"CO"},
	{"zipCode":"81650","state":"CO"},
	{"zipCode":"81652","state":"CO"},
	{"zipCode":"81652","state":"CO"},
	{"zipCode":"81653","state":"CO"},
	{"zipCode":"81654","state":"CO"},
	{"zipCode":"81654","state":"CO"},
	{"zipCode":"81655","state":"CO"},
	{"zipCode":"81656","state":"CO"},
	{"zipCode":"81657","state":"CO"},
	{"zipCode":"81658","state":"CO"},
	{"zipCode":"84001","state":"UT"},
	{"zipCode":"84002","state":"UT"},
	{"zipCode":"84003","state":"UT"},
	{"zipCode":"84004","state":"UT"},
	{"zipCode":"84005","state":"UT"},
	{"zipCode":"84006","state":"UT"},
	{"zipCode":"84006","state":"UT"},
	{"zipCode":"84007","state":"UT"},
	{"zipCode":"84009","state":"UT"},
	{"zipCode":"84010","state":"UT"},
	{"zipCode":"84011","state":"UT"},
	{"zipCode":"84013","state":"UT"},
	{"zipCode":"84014","state":"UT"},
	{"zipCode":"84015","state":"UT"},
	{"zipCode":"84016","state":"UT"},
	{"zipCode":"84017","state":"UT"},
	{"zipCode":"84018","state":"UT"},
	{"zipCode":"84020","state":"UT"},
	{"zipCode":"84021","state":"UT"},
	{"zipCode":"84022","state":"UT"},
	{"zipCode":"84023","state":"UT"},
	{"zipCode":"84024","state":"UT"},
	{"zipCode":"84025","state":"UT"},
	{"zipCode":"84026","state":"UT"},
	{"zipCode":"84027","state":"UT"},
	{"zipCode":"84028","state":"UT"},
	{"zipCode":"84029","state":"UT"},
	{"zipCode":"84031","state":"UT"},
	{"zipCode":"84032","state":"UT"},
	{"zipCode":"84033","state":"UT"},
	{"zipCode":"84034","state":"UT"},
	{"zipCode":"84035","state":"UT"},
	{"zipCode":"84036","state":"UT"},
	{"zipCode":"84037","state":"UT"},
	{"zipCode":"84038","state":"UT"},
	{"zipCode":"84039","state":"UT"},
	{"zipCode":"84040","state":"UT"},
	{"zipCode":"84041","state":"UT"},
	{"zipCode":"84042","state":"UT"},
	{"zipCode":"84043","state":"UT"},
	{"zipCode":"84044","state":"UT"},
	{"zipCode":"84045","state":"UT"},
	{"zipCode":"84046","state":"UT"},
	{"zipCode":"84047","state":"UT"},
	{"zipCode":"84049","state":"UT"},
	{"zipCode":"84050","state":"UT"},
	{"zipCode":"84051","state":"UT"},
	{"zipCode":"84052","state":"UT"},
	{"zipCode":"84053","state":"UT"},
	{"zipCode":"84054","state":"UT"},
	{"zipCode":"84054","state":"UT"},
	{"zipCode":"84055","state":"UT"},
	{"zipCode":"84056","state":"UT"},
	{"zipCode":"84056","state":"UT"},
	{"zipCode":"84057","state":"UT"},
	{"zipCode":"84058","state":"UT"},
	{"zipCode":"84059","state":"UT"},
	{"zipCode":"84060","state":"UT"},
	{"zipCode":"84060","state":"UT"},
	{"zipCode":"84061","state":"UT"},
	{"zipCode":"84062","state":"UT"},
	{"zipCode":"84063","state":"UT"},
	{"zipCode":"84064","state":"UT"},
	{"zipCode":"84065","state":"UT"},
	{"zipCode":"84066","state":"UT"},
	{"zipCode":"84067","state":"UT"},
	{"zipCode":"84068","state":"UT"},
	{"zipCode":"84069","state":"UT"},
	{"zipCode":"84070","state":"UT"},
	{"zipCode":"84070","state":"UT"},
	{"zipCode":"84071","state":"UT"},
	{"zipCode":"84072","state":"UT"},
	{"zipCode":"84073","state":"UT"},
	{"zipCode":"84074","state":"UT"},
	{"zipCode":"84075","state":"UT"},
	{"zipCode":"84076","state":"UT"},
	{"zipCode":"84076","state":"UT"},
	{"zipCode":"84078","state":"UT"},
	{"zipCode":"84079","state":"UT"},
	{"zipCode":"84080","state":"UT"},
	{"zipCode":"84081","state":"UT"},
	{"zipCode":"84082","state":"UT"},
	{"zipCode":"84083","state":"UT"},
	{"zipCode":"84084","state":"UT"},
	{"zipCode":"84085","state":"UT"},
	{"zipCode":"84086","state":"UT"},
	{"zipCode":"84087","state":"UT"},
	{"zipCode":"84088","state":"UT"},
	{"zipCode":"84089","state":"UT"},
	{"zipCode":"84090","state":"UT"},
	{"zipCode":"84091","state":"UT"},
	{"zipCode":"84092","state":"UT"},
	{"zipCode":"84092","state":"UT"},
	{"zipCode":"84092","state":"UT"},
	{"zipCode":"84093","state":"UT"},
	{"zipCode":"84094","state":"UT"},
	{"zipCode":"84095","state":"UT"},
	{"zipCode":"84096","state":"UT"},
	{"zipCode":"84097","state":"UT"},
	{"zipCode":"84098","state":"UT"},
	{"zipCode":"84098","state":"UT"},
	{"zipCode":"84101","state":"UT"},
	{"zipCode":"84102","state":"UT"},
	{"zipCode":"84103","state":"UT"},
	{"zipCode":"84104","state":"UT"},
	{"zipCode":"84105","state":"UT"},
	{"zipCode":"84106","state":"UT"},
	{"zipCode":"84107","state":"UT"},
	{"zipCode":"84108","state":"UT"},
	{"zipCode":"84109","state":"UT"},
	{"zipCode":"84110","state":"UT"},
	{"zipCode":"84111","state":"UT"},
	{"zipCode":"84112","state":"UT"},
	{"zipCode":"84113","state":"UT"},
	{"zipCode":"84114","state":"UT"},
	{"zipCode":"84115","state":"UT"},
	{"zipCode":"84116","state":"UT"},
	{"zipCode":"84117","state":"UT"},
	{"zipCode":"84118","state":"UT"},
	{"zipCode":"84119","state":"UT"},
	{"zipCode":"84120","state":"UT"},
	{"zipCode":"84121","state":"UT"},
	{"zipCode":"84122","state":"UT"},
	{"zipCode":"84123","state":"UT"},
	{"zipCode":"84124","state":"UT"},
	{"zipCode":"84125","state":"UT"},
	{"zipCode":"84126","state":"UT"},
	{"zipCode":"84127","state":"UT"},
	{"zipCode":"84128","state":"UT"},
	{"zipCode":"84129","state":"UT"},
	{"zipCode":"84130","state":"UT"},
	{"zipCode":"84131","state":"UT"},
	{"zipCode":"84133","state":"UT"},
	{"zipCode":"84138","state":"UT"},
	{"zipCode":"84145","state":"UT"},
	{"zipCode":"84147","state":"UT"},
	{"zipCode":"84151","state":"UT"},
	{"zipCode":"84152","state":"UT"},
	{"zipCode":"84157","state":"UT"},
	{"zipCode":"84158","state":"UT"},
	{"zipCode":"84165","state":"UT"},
	{"zipCode":"84170","state":"UT"},
	{"zipCode":"84171","state":"UT"},
	{"zipCode":"84180","state":"UT"},
	{"zipCode":"84301","state":"UT"},
	{"zipCode":"84302","state":"UT"},
	{"zipCode":"84304","state":"UT"},
	{"zipCode":"84305","state":"UT"},
	{"zipCode":"84306","state":"UT"},
	{"zipCode":"84307","state":"UT"},
	{"zipCode":"84308","state":"UT"},
	{"zipCode":"84309","state":"UT"},
	{"zipCode":"84310","state":"UT"},
	{"zipCode":"84311","state":"UT"},
	{"zipCode":"84312","state":"UT"},
	{"zipCode":"84313","state":"UT"},
	{"zipCode":"84313","state":"UT"},
	{"zipCode":"84314","state":"UT"},
	{"zipCode":"84315","state":"UT"},
	{"zipCode":"84316","state":"UT"},
	{"zipCode":"84317","state":"UT"},
	{"zipCode":"84318","state":"UT"},
	{"zipCode":"84319","state":"UT"},
	{"zipCode":"84320","state":"UT"},
	{"zipCode":"84321","state":"UT"},
	{"zipCode":"84323","state":"UT"},
	{"zipCode":"84324","state":"UT"},
	{"zipCode":"84325","state":"UT"},
	{"zipCode":"84326","state":"UT"},
	{"zipCode":"84327","state":"UT"},
	{"zipCode":"84328","state":"UT"},
	{"zipCode":"84329","state":"UT"},
	{"zipCode":"84330","state":"UT"},
	{"zipCode":"84331","state":"UT"},
	{"zipCode":"84332","state":"UT"},
	{"zipCode":"84333","state":"UT"},
	{"zipCode":"84334","state":"UT"},
	{"zipCode":"84335","state":"UT"},
	{"zipCode":"84336","state":"UT"},
	{"zipCode":"84337","state":"UT"},
	{"zipCode":"84338","state":"UT"},
	{"zipCode":"84339","state":"UT"},
	{"zipCode":"84340","state":"UT"},
	{"zipCode":"84341","state":"UT"},
	{"zipCode":"84401","state":"UT"},
	{"zipCode":"84402","state":"UT"},
	{"zipCode":"84403","state":"UT"},
	{"zipCode":"84404","state":"UT"},
	{"zipCode":"84405","state":"UT"},
	{"zipCode":"84409","state":"UT"},
	{"zipCode":"84412","state":"UT"},
	{"zipCode":"84414","state":"UT"},
	{"zipCode":"84415","state":"UT"},
	{"zipCode":"84501","state":"UT"},
	{"zipCode":"84510","state":"UT"},
	{"zipCode":"84511","state":"UT"},
	{"zipCode":"84512","state":"UT"},
	{"zipCode":"84513","state":"UT"},
	{"zipCode":"84515","state":"UT"},
	{"zipCode":"84516","state":"UT"},
	{"zipCode":"84518","state":"UT"},
	{"zipCode":"84520","state":"UT"},
	{"zipCode":"84521","state":"UT"},
	{"zipCode":"84522","state":"UT"},
	{"zipCode":"84523","state":"UT"},
	{"zipCode":"84525","state":"UT"},
	{"zipCode":"84526","state":"UT"},
	{"zipCode":"84526","state":"UT"},
	{"zipCode":"84528","state":"UT"},
	{"zipCode":"84529","state":"UT"},
	{"zipCode":"84530","state":"UT"},
	{"zipCode":"84531","state":"UT"},
	{"zipCode":"84532","state":"UT"},
	{"zipCode":"84533","state":"UT"},
	{"zipCode":"84533","state":"UT"},
	{"zipCode":"84534","state":"UT"},
	{"zipCode":"84535","state":"UT"},
	{"zipCode":"84536","state":"UT"},
	{"zipCode":"84537","state":"UT"},
	{"zipCode":"84539","state":"UT"},
	{"zipCode":"84540","state":"UT"},
	{"zipCode":"84542","state":"UT"},
	{"zipCode":"84542","state":"UT"},
	{"zipCode":"84601","state":"UT"},
	{"zipCode":"84603","state":"UT"},
	{"zipCode":"84604","state":"UT"},
	{"zipCode":"84605","state":"UT"},
	{"zipCode":"84606","state":"UT"},
	{"zipCode":"84620","state":"UT"},
	{"zipCode":"84621","state":"UT"},
	{"zipCode":"84622","state":"UT"},
	{"zipCode":"84623","state":"UT"},
	{"zipCode":"84624","state":"UT"},
	{"zipCode":"84626","state":"UT"},
	{"zipCode":"84627","state":"UT"},
	{"zipCode":"84628","state":"UT"},
	{"zipCode":"84629","state":"UT"},
	{"zipCode":"84629","state":"UT"},
	{"zipCode":"84630","state":"UT"},
	{"zipCode":"84631","state":"UT"},
	{"zipCode":"84632","state":"UT"},
	{"zipCode":"84633","state":"UT"},
	{"zipCode":"84634","state":"UT"},
	{"zipCode":"84635","state":"UT"},
	{"zipCode":"84636","state":"UT"},
	{"zipCode":"84637","state":"UT"},
	{"zipCode":"84638","state":"UT"},
	{"zipCode":"84639","state":"UT"},
	{"zipCode":"84640","state":"UT"},
	{"zipCode":"84642","state":"UT"},
	{"zipCode":"84643","state":"UT"},
	{"zipCode":"84644","state":"UT"},
	{"zipCode":"84645","state":"UT"},
	{"zipCode":"84646","state":"UT"},
	{"zipCode":"84647","state":"UT"},
	{"zipCode":"84648","state":"UT"},
	{"zipCode":"84649","state":"UT"},
	{"zipCode":"84651","state":"UT"},
	{"zipCode":"84652","state":"UT"},
	{"zipCode":"84653","state":"UT"},
	{"zipCode":"84654","state":"UT"},
	{"zipCode":"84655","state":"UT"},
	{"zipCode":"84656","state":"UT"},
	{"zipCode":"84657","state":"UT"},
	{"zipCode":"84660","state":"UT"},
	{"zipCode":"84662","state":"UT"},
	{"zipCode":"84663","state":"UT"},
	{"zipCode":"84664","state":"UT"},
	{"zipCode":"84665","state":"UT"},
	{"zipCode":"84667","state":"UT"},
	{"zipCode":"84701","state":"UT"},
	{"zipCode":"84710","state":"UT"},
	{"zipCode":"84711","state":"UT"},
	{"zipCode":"84712","state":"UT"},
	{"zipCode":"84712","state":"UT"},
	{"zipCode":"84713","state":"UT"},
	{"zipCode":"84714","state":"UT"},
	{"zipCode":"84715","state":"UT"},
	{"zipCode":"84716","state":"UT"},
	{"zipCode":"84718","state":"UT"},
	{"zipCode":"84719","state":"UT"},
	{"zipCode":"84720","state":"UT"},
	{"zipCode":"84721","state":"UT"},
	{"zipCode":"84722","state":"UT"},
	{"zipCode":"84723","state":"UT"},
	{"zipCode":"84724","state":"UT"},
	{"zipCode":"84725","state":"UT"},
	{"zipCode":"84726","state":"UT"},
	{"zipCode":"84728","state":"UT"},
	{"zipCode":"84729","state":"UT"},
	{"zipCode":"84730","state":"UT"},
	{"zipCode":"84731","state":"UT"},
	{"zipCode":"84732","state":"UT"},
	{"zipCode":"84733","state":"UT"},
	{"zipCode":"84734","state":"UT"},
	{"zipCode":"84735","state":"UT"},
	{"zipCode":"84736","state":"UT"},
	{"zipCode":"84737","state":"UT"},
	{"zipCode":"84738","state":"UT"},
	{"zipCode":"84738","state":"UT"},
	{"zipCode":"84739","state":"UT"},
	{"zipCode":"84740","state":"UT"},
	{"zipCode":"84741","state":"UT"},
	{"zipCode":"84741","state":"UT"},
	{"zipCode":"84742","state":"UT"},
	{"zipCode":"84743","state":"UT"},
	{"zipCode":"84744","state":"UT"},
	{"zipCode":"84744","state":"UT"},
	{"zipCode":"84745","state":"UT"},
	{"zipCode":"84745","state":"UT"},
	{"zipCode":"84746","state":"UT"},
	{"zipCode":"84747","state":"UT"},
	{"zipCode":"84749","state":"UT"},
	{"zipCode":"84750","state":"UT"},
	{"zipCode":"84751","state":"UT"},
	{"zipCode":"84752","state":"UT"},
	{"zipCode":"84753","state":"UT"},
	{"zipCode":"84754","state":"UT"},
	{"zipCode":"84755","state":"UT"},
	{"zipCode":"84756","state":"UT"},
	{"zipCode":"84757","state":"UT"},
	{"zipCode":"84757","state":"UT"},
	{"zipCode":"84758","state":"UT"},
	{"zipCode":"84759","state":"UT"},
	{"zipCode":"84760","state":"UT"},
	{"zipCode":"84761","state":"UT"},
	{"zipCode":"84762","state":"UT"},
	{"zipCode":"84763","state":"UT"},
	{"zipCode":"84764","state":"UT"},
	{"zipCode":"84765","state":"UT"},
	{"zipCode":"84766","state":"UT"},
	{"zipCode":"84767","state":"UT"},
	{"zipCode":"84770","state":"UT"},
	{"zipCode":"84771","state":"UT"},
	{"zipCode":"84772","state":"UT"},
	{"zipCode":"84773","state":"UT"},
	{"zipCode":"84774","state":"UT"},
	{"zipCode":"84775","state":"UT"},
	{"zipCode":"84776","state":"UT"},
	{"zipCode":"84779","state":"UT"},
	{"zipCode":"84780","state":"UT"},
	{"zipCode":"84781","state":"UT"},
	{"zipCode":"84782","state":"UT"},
	{"zipCode":"84782","state":"UT"},
	{"zipCode":"84783","state":"UT"},
	{"zipCode":"84784","state":"AZ"},
	{"zipCode":"84790","state":"UT"},
	{"zipCode":"84791","state":"UT"},
	{"zipCode":"85001","state":"AZ"},
	{"zipCode":"85002","state":"AZ"},
	{"zipCode":"85003","state":"AZ"},
	{"zipCode":"85004","state":"AZ"},
	{"zipCode":"85005","state":"AZ"},
	{"zipCode":"85006","state":"AZ"},
	{"zipCode":"85007","state":"AZ"},
	{"zipCode":"85008","state":"AZ"},
	{"zipCode":"85009","state":"AZ"},
	{"zipCode":"85010","state":"AZ"},
	{"zipCode":"85011","state":"AZ"},
	{"zipCode":"85012","state":"AZ"},
	{"zipCode":"85013","state":"AZ"},
	{"zipCode":"85014","state":"AZ"},
	{"zipCode":"85015","state":"AZ"},
	{"zipCode":"85016","state":"AZ"},
	{"zipCode":"85017","state":"AZ"},
	{"zipCode":"85018","state":"AZ"},
	{"zipCode":"85019","state":"AZ"},
	{"zipCode":"85020","state":"AZ"},
	{"zipCode":"85021","state":"AZ"},
	{"zipCode":"85022","state":"AZ"},
	{"zipCode":"85023","state":"AZ"},
	{"zipCode":"85024","state":"AZ"},
	{"zipCode":"85027","state":"AZ"},
	{"zipCode":"85028","state":"AZ"},
	{"zipCode":"85029","state":"AZ"},
	{"zipCode":"85030","state":"AZ"},
	{"zipCode":"85031","state":"AZ"},
	{"zipCode":"85032","state":"AZ"},
	{"zipCode":"85033","state":"AZ"},
	{"zipCode":"85034","state":"AZ"},
	{"zipCode":"85035","state":"AZ"},
	{"zipCode":"85036","state":"AZ"},
	{"zipCode":"85037","state":"AZ"},
	{"zipCode":"85038","state":"AZ"},
	{"zipCode":"85040","state":"AZ"},
	{"zipCode":"85041","state":"AZ"},
	{"zipCode":"85042","state":"AZ"},
	{"zipCode":"85043","state":"AZ"},
	{"zipCode":"85044","state":"AZ"},
	{"zipCode":"85045","state":"AZ"},
	{"zipCode":"85046","state":"AZ"},
	{"zipCode":"85048","state":"AZ"},
	{"zipCode":"85050","state":"AZ"},
	{"zipCode":"85051","state":"AZ"},
	{"zipCode":"85053","state":"AZ"},
	{"zipCode":"85054","state":"AZ"},
	{"zipCode":"85060","state":"AZ"},
	{"zipCode":"85061","state":"AZ"},
	{"zipCode":"85062","state":"AZ"},
	{"zipCode":"85063","state":"AZ"},
	{"zipCode":"85064","state":"AZ"},
	{"zipCode":"85066","state":"AZ"},
	{"zipCode":"85067","state":"AZ"},
	{"zipCode":"85068","state":"AZ"},
	{"zipCode":"85069","state":"AZ"},
	{"zipCode":"85070","state":"AZ"},
	{"zipCode":"85071","state":"AZ"},
	{"zipCode":"85072","state":"AZ"},
	{"zipCode":"85074","state":"AZ"},
	{"zipCode":"85076","state":"AZ"},
	{"zipCode":"85078","state":"AZ"},
	{"zipCode":"85079","state":"AZ"},
	{"zipCode":"85080","state":"AZ"},
	{"zipCode":"85082","state":"AZ"},
	{"zipCode":"85083","state":"AZ"},
	{"zipCode":"85085","state":"AZ"},
	{"zipCode":"85086","state":"AZ"},
	{"zipCode":"85087","state":"AZ"},
	{"zipCode":"85117","state":"AZ"},
	{"zipCode":"85118","state":"AZ"},
	{"zipCode":"85119","state":"AZ"},
	{"zipCode":"85120","state":"AZ"},
	{"zipCode":"85121","state":"AZ"},
	{"zipCode":"85122","state":"AZ"},
	{"zipCode":"85123","state":"AZ"},
	{"zipCode":"85127","state":"AZ"},
	{"zipCode":"85128","state":"AZ"},
	{"zipCode":"85130","state":"AZ"},
	{"zipCode":"85131","state":"AZ"},
	{"zipCode":"85132","state":"AZ"},
	{"zipCode":"85135","state":"AZ"},
	{"zipCode":"85137","state":"AZ"},
	{"zipCode":"85138","state":"AZ"},
	{"zipCode":"85139","state":"AZ"},
	{"zipCode":"85139","state":"AZ"},
	{"zipCode":"85140","state":"AZ"},
	{"zipCode":"85141","state":"AZ"},
	{"zipCode":"85142","state":"AZ"},
	{"zipCode":"85143","state":"AZ"},
	{"zipCode":"85145","state":"AZ"},
	{"zipCode":"85147","state":"AZ"},
	{"zipCode":"85172","state":"AZ"},
	{"zipCode":"85173","state":"AZ"},
	{"zipCode":"85178","state":"AZ"},
	{"zipCode":"85191","state":"AZ"},
	{"zipCode":"85192","state":"AZ"},
	{"zipCode":"85193","state":"AZ"},
	{"zipCode":"85194","state":"AZ"},
	{"zipCode":"85201","state":"AZ"},
	{"zipCode":"85202","state":"AZ"},
	{"zipCode":"85203","state":"AZ"},
	{"zipCode":"85204","state":"AZ"},
	{"zipCode":"85205","state":"AZ"},
	{"zipCode":"85206","state":"AZ"},
	{"zipCode":"85207","state":"AZ"},
	{"zipCode":"85208","state":"AZ"},
	{"zipCode":"85209","state":"AZ"},
	{"zipCode":"85210","state":"AZ"},
	{"zipCode":"85211","state":"AZ"},
	{"zipCode":"85212","state":"AZ"},
	{"zipCode":"85213","state":"AZ"},
	{"zipCode":"85214","state":"AZ"},
	{"zipCode":"85215","state":"AZ"},
	{"zipCode":"85216","state":"AZ"},
	{"zipCode":"85224","state":"AZ"},
	{"zipCode":"85225","state":"AZ"},
	{"zipCode":"85226","state":"AZ"},
	{"zipCode":"85233","state":"AZ"},
	{"zipCode":"85234","state":"AZ"},
	{"zipCode":"85236","state":"AZ"},
	{"zipCode":"85244","state":"AZ"},
	{"zipCode":"85246","state":"AZ"},
	{"zipCode":"85248","state":"AZ"},
	{"zipCode":"85249","state":"AZ"},
	{"zipCode":"85250","state":"AZ"},
	{"zipCode":"85251","state":"AZ"},
	{"zipCode":"85252","state":"AZ"},
	{"zipCode":"85253","state":"AZ"},
	{"zipCode":"85254","state":"AZ"},
	{"zipCode":"85255","state":"AZ"},
	{"zipCode":"85256","state":"AZ"},
	{"zipCode":"85257","state":"AZ"},
	{"zipCode":"85258","state":"AZ"},
	{"zipCode":"85259","state":"AZ"},
	{"zipCode":"85260","state":"AZ"},
	{"zipCode":"85261","state":"AZ"},
	{"zipCode":"85262","state":"AZ"},
	{"zipCode":"85263","state":"AZ"},
	{"zipCode":"85264","state":"AZ"},
	{"zipCode":"85266","state":"AZ"},
	{"zipCode":"85267","state":"AZ"},
	{"zipCode":"85268","state":"AZ"},
	{"zipCode":"85269","state":"AZ"},
	{"zipCode":"85271","state":"AZ"},
	{"zipCode":"85274","state":"AZ"},
	{"zipCode":"85275","state":"AZ"},
	{"zipCode":"85277","state":"AZ"},
	{"zipCode":"85280","state":"AZ"},
	{"zipCode":"85281","state":"AZ"},
	{"zipCode":"85282","state":"AZ"},
	{"zipCode":"85283","state":"AZ"},
	{"zipCode":"85284","state":"AZ"},
	{"zipCode":"85285","state":"AZ"},
	{"zipCode":"85286","state":"AZ"},
	{"zipCode":"85288","state":"AZ"},
	{"zipCode":"85295","state":"AZ"},
	{"zipCode":"85296","state":"AZ"},
	{"zipCode":"85297","state":"AZ"},
	{"zipCode":"85298","state":"AZ"},
	{"zipCode":"85299","state":"AZ"},
	{"zipCode":"85301","state":"AZ"},
	{"zipCode":"85302","state":"AZ"},
	{"zipCode":"85303","state":"AZ"},
	{"zipCode":"85304","state":"AZ"},
	{"zipCode":"85305","state":"AZ"},
	{"zipCode":"85306","state":"AZ"},
	{"zipCode":"85307","state":"AZ"},
	{"zipCode":"85308","state":"AZ"},
	{"zipCode":"85309","state":"AZ"},
	{"zipCode":"85310","state":"AZ"},
	{"zipCode":"85311","state":"AZ"},
	{"zipCode":"85312","state":"AZ"},
	{"zipCode":"85318","state":"AZ"},
	{"zipCode":"85320","state":"AZ"},
	{"zipCode":"85321","state":"AZ"},
	{"zipCode":"85322","state":"AZ"},
	{"zipCode":"85322","state":"AZ"},
	{"zipCode":"85323","state":"AZ"},
	{"zipCode":"85323","state":"AZ"},
	{"zipCode":"85324","state":"AZ"},
	{"zipCode":"85325","state":"AZ"},
	{"zipCode":"85326","state":"AZ"},
	{"zipCode":"85327","state":"AZ"},
	{"zipCode":"85328","state":"AZ"},
	{"zipCode":"85329","state":"AZ"},
	{"zipCode":"85331","state":"AZ"},
	{"zipCode":"85332","state":"AZ"},
	{"zipCode":"85333","state":"AZ"},
	{"zipCode":"85333","state":"AZ"},
	{"zipCode":"85334","state":"AZ"},
	{"zipCode":"85335","state":"AZ"},
	{"zipCode":"85335","state":"AZ"},
	{"zipCode":"85336","state":"AZ"},
	{"zipCode":"85337","state":"AZ"},
	{"zipCode":"85338","state":"AZ"},
	{"zipCode":"85339","state":"AZ"},
	{"zipCode":"85339","state":"AZ"},
	{"zipCode":"85340","state":"AZ"},
	{"zipCode":"85341","state":"AZ"},
	{"zipCode":"85342","state":"AZ"},
	{"zipCode":"85343","state":"AZ"},
	{"zipCode":"85344","state":"AZ"},
	{"zipCode":"85345","state":"AZ"},
	{"zipCode":"85346","state":"AZ"},
	{"zipCode":"85347","state":"AZ"},
	{"zipCode":"85348","state":"AZ"},
	{"zipCode":"85349","state":"AZ"},
	{"zipCode":"85350","state":"AZ"},
	{"zipCode":"85351","state":"AZ"},
	{"zipCode":"85352","state":"AZ"},
	{"zipCode":"85353","state":"AZ"},
	{"zipCode":"85354","state":"AZ"},
	{"zipCode":"85355","state":"AZ"},
	{"zipCode":"85356","state":"AZ"},
	{"zipCode":"85357","state":"AZ"},
	{"zipCode":"85358","state":"AZ"},
	{"zipCode":"85359","state":"AZ"},
	{"zipCode":"85360","state":"AZ"},
	{"zipCode":"85361","state":"AZ"},
	{"zipCode":"85362","state":"AZ"},
	{"zipCode":"85363","state":"AZ"},
	{"zipCode":"85363","state":"AZ"},
	{"zipCode":"85364","state":"AZ"},
	{"zipCode":"85365","state":"AZ"},
	{"zipCode":"85365","state":"AZ"},
	{"zipCode":"85365","state":"AZ"},
	{"zipCode":"85366","state":"AZ"},
	{"zipCode":"85367","state":"AZ"},
	{"zipCode":"85371","state":"AZ"},
	{"zipCode":"85372","state":"AZ"},
	{"zipCode":"85373","state":"AZ"},
	{"zipCode":"85374","state":"AZ"},
	{"zipCode":"85375","state":"AZ"},
	{"zipCode":"85376","state":"AZ"},
	{"zipCode":"85377","state":"AZ"},
	{"zipCode":"85378","state":"AZ"},
	{"zipCode":"85378","state":"AZ"},
	{"zipCode":"85379","state":"AZ"},
	{"zipCode":"85380","state":"AZ"},
	{"zipCode":"85381","state":"AZ"},
	{"zipCode":"85382","state":"AZ"},
	{"zipCode":"85383","state":"AZ"},
	{"zipCode":"85385","state":"AZ"},
	{"zipCode":"85387","state":"AZ"},
	{"zipCode":"85388","state":"AZ"},
	{"zipCode":"85390","state":"AZ"},
	{"zipCode":"85392","state":"AZ"},
	{"zipCode":"85395","state":"AZ"},
	{"zipCode":"85396","state":"AZ"},
	{"zipCode":"85501","state":"AZ"},
	{"zipCode":"85501","state":"AZ"},
	{"zipCode":"85502","state":"AZ"},
	{"zipCode":"85530","state":"AZ"},
	{"zipCode":"85531","state":"AZ"},
	{"zipCode":"85532","state":"AZ"},
	{"zipCode":"85533","state":"AZ"},
	{"zipCode":"85534","state":"AZ"},
	{"zipCode":"85536","state":"AZ"},
	{"zipCode":"85539","state":"AZ"},
	{"zipCode":"85540","state":"AZ"},
	{"zipCode":"85541","state":"AZ"},
	{"zipCode":"85542","state":"AZ"},
	{"zipCode":"85543","state":"AZ"},
	{"zipCode":"85544","state":"AZ"},
	{"zipCode":"85545","state":"AZ"},
	{"zipCode":"85546","state":"AZ"},
	{"zipCode":"85547","state":"AZ"},
	{"zipCode":"85548","state":"AZ"},
	{"zipCode":"85550","state":"AZ"},
	{"zipCode":"85551","state":"AZ"},
	{"zipCode":"85552","state":"AZ"},
	{"zipCode":"85553","state":"AZ"},
	{"zipCode":"85554","state":"AZ"},
	{"zipCode":"85601","state":"AZ"},
	{"zipCode":"85602","state":"AZ"},
	{"zipCode":"85603","state":"AZ"},
	{"zipCode":"85603","state":"AZ"},
	{"zipCode":"85605","state":"AZ"},
	{"zipCode":"85606","state":"AZ"},
	{"zipCode":"85607","state":"AZ"},
	{"zipCode":"85608","state":"AZ"},
	{"zipCode":"85609","state":"AZ"},
	{"zipCode":"85610","state":"AZ"},
	{"zipCode":"85611","state":"AZ"},
	{"zipCode":"85613","state":"AZ"},
	{"zipCode":"85614","state":"AZ"},
	{"zipCode":"85615","state":"AZ"},
	{"zipCode":"85616","state":"AZ"},
	{"zipCode":"85617","state":"AZ"},
	{"zipCode":"85618","state":"AZ"},
	{"zipCode":"85619","state":"AZ"},
	{"zipCode":"85620","state":"AZ"},
	{"zipCode":"85621","state":"AZ"},
	{"zipCode":"85622","state":"AZ"},
	{"zipCode":"85623","state":"AZ"},
	{"zipCode":"85624","state":"AZ"},
	{"zipCode":"85625","state":"AZ"},
	{"zipCode":"85626","state":"AZ"},
	{"zipCode":"85627","state":"AZ"},
	{"zipCode":"85628","state":"AZ"},
	{"zipCode":"85629","state":"AZ"},
	{"zipCode":"85630","state":"AZ"},
	{"zipCode":"85631","state":"AZ"},
	{"zipCode":"85632","state":"AZ"},
	{"zipCode":"85632","state":"AZ"},
	{"zipCode":"85633","state":"AZ"},
	{"zipCode":"85634","state":"AZ"},
	{"zipCode":"85635","state":"AZ"},
	{"zipCode":"85636","state":"AZ"},
	{"zipCode":"85637","state":"AZ"},
	{"zipCode":"85638","state":"AZ"},
	{"zipCode":"85639","state":"AZ"},
	{"zipCode":"85640","state":"AZ"},
	{"zipCode":"85641","state":"AZ"},
	{"zipCode":"85641","state":"AZ"},
	{"zipCode":"85643","state":"AZ"},
	{"zipCode":"85644","state":"AZ"},
	{"zipCode":"85645","state":"AZ"},
	{"zipCode":"85646","state":"AZ"},
	{"zipCode":"85648","state":"AZ"},
	{"zipCode":"85650","state":"AZ"},
	{"zipCode":"85652","state":"AZ"},
	{"zipCode":"85653","state":"AZ"},
	{"zipCode":"85654","state":"AZ"},
	{"zipCode":"85655","state":"AZ"},
	{"zipCode":"85658","state":"AZ"},
	{"zipCode":"85670","state":"AZ"},
	{"zipCode":"85701","state":"AZ"},
	{"zipCode":"85702","state":"AZ"},
	{"zipCode":"85703","state":"AZ"},
	{"zipCode":"85704","state":"AZ"},
	{"zipCode":"85705","state":"AZ"},
	{"zipCode":"85706","state":"AZ"},
	{"zipCode":"85708","state":"AZ"},
	{"zipCode":"85708","state":"AZ"},
	{"zipCode":"85710","state":"AZ"},
	{"zipCode":"85711","state":"AZ"},
	{"zipCode":"85712","state":"AZ"},
	{"zipCode":"85713","state":"AZ"},
	{"zipCode":"85714","state":"AZ"},
	{"zipCode":"85715","state":"AZ"},
	{"zipCode":"85716","state":"AZ"},
	{"zipCode":"85717","state":"AZ"},
	{"zipCode":"85718","state":"AZ"},
	{"zipCode":"85719","state":"AZ"},
	{"zipCode":"85725","state":"AZ"},
	{"zipCode":"85726","state":"AZ"},
	{"zipCode":"85728","state":"AZ"},
	{"zipCode":"85730","state":"AZ"},
	{"zipCode":"85731","state":"AZ"},
	{"zipCode":"85732","state":"AZ"},
	{"zipCode":"85733","state":"AZ"},
	{"zipCode":"85734","state":"AZ"},
	{"zipCode":"85735","state":"AZ"},
	{"zipCode":"85736","state":"AZ"},
	{"zipCode":"85737","state":"AZ"},
	{"zipCode":"85738","state":"AZ"},
	{"zipCode":"85739","state":"AZ"},
	{"zipCode":"85740","state":"AZ"},
	{"zipCode":"85741","state":"AZ"},
	{"zipCode":"85742","state":"AZ"},
	{"zipCode":"85743","state":"AZ"},
	{"zipCode":"85745","state":"AZ"},
	{"zipCode":"85746","state":"AZ"},
	{"zipCode":"85747","state":"AZ"},
	{"zipCode":"85748","state":"AZ"},
	{"zipCode":"85749","state":"AZ"},
	{"zipCode":"85750","state":"AZ"},
	{"zipCode":"85751","state":"AZ"},
	{"zipCode":"85752","state":"AZ"},
	{"zipCode":"85754","state":"AZ"},
	{"zipCode":"85755","state":"AZ"},
	{"zipCode":"85756","state":"AZ"},
	{"zipCode":"85757","state":"AZ"},
	{"zipCode":"85901","state":"AZ"},
	{"zipCode":"85902","state":"AZ"},
	{"zipCode":"85911","state":"AZ"},
	{"zipCode":"85912","state":"AZ"},
	{"zipCode":"85920","state":"AZ"},
	{"zipCode":"85922","state":"AZ"},
	{"zipCode":"85923","state":"AZ"},
	{"zipCode":"85924","state":"AZ"},
	{"zipCode":"85925","state":"AZ"},
	{"zipCode":"85926","state":"AZ"},
	{"zipCode":"85927","state":"AZ"},
	{"zipCode":"85928","state":"AZ"},
	{"zipCode":"85929","state":"AZ"},
	{"zipCode":"85930","state":"AZ"},
	{"zipCode":"85931","state":"AZ"},
	{"zipCode":"85932","state":"AZ"},
	{"zipCode":"85933","state":"AZ"},
	{"zipCode":"85934","state":"AZ"},
	{"zipCode":"85935","state":"AZ"},
	{"zipCode":"85936","state":"AZ"},
	{"zipCode":"85937","state":"AZ"},
	{"zipCode":"85938","state":"AZ"},
	{"zipCode":"85939","state":"AZ"},
	{"zipCode":"85940","state":"AZ"},
	{"zipCode":"85941","state":"AZ"},
	{"zipCode":"85942","state":"AZ"},
	{"zipCode":"86001","state":"AZ"},
	{"zipCode":"86002","state":"AZ"},
	{"zipCode":"86003","state":"AZ"},
	{"zipCode":"86004","state":"AZ"},
	{"zipCode":"86005","state":"AZ"},
	{"zipCode":"86015","state":"AZ"},
	{"zipCode":"86016","state":"AZ"},
	{"zipCode":"86017","state":"AZ"},
	{"zipCode":"86018","state":"AZ"},
	{"zipCode":"86020","state":"AZ"},
	{"zipCode":"86020","state":"AZ"},
	{"zipCode":"86021","state":"AZ"},
	{"zipCode":"86022","state":"AZ"},
	{"zipCode":"86023","state":"AZ"},
	{"zipCode":"86023","state":"AZ"},
	{"zipCode":"86024","state":"AZ"},
	{"zipCode":"86024","state":"AZ"},
	{"zipCode":"86025","state":"AZ"},
	{"zipCode":"86028","state":"AZ"},
	{"zipCode":"86029","state":"AZ"},
	{"zipCode":"86030","state":"AZ"},
	{"zipCode":"86031","state":"AZ"},
	{"zipCode":"86032","state":"AZ"},
	{"zipCode":"86033","state":"AZ"},
	{"zipCode":"86034","state":"AZ"},
	{"zipCode":"86035","state":"AZ"},
	{"zipCode":"86036","state":"AZ"},
	{"zipCode":"86036","state":"AZ"},
	{"zipCode":"86038","state":"AZ"},
	{"zipCode":"86038","state":"AZ"},
	{"zipCode":"86039","state":"AZ"},
	{"zipCode":"86040","state":"AZ"},
	{"zipCode":"86040","state":"AZ"},
	{"zipCode":"86042","state":"AZ"},
	{"zipCode":"86043","state":"AZ"},
	{"zipCode":"86044","state":"AZ"},
	{"zipCode":"86044","state":"AZ"},
	{"zipCode":"86045","state":"AZ"},
	{"zipCode":"86046","state":"AZ"},
	{"zipCode":"86047","state":"AZ"},
	{"zipCode":"86047","state":"AZ"},
	{"zipCode":"86053","state":"AZ"},
	{"zipCode":"86054","state":"AZ"},
	{"zipCode":"86301","state":"AZ"},
	{"zipCode":"86302","state":"AZ"},
	{"zipCode":"86303","state":"AZ"},
	{"zipCode":"86304","state":"AZ"},
	{"zipCode":"86305","state":"AZ"},
	{"zipCode":"86312","state":"AZ"},
	{"zipCode":"86313","state":"AZ"},
	{"zipCode":"86314","state":"AZ"},
	{"zipCode":"86315","state":"AZ"},
	{"zipCode":"86320","state":"AZ"},
	{"zipCode":"86321","state":"AZ"},
	{"zipCode":"86322","state":"AZ"},
	{"zipCode":"86323","state":"AZ"},
	{"zipCode":"86324","state":"AZ"},
	{"zipCode":"86325","state":"AZ"},
	{"zipCode":"86326","state":"AZ"},
	{"zipCode":"86327","state":"AZ"},
	{"zipCode":"86329","state":"AZ"},
	{"zipCode":"86331","state":"AZ"},
	{"zipCode":"86332","state":"AZ"},
	{"zipCode":"86333","state":"AZ"},
	{"zipCode":"86334","state":"AZ"},
	{"zipCode":"86335","state":"AZ"},
	{"zipCode":"86336","state":"AZ"},
	{"zipCode":"86337","state":"AZ"},
	{"zipCode":"86338","state":"AZ"},
	{"zipCode":"86339","state":"AZ"},
	{"zipCode":"86340","state":"AZ"},
	{"zipCode":"86341","state":"AZ"},
	{"zipCode":"86343","state":"AZ"},
	{"zipCode":"86351","state":"AZ"},
	{"zipCode":"86401","state":"AZ"},
	{"zipCode":"86402","state":"AZ"},
	{"zipCode":"86402","state":"AZ"},
	{"zipCode":"86403","state":"AZ"},
	{"zipCode":"86404","state":"AZ"},
	{"zipCode":"86405","state":"AZ"},
	{"zipCode":"86406","state":"AZ"},
	{"zipCode":"86409","state":"AZ"},
	{"zipCode":"86411","state":"AZ"},
	{"zipCode":"86412","state":"AZ"},
	{"zipCode":"86413","state":"AZ"},
	{"zipCode":"86426","state":"AZ"},
	{"zipCode":"86427","state":"AZ"},
	{"zipCode":"86429","state":"AZ"},
	{"zipCode":"86430","state":"AZ"},
	{"zipCode":"86431","state":"AZ"},
	{"zipCode":"86432","state":"AZ"},
	{"zipCode":"86433","state":"AZ"},
	{"zipCode":"86434","state":"AZ"},
	{"zipCode":"86435","state":"AZ"},
	{"zipCode":"86436","state":"AZ"},
	{"zipCode":"86437","state":"AZ"},
	{"zipCode":"86438","state":"AZ"},
	{"zipCode":"86439","state":"AZ"},
	{"zipCode":"86440","state":"AZ"},
	{"zipCode":"86441","state":"AZ"},
	{"zipCode":"86441","state":"AZ"},
	{"zipCode":"86442","state":"AZ"},
	{"zipCode":"86443","state":"AZ"},
	{"zipCode":"86444","state":"AZ"},
	{"zipCode":"86444","state":"AZ"},
	{"zipCode":"86445","state":"AZ"},
	{"zipCode":"86446","state":"AZ"},
	{"zipCode":"86502","state":"AZ"},
	{"zipCode":"86503","state":"AZ"},
	{"zipCode":"86504","state":"AZ"},
	{"zipCode":"86505","state":"AZ"},
	{"zipCode":"86506","state":"AZ"},
	{"zipCode":"86507","state":"AZ"},
	{"zipCode":"86508","state":"AZ"},
	{"zipCode":"86510","state":"AZ"},
	{"zipCode":"86511","state":"AZ"},
	{"zipCode":"86512","state":"AZ"},
	{"zipCode":"86514","state":"AZ"},
	{"zipCode":"86515","state":"AZ"},
	{"zipCode":"86520","state":"AZ"},
	{"zipCode":"86535","state":"AZ"},
	{"zipCode":"86538","state":"AZ"},
	{"zipCode":"86540","state":"AZ"},
	{"zipCode":"86544","state":"AZ"},
	{"zipCode":"86545","state":"AZ"},
	{"zipCode":"86547","state":"AZ"},
	{"zipCode":"86556","state":"AZ"},
	{"zipCode":"88513","state":"TX"},
	{"zipCode":"88540","state":"TX"},
	{"zipCode":"88554","state":"TX"},
	{"zipCode":"88565","state":"TX"},
	{"zipCode":"88575","state":"TX"},
	{"zipCode":"88587","state":"TX"},
	{"zipCode":"88588","state":"TX"},
	{"zipCode":"88590","state":"TX"},
	{"zipCode":"90001","state":"CA"},
	{"zipCode":"90002","state":"CA"},
	{"zipCode":"90002","state":"CA"},
	{"zipCode":"90003","state":"CA"},
	{"zipCode":"90004","state":"CA"},
	{"zipCode":"90005","state":"CA"},
	{"zipCode":"90006","state":"CA"},
	{"zipCode":"90007","state":"CA"},
	{"zipCode":"90008","state":"CA"},
	{"zipCode":"90009","state":"CA"},
	{"zipCode":"90011","state":"CA"},
	{"zipCode":"90012","state":"CA"},
	{"zipCode":"90013","state":"CA"},
	{"zipCode":"90013","state":"CA"},
	{"zipCode":"90014","state":"CA"},
	{"zipCode":"90015","state":"CA"},
	{"zipCode":"90015","state":"CA"},
	{"zipCode":"90016","state":"CA"},
	{"zipCode":"90017","state":"CA"},
	{"zipCode":"90018","state":"CA"},
	{"zipCode":"90019","state":"CA"},
	{"zipCode":"90020","state":"CA"},
	{"zipCode":"90021","state":"CA"},
	{"zipCode":"90021","state":"CA"},
	{"zipCode":"90022","state":"CA"},
	{"zipCode":"90023","state":"CA"},
	{"zipCode":"90024","state":"CA"},
	{"zipCode":"90024","state":"CA"},
	{"zipCode":"90025","state":"CA"},
	{"zipCode":"90025","state":"CA"},
	{"zipCode":"90026","state":"CA"},
	{"zipCode":"90027","state":"CA"},
	{"zipCode":"90028","state":"CA"},
	{"zipCode":"90029","state":"CA"},
	{"zipCode":"90030","state":"CA"},
	{"zipCode":"90031","state":"CA"},
	{"zipCode":"90031","state":"CA"},
	{"zipCode":"90032","state":"CA"},
	{"zipCode":"90032","state":"CA"},
	{"zipCode":"90033","state":"CA"},
	{"zipCode":"90034","state":"CA"},
	{"zipCode":"90035","state":"CA"},
	{"zipCode":"90036","state":"CA"},
	{"zipCode":"90036","state":"CA"},
	{"zipCode":"90037","state":"CA"},
	{"zipCode":"90038","state":"CA"},
	{"zipCode":"90039","state":"CA"},
	{"zipCode":"90040","state":"CA"},
	{"zipCode":"90041","state":"CA"},
	{"zipCode":"90042","state":"CA"},
	{"zipCode":"90042","state":"CA"},
	{"zipCode":"90043","state":"CA"},
	{"zipCode":"90044","state":"CA"},
	{"zipCode":"90045","state":"CA"},
	{"zipCode":"90046","state":"CA"},
	{"zipCode":"90046","state":"CA"},
	{"zipCode":"90047","state":"CA"},
	{"zipCode":"90048","state":"CA"},
	{"zipCode":"90049","state":"CA"},
	{"zipCode":"90050","state":"CA"},
	{"zipCode":"90051","state":"CA"},
	{"zipCode":"90053","state":"CA"},
	{"zipCode":"90054","state":"CA"},
	{"zipCode":"90055","state":"CA"},
	{"zipCode":"90056","state":"CA"},
	{"zipCode":"90057","state":"CA"},
	{"zipCode":"90058","state":"CA"},
	{"zipCode":"90059","state":"CA"},
	{"zipCode":"90059","state":"CA"},
	{"zipCode":"90060","state":"CA"},
	{"zipCode":"90061","state":"CA"},
	{"zipCode":"90061","state":"CA"},
	{"zipCode":"90062","state":"CA"},
	{"zipCode":"90062","state":"CA"},
	{"zipCode":"90063","state":"CA"},
	{"zipCode":"90064","state":"CA"},
	{"zipCode":"90065","state":"CA"},
	{"zipCode":"90066","state":"CA"},
	{"zipCode":"90067","state":"CA"},
	{"zipCode":"90067","state":"CA"},
	{"zipCode":"90068","state":"CA"},
	{"zipCode":"90069","state":"CA"},
	{"zipCode":"90069","state":"CA"},
	{"zipCode":"90070","state":"CA"},
	{"zipCode":"90071","state":"CA"},
	{"zipCode":"90071","state":"CA"},
	{"zipCode":"90071","state":"CA"},
	{"zipCode":"90072","state":"CA"},
	{"zipCode":"90073","state":"CA"},
	{"zipCode":"90075","state":"CA"},
	{"zipCode":"90076","state":"CA"},
	{"zipCode":"90077","state":"CA"},
	{"zipCode":"90078","state":"CA"},
	{"zipCode":"90079","state":"CA"},
	{"zipCode":"90079","state":"CA"},
	{"zipCode":"90080","state":"CA"},
	{"zipCode":"90081","state":"CA"},
	{"zipCode":"90082","state":"CA"},
	{"zipCode":"90083","state":"CA"},
	{"zipCode":"90086","state":"CA"},
	{"zipCode":"90087","state":"CA"},
	{"zipCode":"90091","state":"CA"},
	{"zipCode":"90093","state":"CA"},
	{"zipCode":"90094","state":"CA"},
	{"zipCode":"90201","state":"CA"},
	{"zipCode":"90201","state":"CA"},
	{"zipCode":"90202","state":"CA"},
	{"zipCode":"90209","state":"CA"},
	{"zipCode":"90210","state":"CA"},
	{"zipCode":"90211","state":"CA"},
	{"zipCode":"90212","state":"CA"},
	{"zipCode":"90212","state":"CA"},
	{"zipCode":"90213","state":"CA"},
	{"zipCode":"90220","state":"CA"},
	{"zipCode":"90221","state":"CA"},
	{"zipCode":"90222","state":"CA"},
	{"zipCode":"90223","state":"CA"},
	{"zipCode":"90223","state":"CA"},
	{"zipCode":"90224","state":"CA"},
	{"zipCode":"90230","state":"CA"},
	{"zipCode":"90231","state":"CA"},
	{"zipCode":"90232","state":"CA"},
	{"zipCode":"90232","state":"CA"},
	{"zipCode":"90239","state":"CA"},
	{"zipCode":"90240","state":"CA"},
	{"zipCode":"90240","state":"CA"},
	{"zipCode":"90241","state":"CA"},
	{"zipCode":"90242","state":"CA"},
	{"zipCode":"90245","state":"CA"},
	{"zipCode":"90247","state":"CA"},
	{"zipCode":"90248","state":"CA"},
	{"zipCode":"90248","state":"CA"},
	{"zipCode":"90249","state":"CA"},
	{"zipCode":"90249","state":"CA"},
	{"zipCode":"90250","state":"CA"},
	{"zipCode":"90251","state":"CA"},
	{"zipCode":"90254","state":"CA"},
	{"zipCode":"90254","state":"CA"},
	{"zipCode":"90255","state":"CA"},
	{"zipCode":"90255","state":"CA"},
	{"zipCode":"90255","state":"CA"},
	{"zipCode":"90260","state":"CA"},
	{"zipCode":"90262","state":"CA"},
	{"zipCode":"90262","state":"CA"},
	{"zipCode":"90264","state":"CA"},
	{"zipCode":"90265","state":"CA"},
	{"zipCode":"90265","state":"CA"},
	{"zipCode":"90265","state":"CA"},
	{"zipCode":"90266","state":"CA"},
	{"zipCode":"90266","state":"CA"},
	{"zipCode":"90267","state":"CA"},
	{"zipCode":"90267","state":"CA"},
	{"zipCode":"90270","state":"CA"},
	{"zipCode":"90270","state":"CA"},
	{"zipCode":"90272","state":"CA"},
	{"zipCode":"90274","state":"CA"},
	{"zipCode":"90274","state":"CA"},
	{"zipCode":"90275","state":"CA"},
	{"zipCode":"90277","state":"CA"},
	{"zipCode":"90278","state":"CA"},
	{"zipCode":"90278","state":"CA"},
	{"zipCode":"90280","state":"CA"},
	{"zipCode":"90280","state":"CA"},
	{"zipCode":"90280","state":"CA"},
	{"zipCode":"90290","state":"CA"},
	{"zipCode":"90291","state":"CA"},
	{"zipCode":"90292","state":"CA"},
	{"zipCode":"90293","state":"CA"},
	{"zipCode":"90294","state":"CA"},
	{"zipCode":"90295","state":"CA"},
	{"zipCode":"90296","state":"CA"},
	{"zipCode":"90301","state":"CA"},
	{"zipCode":"90302","state":"CA"},
	{"zipCode":"90303","state":"CA"},
	{"zipCode":"90304","state":"CA"},
	{"zipCode":"90304","state":"CA"},
	{"zipCode":"90305","state":"CA"},
	{"zipCode":"90305","state":"CA"},
	{"zipCode":"90306","state":"CA"},
	{"zipCode":"90307","state":"CA"},
	{"zipCode":"90308","state":"CA"},
	{"zipCode":"90309","state":"CA"},
	{"zipCode":"90310","state":"CA"},
	{"zipCode":"90312","state":"CA"},
	{"zipCode":"90401","state":"CA"},
	{"zipCode":"90402","state":"CA"},
	{"zipCode":"90403","state":"CA"},
	{"zipCode":"90404","state":"CA"},
	{"zipCode":"90405","state":"CA"},
	{"zipCode":"90406","state":"CA"},
	{"zipCode":"90407","state":"CA"},
	{"zipCode":"90408","state":"CA"},
	{"zipCode":"90409","state":"CA"},
	{"zipCode":"90411","state":"CA"},
	{"zipCode":"90501","state":"CA"},
	{"zipCode":"90502","state":"CA"},
	{"zipCode":"90503","state":"CA"},
	{"zipCode":"90503","state":"CA"},
	{"zipCode":"90504","state":"CA"},
	{"zipCode":"90505","state":"CA"},
	{"zipCode":"90505","state":"CA"},
	{"zipCode":"90507","state":"CA"},
	{"zipCode":"90508","state":"CA"},
	{"zipCode":"90509","state":"CA"},
	{"zipCode":"90510","state":"CA"},
	{"zipCode":"90601","state":"CA"},
	{"zipCode":"90602","state":"CA"},
	{"zipCode":"90603","state":"CA"},
	{"zipCode":"90603","state":"CA"},
	{"zipCode":"90604","state":"CA"},
	{"zipCode":"90605","state":"CA"},
	{"zipCode":"90605","state":"CA"},
	{"zipCode":"90606","state":"CA"},
	{"zipCode":"90607","state":"CA"},
	{"zipCode":"90608","state":"CA"},
	{"zipCode":"90609","state":"CA"},
	{"zipCode":"90610","state":"CA"},
	{"zipCode":"90620","state":"CA"},
	{"zipCode":"90621","state":"CA"},
	{"zipCode":"90621","state":"CA"},
	{"zipCode":"90622","state":"CA"},
	{"zipCode":"90623","state":"CA"},
	{"zipCode":"90630","state":"CA"},
	{"zipCode":"90630","state":"CA"},
	{"zipCode":"90631","state":"CA"},
	{"zipCode":"90632","state":"CA"},
	{"zipCode":"90633","state":"CA"},
	{"zipCode":"90637","state":"CA"},
	{"zipCode":"90638","state":"CA"},
	{"zipCode":"90640","state":"CA"},
	{"zipCode":"90640","state":"CA"},
	{"zipCode":"90650","state":"CA"},
	{"zipCode":"90651","state":"CA"},
	{"zipCode":"90652","state":"CA"},
	{"zipCode":"90660","state":"CA"},
	{"zipCode":"90661","state":"CA"},
	{"zipCode":"90662","state":"CA"},
	{"zipCode":"90670","state":"CA"},
	{"zipCode":"90680","state":"CA"},
	{"zipCode":"90701","state":"CA"},
	{"zipCode":"90702","state":"CA"},
	{"zipCode":"90703","state":"CA"},
	{"zipCode":"90703","state":"CA"},
	{"zipCode":"90704","state":"CA"},
	{"zipCode":"90706","state":"CA"},
	{"zipCode":"90707","state":"CA"},
	{"zipCode":"90710","state":"CA"},
	{"zipCode":"90711","state":"CA"},
	{"zipCode":"90712","state":"CA"},
	{"zipCode":"90713","state":"CA"},
	{"zipCode":"90714","state":"CA"},
	{"zipCode":"90715","state":"CA"},
	{"zipCode":"90716","state":"CA"},
	{"zipCode":"90717","state":"CA"},
	{"zipCode":"90720","state":"CA"},
	{"zipCode":"90721","state":"CA"},
	{"zipCode":"90723","state":"CA"},
	{"zipCode":"90731","state":"CA"},
	{"zipCode":"90731","state":"CA"},
	{"zipCode":"90732","state":"CA"},
	{"zipCode":"90733","state":"CA"},
	{"zipCode":"90734","state":"CA"},
	{"zipCode":"90740","state":"CA"},
	{"zipCode":"90740","state":"CA"},
	{"zipCode":"90742","state":"CA"},
	{"zipCode":"90743","state":"CA"},
	{"zipCode":"90744","state":"CA"},
	{"zipCode":"90745","state":"CA"},
	{"zipCode":"90746","state":"CA"},
	{"zipCode":"90748","state":"CA"},
	{"zipCode":"90749","state":"CA"},
	{"zipCode":"90749","state":"CA"},
	{"zipCode":"90755","state":"CA"},
	{"zipCode":"90801","state":"CA"},
	{"zipCode":"90802","state":"CA"},
	{"zipCode":"90803","state":"CA"},
	{"zipCode":"90803","state":"CA"},
	{"zipCode":"90804","state":"CA"},
	{"zipCode":"90805","state":"CA"},
	{"zipCode":"90805","state":"CA"},
	{"zipCode":"90806","state":"CA"},
	{"zipCode":"90807","state":"CA"},
	{"zipCode":"90807","state":"CA"},
	{"zipCode":"90808","state":"CA"},
	{"zipCode":"90808","state":"CA"},
	{"zipCode":"90809","state":"CA"},
	{"zipCode":"90810","state":"CA"},
	{"zipCode":"90810","state":"CA"},
	{"zipCode":"90813","state":"CA"},
	{"zipCode":"90814","state":"CA"},
	{"zipCode":"90815","state":"CA"},
	{"zipCode":"90815","state":"CA"},
	{"zipCode":"90831","state":"CA"},
	{"zipCode":"90832","state":"CA"},
	{"zipCode":"90853","state":"CA"},
	{"zipCode":"91001","state":"CA"},
	{"zipCode":"91003","state":"CA"},
	{"zipCode":"91006","state":"CA"},
	{"zipCode":"91007","state":"CA"},
	{"zipCode":"91008","state":"CA"},
	{"zipCode":"91009","state":"CA"},
	{"zipCode":"91010","state":"CA"},
	{"zipCode":"91011","state":"CA"},
	{"zipCode":"91012","state":"CA"},
	{"zipCode":"91016","state":"CA"},
	{"zipCode":"91017","state":"CA"},
	{"zipCode":"91020","state":"CA"},
	{"zipCode":"91021","state":"CA"},
	{"zipCode":"91024","state":"CA"},
	{"zipCode":"91025","state":"CA"},
	{"zipCode":"91030","state":"CA"},
	{"zipCode":"91031","state":"CA"},
	{"zipCode":"91040","state":"CA"},
	{"zipCode":"91041","state":"CA"},
	{"zipCode":"91042","state":"CA"},
	{"zipCode":"91043","state":"CA"},
	{"zipCode":"91046","state":"CA"},
	{"zipCode":"91066","state":"CA"},
	{"zipCode":"91077","state":"CA"},
	{"zipCode":"91101","state":"CA"},
	{"zipCode":"91102","state":"CA"},
	{"zipCode":"91103","state":"CA"},
	{"zipCode":"91104","state":"CA"},
	{"zipCode":"91105","state":"CA"},
	{"zipCode":"91106","state":"CA"},
	{"zipCode":"91107","state":"CA"},
	{"zipCode":"91108","state":"CA"},
	{"zipCode":"91109","state":"CA"},
	{"zipCode":"91114","state":"CA"},
	{"zipCode":"91115","state":"CA"},
	{"zipCode":"91116","state":"CA"},
	{"zipCode":"91117","state":"CA"},
	{"zipCode":"91118","state":"CA"},
	{"zipCode":"91201","state":"CA"},
	{"zipCode":"91202","state":"CA"},
	{"zipCode":"91203","state":"CA"},
	{"zipCode":"91204","state":"CA"},
	{"zipCode":"91205","state":"CA"},
	{"zipCode":"91206","state":"CA"},
	{"zipCode":"91207","state":"CA"},
	{"zipCode":"91208","state":"CA"},
	{"zipCode":"91209","state":"CA"},
	{"zipCode":"91210","state":"CA"},
	{"zipCode":"91214","state":"CA"},
	{"zipCode":"91221","state":"CA"},
	{"zipCode":"91222","state":"CA"},
	{"zipCode":"91224","state":"CA"},
	{"zipCode":"91225","state":"CA"},
	{"zipCode":"91226","state":"CA"},
	{"zipCode":"91301","state":"CA"},
	{"zipCode":"91302","state":"CA"},
	{"zipCode":"91303","state":"CA"},
	{"zipCode":"91304","state":"CA"},
	{"zipCode":"91305","state":"CA"},
	{"zipCode":"91306","state":"CA"},
	{"zipCode":"91307","state":"CA"},
	{"zipCode":"91308","state":"CA"},
	{"zipCode":"91309","state":"CA"},
	{"zipCode":"91310","state":"CA"},
	{"zipCode":"91311","state":"CA"},
	{"zipCode":"91313","state":"CA"},
	{"zipCode":"91316","state":"CA"},
	{"zipCode":"91319","state":"CA"},
	{"zipCode":"91320","state":"CA"},
	{"zipCode":"91321","state":"CA"},
	{"zipCode":"91322","state":"CA"},
	{"zipCode":"91324","state":"CA"},
	{"zipCode":"91325","state":"CA"},
	{"zipCode":"91326","state":"CA"},
	{"zipCode":"91326","state":"CA"},
	{"zipCode":"91327","state":"CA"},
	{"zipCode":"91328","state":"CA"},
	{"zipCode":"91331","state":"CA"},
	{"zipCode":"91333","state":"CA"},
	{"zipCode":"91334","state":"CA"},
	{"zipCode":"91335","state":"CA"},
	{"zipCode":"91337","state":"CA"},
	{"zipCode":"91340","state":"CA"},
	{"zipCode":"91341","state":"CA"},
	{"zipCode":"91342","state":"CA"},
	{"zipCode":"91343","state":"CA"},
	{"zipCode":"91344","state":"CA"},
	{"zipCode":"91345","state":"CA"},
	{"zipCode":"91346","state":"CA"},
	{"zipCode":"91350","state":"CA"},
	{"zipCode":"91351","state":"CA"},
	{"zipCode":"91352","state":"CA"},
	{"zipCode":"91353","state":"CA"},
	{"zipCode":"91354","state":"CA"},
	{"zipCode":"91355","state":"CA"},
	{"zipCode":"91356","state":"CA"},
	{"zipCode":"91357","state":"CA"},
	{"zipCode":"91358","state":"CA"},
	{"zipCode":"91359","state":"CA"},
	{"zipCode":"91360","state":"CA"},
	{"zipCode":"91361","state":"CA"},
	{"zipCode":"91362","state":"CA"},
	{"zipCode":"91364","state":"CA"},
	{"zipCode":"91365","state":"CA"},
	{"zipCode":"91367","state":"CA"},
	{"zipCode":"91372","state":"CA"},
	{"zipCode":"91376","state":"CA"},
	{"zipCode":"91377","state":"CA"},
	{"zipCode":"91380","state":"CA"},
	{"zipCode":"91381","state":"CA"},
	{"zipCode":"91384","state":"CA"},
	{"zipCode":"91385","state":"CA"},
	{"zipCode":"91386","state":"CA"},
	{"zipCode":"91387","state":"CA"},
	{"zipCode":"91390","state":"CA"},
	{"zipCode":"91392","state":"CA"},
	{"zipCode":"91393","state":"CA"},
	{"zipCode":"91394","state":"CA"},
	{"zipCode":"91395","state":"CA"},
	{"zipCode":"91396","state":"CA"},
	{"zipCode":"91401","state":"CA"},
	{"zipCode":"91402","state":"CA"},
	{"zipCode":"91403","state":"CA"},
	{"zipCode":"91404","state":"CA"},
	{"zipCode":"91405","state":"CA"},
	{"zipCode":"91406","state":"CA"},
	{"zipCode":"91407","state":"CA"},
	{"zipCode":"91408","state":"CA"},
	{"zipCode":"91409","state":"CA"},
	{"zipCode":"91410","state":"CA"},
	{"zipCode":"91411","state":"CA"},
	{"zipCode":"91412","state":"CA"},
	{"zipCode":"91413","state":"CA"},
	{"zipCode":"91416","state":"CA"},
	{"zipCode":"91423","state":"CA"},
	{"zipCode":"91426","state":"CA"},
	{"zipCode":"91436","state":"CA"},
	{"zipCode":"91501","state":"CA"},
	{"zipCode":"91502","state":"CA"},
	{"zipCode":"91503","state":"CA"},
	{"zipCode":"91504","state":"CA"},
	{"zipCode":"91505","state":"CA"},
	{"zipCode":"91506","state":"CA"},
	{"zipCode":"91507","state":"CA"},
	{"zipCode":"91508","state":"CA"},
	{"zipCode":"91510","state":"CA"},
	{"zipCode":"91601","state":"CA"},
	{"zipCode":"91602","state":"CA"},
	{"zipCode":"91603","state":"CA"},
	{"zipCode":"91604","state":"CA"},
	{"zipCode":"91605","state":"CA"},
	{"zipCode":"91606","state":"CA"},
	{"zipCode":"91607","state":"CA"},
	{"zipCode":"91608","state":"CA"},
	{"zipCode":"91609","state":"CA"},
	{"zipCode":"91610","state":"CA"},
	{"zipCode":"91614","state":"CA"},
	{"zipCode":"91615","state":"CA"},
	{"zipCode":"91616","state":"CA"},
	{"zipCode":"91617","state":"CA"},
	{"zipCode":"91701","state":"CA"},
	{"zipCode":"91701","state":"CA"},
	{"zipCode":"91702","state":"CA"},
	{"zipCode":"91706","state":"CA"},
	{"zipCode":"91706","state":"CA"},
	{"zipCode":"91708","state":"CA"},
	{"zipCode":"91709","state":"CA"},
	{"zipCode":"91710","state":"CA"},
	{"zipCode":"91711","state":"CA"},
	{"zipCode":"91711","state":"CA"},
	{"zipCode":"91715","state":"CA"},
	{"zipCode":"91716","state":"CA"},
	{"zipCode":"91722","state":"CA"},
	{"zipCode":"91723","state":"CA"},
	{"zipCode":"91723","state":"CA"},
	{"zipCode":"91724","state":"CA"},
	{"zipCode":"91729","state":"CA"},
	{"zipCode":"91730","state":"CA"},
	{"zipCode":"91731","state":"CA"},
	{"zipCode":"91732","state":"CA"},
	{"zipCode":"91733","state":"CA"},
	{"zipCode":"91733","state":"CA"},
	{"zipCode":"91734","state":"CA"},
	{"zipCode":"91737","state":"CA"},
	{"zipCode":"91739","state":"CA"},
	{"zipCode":"91740","state":"CA"},
	{"zipCode":"91741","state":"CA"},
	{"zipCode":"91743","state":"CA"},
	{"zipCode":"91744","state":"CA"},
	{"zipCode":"91744","state":"CA"},
	{"zipCode":"91745","state":"CA"},
	{"zipCode":"91745","state":"CA"},
	{"zipCode":"91746","state":"CA"},
	{"zipCode":"91746","state":"CA"},
	{"zipCode":"91747","state":"CA"},
	{"zipCode":"91748","state":"CA"},
	{"zipCode":"91748","state":"CA"},
	{"zipCode":"91750","state":"CA"},
	{"zipCode":"91752","state":"CA"},
	{"zipCode":"91754","state":"CA"},
	{"zipCode":"91755","state":"CA"},
	{"zipCode":"91759","state":"CA"},
	{"zipCode":"91761","state":"CA"},
	{"zipCode":"91762","state":"CA"},
	{"zipCode":"91762","state":"CA"},
	{"zipCode":"91762","state":"CA"},
	{"zipCode":"91763","state":"CA"},
	{"zipCode":"91764","state":"CA"},
	{"zipCode":"91765","state":"CA"},
	{"zipCode":"91766","state":"CA"},
	{"zipCode":"91767","state":"CA"},
	{"zipCode":"91768","state":"CA"},
	{"zipCode":"91769","state":"CA"},
	{"zipCode":"91770","state":"CA"},
	{"zipCode":"91770","state":"CA"},
	{"zipCode":"91773","state":"CA"},
	{"zipCode":"91775","state":"CA"},
	{"zipCode":"91776","state":"CA"},
	{"zipCode":"91778","state":"CA"},
	{"zipCode":"91780","state":"CA"},
	{"zipCode":"91784","state":"CA"},
	{"zipCode":"91785","state":"CA"},
	{"zipCode":"91786","state":"CA"},
	{"zipCode":"91788","state":"CA"},
	{"zipCode":"91789","state":"CA"},
	{"zipCode":"91790","state":"CA"},
	{"zipCode":"91791","state":"CA"},
	{"zipCode":"91791","state":"CA"},
	{"zipCode":"91792","state":"CA"},
	{"zipCode":"91793","state":"CA"},
	{"zipCode":"91801","state":"CA"},
	{"zipCode":"91802","state":"CA"},
	{"zipCode":"91803","state":"CA"},
	{"zipCode":"91803","state":"CA"},
	{"zipCode":"91901","state":"CA"},
	{"zipCode":"91902","state":"CA"},
	{"zipCode":"91903","state":"CA"},
	{"zipCode":"91905","state":"CA"},
	{"zipCode":"91906","state":"CA"},
	{"zipCode":"91908","state":"CA"},
	{"zipCode":"91909","state":"CA"},
	{"zipCode":"91910","state":"CA"},
	{"zipCode":"91911","state":"CA"},
	{"zipCode":"91912","state":"CA"},
	{"zipCode":"91913","state":"CA"},
	{"zipCode":"91914","state":"CA"},
	{"zipCode":"91915","state":"CA"},
	{"zipCode":"91916","state":"CA"},
	{"zipCode":"91917","state":"CA"},
	{"zipCode":"91921","state":"CA"},
	{"zipCode":"91931","state":"CA"},
	{"zipCode":"91932","state":"CA"},
	{"zipCode":"91933","state":"CA"},
	{"zipCode":"91934","state":"CA"},
	{"zipCode":"91935","state":"CA"},
	{"zipCode":"91941","state":"CA"},
	{"zipCode":"91942","state":"CA"},
	{"zipCode":"91943","state":"CA"},
	{"zipCode":"91944","state":"CA"},
	{"zipCode":"91945","state":"CA"},
	{"zipCode":"91946","state":"CA"},
	{"zipCode":"91948","state":"CA"},
	{"zipCode":"91950","state":"CA"},
	{"zipCode":"91951","state":"CA"},
	{"zipCode":"91962","state":"CA"},
	{"zipCode":"91963","state":"CA"},
	{"zipCode":"91976","state":"CA"},
	{"zipCode":"91977","state":"CA"},
	{"zipCode":"91978","state":"CA"},
	{"zipCode":"91979","state":"CA"},
	{"zipCode":"91980","state":"CA"},
	{"zipCode":"92003","state":"CA"},
	{"zipCode":"92004","state":"CA"},
	{"zipCode":"92007","state":"CA"},
	{"zipCode":"92007","state":"CA"},
	{"zipCode":"92008","state":"CA"},
	{"zipCode":"92009","state":"CA"},
	{"zipCode":"92010","state":"CA"},
	{"zipCode":"92011","state":"CA"},
	{"zipCode":"92013","state":"CA"},
	{"zipCode":"92014","state":"CA"},
	{"zipCode":"92018","state":"CA"},
	{"zipCode":"92019","state":"CA"},
	{"zipCode":"92020","state":"CA"},
	{"zipCode":"92021","state":"CA"},
	{"zipCode":"92022","state":"CA"},
	{"zipCode":"92023","state":"CA"},
	{"zipCode":"92023","state":"CA"},
	{"zipCode":"92024","state":"CA"},
	{"zipCode":"92025","state":"CA"},
	{"zipCode":"92026","state":"CA"},
	{"zipCode":"92027","state":"CA"},
	{"zipCode":"92028","state":"CA"},
	{"zipCode":"92029","state":"CA"},
	{"zipCode":"92030","state":"CA"},
	{"zipCode":"92033","state":"CA"},
	{"zipCode":"92036","state":"CA"},
	{"zipCode":"92037","state":"CA"},
	{"zipCode":"92038","state":"CA"},
	{"zipCode":"92039","state":"CA"},
	{"zipCode":"92040","state":"CA"},
	{"zipCode":"92046","state":"CA"},
	{"zipCode":"92049","state":"CA"},
	{"zipCode":"92051","state":"CA"},
	{"zipCode":"92052","state":"CA"},
	{"zipCode":"92054","state":"CA"},
	{"zipCode":"92056","state":"CA"},
	{"zipCode":"92057","state":"CA"},
	{"zipCode":"92058","state":"CA"},
	{"zipCode":"92059","state":"CA"},
	{"zipCode":"92060","state":"CA"},
	{"zipCode":"92061","state":"CA"},
	{"zipCode":"92064","state":"CA"},
	{"zipCode":"92065","state":"CA"},
	{"zipCode":"92066","state":"CA"},
	{"zipCode":"92067","state":"CA"},
	{"zipCode":"92067","state":"CA"},
	{"zipCode":"92068","state":"CA"},
	{"zipCode":"92069","state":"CA"},
	{"zipCode":"92070","state":"CA"},
	{"zipCode":"92071","state":"CA"},
	{"zipCode":"92072","state":"CA"},
	{"zipCode":"92074","state":"CA"},
	{"zipCode":"92075","state":"CA"},
	{"zipCode":"92078","state":"CA"},
	{"zipCode":"92079","state":"CA"},
	{"zipCode":"92081","state":"CA"},
	{"zipCode":"92082","state":"CA"},
	{"zipCode":"92083","state":"CA"},
	{"zipCode":"92084","state":"CA"},
	{"zipCode":"92085","state":"CA"},
	{"zipCode":"92086","state":"CA"},
	{"zipCode":"92088","state":"CA"},
	{"zipCode":"92091","state":"CA"},
	{"zipCode":"92101","state":"CA"},
	{"zipCode":"92102","state":"CA"},
	{"zipCode":"92103","state":"CA"},
	{"zipCode":"92104","state":"CA"},
	{"zipCode":"92105","state":"CA"},
	{"zipCode":"92106","state":"CA"},
	{"zipCode":"92107","state":"CA"},
	{"zipCode":"92108","state":"CA"},
	{"zipCode":"92109","state":"CA"},
	{"zipCode":"92110","state":"CA"},
	{"zipCode":"92111","state":"CA"},
	{"zipCode":"92112","state":"CA"},
	{"zipCode":"92113","state":"CA"},
	{"zipCode":"92114","state":"CA"},
	{"zipCode":"92115","state":"CA"},
	{"zipCode":"92116","state":"CA"},
	{"zipCode":"92117","state":"CA"},
	{"zipCode":"92118","state":"CA"},
	{"zipCode":"92119","state":"CA"},
	{"zipCode":"92120","state":"CA"},
	{"zipCode":"92121","state":"CA"},
	{"zipCode":"92122","state":"CA"},
	{"zipCode":"92123","state":"CA"},
	{"zipCode":"92124","state":"CA"},
	{"zipCode":"92126","state":"CA"},
	{"zipCode":"92127","state":"CA"},
	{"zipCode":"92128","state":"CA"},
	{"zipCode":"92129","state":"CA"},
	{"zipCode":"92130","state":"CA"},
	{"zipCode":"92130","state":"CA"},
	{"zipCode":"92131","state":"CA"},
	{"zipCode":"92137","state":"CA"},
	{"zipCode":"92138","state":"CA"},
	{"zipCode":"92139","state":"CA"},
	{"zipCode":"92142","state":"CA"},
	{"zipCode":"92143","state":"CA"},
	{"zipCode":"92149","state":"CA"},
	{"zipCode":"92150","state":"CA"},
	{"zipCode":"92153","state":"CA"},
	{"zipCode":"92154","state":"CA"},
	{"zipCode":"92159","state":"CA"},
	{"zipCode":"92160","state":"CA"},
	{"zipCode":"92163","state":"CA"},
	{"zipCode":"92165","state":"CA"},
	{"zipCode":"92166","state":"CA"},
	{"zipCode":"92167","state":"CA"},
	{"zipCode":"92168","state":"CA"},
	{"zipCode":"92169","state":"CA"},
	{"zipCode":"92170","state":"CA"},
	{"zipCode":"92171","state":"CA"},
	{"zipCode":"92172","state":"CA"},
	{"zipCode":"92173","state":"CA"},
	{"zipCode":"92174","state":"CA"},
	{"zipCode":"92175","state":"CA"},
	{"zipCode":"92176","state":"CA"},
	{"zipCode":"92177","state":"CA"},
	{"zipCode":"92178","state":"CA"},
	{"zipCode":"92186","state":"CA"},
	{"zipCode":"92191","state":"CA"},
	{"zipCode":"92192","state":"CA"},
	{"zipCode":"92192","state":"CA"},
	{"zipCode":"92193","state":"CA"},
	{"zipCode":"92195","state":"CA"},
	{"zipCode":"92196","state":"CA"},
	{"zipCode":"92198","state":"CA"},
	{"zipCode":"92201","state":"CA"},
	{"zipCode":"92202","state":"CA"},
	{"zipCode":"92203","state":"CA"},
	{"zipCode":"92210","state":"CA"},
	{"zipCode":"92211","state":"CA"},
	{"zipCode":"92220","state":"CA"},
	{"zipCode":"92222","state":"CA"},
	{"zipCode":"92223","state":"CA"},
	{"zipCode":"92225","state":"CA"},
	{"zipCode":"92226","state":"CA"},
	{"zipCode":"92227","state":"CA"},
	{"zipCode":"92230","state":"CA"},
	{"zipCode":"92231","state":"CA"},
	{"zipCode":"92232","state":"CA"},
	{"zipCode":"92233","state":"CA"},
	{"zipCode":"92234","state":"CA"},
	{"zipCode":"92235","state":"CA"},
	{"zipCode":"92236","state":"CA"},
	{"zipCode":"92239","state":"CA"},
	{"zipCode":"92240","state":"CA"},
	{"zipCode":"92241","state":"CA"},
	{"zipCode":"92242","state":"CA"},
	{"zipCode":"92242","state":"CA"},
	{"zipCode":"92243","state":"CA"},
	{"zipCode":"92244","state":"CA"},
	{"zipCode":"92247","state":"CA"},
	{"zipCode":"92248","state":"CA"},
	{"zipCode":"92249","state":"CA"},
	{"zipCode":"92250","state":"CA"},
	{"zipCode":"92251","state":"CA"},
	{"zipCode":"92252","state":"CA"},
	{"zipCode":"92253","state":"CA"},
	{"zipCode":"92254","state":"CA"},
	{"zipCode":"92255","state":"CA"},
	{"zipCode":"92256","state":"CA"},
	{"zipCode":"92257","state":"CA"},
	{"zipCode":"92258","state":"CA"},
	{"zipCode":"92259","state":"CA"},
	{"zipCode":"92260","state":"CA"},
	{"zipCode":"92261","state":"CA"},
	{"zipCode":"92262","state":"CA"},
	{"zipCode":"92263","state":"CA"},
	{"zipCode":"92263","state":"CA"},
	{"zipCode":"92264","state":"CA"},
	{"zipCode":"92266","state":"CA"},
	{"zipCode":"92267","state":"CA"},
	{"zipCode":"92268","state":"CA"},
	{"zipCode":"92270","state":"CA"},
	{"zipCode":"92273","state":"CA"},
	{"zipCode":"92274","state":"CA"},
	{"zipCode":"92274","state":"CA"},
	{"zipCode":"92275","state":"CA"},
	{"zipCode":"92276","state":"CA"},
	{"zipCode":"92277","state":"CA"},
	{"zipCode":"92278","state":"CA"},
	{"zipCode":"92280","state":"CA"},
	{"zipCode":"92281","state":"CA"},
	{"zipCode":"92282","state":"CA"},
	{"zipCode":"92283","state":"CA"},
	{"zipCode":"92284","state":"CA"},
	{"zipCode":"92285","state":"CA"},
	{"zipCode":"92285","state":"CA"},
	{"zipCode":"92286","state":"CA"},
	{"zipCode":"92301","state":"CA"},
	{"zipCode":"92304","state":"CA"},
	{"zipCode":"92305","state":"CA"},
	{"zipCode":"92307","state":"CA"},
	{"zipCode":"92308","state":"CA"},
	{"zipCode":"92309","state":"CA"},
	{"zipCode":"92310","state":"CA"},
	{"zipCode":"92311","state":"CA"},
	{"zipCode":"92312","state":"CA"},
	{"zipCode":"92313","state":"CA"},
	{"zipCode":"92314","state":"CA"},
	{"zipCode":"92315","state":"CA"},
	{"zipCode":"92315","state":"CA"},
	{"zipCode":"92316","state":"CA"},
	{"zipCode":"92317","state":"CA"},
	{"zipCode":"92318","state":"CA"},
	{"zipCode":"92320","state":"CA"},
	{"zipCode":"92321","state":"CA"},
	{"zipCode":"92322","state":"CA"},
	{"zipCode":"92323","state":"CA"},
	{"zipCode":"92324","state":"CA"},
	{"zipCode":"92325","state":"CA"},
	{"zipCode":"92325","state":"CA"},
	{"zipCode":"92327","state":"CA"},
	{"zipCode":"92327","state":"CA"},
	{"zipCode":"92328","state":"CA"},
	{"zipCode":"92329","state":"CA"},
	{"zipCode":"92331","state":"CA"},
	{"zipCode":"92332","state":"CA"},
	{"zipCode":"92333","state":"CA"},
	{"zipCode":"92334","state":"CA"},
	{"zipCode":"92335","state":"CA"},
	{"zipCode":"92336","state":"CA"},
	{"zipCode":"92337","state":"CA"},
	{"zipCode":"92338","state":"CA"},
	{"zipCode":"92339","state":"CA"},
	{"zipCode":"92340","state":"CA"},
	{"zipCode":"92341","state":"CA"},
	{"zipCode":"92342","state":"CA"},
	{"zipCode":"92344","state":"CA"},
	{"zipCode":"92345","state":"CA"},
	{"zipCode":"92346","state":"CA"},
	{"zipCode":"92347","state":"CA"},
	{"zipCode":"92347","state":"CA"},
	{"zipCode":"92352","state":"CA"},
	{"zipCode":"92354","state":"CA"},
	{"zipCode":"92356","state":"CA"},
	{"zipCode":"92358","state":"CA"},
	{"zipCode":"92359","state":"CA"},
	{"zipCode":"92363","state":"CA"},
	{"zipCode":"92363","state":"CA"},
	{"zipCode":"92364","state":"CA"},
	{"zipCode":"92365","state":"CA"},
	{"zipCode":"92368","state":"CA"},
	{"zipCode":"92369","state":"CA"},
	{"zipCode":"92371","state":"CA"},
	{"zipCode":"92372","state":"CA"},
	{"zipCode":"92373","state":"CA"},
	{"zipCode":"92373","state":"CA"},
	{"zipCode":"92374","state":"CA"},
	{"zipCode":"92375","state":"CA"},
	{"zipCode":"92376","state":"CA"},
	{"zipCode":"92377","state":"CA"},
	{"zipCode":"92377","state":"CA"},
	{"zipCode":"92378","state":"CA"},
	{"zipCode":"92382","state":"CA"},
	{"zipCode":"92384","state":"CA"},
	{"zipCode":"92385","state":"CA"},
	{"zipCode":"92386","state":"CA"},
	{"zipCode":"92389","state":"CA"},
	{"zipCode":"92391","state":"CA"},
	{"zipCode":"92392","state":"CA"},
	{"zipCode":"92393","state":"CA"},
	{"zipCode":"92394","state":"CA"},
	{"zipCode":"92395","state":"CA"},
	{"zipCode":"92395","state":"CA"},
	{"zipCode":"92397","state":"CA"},
	{"zipCode":"92398","state":"CA"},
	{"zipCode":"92399","state":"CA"},
	{"zipCode":"92401","state":"CA"},
	{"zipCode":"92402","state":"CA"},
	{"zipCode":"92404","state":"CA"},
	{"zipCode":"92405","state":"CA"},
	{"zipCode":"92406","state":"CA"},
	{"zipCode":"92407","state":"CA"},
	{"zipCode":"92408","state":"CA"},
	{"zipCode":"92410","state":"CA"},
	{"zipCode":"92411","state":"CA"},
	{"zipCode":"92413","state":"CA"},
	{"zipCode":"92423","state":"CA"},
	{"zipCode":"92427","state":"CA"},
	{"zipCode":"92501","state":"CA"},
	{"zipCode":"92502","state":"CA"},
	{"zipCode":"92503","state":"CA"},
	{"zipCode":"92504","state":"CA"},
	{"zipCode":"92505","state":"CA"},
	{"zipCode":"92506","state":"CA"},
	{"zipCode":"92507","state":"CA"},
	{"zipCode":"92508","state":"CA"},
	{"zipCode":"92509","state":"CA"},
	{"zipCode":"92513","state":"CA"},
	{"zipCode":"92514","state":"CA"},
	{"zipCode":"92516","state":"CA"},
	{"zipCode":"92517","state":"CA"},
	{"zipCode":"92518","state":"CA"},
	{"zipCode":"92518","state":"CA"},
	{"zipCode":"92519","state":"CA"},
	{"zipCode":"92530","state":"CA"},
	{"zipCode":"92531","state":"CA"},
	{"zipCode":"92532","state":"CA"},
	{"zipCode":"92536","state":"CA"},
	{"zipCode":"92539","state":"CA"},
	{"zipCode":"92543","state":"CA"},
	{"zipCode":"92544","state":"CA"},
	{"zipCode":"92544","state":"CA"},
	{"zipCode":"92545","state":"CA"},
	{"zipCode":"92546","state":"CA"},
	{"zipCode":"92548","state":"CA"},
	{"zipCode":"92548","state":"CA"},
	{"zipCode":"92549","state":"CA"},
	{"zipCode":"92551","state":"CA"},
	{"zipCode":"92552","state":"CA"},
	{"zipCode":"92553","state":"CA"},
	{"zipCode":"92554","state":"CA"},
	{"zipCode":"92555","state":"CA"},
	{"zipCode":"92556","state":"CA"},
	{"zipCode":"92557","state":"CA"},
	{"zipCode":"92561","state":"CA"},
	{"zipCode":"92562","state":"CA"},
	{"zipCode":"92563","state":"CA"},
	{"zipCode":"92564","state":"CA"},
	{"zipCode":"92567","state":"CA"},
	{"zipCode":"92570","state":"CA"},
	{"zipCode":"92571","state":"CA"},
	{"zipCode":"92572","state":"CA"},
	{"zipCode":"92581","state":"CA"},
	{"zipCode":"92582","state":"CA"},
	{"zipCode":"92583","state":"CA"},
	{"zipCode":"92584","state":"CA"},
	{"zipCode":"92585","state":"CA"},
	{"zipCode":"92585","state":"CA"},
	{"zipCode":"92586","state":"CA"},
	{"zipCode":"92586","state":"CA"},
	{"zipCode":"92587","state":"CA"},
	{"zipCode":"92589","state":"CA"},
	{"zipCode":"92590","state":"CA"},
	{"zipCode":"92591","state":"CA"},
	{"zipCode":"92592","state":"CA"},
	{"zipCode":"92593","state":"CA"},
	{"zipCode":"92595","state":"CA"},
	{"zipCode":"92595","state":"CA"},
	{"zipCode":"92596","state":"CA"},
	{"zipCode":"92596","state":"CA"},
	{"zipCode":"92602","state":"CA"},
	{"zipCode":"92603","state":"CA"},
	{"zipCode":"92604","state":"CA"},
	{"zipCode":"92605","state":"CA"},
	{"zipCode":"92606","state":"CA"},
	{"zipCode":"92607","state":"CA"},
	{"zipCode":"92609","state":"CA"},
	{"zipCode":"92610","state":"CA"},
	{"zipCode":"92612","state":"CA"},
	{"zipCode":"92614","state":"CA"},
	{"zipCode":"92615","state":"CA"},
	{"zipCode":"92616","state":"CA"},
	{"zipCode":"92617","state":"CA"},
	{"zipCode":"92618","state":"CA"},
	{"zipCode":"92619","state":"CA"},
	{"zipCode":"92620","state":"CA"},
	{"zipCode":"92623","state":"CA"},
	{"zipCode":"92624","state":"CA"},
	{"zipCode":"92624","state":"CA"},
	{"zipCode":"92625","state":"CA"},
	{"zipCode":"92625","state":"CA"},
	{"zipCode":"92626","state":"CA"},
	{"zipCode":"92627","state":"CA"},
	{"zipCode":"92628","state":"CA"},
	{"zipCode":"92629","state":"CA"},
	{"zipCode":"92630","state":"CA"},
	{"zipCode":"92637","state":"CA"},
	{"zipCode":"92646","state":"CA"},
	{"zipCode":"92647","state":"CA"},
	{"zipCode":"92648","state":"CA"},
	{"zipCode":"92648","state":"CA"},
	{"zipCode":"92649","state":"CA"},
	{"zipCode":"92650","state":"CA"},
	{"zipCode":"92651","state":"CA"},
	{"zipCode":"92652","state":"CA"},
	{"zipCode":"92652","state":"CA"},
	{"zipCode":"92653","state":"CA"},
	{"zipCode":"92654","state":"CA"},
	{"zipCode":"92655","state":"CA"},
	{"zipCode":"92655","state":"CA"},
	{"zipCode":"92656","state":"CA"},
	{"zipCode":"92657","state":"CA"},
	{"zipCode":"92658","state":"CA"},
	{"zipCode":"92659","state":"CA"},
	{"zipCode":"92660","state":"CA"},
	{"zipCode":"92661","state":"CA"},
	{"zipCode":"92661","state":"CA"},
	{"zipCode":"92662","state":"CA"},
	{"zipCode":"92662","state":"CA"},
	{"zipCode":"92663","state":"CA"},
	{"zipCode":"92672","state":"CA"},
	{"zipCode":"92673","state":"CA"},
	{"zipCode":"92674","state":"CA"},
	{"zipCode":"92674","state":"CA"},
	{"zipCode":"92675","state":"CA"},
	{"zipCode":"92676","state":"CA"},
	{"zipCode":"92677","state":"CA"},
	{"zipCode":"92678","state":"CA"},
	{"zipCode":"92679","state":"CA"},
	{"zipCode":"92683","state":"CA"},
	{"zipCode":"92684","state":"CA"},
	{"zipCode":"92684","state":"CA"},
	{"zipCode":"92685","state":"CA"},
	{"zipCode":"92688","state":"CA"},
	{"zipCode":"92688","state":"CA"},
	{"zipCode":"92690","state":"CA"},
	{"zipCode":"92691","state":"CA"},
	{"zipCode":"92692","state":"CA"},
	{"zipCode":"92693","state":"CA"},
	{"zipCode":"92694","state":"CA"},
	{"zipCode":"92701","state":"CA"},
	{"zipCode":"92702","state":"CA"},
	{"zipCode":"92703","state":"CA"},
	{"zipCode":"92704","state":"CA"},
	{"zipCode":"92705","state":"CA"},
	{"zipCode":"92706","state":"CA"},
	{"zipCode":"92706","state":"CA"},
	{"zipCode":"92707","state":"CA"},
	{"zipCode":"92707","state":"CA"},
	{"zipCode":"92708","state":"CA"},
	{"zipCode":"92711","state":"CA"},
	{"zipCode":"92728","state":"CA"},
	{"zipCode":"92735","state":"CA"},
	{"zipCode":"92780","state":"CA"},
	{"zipCode":"92781","state":"CA"},
	{"zipCode":"92782","state":"CA"},
	{"zipCode":"92799","state":"CA"},
	{"zipCode":"92801","state":"CA"},
	{"zipCode":"92802","state":"CA"},
	{"zipCode":"92803","state":"CA"},
	{"zipCode":"92804","state":"CA"},
	{"zipCode":"92805","state":"CA"},
	{"zipCode":"92806","state":"CA"},
	{"zipCode":"92807","state":"CA"},
	{"zipCode":"92808","state":"CA"},
	{"zipCode":"92809","state":"CA"},
	{"zipCode":"92811","state":"CA"},
	{"zipCode":"92812","state":"CA"},
	{"zipCode":"92814","state":"CA"},
	{"zipCode":"92815","state":"CA"},
	{"zipCode":"92816","state":"CA"},
	{"zipCode":"92817","state":"CA"},
	{"zipCode":"92821","state":"CA"},
	{"zipCode":"92822","state":"CA"},
	{"zipCode":"92823","state":"CA"},
	{"zipCode":"92825","state":"CA"},
	{"zipCode":"92831","state":"CA"},
	{"zipCode":"92832","state":"CA"},
	{"zipCode":"92833","state":"CA"},
	{"zipCode":"92834","state":"CA"},
	{"zipCode":"92835","state":"CA"},
	{"zipCode":"92836","state":"CA"},
	{"zipCode":"92837","state":"CA"},
	{"zipCode":"92838","state":"CA"},
	{"zipCode":"92840","state":"CA"},
	{"zipCode":"92841","state":"CA"},
	{"zipCode":"92842","state":"CA"},
	{"zipCode":"92843","state":"CA"},
	{"zipCode":"92844","state":"CA"},
	{"zipCode":"92845","state":"CA"},
	{"zipCode":"92846","state":"CA"},
	{"zipCode":"92856","state":"CA"},
	{"zipCode":"92857","state":"CA"},
	{"zipCode":"92859","state":"CA"},
	{"zipCode":"92860","state":"CA"},
	{"zipCode":"92861","state":"CA"},
	{"zipCode":"92863","state":"CA"},
	{"zipCode":"92865","state":"CA"},
	{"zipCode":"92866","state":"CA"},
	{"zipCode":"92867","state":"CA"},
	{"zipCode":"92868","state":"CA"},
	{"zipCode":"92869","state":"CA"},
	{"zipCode":"92870","state":"CA"},
	{"zipCode":"92871","state":"CA"},
	{"zipCode":"92877","state":"CA"},
	{"zipCode":"92878","state":"CA"},
	{"zipCode":"92879","state":"CA"},
	{"zipCode":"92880","state":"CA"},
	{"zipCode":"92881","state":"CA"},
	{"zipCode":"92882","state":"CA"},
	{"zipCode":"92883","state":"CA"},
	{"zipCode":"92885","state":"CA"},
	{"zipCode":"92886","state":"CA"},
	{"zipCode":"92887","state":"CA"},
	{"zipCode":"93001","state":"CA"},
	{"zipCode":"93002","state":"CA"},
	{"zipCode":"93003","state":"CA"},
	{"zipCode":"93004","state":"CA"},
	{"zipCode":"93005","state":"CA"},
	{"zipCode":"93006","state":"CA"},
	{"zipCode":"93007","state":"CA"},
	{"zipCode":"93010","state":"CA"},
	{"zipCode":"93011","state":"CA"},
	{"zipCode":"93012","state":"CA"},
	{"zipCode":"93013","state":"CA"},
	{"zipCode":"93014","state":"CA"},
	{"zipCode":"93015","state":"CA"},
	{"zipCode":"93016","state":"CA"},
	{"zipCode":"93020","state":"CA"},
	{"zipCode":"93021","state":"CA"},
	{"zipCode":"93022","state":"CA"},
	{"zipCode":"93023","state":"CA"},
	{"zipCode":"93024","state":"CA"},
	{"zipCode":"93030","state":"CA"},
	{"zipCode":"93031","state":"CA"},
	{"zipCode":"93032","state":"CA"},
	{"zipCode":"93033","state":"CA"},
	{"zipCode":"93034","state":"CA"},
	{"zipCode":"93035","state":"CA"},
	{"zipCode":"93036","state":"CA"},
	{"zipCode":"93040","state":"CA"},
	{"zipCode":"93041","state":"CA"},
	{"zipCode":"93044","state":"CA"},
	{"zipCode":"93060","state":"CA"},
	{"zipCode":"93061","state":"CA"},
	{"zipCode":"93062","state":"CA"},
	{"zipCode":"93063","state":"CA"},
	{"zipCode":"93065","state":"CA"},
	{"zipCode":"93066","state":"CA"},
	{"zipCode":"93066","state":"CA"},
	{"zipCode":"93067","state":"CA"},
	{"zipCode":"93094","state":"CA"},
	{"zipCode":"93101","state":"CA"},
	{"zipCode":"93102","state":"CA"},
	{"zipCode":"93103","state":"CA"},
	{"zipCode":"93105","state":"CA"},
	{"zipCode":"93108","state":"CA"},
	{"zipCode":"93109","state":"CA"},
	{"zipCode":"93110","state":"CA"},
	{"zipCode":"93111","state":"CA"},
	{"zipCode":"93116","state":"CA"},
	{"zipCode":"93117","state":"CA"},
	{"zipCode":"93118","state":"CA"},
	{"zipCode":"93120","state":"CA"},
	{"zipCode":"93121","state":"CA"},
	{"zipCode":"93130","state":"CA"},
	{"zipCode":"93140","state":"CA"},
	{"zipCode":"93150","state":"CA"},
	{"zipCode":"93160","state":"CA"},
	{"zipCode":"93190","state":"CA"},
	{"zipCode":"93201","state":"CA"},
	{"zipCode":"93202","state":"CA"},
	{"zipCode":"93203","state":"CA"},
	{"zipCode":"93204","state":"CA"},
	{"zipCode":"93205","state":"CA"},
	{"zipCode":"93206","state":"CA"},
	{"zipCode":"93207","state":"CA"},
	{"zipCode":"93210","state":"CA"},
	{"zipCode":"93212","state":"CA"},
	{"zipCode":"93215","state":"CA"},
	{"zipCode":"93216","state":"CA"},
	{"zipCode":"93218","state":"CA"},
	{"zipCode":"93219","state":"CA"},
	{"zipCode":"93220","state":"CA"},
	{"zipCode":"93221","state":"CA"},
	{"zipCode":"93222","state":"CA"},
	{"zipCode":"93223","state":"CA"},
	{"zipCode":"93224","state":"CA"},
	{"zipCode":"93225","state":"CA"},
	{"zipCode":"93226","state":"CA"},
	{"zipCode":"93227","state":"CA"},
	{"zipCode":"93230","state":"CA"},
	{"zipCode":"93232","state":"CA"},
	{"zipCode":"93234","state":"CA"},
	{"zipCode":"93235","state":"CA"},
	{"zipCode":"93237","state":"CA"},
	{"zipCode":"93238","state":"CA"},
	{"zipCode":"93239","state":"CA"},
	{"zipCode":"93240","state":"CA"},
	{"zipCode":"93240","state":"CA"},
	{"zipCode":"93241","state":"CA"},
	{"zipCode":"93242","state":"CA"},
	{"zipCode":"93243","state":"CA"},
	{"zipCode":"93243","state":"CA"},
	{"zipCode":"93244","state":"CA"},
	{"zipCode":"93245","state":"CA"},
	{"zipCode":"93245","state":"CA"},
	{"zipCode":"93247","state":"CA"},
	{"zipCode":"93249","state":"CA"},
	{"zipCode":"93250","state":"CA"},
	{"zipCode":"93251","state":"CA"},
	{"zipCode":"93252","state":"CA"},
	{"zipCode":"93254","state":"CA"},
	{"zipCode":"93255","state":"CA"},
	{"zipCode":"93256","state":"CA"},
	{"zipCode":"93257","state":"CA"},
	{"zipCode":"93258","state":"CA"},
	{"zipCode":"93258","state":"CA"},
	{"zipCode":"93258","state":"CA"},
	{"zipCode":"93260","state":"CA"},
	{"zipCode":"93261","state":"CA"},
	{"zipCode":"93262","state":"CA"},
	{"zipCode":"93263","state":"CA"},
	{"zipCode":"93265","state":"CA"},
	{"zipCode":"93266","state":"CA"},
	{"zipCode":"93267","state":"CA"},
	{"zipCode":"93268","state":"CA"},
	{"zipCode":"93270","state":"CA"},
	{"zipCode":"93271","state":"CA"},
	{"zipCode":"93272","state":"CA"},
	{"zipCode":"93274","state":"CA"},
	{"zipCode":"93275","state":"CA"},
	{"zipCode":"93276","state":"CA"},
	{"zipCode":"93277","state":"CA"},
	{"zipCode":"93278","state":"CA"},
	{"zipCode":"93279","state":"CA"},
	{"zipCode":"93280","state":"CA"},
	{"zipCode":"93283","state":"CA"},
	{"zipCode":"93285","state":"CA"},
	{"zipCode":"93286","state":"CA"},
	{"zipCode":"93287","state":"CA"},
	{"zipCode":"93290","state":"CA"},
	{"zipCode":"93291","state":"CA"},
	{"zipCode":"93292","state":"CA"},
	{"zipCode":"93301","state":"CA"},
	{"zipCode":"93302","state":"CA"},
	{"zipCode":"93303","state":"CA"},
	{"zipCode":"93304","state":"CA"},
	{"zipCode":"93305","state":"CA"},
	{"zipCode":"93306","state":"CA"},
	{"zipCode":"93307","state":"CA"},
	{"zipCode":"93308","state":"CA"},
	{"zipCode":"93309","state":"CA"},
	{"zipCode":"93311","state":"CA"},
	{"zipCode":"93312","state":"CA"},
	{"zipCode":"93313","state":"CA"},
	{"zipCode":"93314","state":"CA"},
	{"zipCode":"93380","state":"CA"},
	{"zipCode":"93383","state":"CA"},
	{"zipCode":"93384","state":"CA"},
	{"zipCode":"93385","state":"CA"},
	{"zipCode":"93386","state":"CA"},
	{"zipCode":"93387","state":"CA"},
	{"zipCode":"93388","state":"CA"},
	{"zipCode":"93389","state":"CA"},
	{"zipCode":"93390","state":"CA"},
	{"zipCode":"93401","state":"CA"},
	{"zipCode":"93402","state":"CA"},
	{"zipCode":"93403","state":"CA"},
	{"zipCode":"93405","state":"CA"},
	{"zipCode":"93406","state":"CA"},
	{"zipCode":"93412","state":"CA"},
	{"zipCode":"93420","state":"CA"},
	{"zipCode":"93421","state":"CA"},
	{"zipCode":"93422","state":"CA"},
	{"zipCode":"93423","state":"CA"},
	{"zipCode":"93424","state":"CA"},
	{"zipCode":"93426","state":"CA"},
	{"zipCode":"93427","state":"CA"},
	{"zipCode":"93428","state":"CA"},
	{"zipCode":"93429","state":"CA"},
	{"zipCode":"93430","state":"CA"},
	{"zipCode":"93432","state":"CA"},
	{"zipCode":"93433","state":"CA"},
	{"zipCode":"93434","state":"CA"},
	{"zipCode":"93436","state":"CA"},
	{"zipCode":"93437","state":"CA"},
	{"zipCode":"93437","state":"CA"},
	{"zipCode":"93438","state":"CA"},
	{"zipCode":"93440","state":"CA"},
	{"zipCode":"93441","state":"CA"},
	{"zipCode":"93442","state":"CA"},
	{"zipCode":"93443","state":"CA"},
	{"zipCode":"93444","state":"CA"},
	{"zipCode":"93445","state":"CA"},
	{"zipCode":"93446","state":"CA"},
	{"zipCode":"93447","state":"CA"},
	{"zipCode":"93448","state":"CA"},
	{"zipCode":"93448","state":"CA"},
	{"zipCode":"93449","state":"CA"},
	{"zipCode":"93450","state":"CA"},
	{"zipCode":"93451","state":"CA"},
	{"zipCode":"93452","state":"CA"},
	{"zipCode":"93452","state":"CA"},
	{"zipCode":"93453","state":"CA"},
	{"zipCode":"93454","state":"CA"},
	{"zipCode":"93455","state":"CA"},
	{"zipCode":"93456","state":"CA"},
	{"zipCode":"93457","state":"CA"},
	{"zipCode":"93458","state":"CA"},
	{"zipCode":"93460","state":"CA"},
	{"zipCode":"93461","state":"CA"},
	{"zipCode":"93463","state":"CA"},
	{"zipCode":"93464","state":"CA"},
	{"zipCode":"93465","state":"CA"},
	{"zipCode":"93475","state":"CA"},
	{"zipCode":"93483","state":"CA"},
	{"zipCode":"93501","state":"CA"},
	{"zipCode":"93502","state":"CA"},
	{"zipCode":"93504","state":"CA"},
	{"zipCode":"93505","state":"CA"},
	{"zipCode":"93510","state":"CA"},
	{"zipCode":"93512","state":"CA"},
	{"zipCode":"93513","state":"CA"},
	{"zipCode":"93514","state":"CA"},
	{"zipCode":"93515","state":"CA"},
	{"zipCode":"93516","state":"CA"},
	{"zipCode":"93517","state":"CA"},
	{"zipCode":"93518","state":"CA"},
	{"zipCode":"93519","state":"CA"},
	{"zipCode":"93522","state":"CA"},
	{"zipCode":"93523","state":"CA"},
	{"zipCode":"93526","state":"CA"},
	{"zipCode":"93527","state":"CA"},
	{"zipCode":"93528","state":"CA"},
	{"zipCode":"93529","state":"CA"},
	{"zipCode":"93530","state":"CA"},
	{"zipCode":"93531","state":"CA"},
	{"zipCode":"93532","state":"CA"},
	{"zipCode":"93534","state":"CA"},
	{"zipCode":"93535","state":"CA"},
	{"zipCode":"93536","state":"CA"},
	{"zipCode":"93539","state":"CA"},
	{"zipCode":"93541","state":"CA"},
	{"zipCode":"93542","state":"CA"},
	{"zipCode":"93543","state":"CA"},
	{"zipCode":"93544","state":"CA"},
	{"zipCode":"93545","state":"CA"},
	{"zipCode":"93546","state":"CA"},
	{"zipCode":"93549","state":"CA"},
	{"zipCode":"93550","state":"CA"},
	{"zipCode":"93550","state":"CA"},
	{"zipCode":"93551","state":"CA"},
	{"zipCode":"93551","state":"CA"},
	{"zipCode":"93552","state":"CA"},
	{"zipCode":"93553","state":"CA"},
	{"zipCode":"93554","state":"CA"},
	{"zipCode":"93555","state":"CA"},
	{"zipCode":"93556","state":"CA"},
	{"zipCode":"93558","state":"CA"},
	{"zipCode":"93560","state":"CA"},
	{"zipCode":"93561","state":"CA"},
	{"zipCode":"93562","state":"CA"},
	{"zipCode":"93563","state":"CA"},
	{"zipCode":"93563","state":"CA"},
	{"zipCode":"93581","state":"CA"},
	{"zipCode":"93584","state":"CA"},
	{"zipCode":"93586","state":"CA"},
	{"zipCode":"93586","state":"CA"},
	{"zipCode":"93590","state":"CA"},
	{"zipCode":"93591","state":"CA"},
	{"zipCode":"93592","state":"CA"},
	{"zipCode":"93596","state":"CA"},
	{"zipCode":"93601","state":"CA"},
	{"zipCode":"93602","state":"CA"},
	{"zipCode":"93603","state":"CA"},
	{"zipCode":"93604","state":"CA"},
	{"zipCode":"93605","state":"CA"},
	{"zipCode":"93606","state":"CA"},
	{"zipCode":"93607","state":"CA"},
	{"zipCode":"93608","state":"CA"},
	{"zipCode":"93609","state":"CA"},
	{"zipCode":"93610","state":"CA"},
	{"zipCode":"93611","state":"CA"},
	{"zipCode":"93612","state":"CA"},
	{"zipCode":"93613","state":"CA"},
	{"zipCode":"93614","state":"CA"},
	{"zipCode":"93615","state":"CA"},
	{"zipCode":"93616","state":"CA"},
	{"zipCode":"93618","state":"CA"},
	{"zipCode":"93619","state":"CA"},
	{"zipCode":"93620","state":"CA"},
	{"zipCode":"93621","state":"CA"},
	{"zipCode":"93622","state":"CA"},
	{"zipCode":"93623","state":"CA"},
	{"zipCode":"93624","state":"CA"},
	{"zipCode":"93625","state":"CA"},
	{"zipCode":"93626","state":"CA"},
	{"zipCode":"93627","state":"CA"},
	{"zipCode":"93628","state":"CA"},
	{"zipCode":"93630","state":"CA"},
	{"zipCode":"93631","state":"CA"},
	{"zipCode":"93633","state":"CA"},
	{"zipCode":"93634","state":"CA"},
	{"zipCode":"93635","state":"CA"},
	{"zipCode":"93636","state":"CA"},
	{"zipCode":"93637","state":"CA"},
	{"zipCode":"93638","state":"CA"},
	{"zipCode":"93639","state":"CA"},
	{"zipCode":"93640","state":"CA"},
	{"zipCode":"93641","state":"CA"},
	{"zipCode":"93643","state":"CA"},
	{"zipCode":"93644","state":"CA"},
	{"zipCode":"93645","state":"CA"},
	{"zipCode":"93646","state":"CA"},
	{"zipCode":"93647","state":"CA"},
	{"zipCode":"93648","state":"CA"},
	{"zipCode":"93649","state":"CA"},
	{"zipCode":"93650","state":"CA"},
	{"zipCode":"93650","state":"CA"},
	{"zipCode":"93651","state":"CA"},
	{"zipCode":"93652","state":"CA"},
	{"zipCode":"93653","state":"CA"},
	{"zipCode":"93654","state":"CA"},
	{"zipCode":"93656","state":"CA"},
	{"zipCode":"93657","state":"CA"},
	{"zipCode":"93660","state":"CA"},
	{"zipCode":"93661","state":"CA"},
	{"zipCode":"93662","state":"CA"},
	{"zipCode":"93664","state":"CA"},
	{"zipCode":"93665","state":"CA"},
	{"zipCode":"93666","state":"CA"},
	{"zipCode":"93667","state":"CA"},
	{"zipCode":"93668","state":"CA"},
	{"zipCode":"93670","state":"CA"},
	{"zipCode":"93673","state":"CA"},
	{"zipCode":"93675","state":"CA"},
	{"zipCode":"93701","state":"CA"},
	{"zipCode":"93702","state":"CA"},
	{"zipCode":"93702","state":"CA"},
	{"zipCode":"93703","state":"CA"},
	{"zipCode":"93704","state":"CA"},
	{"zipCode":"93705","state":"CA"},
	{"zipCode":"93706","state":"CA"},
	{"zipCode":"93707","state":"CA"},
	{"zipCode":"93708","state":"CA"},
	{"zipCode":"93709","state":"CA"},
	{"zipCode":"93710","state":"CA"},
	{"zipCode":"93711","state":"CA"},
	{"zipCode":"93712","state":"CA"},
	{"zipCode":"93714","state":"CA"},
	{"zipCode":"93715","state":"CA"},
	{"zipCode":"93716","state":"CA"},
	{"zipCode":"93717","state":"CA"},
	{"zipCode":"93718","state":"CA"},
	{"zipCode":"93720","state":"CA"},
	{"zipCode":"93721","state":"CA"},
	{"zipCode":"93722","state":"CA"},
	{"zipCode":"93723","state":"CA"},
	{"zipCode":"93725","state":"CA"},
	{"zipCode":"93726","state":"CA"},
	{"zipCode":"93727","state":"CA"},
	{"zipCode":"93728","state":"CA"},
	{"zipCode":"93729","state":"CA"},
	{"zipCode":"93730","state":"CA"},
	{"zipCode":"93737","state":"CA"},
	{"zipCode":"93744","state":"CA"},
	{"zipCode":"93745","state":"CA"},
	{"zipCode":"93747","state":"CA"},
	{"zipCode":"93755","state":"CA"},
	{"zipCode":"93771","state":"CA"},
	{"zipCode":"93772","state":"CA"},
	{"zipCode":"93773","state":"CA"},
	{"zipCode":"93774","state":"CA"},
	{"zipCode":"93775","state":"CA"},
	{"zipCode":"93776","state":"CA"},
	{"zipCode":"93777","state":"CA"},
	{"zipCode":"93778","state":"CA"},
	{"zipCode":"93779","state":"CA"},
	{"zipCode":"93790","state":"CA"},
	{"zipCode":"93791","state":"CA"},
	{"zipCode":"93792","state":"CA"},
	{"zipCode":"93793","state":"CA"},
	{"zipCode":"93794","state":"CA"},
	{"zipCode":"93901","state":"CA"},
	{"zipCode":"93902","state":"CA"},
	{"zipCode":"93905","state":"CA"},
	{"zipCode":"93906","state":"CA"},
	{"zipCode":"93907","state":"CA"},
	{"zipCode":"93908","state":"CA"},
	{"zipCode":"93912","state":"CA"},
	{"zipCode":"93915","state":"CA"},
	{"zipCode":"93920","state":"CA"},
	{"zipCode":"93921","state":"CA"},
	{"zipCode":"93922","state":"CA"},
	{"zipCode":"93923","state":"CA"},
	{"zipCode":"93924","state":"CA"},
	{"zipCode":"93925","state":"CA"},
	{"zipCode":"93926","state":"CA"},
	{"zipCode":"93927","state":"CA"},
	{"zipCode":"93928","state":"CA"},
	{"zipCode":"93930","state":"CA"},
	{"zipCode":"93932","state":"CA"},
	{"zipCode":"93933","state":"CA"},
	{"zipCode":"93940","state":"CA"},
	{"zipCode":"93942","state":"CA"},
	{"zipCode":"93943","state":"CA"},
	{"zipCode":"93944","state":"CA"},
	{"zipCode":"93950","state":"CA"},
	{"zipCode":"93953","state":"CA"},
	{"zipCode":"93954","state":"CA"},
	{"zipCode":"93955","state":"CA"},
	{"zipCode":"93960","state":"CA"},
	{"zipCode":"93962","state":"CA"},
	{"zipCode":"94002","state":"CA"},
	{"zipCode":"94005","state":"CA"},
	{"zipCode":"94010","state":"CA"},
	{"zipCode":"94011","state":"CA"},
	{"zipCode":"94011","state":"CA"},
	{"zipCode":"94011","state":"CA"},
	{"zipCode":"94014","state":"CA"},
	{"zipCode":"94014","state":"CA"},
	{"zipCode":"94015","state":"CA"},
	{"zipCode":"94015","state":"CA"},
	{"zipCode":"94016","state":"CA"},
	{"zipCode":"94017","state":"CA"},
	{"zipCode":"94018","state":"CA"},
	{"zipCode":"94019","state":"CA"},
	{"zipCode":"94020","state":"CA"},
	{"zipCode":"94021","state":"CA"},
	{"zipCode":"94022","state":"CA"},
	{"zipCode":"94023","state":"CA"},
	{"zipCode":"94024","state":"CA"},
	{"zipCode":"94025","state":"CA"},
	{"zipCode":"94026","state":"CA"},
	{"zipCode":"94026","state":"CA"},
	{"zipCode":"94026","state":"CA"},
	{"zipCode":"94027","state":"CA"},
	{"zipCode":"94028","state":"CA"},
	{"zipCode":"94030","state":"CA"},
	{"zipCode":"94035","state":"CA"},
	{"zipCode":"94037","state":"CA"},
	{"zipCode":"94038","state":"CA"},
	{"zipCode":"94039","state":"CA"},
	{"zipCode":"94040","state":"CA"},
	{"zipCode":"94040","state":"CA"},
	{"zipCode":"94041","state":"CA"},
	{"zipCode":"94042","state":"CA"},
	{"zipCode":"94043","state":"CA"},
	{"zipCode":"94044","state":"CA"},
	{"zipCode":"94044","state":"CA"},
	{"zipCode":"94060","state":"CA"},
	{"zipCode":"94061","state":"CA"},
	{"zipCode":"94061","state":"CA"},
	{"zipCode":"94062","state":"CA"},
	{"zipCode":"94062","state":"CA"},
	{"zipCode":"94063","state":"CA"},
	{"zipCode":"94064","state":"CA"},
	{"zipCode":"94065","state":"CA"},
	{"zipCode":"94066","state":"CA"},
	{"zipCode":"94070","state":"CA"},
	{"zipCode":"94074","state":"CA"},
	{"zipCode":"94080","state":"CA"},
	{"zipCode":"94083","state":"CA"},
	{"zipCode":"94083","state":"CA"},
	{"zipCode":"94085","state":"CA"},
	{"zipCode":"94086","state":"CA"},
	{"zipCode":"94086","state":"CA"},
	{"zipCode":"94087","state":"CA"},
	{"zipCode":"94087","state":"CA"},
	{"zipCode":"94088","state":"CA"},
	{"zipCode":"94089","state":"CA"},
	{"zipCode":"94102","state":"CA"},
	{"zipCode":"94103","state":"CA"},
	{"zipCode":"94104","state":"CA"},
	{"zipCode":"94104","state":"CA"},
	{"zipCode":"94105","state":"CA"},
	{"zipCode":"94107","state":"CA"},
	{"zipCode":"94107","state":"CA"},
	{"zipCode":"94108","state":"CA"},
	{"zipCode":"94109","state":"CA"},
	{"zipCode":"94110","state":"CA"},
	{"zipCode":"94111","state":"CA"},
	{"zipCode":"94112","state":"CA"},
	{"zipCode":"94112","state":"CA"},
	{"zipCode":"94114","state":"CA"},
	{"zipCode":"94114","state":"CA"},
	{"zipCode":"94115","state":"CA"},
	{"zipCode":"94116","state":"CA"},
	{"zipCode":"94117","state":"CA"},
	{"zipCode":"94117","state":"CA"},
	{"zipCode":"94118","state":"CA"},
	{"zipCode":"94119","state":"CA"},
	{"zipCode":"94120","state":"CA"},
	{"zipCode":"94121","state":"CA"},
	{"zipCode":"94121","state":"CA"},
	{"zipCode":"94122","state":"CA"},
	{"zipCode":"94122","state":"CA"},
	{"zipCode":"94122","state":"CA"},
	{"zipCode":"94123","state":"CA"},
	{"zipCode":"94124","state":"CA"},
	{"zipCode":"94125","state":"CA"},
	{"zipCode":"94126","state":"CA"},
	{"zipCode":"94127","state":"CA"},
	{"zipCode":"94127","state":"CA"},
	{"zipCode":"94128","state":"CA"},
	{"zipCode":"94129","state":"CA"},
	{"zipCode":"94129","state":"CA"},
	{"zipCode":"94130","state":"CA"},
	{"zipCode":"94131","state":"CA"},
	{"zipCode":"94131","state":"CA"},
	{"zipCode":"94132","state":"CA"},
	{"zipCode":"94132","state":"CA"},
	{"zipCode":"94133","state":"CA"},
	{"zipCode":"94133","state":"CA"},
	{"zipCode":"94134","state":"CA"},
	{"zipCode":"94134","state":"CA"},
	{"zipCode":"94134","state":"CA"},
	{"zipCode":"94140","state":"CA"},
	{"zipCode":"94141","state":"CA"},
	{"zipCode":"94142","state":"CA"},
	{"zipCode":"94146","state":"CA"},
	{"zipCode":"94147","state":"CA"},
	{"zipCode":"94147","state":"CA"},
	{"zipCode":"94158","state":"CA"},
	{"zipCode":"94159","state":"CA"},
	{"zipCode":"94164","state":"CA"},
	{"zipCode":"94172","state":"CA"},
	{"zipCode":"94188","state":"CA"},
	{"zipCode":"94301","state":"CA"},
	{"zipCode":"94302","state":"CA"},
	{"zipCode":"94303","state":"CA"},
	{"zipCode":"94304","state":"CA"},
	{"zipCode":"94304","state":"CA"},
	{"zipCode":"94305","state":"CA"},
	{"zipCode":"94306","state":"CA"},
	{"zipCode":"94306","state":"CA"},
	{"zipCode":"94309","state":"CA"},
	{"zipCode":"94401","state":"CA"},
	{"zipCode":"94401","state":"CA"},
	{"zipCode":"94402","state":"CA"},
	{"zipCode":"94403","state":"CA"},
	{"zipCode":"94403","state":"CA"},
	{"zipCode":"94404","state":"CA"},
	{"zipCode":"94404","state":"CA"},
	{"zipCode":"94501","state":"CA"},
	{"zipCode":"94501","state":"CA"},
	{"zipCode":"94501","state":"CA"},
	{"zipCode":"94502","state":"CA"},
	{"zipCode":"94503","state":"CA"},
	{"zipCode":"94503","state":"CA"},
	{"zipCode":"94505","state":"CA"},
	{"zipCode":"94506","state":"CA"},
	{"zipCode":"94507","state":"CA"},
	{"zipCode":"94508","state":"CA"},
	{"zipCode":"94509","state":"CA"},
	{"zipCode":"94509","state":"CA"},
	{"zipCode":"94510","state":"CA"},
	{"zipCode":"94511","state":"CA"},
	{"zipCode":"94512","state":"CA"},
	{"zipCode":"94513","state":"CA"},
	{"zipCode":"94514","state":"CA"},
	{"zipCode":"94514","state":"CA"},
	{"zipCode":"94515","state":"CA"},
	{"zipCode":"94516","state":"CA"},
	{"zipCode":"94517","state":"CA"},
	{"zipCode":"94518","state":"CA"},
	{"zipCode":"94519","state":"CA"},
	{"zipCode":"94520","state":"CA"},
	{"zipCode":"94521","state":"CA"},
	{"zipCode":"94521","state":"CA"},
	{"zipCode":"94522","state":"CA"},
	{"zipCode":"94523","state":"CA"},
	{"zipCode":"94523","state":"CA"},
	{"zipCode":"94524","state":"CA"},
	{"zipCode":"94525","state":"CA"},
	{"zipCode":"94526","state":"CA"},
	{"zipCode":"94526","state":"CA"},
	{"zipCode":"94528","state":"CA"},
	{"zipCode":"94530","state":"CA"},
	{"zipCode":"94530","state":"CA"},
	{"zipCode":"94530","state":"CA"},
	{"zipCode":"94531","state":"CA"},
	{"zipCode":"94533","state":"CA"},
	{"zipCode":"94533","state":"CA"},
	{"zipCode":"94534","state":"CA"},
	{"zipCode":"94534","state":"CA"},
	{"zipCode":"94535","state":"CA"},
	{"zipCode":"94535","state":"CA"},
	{"zipCode":"94536","state":"CA"},
	{"zipCode":"94536","state":"CA"},
	{"zipCode":"94537","state":"CA"},
	{"zipCode":"94538","state":"CA"},
	{"zipCode":"94539","state":"CA"},
	{"zipCode":"94539","state":"CA"},
	{"zipCode":"94539","state":"CA"},
	{"zipCode":"94540","state":"CA"},
	{"zipCode":"94541","state":"CA"},
	{"zipCode":"94542","state":"CA"},
	{"zipCode":"94543","state":"CA"},
	{"zipCode":"94544","state":"CA"},
	{"zipCode":"94545","state":"CA"},
	{"zipCode":"94546","state":"CA"},
	{"zipCode":"94547","state":"CA"},
	{"zipCode":"94547","state":"CA"},
	{"zipCode":"94548","state":"CA"},
	{"zipCode":"94549","state":"CA"},
	{"zipCode":"94549","state":"CA"},
	{"zipCode":"94550","state":"CA"},
	{"zipCode":"94551","state":"CA"},
	{"zipCode":"94551","state":"CA"},
	{"zipCode":"94552","state":"CA"},
	{"zipCode":"94553","state":"CA"},
	{"zipCode":"94553","state":"CA"},
	{"zipCode":"94555","state":"CA"},
	{"zipCode":"94556","state":"CA"},
	{"zipCode":"94557","state":"CA"},
	{"zipCode":"94558","state":"CA"},
	{"zipCode":"94559","state":"CA"},
	{"zipCode":"94559","state":"CA"},
	{"zipCode":"94560","state":"CA"},
	{"zipCode":"94561","state":"CA"},
	{"zipCode":"94562","state":"CA"},
	{"zipCode":"94563","state":"CA"},
	{"zipCode":"94564","state":"CA"},
	{"zipCode":"94565","state":"CA"},
	{"zipCode":"94565","state":"CA"},
	{"zipCode":"94565","state":"CA"},
	{"zipCode":"94566","state":"CA"},
	{"zipCode":"94567","state":"CA"},
	{"zipCode":"94567","state":"CA"},
	{"zipCode":"94568","state":"CA"},
	{"zipCode":"94568","state":"CA"},
	{"zipCode":"94569","state":"CA"},
	{"zipCode":"94570","state":"CA"},
	{"zipCode":"94571","state":"CA"},
	{"zipCode":"94572","state":"CA"},
	{"zipCode":"94573","state":"CA"},
	{"zipCode":"94574","state":"CA"},
	{"zipCode":"94576","state":"CA"},
	{"zipCode":"94577","state":"CA"},
	{"zipCode":"94577","state":"CA"},
	{"zipCode":"94578","state":"CA"},
	{"zipCode":"94579","state":"CA"},
	{"zipCode":"94579","state":"CA"},
	{"zipCode":"94580","state":"CA"},
	{"zipCode":"94580","state":"CA"},
	{"zipCode":"94581","state":"CA"},
	{"zipCode":"94582","state":"CA"},
	{"zipCode":"94583","state":"CA"},
	{"zipCode":"94585","state":"CA"},
	{"zipCode":"94586","state":"CA"},
	{"zipCode":"94587","state":"CA"},
	{"zipCode":"94587","state":"CA"},
	{"zipCode":"94588","state":"CA"},
	{"zipCode":"94588","state":"CA"},
	{"zipCode":"94589","state":"CA"},
	{"zipCode":"94590","state":"CA"},
	{"zipCode":"94590","state":"CA"},
	{"zipCode":"94591","state":"CA"},
	{"zipCode":"94592","state":"CA"},
	{"zipCode":"94595","state":"CA"},
	{"zipCode":"94595","state":"CA"},
	{"zipCode":"94596","state":"CA"},
	{"zipCode":"94597","state":"CA"},
	{"zipCode":"94598","state":"CA"},
	{"zipCode":"94598","state":"CA"},
	{"zipCode":"94599","state":"CA"},
	{"zipCode":"94599","state":"CA"},
	{"zipCode":"94601","state":"CA"},
	{"zipCode":"94601","state":"CA"},
	{"zipCode":"94602","state":"CA"},
	{"zipCode":"94602","state":"CA"},
	{"zipCode":"94603","state":"CA"},
	{"zipCode":"94604","state":"CA"},
	{"zipCode":"94605","state":"CA"},
	{"zipCode":"94606","state":"CA"},
	{"zipCode":"94607","state":"CA"},
	{"zipCode":"94608","state":"CA"},
	{"zipCode":"94609","state":"CA"},
	{"zipCode":"94610","state":"CA"},
	{"zipCode":"94610","state":"CA"},
	{"zipCode":"94611","state":"CA"},
	{"zipCode":"94612","state":"CA"},
	{"zipCode":"94612","state":"CA"},
	{"zipCode":"94614","state":"CA"},
	{"zipCode":"94618","state":"CA"},
	{"zipCode":"94619","state":"CA"},
	{"zipCode":"94620","state":"CA"},
	{"zipCode":"94621","state":"CA"},
	{"zipCode":"94623","state":"CA"},
	{"zipCode":"94624","state":"CA"},
	{"zipCode":"94661","state":"CA"},
	{"zipCode":"94662","state":"CA"},
	{"zipCode":"94701","state":"CA"},
	{"zipCode":"94702","state":"CA"},
	{"zipCode":"94702","state":"CA"},
	{"zipCode":"94703","state":"CA"},
	{"zipCode":"94703","state":"CA"},
	{"zipCode":"94704","state":"CA"},
	{"zipCode":"94704","state":"CA"},
	{"zipCode":"94705","state":"CA"},
	{"zipCode":"94706","state":"CA"},
	{"zipCode":"94706","state":"CA"},
	{"zipCode":"94707","state":"CA"},
	{"zipCode":"94707","state":"CA"},
	{"zipCode":"94708","state":"CA"},
	{"zipCode":"94709","state":"CA"},
	{"zipCode":"94709","state":"CA"},
	{"zipCode":"94710","state":"CA"},
	{"zipCode":"94712","state":"CA"},
	{"zipCode":"94801","state":"CA"},
	{"zipCode":"94802","state":"CA"},
	{"zipCode":"94803","state":"CA"},
	{"zipCode":"94804","state":"CA"},
	{"zipCode":"94805","state":"CA"},
	{"zipCode":"94805","state":"CA"},
	{"zipCode":"94806","state":"CA"},
	{"zipCode":"94806","state":"CA"},
	{"zipCode":"94807","state":"CA"},
	{"zipCode":"94808","state":"CA"},
	{"zipCode":"94820","state":"CA"},
	{"zipCode":"94901","state":"CA"},
	{"zipCode":"94903","state":"CA"},
	{"zipCode":"94903","state":"CA"},
	{"zipCode":"94903","state":"CA"},
	{"zipCode":"94904","state":"CA"},
	{"zipCode":"94912","state":"CA"},
	{"zipCode":"94913","state":"CA"},
	{"zipCode":"94914","state":"CA"},
	{"zipCode":"94915","state":"CA"},
	{"zipCode":"94920","state":"CA"},
	{"zipCode":"94922","state":"CA"},
	{"zipCode":"94923","state":"CA"},
	{"zipCode":"94924","state":"CA"},
	{"zipCode":"94925","state":"CA"},
	{"zipCode":"94927","state":"CA"},
	{"zipCode":"94928","state":"CA"},
	{"zipCode":"94929","state":"CA"},
	{"zipCode":"94930","state":"CA"},
	{"zipCode":"94931","state":"CA"},
	{"zipCode":"94931","state":"CA"},
	{"zipCode":"94933","state":"CA"},
	{"zipCode":"94937","state":"CA"},
	{"zipCode":"94938","state":"CA"},
	{"zipCode":"94939","state":"CA"},
	{"zipCode":"94940","state":"CA"},
	{"zipCode":"94941","state":"CA"},
	{"zipCode":"94942","state":"CA"},
	{"zipCode":"94945","state":"CA"},
	{"zipCode":"94946","state":"CA"},
	{"zipCode":"94947","state":"CA"},
	{"zipCode":"94948","state":"CA"},
	{"zipCode":"94948","state":"CA"},
	{"zipCode":"94949","state":"CA"},
	{"zipCode":"94950","state":"CA"},
	{"zipCode":"94951","state":"CA"},
	{"zipCode":"94952","state":"CA"},
	{"zipCode":"94953","state":"CA"},
	{"zipCode":"94954","state":"CA"},
	{"zipCode":"94955","state":"CA"},
	{"zipCode":"94956","state":"CA"},
	{"zipCode":"94957","state":"CA"},
	{"zipCode":"94960","state":"CA"},
	{"zipCode":"94963","state":"CA"},
	{"zipCode":"94964","state":"CA"},
	{"zipCode":"94965","state":"CA"},
	{"zipCode":"94966","state":"CA"},
	{"zipCode":"94970","state":"CA"},
	{"zipCode":"94971","state":"CA"},
	{"zipCode":"94972","state":"CA"},
	{"zipCode":"94973","state":"CA"},
	{"zipCode":"94975","state":"CA"},
	{"zipCode":"94976","state":"CA"},
	{"zipCode":"94977","state":"CA"},
	{"zipCode":"94978","state":"CA"},
	{"zipCode":"94979","state":"CA"},
	{"zipCode":"95001","state":"CA"},
	{"zipCode":"95002","state":"CA"},
	{"zipCode":"95003","state":"CA"},
	{"zipCode":"95004","state":"CA"},
	{"zipCode":"95004","state":"CA"},
	{"zipCode":"95005","state":"CA"},
	{"zipCode":"95006","state":"CA"},
	{"zipCode":"95007","state":"CA"},
	{"zipCode":"95008","state":"CA"},
	{"zipCode":"95009","state":"CA"},
	{"zipCode":"95010","state":"CA"},
	{"zipCode":"95011","state":"CA"},
	{"zipCode":"95012","state":"CA"},
	{"zipCode":"95013","state":"CA"},
	{"zipCode":"95014","state":"CA"},
	{"zipCode":"95015","state":"CA"},
	{"zipCode":"95017","state":"CA"},
	{"zipCode":"95018","state":"CA"},
	{"zipCode":"95019","state":"CA"},
	{"zipCode":"95019","state":"CA"},
	{"zipCode":"95020","state":"CA"},
	{"zipCode":"95021","state":"CA"},
	{"zipCode":"95023","state":"CA"},
	{"zipCode":"95024","state":"CA"},
	{"zipCode":"95026","state":"CA"},
	{"zipCode":"95030","state":"CA"},
	{"zipCode":"95031","state":"CA"},
	{"zipCode":"95032","state":"CA"},
	{"zipCode":"95033","state":"CA"},
	{"zipCode":"95035","state":"CA"},
	{"zipCode":"95036","state":"CA"},
	{"zipCode":"95037","state":"CA"},
	{"zipCode":"95038","state":"CA"},
	{"zipCode":"95039","state":"CA"},
	{"zipCode":"95041","state":"CA"},
	{"zipCode":"95042","state":"CA"},
	{"zipCode":"95043","state":"CA"},
	{"zipCode":"95044","state":"CA"},
	{"zipCode":"95045","state":"CA"},
	{"zipCode":"95046","state":"CA"},
	{"zipCode":"95050","state":"CA"},
	{"zipCode":"95051","state":"CA"},
	{"zipCode":"95052","state":"CA"},
	{"zipCode":"95054","state":"CA"},
	{"zipCode":"95055","state":"CA"},
	{"zipCode":"95056","state":"CA"},
	{"zipCode":"95060","state":"CA"},
	{"zipCode":"95061","state":"CA"},
	{"zipCode":"95062","state":"CA"},
	{"zipCode":"95063","state":"CA"},
	{"zipCode":"95064","state":"CA"},
	{"zipCode":"95065","state":"CA"},
	{"zipCode":"95066","state":"CA"},
	{"zipCode":"95067","state":"CA"},
	{"zipCode":"95070","state":"CA"},
	{"zipCode":"95071","state":"CA"},
	{"zipCode":"95073","state":"CA"},
	{"zipCode":"95073","state":"CA"},
	{"zipCode":"95075","state":"CA"},
	{"zipCode":"95076","state":"CA"},
	{"zipCode":"95077","state":"CA"},
	{"zipCode":"95103","state":"CA"},
	{"zipCode":"95106","state":"CA"},
	{"zipCode":"95108","state":"CA"},
	{"zipCode":"95109","state":"CA"},
	{"zipCode":"95110","state":"CA"},
	{"zipCode":"95111","state":"CA"},
	{"zipCode":"95112","state":"CA"},
	{"zipCode":"95113","state":"CA"},
	{"zipCode":"95116","state":"CA"},
	{"zipCode":"95117","state":"CA"},
	{"zipCode":"95118","state":"CA"},
	{"zipCode":"95119","state":"CA"},
	{"zipCode":"95120","state":"CA"},
	{"zipCode":"95121","state":"CA"},
	{"zipCode":"95122","state":"CA"},
	{"zipCode":"95123","state":"CA"},
	{"zipCode":"95124","state":"CA"},
	{"zipCode":"95125","state":"CA"},
	{"zipCode":"95126","state":"CA"},
	{"zipCode":"95127","state":"CA"},
	{"zipCode":"95128","state":"CA"},
	{"zipCode":"95129","state":"CA"},
	{"zipCode":"95130","state":"CA"},
	{"zipCode":"95131","state":"CA"},
	{"zipCode":"95132","state":"CA"},
	{"zipCode":"95133","state":"CA"},
	{"zipCode":"95134","state":"CA"},
	{"zipCode":"95135","state":"CA"},
	{"zipCode":"95136","state":"CA"},
	{"zipCode":"95138","state":"CA"},
	{"zipCode":"95139","state":"CA"},
	{"zipCode":"95140","state":"CA"},
	{"zipCode":"95148","state":"CA"},
	{"zipCode":"95150","state":"CA"},
	{"zipCode":"95151","state":"CA"},
	{"zipCode":"95152","state":"CA"},
	{"zipCode":"95153","state":"CA"},
	{"zipCode":"95154","state":"CA"},
	{"zipCode":"95155","state":"CA"},
	{"zipCode":"95156","state":"CA"},
	{"zipCode":"95157","state":"CA"},
	{"zipCode":"95158","state":"CA"},
	{"zipCode":"95159","state":"CA"},
	{"zipCode":"95160","state":"CA"},
	{"zipCode":"95161","state":"CA"},
	{"zipCode":"95164","state":"CA"},
	{"zipCode":"95170","state":"CA"},
	{"zipCode":"95172","state":"CA"},
	{"zipCode":"95173","state":"CA"},
	{"zipCode":"95201","state":"CA"},
	{"zipCode":"95202","state":"CA"},
	{"zipCode":"95203","state":"CA"},
	{"zipCode":"95204","state":"CA"},
	{"zipCode":"95204","state":"CA"},
	{"zipCode":"95205","state":"CA"},
	{"zipCode":"95206","state":"CA"},
	{"zipCode":"95206","state":"CA"},
	{"zipCode":"95207","state":"CA"},
	{"zipCode":"95208","state":"CA"},
	{"zipCode":"95209","state":"CA"},
	{"zipCode":"95210","state":"CA"},
	{"zipCode":"95212","state":"CA"},
	{"zipCode":"95213","state":"CA"},
	{"zipCode":"95215","state":"CA"},
	{"zipCode":"95219","state":"CA"},
	{"zipCode":"95220","state":"CA"},
	{"zipCode":"95221","state":"CA"},
	{"zipCode":"95222","state":"CA"},
	{"zipCode":"95223","state":"CA"},
	{"zipCode":"95223","state":"CA"},
	{"zipCode":"95223","state":"CA"},
	{"zipCode":"95224","state":"CA"},
	{"zipCode":"95225","state":"CA"},
	{"zipCode":"95226","state":"CA"},
	{"zipCode":"95226","state":"CA"},
	{"zipCode":"95227","state":"CA"},
	{"zipCode":"95228","state":"CA"},
	{"zipCode":"95229","state":"CA"},
	{"zipCode":"95230","state":"CA"},
	{"zipCode":"95231","state":"CA"},
	{"zipCode":"95232","state":"CA"},
	{"zipCode":"95233","state":"CA"},
	{"zipCode":"95234","state":"CA"},
	{"zipCode":"95236","state":"CA"},
	{"zipCode":"95237","state":"CA"},
	{"zipCode":"95240","state":"CA"},
	{"zipCode":"95241","state":"CA"},
	{"zipCode":"95242","state":"CA"},
	{"zipCode":"95245","state":"CA"},
	{"zipCode":"95246","state":"CA"},
	{"zipCode":"95247","state":"CA"},
	{"zipCode":"95248","state":"CA"},
	{"zipCode":"95249","state":"CA"},
	{"zipCode":"95251","state":"CA"},
	{"zipCode":"95252","state":"CA"},
	{"zipCode":"95253","state":"CA"},
	{"zipCode":"95254","state":"CA"},
	{"zipCode":"95255","state":"CA"},
	{"zipCode":"95257","state":"CA"},
	{"zipCode":"95258","state":"CA"},
	{"zipCode":"95267","state":"CA"},
	{"zipCode":"95269","state":"CA"},
	{"zipCode":"95301","state":"CA"},
	{"zipCode":"95303","state":"CA"},
	{"zipCode":"95304","state":"CA"},
	{"zipCode":"95305","state":"CA"},
	{"zipCode":"95306","state":"CA"},
	{"zipCode":"95307","state":"CA"},
	{"zipCode":"95309","state":"CA"},
	{"zipCode":"95310","state":"CA"},
	{"zipCode":"95311","state":"CA"},
	{"zipCode":"95312","state":"CA"},
	{"zipCode":"95313","state":"CA"},
	{"zipCode":"95315","state":"CA"},
	{"zipCode":"95316","state":"CA"},
	{"zipCode":"95317","state":"CA"},
	{"zipCode":"95318","state":"CA"},
	{"zipCode":"95319","state":"CA"},
	{"zipCode":"95320","state":"CA"},
	{"zipCode":"95321","state":"CA"},
	{"zipCode":"95322","state":"CA"},
	{"zipCode":"95323","state":"CA"},
	{"zipCode":"95324","state":"CA"},
	{"zipCode":"95325","state":"CA"},
	{"zipCode":"95326","state":"CA"},
	{"zipCode":"95327","state":"CA"},
	{"zipCode":"95328","state":"CA"},
	{"zipCode":"95329","state":"CA"},
	{"zipCode":"95330","state":"CA"},
	{"zipCode":"95333","state":"CA"},
	{"zipCode":"95334","state":"CA"},
	{"zipCode":"95335","state":"CA"},
	{"zipCode":"95336","state":"CA"},
	{"zipCode":"95337","state":"CA"},
	{"zipCode":"95338","state":"CA"},
	{"zipCode":"95338","state":"CA"},
	{"zipCode":"95340","state":"CA"},
	{"zipCode":"95341","state":"CA"},
	{"zipCode":"95341","state":"CA"},
	{"zipCode":"95344","state":"CA"},
	{"zipCode":"95345","state":"CA"},
	{"zipCode":"95346","state":"CA"},
	{"zipCode":"95347","state":"CA"},
	{"zipCode":"95348","state":"CA"},
	{"zipCode":"95350","state":"CA"},
	{"zipCode":"95351","state":"CA"},
	{"zipCode":"95352","state":"CA"},
	{"zipCode":"95353","state":"CA"},
	{"zipCode":"95354","state":"CA"},
	{"zipCode":"95355","state":"CA"},
	{"zipCode":"95356","state":"CA"},
	{"zipCode":"95357","state":"CA"},
	{"zipCode":"95358","state":"CA"},
	{"zipCode":"95360","state":"CA"},
	{"zipCode":"95361","state":"CA"},
	{"zipCode":"95361","state":"CA"},
	{"zipCode":"95363","state":"CA"},
	{"zipCode":"95364","state":"CA"},
	{"zipCode":"95365","state":"CA"},
	{"zipCode":"95366","state":"CA"},
	{"zipCode":"95367","state":"CA"},
	{"zipCode":"95368","state":"CA"},
	{"zipCode":"95369","state":"CA"},
	{"zipCode":"95370","state":"CA"},
	{"zipCode":"95372","state":"CA"},
	{"zipCode":"95373","state":"CA"},
	{"zipCode":"95374","state":"CA"},
	{"zipCode":"95376","state":"CA"},
	{"zipCode":"95377","state":"CA"},
	{"zipCode":"95378","state":"CA"},
	{"zipCode":"95379","state":"CA"},
	{"zipCode":"95380","state":"CA"},
	{"zipCode":"95381","state":"CA"},
	{"zipCode":"95382","state":"CA"},
	{"zipCode":"95383","state":"CA"},
	{"zipCode":"95385","state":"CA"},
	{"zipCode":"95386","state":"CA"},
	{"zipCode":"95387","state":"CA"},
	{"zipCode":"95388","state":"CA"},
	{"zipCode":"95389","state":"CA"},
	{"zipCode":"95389","state":"CA"},
	{"zipCode":"95389","state":"CA"},
	{"zipCode":"95391","state":"CA"},
	{"zipCode":"95401","state":"CA"},
	{"zipCode":"95402","state":"CA"},
	{"zipCode":"95403","state":"CA"},
	{"zipCode":"95404","state":"CA"},
	{"zipCode":"95405","state":"CA"},
	{"zipCode":"95405","state":"CA"},
	{"zipCode":"95406","state":"CA"},
	{"zipCode":"95407","state":"CA"},
	{"zipCode":"95407","state":"CA"},
	{"zipCode":"95409","state":"CA"},
	{"zipCode":"95410","state":"CA"},
	{"zipCode":"95412","state":"CA"},
	{"zipCode":"95415","state":"CA"},
	{"zipCode":"95416","state":"CA"},
	{"zipCode":"95417","state":"CA"},
	{"zipCode":"95418","state":"CA"},
	{"zipCode":"95419","state":"CA"},
	{"zipCode":"95420","state":"CA"},
	{"zipCode":"95421","state":"CA"},
	{"zipCode":"95422","state":"CA"},
	{"zipCode":"95423","state":"CA"},
	{"zipCode":"95424","state":"CA"},
	{"zipCode":"95425","state":"CA"},
	{"zipCode":"95426","state":"CA"},
	{"zipCode":"95427","state":"CA"},
	{"zipCode":"95428","state":"CA"},
	{"zipCode":"95429","state":"CA"},
	{"zipCode":"95430","state":"CA"},
	{"zipCode":"95431","state":"CA"},
	{"zipCode":"95432","state":"CA"},
	{"zipCode":"95433","state":"CA"},
	{"zipCode":"95435","state":"CA"},
	{"zipCode":"95436","state":"CA"},
	{"zipCode":"95437","state":"CA"},
	{"zipCode":"95439","state":"CA"},
	{"zipCode":"95441","state":"CA"},
	{"zipCode":"95442","state":"CA"},
	{"zipCode":"95443","state":"CA"},
	{"zipCode":"95443","state":"CA"},
	{"zipCode":"95444","state":"CA"},
	{"zipCode":"95445","state":"CA"},
	{"zipCode":"95446","state":"CA"},
	{"zipCode":"95448","state":"CA"},
	{"zipCode":"95449","state":"CA"},
	{"zipCode":"95449","state":"CA"},
	{"zipCode":"95450","state":"CA"},
	{"zipCode":"95451","state":"CA"},
	{"zipCode":"95452","state":"CA"},
	{"zipCode":"95453","state":"CA"},
	{"zipCode":"95454","state":"CA"},
	{"zipCode":"95456","state":"CA"},
	{"zipCode":"95457","state":"CA"},
	{"zipCode":"95458","state":"CA"},
	{"zipCode":"95459","state":"CA"},
	{"zipCode":"95460","state":"CA"},
	{"zipCode":"95461","state":"CA"},
	{"zipCode":"95462","state":"CA"},
	{"zipCode":"95463","state":"CA"},
	{"zipCode":"95464","state":"CA"},
	{"zipCode":"95465","state":"CA"},
	{"zipCode":"95466","state":"CA"},
	{"zipCode":"95467","state":"CA"},
	{"zipCode":"95468","state":"CA"},
	{"zipCode":"95469","state":"CA"},
	{"zipCode":"95470","state":"CA"},
	{"zipCode":"95470","state":"CA"},
	{"zipCode":"95471","state":"CA"},
	{"zipCode":"95472","state":"CA"},
	{"zipCode":"95473","state":"CA"},
	{"zipCode":"95476","state":"CA"},
	{"zipCode":"95480","state":"CA"},
	{"zipCode":"95481","state":"CA"},
	{"zipCode":"95482","state":"CA"},
	{"zipCode":"95485","state":"CA"},
	{"zipCode":"95486","state":"CA"},
	{"zipCode":"95487","state":"CA"},
	{"zipCode":"95488","state":"CA"},
	{"zipCode":"95488","state":"CA"},
	{"zipCode":"95490","state":"CA"},
	{"zipCode":"95492","state":"CA"},
	{"zipCode":"95493","state":"CA"},
	{"zipCode":"95494","state":"CA"},
	{"zipCode":"95497","state":"CA"},
	{"zipCode":"95501","state":"CA"},
	{"zipCode":"95502","state":"CA"},
	{"zipCode":"95502","state":"CA"},
	{"zipCode":"95502","state":"CA"},
	{"zipCode":"95503","state":"CA"},
	{"zipCode":"95511","state":"CA"},
	{"zipCode":"95514","state":"CA"},
	{"zipCode":"95518","state":"CA"},
	{"zipCode":"95519","state":"CA"},
	{"zipCode":"95519","state":"CA"},
	{"zipCode":"95521","state":"CA"},
	{"zipCode":"95524","state":"CA"},
	{"zipCode":"95525","state":"CA"},
	{"zipCode":"95526","state":"CA"},
	{"zipCode":"95527","state":"CA"},
	{"zipCode":"95528","state":"CA"},
	{"zipCode":"95528","state":"CA"},
	{"zipCode":"95531","state":"CA"},
	{"zipCode":"95534","state":"CA"},
	{"zipCode":"95536","state":"CA"},
	{"zipCode":"95537","state":"CA"},
	{"zipCode":"95538","state":"CA"},
	{"zipCode":"95540","state":"CA"},
	{"zipCode":"95542","state":"CA"},
	{"zipCode":"95543","state":"CA"},
	{"zipCode":"95545","state":"CA"},
	{"zipCode":"95546","state":"CA"},
	{"zipCode":"95547","state":"CA"},
	{"zipCode":"95547","state":"CA"},
	{"zipCode":"95548","state":"CA"},
	{"zipCode":"95549","state":"CA"},
	{"zipCode":"95550","state":"CA"},
	{"zipCode":"95551","state":"CA"},
	{"zipCode":"95552","state":"CA"},
	{"zipCode":"95553","state":"CA"},
	{"zipCode":"95554","state":"CA"},
	{"zipCode":"95554","state":"CA"},
	{"zipCode":"95555","state":"CA"},
	{"zipCode":"95556","state":"CA"},
	{"zipCode":"95558","state":"CA"},
	{"zipCode":"95559","state":"CA"},
	{"zipCode":"95560","state":"CA"},
	{"zipCode":"95562","state":"CA"},
	{"zipCode":"95563","state":"CA"},
	{"zipCode":"95564","state":"CA"},
	{"zipCode":"95564","state":"CA"},
	{"zipCode":"95565","state":"CA"},
	{"zipCode":"95565","state":"CA"},
	{"zipCode":"95567","state":"CA"},
	{"zipCode":"95568","state":"CA"},
	{"zipCode":"95569","state":"CA"},
	{"zipCode":"95569","state":"CA"},
	{"zipCode":"95570","state":"CA"},
	{"zipCode":"95571","state":"CA"},
	{"zipCode":"95573","state":"CA"},
	{"zipCode":"95585","state":"CA"},
	{"zipCode":"95587","state":"CA"},
	{"zipCode":"95589","state":"CA"},
	{"zipCode":"95595","state":"CA"},
	{"zipCode":"95601","state":"CA"},
	{"zipCode":"95602","state":"CA"},
	{"zipCode":"95603","state":"CA"},
	{"zipCode":"95604","state":"CA"},
	{"zipCode":"95604","state":"CA"},
	{"zipCode":"95604","state":"CA"},
	{"zipCode":"95604","state":"CA"},
	{"zipCode":"95605","state":"CA"},
	{"zipCode":"95606","state":"CA"},
	{"zipCode":"95608","state":"CA"},
	{"zipCode":"95609","state":"CA"},
	{"zipCode":"95610","state":"CA"},
	{"zipCode":"95611","state":"CA"},
	{"zipCode":"95612","state":"CA"},
	{"zipCode":"95613","state":"CA"},
	{"zipCode":"95614","state":"CA"},
	{"zipCode":"95615","state":"CA"},
	{"zipCode":"95616","state":"CA"},
	{"zipCode":"95617","state":"CA"},
	{"zipCode":"95617","state":"CA"},
	{"zipCode":"95618","state":"CA"},
	{"zipCode":"95619","state":"CA"},
	{"zipCode":"95620","state":"CA"},
	{"zipCode":"95621","state":"CA"},
	{"zipCode":"95621","state":"CA"},
	{"zipCode":"95623","state":"CA"},
	{"zipCode":"95624","state":"CA"},
	{"zipCode":"95625","state":"CA"},
	{"zipCode":"95626","state":"CA"},
	{"zipCode":"95627","state":"CA"},
	{"zipCode":"95628","state":"CA"},
	{"zipCode":"95629","state":"CA"},
	{"zipCode":"95630","state":"CA"},
	{"zipCode":"95631","state":"CA"},
	{"zipCode":"95632","state":"CA"},
	{"zipCode":"95633","state":"CA"},
	{"zipCode":"95634","state":"CA"},
	{"zipCode":"95635","state":"CA"},
	{"zipCode":"95636","state":"CA"},
	{"zipCode":"95637","state":"CA"},
	{"zipCode":"95638","state":"CA"},
	{"zipCode":"95639","state":"CA"},
	{"zipCode":"95640","state":"CA"},
	{"zipCode":"95641","state":"CA"},
	{"zipCode":"95642","state":"CA"},
	{"zipCode":"95645","state":"CA"},
	{"zipCode":"95648","state":"CA"},
	{"zipCode":"95650","state":"CA"},
	{"zipCode":"95651","state":"CA"},
	{"zipCode":"95652","state":"CA"},
	{"zipCode":"95653","state":"CA"},
	{"zipCode":"95654","state":"CA"},
	{"zipCode":"95655","state":"CA"},
	{"zipCode":"95656","state":"CA"},
	{"zipCode":"95658","state":"CA"},
	{"zipCode":"95659","state":"CA"},
	{"zipCode":"95660","state":"CA"},
	{"zipCode":"95661","state":"CA"},
	{"zipCode":"95662","state":"CA"},
	{"zipCode":"95663","state":"CA"},
	{"zipCode":"95664","state":"CA"},
	{"zipCode":"95665","state":"CA"},
	{"zipCode":"95666","state":"CA"},
	{"zipCode":"95667","state":"CA"},
	{"zipCode":"95668","state":"CA"},
	{"zipCode":"95669","state":"CA"},
	{"zipCode":"95670","state":"CA"},
	{"zipCode":"95672","state":"CA"},
	{"zipCode":"95672","state":"CA"},
	{"zipCode":"95673","state":"CA"},
	{"zipCode":"95674","state":"CA"},
	{"zipCode":"95675","state":"CA"},
	{"zipCode":"95676","state":"CA"},
	{"zipCode":"95677","state":"CA"},
	{"zipCode":"95678","state":"CA"},
	{"zipCode":"95678","state":"CA"},
	{"zipCode":"95679","state":"CA"},
	{"zipCode":"95680","state":"CA"},
	{"zipCode":"95681","state":"CA"},
	{"zipCode":"95681","state":"CA"},
	{"zipCode":"95682","state":"CA"},
	{"zipCode":"95683","state":"CA"},
	{"zipCode":"95684","state":"CA"},
	{"zipCode":"95685","state":"CA"},
	{"zipCode":"95686","state":"CA"},
	{"zipCode":"95687","state":"CA"},
	{"zipCode":"95688","state":"CA"},
	{"zipCode":"95689","state":"CA"},
	{"zipCode":"95690","state":"CA"},
	{"zipCode":"95691","state":"CA"},
	{"zipCode":"95692","state":"CA"},
	{"zipCode":"95693","state":"CA"},
	{"zipCode":"95694","state":"CA"},
	{"zipCode":"95695","state":"CA"},
	{"zipCode":"95696","state":"CA"},
	{"zipCode":"95696","state":"CA"},
	{"zipCode":"95697","state":"CA"},
	{"zipCode":"95698","state":"CA"},
	{"zipCode":"95699","state":"CA"},
	{"zipCode":"95701","state":"CA"},
	{"zipCode":"95703","state":"CA"},
	{"zipCode":"95709","state":"CA"},
	{"zipCode":"95712","state":"CA"},
	{"zipCode":"95713","state":"CA"},
	{"zipCode":"95713","state":"CA"},
	{"zipCode":"95714","state":"CA"},
	{"zipCode":"95715","state":"CA"},
	{"zipCode":"95717","state":"CA"},
	{"zipCode":"95720","state":"CA"},
	{"zipCode":"95721","state":"CA"},
	{"zipCode":"95722","state":"CA"},
	{"zipCode":"95724","state":"CA"},
	{"zipCode":"95726","state":"CA"},
	{"zipCode":"95728","state":"CA"},
	{"zipCode":"95735","state":"CA"},
	{"zipCode":"95736","state":"CA"},
	{"zipCode":"95741","state":"CA"},
	{"zipCode":"95742","state":"CA"},
	{"zipCode":"95746","state":"CA"},
	{"zipCode":"95746","state":"CA"},
	{"zipCode":"95747","state":"CA"},
	{"zipCode":"95757","state":"CA"},
	{"zipCode":"95758","state":"CA"},
	{"zipCode":"95758","state":"CA"},
	{"zipCode":"95759","state":"CA"},
	{"zipCode":"95762","state":"CA"},
	{"zipCode":"95763","state":"CA"},
	{"zipCode":"95765","state":"CA"},
	{"zipCode":"95776","state":"CA"},
	{"zipCode":"95776","state":"CA"},
	{"zipCode":"95798","state":"CA"},
	{"zipCode":"95811","state":"CA"},
	{"zipCode":"95812","state":"CA"},
	{"zipCode":"95813","state":"CA"},
	{"zipCode":"95814","state":"CA"},
	{"zipCode":"95815","state":"CA"},
	{"zipCode":"95816","state":"CA"},
	{"zipCode":"95817","state":"CA"},
	{"zipCode":"95818","state":"CA"},
	{"zipCode":"95818","state":"CA"},
	{"zipCode":"95819","state":"CA"},
	{"zipCode":"95819","state":"CA"},
	{"zipCode":"95820","state":"CA"},
	{"zipCode":"95821","state":"CA"},
	{"zipCode":"95822","state":"CA"},
	{"zipCode":"95823","state":"CA"},
	{"zipCode":"95824","state":"CA"},
	{"zipCode":"95825","state":"CA"},
	{"zipCode":"95826","state":"CA"},
	{"zipCode":"95827","state":"CA"},
	{"zipCode":"95828","state":"CA"},
	{"zipCode":"95829","state":"CA"},
	{"zipCode":"95830","state":"CA"},
	{"zipCode":"95831","state":"CA"},
	{"zipCode":"95832","state":"CA"},
	{"zipCode":"95833","state":"CA"},
	{"zipCode":"95834","state":"CA"},
	{"zipCode":"95834","state":"CA"},
	{"zipCode":"95835","state":"CA"},
	{"zipCode":"95836","state":"CA"},
	{"zipCode":"95837","state":"CA"},
	{"zipCode":"95838","state":"CA"},
	{"zipCode":"95841","state":"CA"},
	{"zipCode":"95842","state":"CA"},
	{"zipCode":"95843","state":"CA"},
	{"zipCode":"95851","state":"CA"},
	{"zipCode":"95852","state":"CA"},
	{"zipCode":"95853","state":"CA"},
	{"zipCode":"95860","state":"CA"},
	{"zipCode":"95864","state":"CA"},
	{"zipCode":"95865","state":"CA"},
	{"zipCode":"95866","state":"CA"},
	{"zipCode":"95899","state":"CA"},
	{"zipCode":"95901","state":"CA"},
	{"zipCode":"95901","state":"CA"},
	{"zipCode":"95903","state":"CA"},
	{"zipCode":"95903","state":"CA"},
	{"zipCode":"95910","state":"CA"},
	{"zipCode":"95912","state":"CA"},
	{"zipCode":"95913","state":"CA"},
	{"zipCode":"95914","state":"CA"},
	{"zipCode":"95915","state":"CA"},
	{"zipCode":"95916","state":"CA"},
	{"zipCode":"95917","state":"CA"},
	{"zipCode":"95918","state":"CA"},
	{"zipCode":"95919","state":"CA"},
	{"zipCode":"95920","state":"CA"},
	{"zipCode":"95922","state":"CA"},
	{"zipCode":"95923","state":"CA"},
	{"zipCode":"95924","state":"CA"},
	{"zipCode":"95925","state":"CA"},
	{"zipCode":"95926","state":"CA"},
	{"zipCode":"95927","state":"CA"},
	{"zipCode":"95927","state":"CA"},
	{"zipCode":"95928","state":"CA"},
	{"zipCode":"95930","state":"CA"},
	{"zipCode":"95932","state":"CA"},
	{"zipCode":"95934","state":"CA"},
	{"zipCode":"95935","state":"CA"},
	{"zipCode":"95936","state":"CA"},
	{"zipCode":"95937","state":"CA"},
	{"zipCode":"95938","state":"CA"},
	{"zipCode":"95939","state":"CA"},
	{"zipCode":"95940","state":"CA"},
	{"zipCode":"95941","state":"CA"},
	{"zipCode":"95942","state":"CA"},
	{"zipCode":"95943","state":"CA"},
	{"zipCode":"95944","state":"CA"},
	{"zipCode":"95945","state":"CA"},
	{"zipCode":"95946","state":"CA"},
	{"zipCode":"95947","state":"CA"},
	{"zipCode":"95947","state":"CA"},
	{"zipCode":"95948","state":"CA"},
	{"zipCode":"95949","state":"CA"},
	{"zipCode":"95950","state":"CA"},
	{"zipCode":"95951","state":"CA"},
	{"zipCode":"95953","state":"CA"},
	{"zipCode":"95954","state":"CA"},
	{"zipCode":"95954","state":"CA"},
	{"zipCode":"95955","state":"CA"},
	{"zipCode":"95956","state":"CA"},
	{"zipCode":"95957","state":"CA"},
	{"zipCode":"95958","state":"CA"},
	{"zipCode":"95959","state":"CA"},
	{"zipCode":"95960","state":"CA"},
	{"zipCode":"95961","state":"CA"},
	{"zipCode":"95962","state":"CA"},
	{"zipCode":"95963","state":"CA"},
	{"zipCode":"95965","state":"CA"},
	{"zipCode":"95965","state":"CA"},
	{"zipCode":"95966","state":"CA"},
	{"zipCode":"95966","state":"CA"},
	{"zipCode":"95967","state":"CA"},
	{"zipCode":"95968","state":"CA"},
	{"zipCode":"95969","state":"CA"},
	{"zipCode":"95970","state":"CA"},
	{"zipCode":"95971","state":"CA"},
	{"zipCode":"95971","state":"CA"},
	{"zipCode":"95972","state":"CA"},
	{"zipCode":"95973","state":"CA"},
	{"zipCode":"95974","state":"CA"},
	{"zipCode":"95975","state":"CA"},
	{"zipCode":"95977","state":"CA"},
	{"zipCode":"95978","state":"CA"},
	{"zipCode":"95979","state":"CA"},
	{"zipCode":"95980","state":"CA"},
	{"zipCode":"95981","state":"CA"},
	{"zipCode":"95982","state":"CA"},
	{"zipCode":"95983","state":"CA"},
	{"zipCode":"95984","state":"CA"},
	{"zipCode":"95986","state":"CA"},
	{"zipCode":"95987","state":"CA"},
	{"zipCode":"95988","state":"CA"},
	{"zipCode":"95991","state":"CA"},
	{"zipCode":"95992","state":"CA"},
	{"zipCode":"95992","state":"CA"},
	{"zipCode":"95993","state":"CA"},
	{"zipCode":"96001","state":"CA"},
	{"zipCode":"96002","state":"CA"},
	{"zipCode":"96003","state":"CA"},
	{"zipCode":"96006","state":"CA"},
	{"zipCode":"96007","state":"CA"},
	{"zipCode":"96008","state":"CA"},
	{"zipCode":"96009","state":"CA"},
	{"zipCode":"96010","state":"CA"},
	{"zipCode":"96011","state":"CA"},
	{"zipCode":"96013","state":"CA"},
	{"zipCode":"96014","state":"CA"},
	{"zipCode":"96015","state":"CA"},
	{"zipCode":"96016","state":"CA"},
	{"zipCode":"96017","state":"CA"},
	{"zipCode":"96019","state":"CA"},
	{"zipCode":"96020","state":"CA"},
	{"zipCode":"96021","state":"CA"},
	{"zipCode":"96022","state":"CA"},
	{"zipCode":"96023","state":"CA"},
	{"zipCode":"96024","state":"CA"},
	{"zipCode":"96025","state":"CA"},
	{"zipCode":"96027","state":"CA"},
	{"zipCode":"96027","state":"CA"},
	{"zipCode":"96028","state":"CA"},
	{"zipCode":"96029","state":"CA"},
	{"zipCode":"96031","state":"CA"},
	{"zipCode":"96032","state":"CA"},
	{"zipCode":"96033","state":"CA"},
	{"zipCode":"96034","state":"CA"},
	{"zipCode":"96035","state":"CA"},
	{"zipCode":"96037","state":"CA"},
	{"zipCode":"96038","state":"CA"},
	{"zipCode":"96039","state":"CA"},
	{"zipCode":"96040","state":"CA"},
	{"zipCode":"96041","state":"CA"},
	{"zipCode":"96044","state":"CA"},
	{"zipCode":"96046","state":"CA"},
	{"zipCode":"96047","state":"CA"},
	{"zipCode":"96048","state":"CA"},
	{"zipCode":"96049","state":"CA"},
	{"zipCode":"96050","state":"CA"},
	{"zipCode":"96051","state":"CA"},
	{"zipCode":"96052","state":"CA"},
	{"zipCode":"96054","state":"CA"},
	{"zipCode":"96055","state":"CA"},
	{"zipCode":"96056","state":"CA"},
	{"zipCode":"96057","state":"CA"},
	{"zipCode":"96057","state":"CA"},
	{"zipCode":"96058","state":"CA"},
	{"zipCode":"96059","state":"CA"},
	{"zipCode":"96061","state":"CA"},
	{"zipCode":"96062","state":"CA"},
	{"zipCode":"96063","state":"CA"},
	{"zipCode":"96064","state":"CA"},
	{"zipCode":"96065","state":"CA"},
	{"zipCode":"96067","state":"CA"},
	{"zipCode":"96068","state":"CA"},
	{"zipCode":"96069","state":"CA"},
	{"zipCode":"96069","state":"CA"},
	{"zipCode":"96071","state":"CA"},
	{"zipCode":"96073","state":"CA"},
	{"zipCode":"96074","state":"CA"},
	{"zipCode":"96075","state":"CA"},
	{"zipCode":"96075","state":"CA"},
	{"zipCode":"96076","state":"CA"},
	{"zipCode":"96078","state":"CA"},
	{"zipCode":"96079","state":"CA"},
	{"zipCode":"96080","state":"CA"},
	{"zipCode":"96084","state":"CA"},
	{"zipCode":"96085","state":"CA"},
	{"zipCode":"96086","state":"CA"},
	{"zipCode":"96087","state":"CA"},
	{"zipCode":"96088","state":"CA"},
	{"zipCode":"96089","state":"CA"},
	{"zipCode":"96090","state":"CA"},
	{"zipCode":"96091","state":"CA"},
	{"zipCode":"96092","state":"CA"},
	{"zipCode":"96093","state":"CA"},
	{"zipCode":"96094","state":"CA"},
	{"zipCode":"96095","state":"CA"},
	{"zipCode":"96096","state":"CA"},
	{"zipCode":"96097","state":"CA"},
	{"zipCode":"96099","state":"CA"},
	{"zipCode":"96101","state":"CA"},
	{"zipCode":"96103","state":"CA"},
	{"zipCode":"96103","state":"CA"},
	{"zipCode":"96104","state":"CA"},
	{"zipCode":"96105","state":"CA"},
	{"zipCode":"96106","state":"CA"},
	{"zipCode":"96107","state":"CA"},
	{"zipCode":"96108","state":"CA"},
	{"zipCode":"96109","state":"CA"},
	{"zipCode":"96110","state":"CA"},
	{"zipCode":"96112","state":"CA"},
	{"zipCode":"96113","state":"CA"},
	{"zipCode":"96114","state":"CA"},
	{"zipCode":"96115","state":"CA"},
	{"zipCode":"96116","state":"CA"},
	{"zipCode":"96117","state":"CA"},
	{"zipCode":"96118","state":"CA"},
	{"zipCode":"96119","state":"CA"},
	{"zipCode":"96120","state":"CA"},
	{"zipCode":"96121","state":"CA"},
	{"zipCode":"96122","state":"CA"},
	{"zipCode":"96123","state":"CA"},
	{"zipCode":"96124","state":"CA"},
	{"zipCode":"96125","state":"CA"},
	{"zipCode":"96126","state":"CA"},
	{"zipCode":"96127","state":"CA"},
	{"zipCode":"96128","state":"CA"},
	{"zipCode":"96130","state":"CA"},
	{"zipCode":"96130","state":"CA"},
	{"zipCode":"96132","state":"CA"},
	{"zipCode":"96133","state":"CA"},
	{"zipCode":"96134","state":"CA"},
	{"zipCode":"96135","state":"CA"},
	{"zipCode":"96137","state":"CA"},
	{"zipCode":"96140","state":"CA"},
	{"zipCode":"96141","state":"CA"},
	{"zipCode":"96142","state":"CA"},
	{"zipCode":"96143","state":"CA"},
	{"zipCode":"96145","state":"CA"},
	{"zipCode":"96146","state":"CA"},
	{"zipCode":"96148","state":"CA"},
	{"zipCode":"96150","state":"CA"},
	{"zipCode":"96151","state":"CA"},
	{"zipCode":"96155","state":"CA"},
	{"zipCode":"96158","state":"CA"},
	{"zipCode":"96160","state":"CA"},
	{"zipCode":"96161","state":"CA"},
	{"zipCode":"96162","state":"CA"}
];

module.exports = { zipCodesUsps }
