import React, { useState } from 'react';
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router';
import {
  Form,
  Button,
  Container,
  Col,
  Row
} from "react-bootstrap";
import { generateRoute } from '../services/routes';
import {
  getContent
} from '../services/language';

function ChooseAppointmentTypePage(props) {
  const history = useHistory();
  const params = useParams();
  const queryString = useLocation().search;

  const handleSetAppointmentType = (e, type) => {
    e.preventDefault();
    props.setAppState({ appointmentType: type });
    const route = generateRoute(params, "utility", null, queryString);
    history.push(route);
  }
  let isInPerson = false;
  if (!!props.appState && !!props.appState.appointmentType && props.appState.appointmentType === 'in-person') {
    isInPerson = true;
  }
  return (
    <Container className="mt-5 landingWidth">
      <Row className="">
        <Col className="text-center">
          {isInPerson && (<><h2 className="text-3-desktop text-black display-4">{getContent('bookInPersonAppointment', props.appState.language)}</h2>
          <Button className="mButton" variant="primary" onClick={e => handleSetAppointmentType(e, 'in-person')}>
            {getContent('next', props.appState.language)}
          </Button></>)}
          {!isInPerson && (<>
          <h2 className="text-3-desktop text-black display-4">{getContent('bookOnlineAppointment', props.appState.language)}</h2>
          <Button className="mButton" variant="primary" onClick={e => handleSetAppointmentType(e, 'online')}>
            {getContent('next', props.appState.language)}
          </Button>
          </>)}
        </Col>
      </Row>
    </Container>

  )
}

export default ChooseAppointmentTypePage;
