import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Col,
  Row
} from "react-bootstrap";
function ThankYouInPersonPage() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const start = query.get("start");
  if (!!start) {
    return (
      <Container>
        <Row className="mt-20">
          <Col>
            <h5 className="center lh-3 text-muted display-4 text-black">Congratulations, your appointment has been scheduled for <span className="text-blue">{start}</span></h5>
          </Col>
        </Row>
      </Container>
    )
  } else {
    return (
      <Container>
        <Row className="mt-20">
          <Col className="p-50">
            <h5 className="center lh-3 text-muted display-4 text-black">Congratulations, your appointment has been scheduled!</h5>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ThankYouInPersonPage;
