export const generateRoute = (params, type, location, queryString = '', providedZipCode) => {
  let lat = 'lat';
  let lng = 'lng';
  let zipCode = 'zipcode';
  if (!!location && !!location.lat) {
    lat = location.lat;
  } else if (!!params && !!params.lat) {
    lat = params.lat;
  }
  if (!!location && !!location.lng) {
    lng = location.lng;
  } else if (!!params && !!params.lng) {
    lng = params.lng;
  }
  if (!!providedZipCode) {
    zipCode = providedZipCode;
  } else if (!!params && !!params.zipCode) {
    zipCode = params.zipCode;
  }

  const leadProvider = !!params && !!params.leadProvider ? params.leadProvider : '0';
  const leadId = !!params && !!params.leadId ? params.leadId : '0';
  if (type === 'landing') {
    return `/${leadProvider}/${leadId}${queryString}`
  }
  if(!!leadProvider && leadProvider === "jayden-smith") {
    if(type === "in-person" || type === "online") {
      return `/${leadProvider}/${leadId}/channel-partners/${lat}/${lng}/${zipCode}${queryString}`
    }
  }
  return `/${leadProvider}/${leadId}/${type}/${lat}/${lng}/${zipCode}${queryString}`
}

export const getIsCustomerFacingPageByParams = (params) => {
  if (!!params && !!params.leadProvider) {
    console.log(params);
    if (['0'].indexOf(params.leadProvider) > -1) {
      return true;
    }
  }
}
