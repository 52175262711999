import { zipCodesUsps } from './zipCodesUsps';
import { approvedZipCodes } from './approvedZipCodes';
import { inPersonZipCodes } from './inPersonZipCodes';
import { approvedZipCodesWithData } from './approvedZipCodesWithData';

export const getIsInPersonZip = (providedZipCode, language) => {

  let isInPersonZip = false;
  let region = '';
  const zipCode = parseInt(providedZipCode);
  if (!!zipCode) {
    const match = inPersonZipCodes.find(item => item.zipCode == zipCode);
    if (!!match) {
      if (!!match[language] && !!match[language].calendarUrl) {
        isInPersonZip = true;
        region = match.region;
      }
    }
  }

  return {
    isInPersonZip,
    region
  };
}

export const getIsZipInServiceArea = (zipCode) => {
  if (!!zipCode) {
    return approvedZipCodes.includes(zipCode);
  }
}

export const getIsValidZip = (userEnteredZip) => {
  let isValidZip = false;
  if (!!userEnteredZip) {
    const zip = userEnteredZip.replace(/\D/g, "");
    if (!!zip) {
      if (zip.length === 5) {
        isValidZip = true;
      }
    }
  }
  return isValidZip;
}

export const getIsZipCodeHouston = zipCode => {
  if (!!zipCode) {
    const result = approvedZipCodesWithData.find(item => item.zipCode == zipCode);
    if(!!result && !!result.city && result.city.toLowerCase() === "houston") {
      return true;
    } else if (!!result && !!result.region) {
      if(result.region === "HOU") {
        return true;
      }
    }
  }
}

export const getIsZipCodeTexasAndInServiceArea = zipCode => {
  if(!!zipCode) {
    const result = approvedZipCodesWithData.find(item => item.zipCode == zipCode);
    if(!!result && !!result.state && result.state.toLowerCase() === "texas") {
      return true;
    }
  }
}

export const getIsZipCodeCaliforniaAndInServiceArea = zipCode => {
  if(!!zipCode) {
    const result = approvedZipCodesWithData.find(item => item.zipCode == zipCode);
    if(!!result && !!result.state && result.state.toLowerCase() === "california") {
      return true;
    } else if (!!result && !!result.region && result.region === "HPSC") {
      return true;
    }
  }
}

export const getIsZipCodeNevadaAndInServiceArea = zipCode => {
  if(!!zipCode) {
    const result = approvedZipCodesWithData.find(item => item.zipCode == zipCode);
    if (!!result && !!result.region && result.region === "NEV") {
      return true;
    }
  }
}

export const getIsValidAreaForGenerationSolar = zipCode => {
  if(zipCode) {
    const result = approvedZipCodesWithData.find(item => item.zipCode == zipCode);
    if(!!result) {
      if(!!result.region && result.region === "GS") {
        return true;
      }
    }
  }
}

export const getStateFromZip = zip => {
  let state = null;
  if (zip) {
    const zipInfo = zipCodesUsps.find(item => item.zipCode == zip);
    if (zipInfo) {
      return zipInfo.state;
    } else {
      if (['75072'].indexOf(zip) > -1) {
        return "TX";
      }
    }
  }
  return state;
}

export const getIsStateOutOfCaButInServiceArea = state => {
  if (!!state) {
    if (state === "TX" || state === "CO" || state === "UT") {
      return true;
    }
  }
}
