export const getZipFromAddressComponents = (addressComponents) => {
  let zip = '';
  if (!!addressComponents && addressComponents.length > 0) {
    addressComponents.forEach(addressComponent => {
      if (!!addressComponent && !!addressComponent.types && addressComponent.types.length > 0) {
        if (addressComponent.types.includes("postal_code")) {
          zip = addressComponent.long_name || addressComponent.short_name;
        }
      }
    });
    return zip;
  }
}