import React, { useState } from 'react';
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router';
import {
  Form,
  Button,
  Container,
  Col,
  Row
} from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { generateRoute } from '../services/routes';
import {
  getContent
} from '../services/language';
import { getUserOptionsBasedOnParams } from '../services/users';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function ChooseUserPage(props) {
  let defaultUser = '';
  let defaultEnteredUser = '';
  if (!!props.appState) {
    defaultUser = props.appState.user;
    defaultEnteredUser = props.appState.enteredUser;
  }
  const initialState = {
    user: defaultUser,
    enteredUser: defaultEnteredUser
  }

  const [state, setState] = useState(initialState);

  const history = useHistory();
  const params = useParams();
  const queryString = useLocation().search;

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleUpdateUser = value => {
    setState({
      ...state,
      user: value
    });
  }

  const handleGoBack = e => {
    e.preventDefault();
    const route = generateRoute(params, "zip", null, queryString);
    history.push(route);
  }

  const handleNext = e => {
    e.preventDefault();
    const user = state.user;
    let enteredUser = state.enteredUser;
    if (!!user && JSON.stringify(user) !== '["other"]') {
      enteredUser = '';
    }
    props.setAppState({ user, enteredUser });
    const route = generateRoute(params, "utility", null, queryString);
    history.push(route);
  }

  const {
    user,
    enteredUser
  } = state;

  const userOptions = getUserOptionsBasedOnParams(params, true);

  let allowSubmission = false;
  if (!!user) {
    if (JSON.stringify(user) === '["other"]') {
      if (!!enteredUser) {
        allowSubmission = true;
      }
    } else {
      allowSubmission = true;
    }
  }

  return (
    <Container className="mt-5 landingWidth">
      <Row className="">
        <Col className="text-center">
          <Form onSubmit={handleNext}>
            <Form.Group controlId="user">
              <Typeahead
                id="basic-typeahead-single"
                labelKey="name"
                name="user"
                onChange={handleUpdateUser}
                options={userOptions}
                placeholder={getContent('whoAreYou', props.appState.language)}
                selected={user}
              />
            </Form.Group>
            {!!user && JSON.stringify(user) === '["other"]' && <Form.Group className="mt-20" controlId="enteredUser">
              <Form.Label>{getContent('enterName', props.appState.language)}</Form.Label>
              <Form.Control
                className="text-center"
                type="text"
                placeholder={getContent('name', props.appState.language)}
                name="enteredUser"
                onChange={handleChange}
                value={enteredUser}
              />
            </Form.Group>}
            <div className="flexCenter spaceBetween mt-20">
              <Button className="btn btn-secondary" variant="outline" onClick={handleGoBack}>
                {getContent('back', props.appState.language)}
              </Button>
              <Button variant="primary" type="submit" disabled={!allowSubmission}>
                {getContent('next', props.appState.language)}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>

  )
}

export default ChooseUserPage;
