import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  useHistory,
  useParams
} from 'react-router';
import {
  Form,
  Button,
  Container,
  Col,
  Row
} from "react-bootstrap";
import {
  generateRoute
} from '../services/routes';
import {
  getContent
} from '../services/language';

function LandingPage(props) {
  const history = useHistory();
  const params = useParams();
  const queryString = useLocation().search;

  const handleSubmit = (e, language) => {
    e.preventDefault();
    props.setAppState({
      language
    });
    const route = generateRoute(params, 'zip', null, queryString);
    history.push(route);
  }

  return (
    <Container className="mt-5-desktop center landingWidth">
      <Row className="center text-center">
        <Col>
          <p className="text-black display-4 center">{getContent('chooseLanguage', props.appState.language)}</p>
          <Container>
            <Row className="responsiveButtonContainer">
              <Button className="btn btn-primary mt-20" variant="primary" onClick={e => handleSubmit(e, 'english')}>
                English
              </Button>
              <Button className="btn btn-primary mt-20" variant="outline" onClick={e => handleSubmit(e, 'spanish')}>
                Español
              </Button>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default LandingPage;
