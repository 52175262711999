import React, { useState } from 'react';
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router';
import {
  Form,
  Button,
  Container,
  Col,
  Row
} from "react-bootstrap";
import { generateRoute } from '../services/routes';
import {
  getContent
} from '../services/language';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function TimezonePage(props) {
  const history = useHistory();
  const params = useParams();
  const queryString = useLocation().search;

  function useQuery() {
    return new URLSearchParams(queryString);
  }
  const query = useQuery();

  let defaultTimezone = '';
  if (!!props.appState) {
    defaultTimezone = props.appState.timezone;
  }
  if (!defaultTimezone) {
    const timezoneFromUrl = query.get("timezone");
    if (!!timezoneFromUrl) {
      let parsedTimezoneFromUrl = decodeURIComponent(timezoneFromUrl);
      if(parsedTimezoneFromUrl === "America/Los_Angeles") {
        parsedTimezoneFromUrl = "USA/Pacific";
      } else if (parsedTimezoneFromUrl === "America/Chicago") {
        parsedTimezoneFromUrl = "USA/Central";
      } else if (parsedTimezoneFromUrl === "US/Mountain" || parsedTimezoneFromUrl === "America/Denver") {
        parsedTimezoneFromUrl = "USA/Mountain";
      } else if (parsedTimezoneFromUrl === "America/Phoenix") {
        parsedTimezoneFromUrl = "USA/Arizona";
      }
      defaultTimezone = parsedTimezoneFromUrl;
    }
  }
  const initialState = {
    timezone: defaultTimezone,
  }

  const [state, setState] = useState(initialState);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleGoBack = e => {
    e.preventDefault();
    const page = "address";
    const route = generateRoute(params, page, null, queryString);
    history.push(route);
  }

  const handleNext = e => {
    e.preventDefault();
    let route;
    if (!!props.appState && !!props.appState.appointmentType) {
      route = props.appState.appointmentType;
    } else {
      route = "online";
    }
    route = generateRoute(params, route, { lat: props.appState.lat, lng: props.appState.lng }, queryString);
    history.push(route);
  }

  const {
    timezone
  } = state;

  let allowSubmission = false;
  if (!!timezone) {
    allowSubmission = true;
  }

  return (
    <Container className="mt-5">
      <Row className="rowWide">
        <Col className="text-center">
          <Form className="maxWidth-90" onSubmit={handleNext} value={timezone}>
            <Form.Group controlId="timezone">
              <Form.Label>{getContent('enterTimezone', props.appState.language)}</Form.Label>
              <Form.Select
                className="select-center"
                value={timezone}
                name="timezone"
                onChange={handleChange}
              >
                <option id=""></option>
                <option
                  id="USA/Pacific"
                >USA/Pacific</option>
                <option
                  id="USA/Mountain"
                >USA/Mountain</option>
                <option
                  id="USA/Arizona"
                >USA/Arizona</option>
                <option
                  id="USA/Central"
                >US/Central</option>
                <option
                  id="Other"
                >Other</option>
              </Form.Select>
            </Form.Group>
            <div className="flexCenter spaceBetween mt-20">
              <Button className="btn btn-secondary" variant="outline" onClick={handleGoBack}>
                {getContent('back', props.appState.language)}
              </Button>
              <Button variant="primary" type="submit" disabled={!allowSubmission}>
                {getContent('next', props.appState.language)}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>

  )
}

export default TimezonePage;
